import tx3VeraDevOps from "../../../../images/company-logo.png";
import {
  DetailsQueryParams,
  RecordAttachment,
} from "../../../interfaces/DetailsPage";
import PrintVIewLinkedRecord from "./PrintVIewLinkedRecord";
import PrintViewSystemDetails from "./PrintViewSystemDetails";
import PrintViewRejectionReason from "./PrintViewRejectionReason";
import PrintViewSubRecords from "./PrintViewSubRecords";
import { shouldDisplayAuthor } from "../../../../helpers/entity-details.helper";
import { unescape } from "lodash";
import PrintViewVeraDetails from "./PrintViewVeraDetails";
import PrintViewSignatures from "./PrintViewSignatures";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Loader from "../../../modules/Loader";
import { useEffect } from "react";
import { useActions } from "../../../../hooks/useActions";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../constants/general.constants";
import { downloadFile } from "../../../../helpers/file.helper";
import PrintViewContentWrapper from "./PrintViewContentWrapper";

const PrintView = () => {
  const { fetchRecordDetails, logout } = useActions();
  const { data } = useTypedSelector((state) => state.recordDetails);
  const location = useLocation();

  const match = matchPath<DetailsQueryParams>(location.pathname, {
    path: [AppRoutes.DetailsPage, AppRoutes.RevisionPage],
  });

  const { routeId, recordId, revision, menuSelector } = match?.params ?? {};

  const history = useHistory();

  const getAttachmentsList = (attachments: RecordAttachment[]) =>
    attachments.map((attachment, index) => {
      return `${attachment.name}${
        index === attachments.length - 1 ? "" : ", "
      }`;
    });

  const getAttachmentTable = (attachment: RecordAttachment) => (
    <div className="mb-2">
      <img alt={attachment.name} className="mw-100" src={attachment.url} />
      <caption>{attachment.name}</caption>
    </div>
  );

  const getBackgroudStatusText = (status: string) => {
    switch (status) {
      case "Approved":
        return "";
      case "Superseded":
        return "Superseded";
      case "Rejected":
        return "Rejected";
      default:
        return "DRAFT";
    }
  };

  const handleOnClickDownload = async (e: any) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLAnchorElement;

    downloadFile({ name: target.download, url: target.href }).catch(
      (response) => {
        if (response.status === 401) {
          logout(history);
        }
      },
    );
  };

  useEffect(() => {
    fetchRecordDetails(history, routeId, recordId, revision, menuSelector);
  }, []);

  if (!data || !data.fullRecord) {
    return <Loader message={"Loading, please wait..."} />;
  }

  const { fullRecord, rejectionReason, route } = data;

  const shouldDisplayAuthorField = shouldDisplayAuthor(fullRecord);

  return (
    <>
      <PrintViewContentWrapper>
        <div aria-label="print-view-tab" className="row" id="print-view-header">
          <img
            alt="tx3VeraDevOps"
            className="d-inline-block align-top mr-2"
            src={tx3VeraDevOps}
            style={{ height: "30px" }}
          />
          <button
            className="btn btn-primary btn-sm text-white mr-4 no-print"
            id="btnPrint"
            onClick={() => window.print()}
          >
            <i className="fa fa-print" /> Print
          </button>
        </div>
        <div className="content">
          <div className="row mt-3">
            <h2>
              <p className="text-wrap">{unescape(fullRecord.name)}</p>
            </h2>
          </div>

          <div className="mt-3" id="content">
            <PrintViewVeraDetails
              record={fullRecord}
              shouldDisplayAuthorField={shouldDisplayAuthorField}
            />

            <PrintViewSignatures route={route} />

            <PrintViewSystemDetails
              getAttachmentsList={getAttachmentsList}
              record={fullRecord}
            />

            {rejectionReason && (
              <PrintViewRejectionReason rejectionReason={rejectionReason} />
            )}

            {fullRecord.attachments.length > 0 && (
              <>
                <p style={{ pageBreakAfter: "always" }}>&nbsp;</p>

                <div
                  className="container-fluid"
                  id="print-view-record-attachments"
                >
                  <h6>{fullRecord.original_location.system} Attachment(s):</h6>

                  {fullRecord.attachments.map((attachment) => (
                    <table>
                      {attachment.FileType === "image" ? (
                        getAttachmentTable(attachment)
                      ) : (
                        <div className="m-2">
                          <a
                            download={attachment.name}
                            href={attachment.url}
                            onClick={handleOnClickDownload}
                          >
                            {attachment.name}
                          </a>
                        </div>
                      )}
                    </table>
                  ))}
                </div>
              </>
            )}

            {fullRecord.sub_records.length > 0 && (
              <PrintViewSubRecords
                getAttachmentsList={getAttachmentsList}
                getAttachmentTable={getAttachmentTable}
                record={fullRecord}
              />
            )}

            {fullRecord.linked_records && (
              <PrintVIewLinkedRecord
                linkedRecords={fullRecord.linked_records}
              />
            )}

            <h4 className="text-center" id="end-of-file">
              --- End of Document ---
            </h4>
          </div>
        </div>
      </PrintViewContentWrapper>

      <div id="print-view-footer">
        VERA Record Detail Report
        <p id="footerDateTime">{`Generated On: ${Date()}`}</p>
      </div>

      <div id="print-view-background">
        <p id="print-view-background-text">
          {getBackgroudStatusText(fullRecord.status)}
        </p>
      </div>
    </>
  );
};

export default PrintView;
