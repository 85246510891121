import axios, { AxiosError } from "axios";
import fileDownload from "js-file-download";

export const readUploadedFileAsText = (inputFile: File): Promise<string> => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result as string);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

export const convertMegaBytesToBytes = (size: number): number => {
  return size * 1024 * 1024;
};

export const downloadFile = async ({ name, params, url }: any) => {
  try {
    const response = await axios.get(url, { responseType: "blob", params });
    if (response.status === 200) {
      fileDownload(response.data, name);
      return Promise.resolve({ status: response.status });
    }

    return Promise.reject({ status: response.status });
  } catch (err: any) {
    const axiosError = err as AxiosError;
    const status = axiosError ? axiosError.response?.status : 400;

    return Promise.reject({ status });
  }
};
