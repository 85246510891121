import HelpDropdown from "./HelpDropdown";
import UserDropdown from "./UserDropdown";

const HeaderActionBar = () => {
  return (
    <div className="header-action-bar">
      <HelpDropdown />
      <UserDropdown />
    </div>
  );
};

export default HeaderActionBar;
