import { IFilterItem } from "../../components/interfaces/LocateRecordsFilter";
import {
  ProjectAction,
  LocateRecordsFilterActionTypes,
  ILocateRecordsState,
} from "../types/locateRecordsFilter";

const initialState: ILocateRecordsState = {
  projects: {
    items: [],
    total: 0,
  },
  projectSearchField: "",
  recordTypes: {
    items: [],
    total: 0,
  },
  recordTypeSearchField: "",
  recordStatus: {
    items: [],
    total: 0,
  },
  recordStatusSearchField: "",
  recordNameSearchField: "",
  isLoadingProjects: false,
  isLoadingRecordTypes: false,
  isLoadingRecordStatuses: false,
  selectedProjects: [],
  selectedRecordTypes: [],
  skipProjects: 0,
  skipRecordTypes: 0,
};

const convertToFilterItem = (rows: string[]): IFilterItem[] => {
  return rows.map(
    (item: string) =>
      ({ id: item, name: item, isSelected: false }) as IFilterItem,
  );
};

export const locateRecordsReducer = (
  state = initialState,
  action: ProjectAction,
): ILocateRecordsState => {
  switch (action.type) {
    case LocateRecordsFilterActionTypes.FETCHING_PROJECTS:
      return {
        ...state,
        skipProjects: action.payload,
        isLoadingProjects: true,
      };
    case LocateRecordsFilterActionTypes.UPDATE_PROJECTS:
      return {
        ...state,
        isLoadingProjects: false,
        projects: {
          items: convertToFilterItem(action.payload.rows),
          total: action.payload.total,
        },
      };
    case LocateRecordsFilterActionTypes.SHOW_MORE_PROJECTS:
      return {
        ...state,
        isLoadingProjects: false,
        projects: {
          items: [
            ...state.projects.items,
            ...convertToFilterItem(action.payload.rows),
          ],
          total: action.payload.total,
        },
      };
    case LocateRecordsFilterActionTypes.SHOW_MORE_RECORD_TYPES:
      return {
        ...state,
        isLoadingRecordTypes: false,
        recordTypes: {
          items: [
            ...state.recordTypes.items,
            ...convertToFilterItem(action.payload.rows),
          ],
          total: action.payload.total,
        },
      };
    case LocateRecordsFilterActionTypes.SHOW_MORE_RECORD_STATUSES:
      return {
        ...state,
        isLoadingRecordStatuses: false,
        recordStatus: {
          ...state.recordStatus,
          items: [
            ...state.recordStatus.items,
            ...convertToFilterItem(action.payload.rows),
          ],
          total: action.payload.total,
        },
      };
    case LocateRecordsFilterActionTypes.UPDATE_SELECTED_PROJECT: {
      const set = new Set(state.selectedProjects);
      if (set.has(action.payload)) {
        set.delete(action.payload);
      } else {
        set.add(action.payload);
      }

      return {
        ...state,
        selectedProjects: Array.from(set),
      };
    }
    case LocateRecordsFilterActionTypes.FETCHING_RECORD_TYPES:
      return {
        ...state,
        isLoadingRecordTypes: true,
        skipRecordTypes: action.payload,
      };
    case LocateRecordsFilterActionTypes.UPDATE_RECORD_TYPES: {
      const recordTypesList = convertToFilterItem(action.payload.rows);

      return {
        ...state,
        isLoadingRecordTypes: false,
        recordTypes: {
          items: recordTypesList,
          total: action.payload.total,
        },
      };
    }
    case LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_TYPE: {
      const set = new Set(state.selectedRecordTypes);
      if (set.has(action.payload)) {
        set.delete(action.payload);
      } else {
        set.add(action.payload);
      }

      return {
        ...state,
        selectedRecordTypes: Array.from(set),
      };
    }
    case LocateRecordsFilterActionTypes.FETCHING_RECORD_STATUS:
      return { ...state, isLoadingRecordStatuses: true };
    case LocateRecordsFilterActionTypes.UPDATE_RECORD_STATUS: {
      const recordStatusesList = convertToFilterItem(action.payload.rows);
      return {
        ...state,
        isLoadingRecordStatuses: false,
        recordStatus: {
          ...state.recordStatus,
          items: recordStatusesList,
          total: action.payload.total,
        },
      };
    }
    case LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_STATUS: {
      const recordStatusIndex = state.recordStatus.items.findIndex(
        (x) => x.name === action.payload.name,
      );
      const recordStatuses = [...state.recordStatus.items];
      recordStatuses[recordStatusIndex].isSelected = action.payload.isSelected;
      return {
        ...state,
        recordStatus: {
          ...state.recordStatus,
          items: recordStatuses,
        },
      };
    }
    case LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_NAME:
      return {
        ...state,
        recordNameSearchField: action.payload.searchField,
      };
    case LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_PROJECT:
      return {
        ...state,
        projectSearchField: action.payload.searchField,
      };
    case LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_TYPE:
      return {
        ...state,
        recordTypeSearchField: action.payload.searchField,
      };
    case LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_STATUS:
      return {
        ...state,
        recordStatusSearchField: action.payload.searchField,
      };

    case LocateRecordsFilterActionTypes.CLEAR_FILTER:
      return {
        ...state,
        projectSearchField: "",
        recordTypeSearchField: "",
        recordStatusSearchField: "",
        recordNameSearchField: "",
        selectedProjects: [],
        selectedRecordTypes: [],
        recordStatus: {
          ...state.recordStatus,
          ...{
            items: state.recordStatus.items.map((x) => ({
              ...x,
              isSelected: false,
            })),
          },
        },
      };
    case LocateRecordsFilterActionTypes.CLEAR_SELECTED_PROJECT:
      return {
        ...state,
        selectedProjects: [],
      };
    case LocateRecordsFilterActionTypes.CLEAR_SELECTED_RECORD_TYPE:
      return {
        ...state,
        selectedRecordTypes: [],
      };
    case LocateRecordsFilterActionTypes.CLEAR_SELECTED_RECORD_STATUS:
      return {
        ...state,
        recordStatus: {
          ...state.recordStatus,
          items: state.recordStatus.items.map((x) => ({
            ...x,
            isSelected: false,
          })),
        },
      };
    case LocateRecordsFilterActionTypes.UPDATE_ALL_FILTERS: {
      const allProjects = getAllSelectedItems(
        state.projects.items,
        action.payload.projects,
      );
      const allRecordTypes = getAllSelectedItems(
        state.recordTypes.items,
        action.payload.recordTypes,
      );
      const allRecordStatuses = getAllSelectedItems(
        state.recordStatus.items,
        action.payload.recordStatus,
      );

      return {
        ...state,
        projects: {
          ...state.projects,
          items: allProjects,
        },
        recordTypes: {
          ...state.recordTypes,
          items: allRecordTypes,
        },
        recordStatus: {
          ...state.recordStatus,
          items: allRecordStatuses,
        },
        selectedProjects: action.payload.projects,
        selectedRecordTypes: action.payload.recordTypes,
      };
    }
    case LocateRecordsFilterActionTypes.UPDATE_SELECTED_PROJECTS: {
      const selectedProjects = getAllSelectedItems(
        state.projects.items,
        action.payload.projects,
      );
      return {
        ...state,
        projects: {
          ...state.projects,
          items: selectedProjects,
        },
        selectedProjects: action.payload.projects,
      };
    }
    case LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_TYPES: {
      const selectedRecordTypes = getAllSelectedItems(
        state.recordTypes.items,
        action.payload.recordTypes,
      );
      return {
        ...state,
        recordTypes: {
          ...state.recordTypes,
          items: selectedRecordTypes,
        },
        selectedRecordTypes: action.payload.recordTypes,
      };
    }
    case LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_STATUSES: {
      const selectedRecordStatuses = getAllSelectedItems(
        state.recordStatus.items,
        action.payload.recordStatuses,
      );
      return {
        ...state,
        recordStatus: {
          ...state.recordStatus,
          items: selectedRecordStatuses,
        },
      };
    }
    default:
      return state;
  }
};

const getAllSelectedItems = (items: IFilterItem[], queryItems: string[]) => {
  const selectedItems = [...items];

  for (const queryItem of queryItems) {
    const i = selectedItems.findIndex((item) => item.name === queryItem);
    if (i > -1)
      selectedItems[i] = {
        ...selectedItems[i],
        name: queryItem,
        isSelected: true,
      };
    else
      selectedItems.push({ id: queryItem, name: queryItem, isSelected: true });
  }

  return selectedItems;
};
