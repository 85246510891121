import {
  DetailsQueryParams,
  HistoryDetails,
  RecordField,
  SubDetailsData,
} from "../../../interfaces/DetailsPage";
import RecordHistoryDetailsItem from "./RecordHistoryDetailsItem";
import RecordHistorySubDetails from "./RecordHistorySubDetails";
import { shouldDisplayAuthor } from "../../../../helpers/entity-details.helper";
import { unescape } from "lodash";
import CallTest from "../../../../models/recordTypes/CallTest";
import XRayTestRepository from "../../../../models/recordTypes/XRayTestRepository";
import { tryParse } from "../../../../helpers";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Loader from "../../../modules/Loader";
import { useActions } from "../../../../hooks/useActions";
import { useEffect } from "react";
import {
  matchPath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { AppRoutes } from "../../../../constants/general.constants";

const RecordHistory = () => {
  const { fetchRecordHistory, fetchRecordDetails } = useActions();
  const { data, recordHistory, isLoadingRecordHistory } = useTypedSelector(
    (state) => state.recordDetails,
  );

  const history = useHistory();
  const location = useLocation();

  const match = matchPath<DetailsQueryParams>(location.pathname, {
    path: [AppRoutes.DetailsPage, AppRoutes.RevisionPage],
  });

  const { routeId, recordId, revision, menuSelector } = match?.params ?? {};

  function getDetailsMarkup(detail: HistoryDetails | SubDetailsData) {
    const longItems: RecordField[] = [];
    const shortItems: RecordField[] = [];

    for (const [key, value] of Object.entries(detail)) {
      const parsedValue = tryParse(value);
      if (parsedValue != null) {
        if (parsedValue instanceof CallTest) {
          shortItems.push({
            label: key,
            value: `${parsedValue.Title} ${parsedValue.Key} ${parsedValue.Summary}`,
          });
        } else if (parsedValue instanceof XRayTestRepository) {
          shortItems.push({ label: key, value: parsedValue.Name });
        }
      } else if (key === "authors" && shouldDisplayAuthorField) {
        if (!value || !value.length) {
          shortItems.push({ label: "Author", value: "Unknown" });
        } else if (value.length === 1) {
          const { userFullName, userName } = value[0];
          shortItems.push({
            label: "Author",
            value: `${userFullName} ${userName ? `(${userName})` : ""}`,
          });
        } else {
          shortItems.push({
            label: "Author",
            value: value,
          });
        }
      } else if (
        Object.prototype.hasOwnProperty.call(detail, key) &&
        key !== "name" &&
        key !== "subDetails"
      ) {
        if (!value || typeof value !== "string") {
          shortItems.push({ label: key, value: "" });
        } else if (value.length < 60 && value.indexOf("<p>")) {
          shortItems.push({ label: key, value: value });
        } else {
          longItems.push({ label: key, value: value });
        }
      }
    }
    return { name: detail.name, shortItems: shortItems, longItems: longItems };
  }

  useEffect(() => {
    fetchRecordDetails(history, routeId, recordId, revision, menuSelector);
  }, []);

  useEffect(() => {
    data?.record?._id &&
      !recordHistory &&
      fetchRecordHistory(data?.record?._id);
  }, [data]);

  if (!data || isLoadingRecordHistory) {
    return <Loader message={"Loading, please wait..."} />;
  }

  const { record } = data;

  const shouldDisplayAuthorField = record && shouldDisplayAuthor(record);

  return (
    <>
      <h6>
        <b>{unescape(record?.name)}</b>
      </h6>

      <div aria-label="activity-history-tab" id="recordHistoryTable">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>User</th>
            </tr>
          </thead>

          <tbody>
            {recordHistory?.map((record) => (
              <>
                <tr
                  className="clickable"
                  data-bs-target={`#historyRecord${record._id}`}
                  data-bs-toggle="collapse"
                  id={`accordionRecordHistory${record._id}`}
                >
                  <td>{record.timestamp}</td>

                  <td>{unescape(record.name)}</td>

                  <td>
                    {record.userFullName} ({record.userId})
                  </td>
                </tr>

                <tr>
                  <td
                    className="collapse pt-2 pb-2"
                    colSpan={3}
                    data-bs-parent={`#accordionRecordHistory${record._id}`}
                    id={`historyRecord${record._id}`}
                  >
                    <div className="container-fluid pt-2">
                      {record.details.map((detailsItem) => (
                        <>
                          <RecordHistoryDetailsItem
                            detailsItem={detailsItem}
                            getDetailsMarkup={getDetailsMarkup}
                          />

                          {detailsItem.subDetails.length > 0 && (
                            <RecordHistorySubDetails
                              getDetailsMarkup={getDetailsMarkup}
                              recordId={record._id}
                              subDetails={detailsItem.subDetails}
                            />
                          )}

                          {/* displays divider if there are more than 1 item */}
                          {record.details.indexOf(detailsItem) !==
                            record.details.length - 1 && <hr />}
                        </>
                      ))}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RecordHistory;
