import React, { useEffect } from "react";
import TableHead from "../../../modules/TableHead";
import TablePagination from "../../../modules/TablePagination";
import Loader from "../../../modules/Loader";
import DomainsTableItem from "./DomainsTableItem";
import CreateDomainModal from "./CreateDomainModal";
import TableSearchField from "../../../modules/TableSearchField";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { ISortParams } from "../../../interfaces/ApprovalQueue";

const DomainManagement = () => {
  const [isCreateNewDomainModalOpen, setCreateNewDomainModalOpen] =
    React.useState<boolean>(false);
  const [columnSearchingFieldsAreActive, setColumnSearchingFieldsAreActive] =
    React.useState<boolean>(true);
  const [tableSearchQuery, setTableSearchQuery] = React.useState({});
  const { tableData, tableHeaders, isLoading, sortParams, selectedPage } =
    useTypedSelector((state) => state.domain);
  const { user } = useTypedSelector((state) => state.user);
  const {
    getDomainsData,
    updateDomainSortParams,
    updateDomainPageParams,
    updateDomainSelectedPage,
    resetDomainData,
  } = useActions();

  const updateSortParams = (
    sort: string = sortParams.sort,
    order: "asc" | "desc" = sortParams.order,
  ) => {
    updateDomainSortParams(sort, order);
  };

  const updatePageParams = (
    offset: number = sortParams.offset,
    limit: number = sortParams.limit,
  ) => {
    updateDomainPageParams(offset, limit);
  };

  const getDomains = (sortParams: ISortParams, tableSearchQuery: {}) => {
    getDomainsData(sortParams, tableSearchQuery);
  };

  useEffect(() => {
    getDomainsData(sortParams, tableSearchQuery);
  }, [sortParams, tableSearchQuery]);

  useEffect(() => {
    getDomains(sortParams, tableSearchQuery);
  }, [sortParams, tableSearchQuery]);

  useEffect(
    () => () => {
      resetDomainData();
    },
    [],
  );

  return (
    <div aria-label="domain-management-table">
      <div className="d-flex justify-content-between">
        <div
          aria-label="signature-verification"
          className="table-heading-wrapper"
        >
          <b>Domain Management</b>
        </div>
        {user?.isSystemAdmin && (
          <a
            className="btn btn-primary btn-sm text-white"
            href="#!"
            id="create-domain-btn"
            onClick={() => setCreateNewDomainModalOpen(true)}
            role="button"
          >
            <i className="fa fa-plus" /> Create Domain
          </a>
        )}

        {isCreateNewDomainModalOpen && (
          <CreateDomainModal
            closeModal={() => setCreateNewDomainModalOpen(false)}
            isOpen={isCreateNewDomainModalOpen}
            updateTableData={() => getDomains(sortParams, tableSearchQuery)}
          />
        )}
      </div>

      <div className="table-search-field-wrapper">
        <TableSearchField
          fetchTableData={(sortParams, searchQuery) =>
            getDomains(sortParams, searchQuery || tableSearchQuery)
          }
          sortParams={sortParams}
        />
      </div>

      <table className="table table-bordered table-sm">
        <TableHead
          columnFiltrationIsActive={columnSearchingFieldsAreActive}
          displayActionsColumn={user?.isSystemAdmin}
          isLoading={isLoading}
          setPageParams={updatePageParams}
          setSortParams={updateSortParams}
          sortParams={sortParams}
          tableHeaders={tableHeaders}
          updateTableSearchingQuery={setTableSearchQuery}
        />

        <tbody
          className="position-relative"
          style={{ height: isLoading || !tableData?.rows?.length ? "40vh" : 0 }}
        >
          {isLoading && (
            <Loader message={"Loading, please wait..."} tableMode={true} />
          )}
          {!isLoading &&
            tableData?.rows?.map((domain) => (
              <DomainsTableItem
                domainData={domain}
                key={domain._id}
                updateTableData={() => getDomains(sortParams, tableSearchQuery)}
              />
            ))}
        </tbody>
      </table>

      {tableData && (
        <TablePagination
          initialRowsPerPage={10}
          selectedPage={selectedPage}
          tableData={tableData}
          updatePageParams={updatePageParams}
          updateSelectedPage={updateDomainSelectedPage}
        />
      )}
    </div>
  );
};

export default DomainManagement;
