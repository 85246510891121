import { DropdownItemCheckboxGroup } from "@atlaskit/dropdown-menu";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../constants/general.constants";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import DropdownLinkItem from "./DropdownLinkItem";
import ArrowDownIcon from "@atlaskit/icon/glyph/chevron-down";
import UserDetailIcon from "./../../../images/bxs-user.svg";
import HeaderDropdownMenu from "./HeaderDropdownMenu";

const UserDropdown: React.FC = () => {
  const { username, veraIdp } = useTypedSelector((state) => state.user);
  const { logout } = useActions();
  const history = useHistory();

  return (
    <div className="user-dropdown" id="user-dropdown">
      <HeaderDropdownMenu
        buttonChildren={
          <span className="user-dropdown-button">
            <span className="dropdown-button-username">{username}</span>
            <ArrowDownIcon
              label="arrow down icon"
              primaryColor="#17171e"
              secondaryColor="#1b365d"
              size="medium"
            />
          </span>
        }
        iconBefore={
          <span className="user-icon">
            <img src={UserDetailIcon} />
          </span>
        }
      >
        <DropdownItemCheckboxGroup id="user">
          <DropdownLinkItem
            id="my-profile-dropdown"
            label="My Profile"
            pathname={AppRoutes.ProfilePage}
          />
          {veraIdp && (
            <DropdownLinkItem
              id="change-password-dropdown"
              label="Change Password"
              pathname={AppRoutes.ChangePasswordPage}
            />
          )}
          <DropdownLinkItem
            href="#!"
            id="logout-dropdown"
            label="Logout"
            onClick={() => logout(history)}
          />
        </DropdownItemCheckboxGroup>
      </HeaderDropdownMenu>
    </div>
  );
};

export default UserDropdown;
