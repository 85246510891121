import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import {
  IRecordData,
  TaskApproveDataResponse,
  TaskApproveRejectResponse,
} from "../../interfaces/ApprovalQueue";
import ApprovalQueueTableItem from "./ApprovalQueueTableItem";
import TablePagination from "../../modules/TablePagination";
import TableSearchField from "../../modules/TableSearchField";
import TableHead from "../../modules/TableHead";
import Loader from "../../modules/Loader";
import ApproveFooter from "./ApproveFooter";
import {
  ApprovalPageRequestRoutes,
  ErrorMessage,
} from "../../../constants/general.constants";

const ApprovalQueuePage = () => {
  const [columnSearchingFieldsAreActive, setColumnSearchingFieldsAreActive] =
    React.useState<boolean>(true);
  const [tableSearchQuery, setTableSearchQuery] = React.useState({});
  const { user, veraIdp } = useTypedSelector((state) => state.user);
  const sessionID = useTypedSelector(
    (state) => state.approveFooter.approveSessionID,
  );
  const isFirstRun = useRef(true);

  const {
    tableData,
    tableHeaders,
    rowsData,
    sortParams,
    isLoading,
    selectedPage,
    columnSearchField,
  } = useTypedSelector((state) => state.approvalQueue);

  const {
    fetchTableData,
    updateAllSortParams,
    updateSortParams,
    updatePageParams,
    handleBulkCheckboxSelectAction,
    updateSelectedPage,
    unselectRows,
    updateColumnSearchField,
    openApproveModal,
  } = useActions();

  const setSortParams = (
    sort: string = sortParams.sort,
    order: "asc" | "desc" = sortParams.order,
  ) => {
    updateSortParams(sort, order);
  };

  const setPageParams = (
    offset: number = sortParams.offset,
    limit: number = sortParams.limit,
  ) => {
    updatePageParams(offset, limit);
  };

  const setSelectedPage = (pageNumber: number) => {
    updateSelectedPage(pageNumber);
  };

  const onCloseModalApprove = (
    shouldRefresh: boolean,
    numOfApprovedOrRejectedRecords?: number,
  ) => {
    if (shouldRefresh) {
      if (rowsData?.length === numOfApprovedOrRejectedRecords) {
        updatePageParams(0, sortParams.limit);
      } else {
        updatePageParams(sortParams.offset, sortParams.limit);
      }
    }
  };

  const handleTaskApprove = async (
    approvalId: string | null,
    password: string,
    recordList?: string | null,
  ) => {
    try {
      const { data }: TaskApproveRejectResponse = await axios.post(
        veraIdp
          ? ApprovalPageRequestRoutes.TASK_APPROVE
          : ApprovalPageRequestRoutes.SAML_LOGIN,
        {
          username: user?.username,
          password: password,
          approvalTaskId: approvalId,
          recordList: recordList,
          redirectTo: window.location.pathname,
          selectedPage,
          sortParams,
          tableSearchQuery,
          columnSearchField,
          sessionID,
        },
      );

      if (!veraIdp && data.url) {
        window.location.replace(data.url);
      } else if (data.message) {
        toast.error(data.message);
        return false;
      } else if (data.error) {
        toast.error(data.error);
        return false;
      } else {
        return true;
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  const handleBulkTaskApprove = async (password: string) => {
    const selectedItems: IRecordData[] =
      rowsData?.filter((item) => item.isSelected) || [];
    if (selectedItems.length > 1) {
      const recordList: any = selectedItems.map((item) => item._id);
      return await handleTaskApprove(null, password, recordList);
    }

    return await handleTaskApprove(selectedItems[0]?._id, password);
  };

  const handleTaskReject = async (taskId: string, reason: string) => {
    try {
      const { data }: TaskApproveRejectResponse = await axios.post(
        ApprovalPageRequestRoutes.TASK_REJECT,
        {
          rejectionReason: reason,
          rejectionTaskId: taskId,
        },
      );

      if (data.apiStatusCode !== 200) {
        toast.error(data.message);
        return;
      } else {
        toast.success(data.message);
        fetchTableData(sortParams, tableSearchQuery);
        return true;
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  const approveRecordsFromSSOUser = async () => {
    const result: TaskApproveDataResponse = await axios.get(
      ApprovalPageRequestRoutes.SAML_TASK_APPROVE_DATA,
    );
    if (result.data?.sortParams) {
      const {
        bulkTasks,
        selectedPage,
        sortParams,
        taskId,
        tableSearchQuery,
        columnSearchField,
      } = result.data;

      const tasksId = taskId ? [taskId] : bulkTasks;

      updateColumnSearchField(columnSearchField);
      updateAllSortParams(sortParams);
      updateSelectedPage(selectedPage);
      setTableSearchQuery(tableSearchQuery);
      fetchTableData(sortParams, tableSearchQuery, tasksId);
      openApproveModal();
    }
  };

  useEffect(
    () => () => {
      unselectRows();
    },
    [],
  );

  useEffect(() => {
    if (!isFirstRun.current) {
      const params = new URLSearchParams(window.location.search);
      if (params.get("approve") === null) {
        fetchTableData(sortParams, tableSearchQuery);
      }
    }

    isFirstRun.current = false;
  }, [sortParams, tableSearchQuery]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("approve") !== null && !veraIdp) {
      approveRecordsFromSSOUser();
    } else {
      fetchTableData(sortParams, tableSearchQuery);
    }
  }, []);

  return (
    <>
      <div className="table-heading-wrapper mb-3">
        <b>Approval Queue</b>
        <TableSearchField
          fetchTableData={(sortParams, searchQuery) =>
            fetchTableData(sortParams, searchQuery || tableSearchQuery)
          }
          sortParams={sortParams}
        />
      </div>

      <table className="table table-bordered table-sm">
        <TableHead
          columnFiltrationIsActive={columnSearchingFieldsAreActive}
          columnsSearchField={columnSearchField}
          handleSelectAllAction={handleBulkCheckboxSelectAction}
          isLoading={isLoading}
          rowsData={rowsData!}
          setColumnsSearchField={updateColumnSearchField}
          setPageParams={setPageParams}
          setSortParams={setSortParams}
          sortParams={sortParams}
          tableHeaders={tableHeaders}
          updateTableSearchingQuery={setTableSearchQuery}
        />

        <tbody
          className="position-relative"
          style={{ height: !rowsData?.length || isLoading ? "40vh" : 0 }}
        >
          {!isLoading && !rowsData?.length && (
            <div className="table-no-records-found">
              No matching records found
            </div>
          )}

          {isLoading && (
            <tr key="loading">
              <th className="loading-column" colSpan={9}>
                {
                  <Loader
                    message={"Loading, please wait..."}
                    tableMode={true}
                  />
                }
              </th>
            </tr>
          )}

          {!isLoading &&
            rowsData?.map((itemData: IRecordData) => (
              <ApprovalQueueTableItem
                key={itemData._id}
                onCloseModal={onCloseModalApprove}
                onTaskApprove={handleTaskApprove}
                onTaskReject={handleTaskReject}
                tableItem={itemData}
              />
            ))}
        </tbody>
      </table>

      {tableData && (
        <TablePagination
          selectedPage={selectedPage}
          tableData={tableData}
          updatePageParams={setPageParams}
          updateSelectedPage={setSelectedPage}
        />
      )}

      <ApproveFooter
        onBulkApprove={handleBulkTaskApprove}
        onCloseApproveModal={onCloseModalApprove}
      />
    </>
  );
};

export default ApprovalQueuePage;
