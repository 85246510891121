import { DropdownItemCheckboxGroup } from "@atlaskit/dropdown-menu";
import { AppRoutes } from "../../../constants/general.constants";
import DropdownLinkItem from "./DropdownLinkItem";
import HeaderDropdownMenu from "./HeaderDropdownMenu";
import QuestionIcon from "@atlaskit/icon/glyph/question-circle";

const HelpDropdown: React.FC = () => {
  return (
    <div className="help-dropdown">
      <HeaderDropdownMenu
        iconBefore={
          <span className="question-icon">
            <QuestionIcon
              label="question icon"
              primaryColor="white"
              secondaryColor="#1b365d"
              size="medium"
            />
          </span>
        }
      >
        <DropdownItemCheckboxGroup id="help">
          <DropdownLinkItem
            id="vera-user-guide"
            label="VERA User Guide"
            pathname={"https://tx3.atlassian.net/l/cp/k6AD2VJ8"}
            target="_blank"
          />
          <DropdownLinkItem
            id="versions-dropdown"
            label="Versions"
            pathname={AppRoutes.VersionsPage}
          />
        </DropdownItemCheckboxGroup>
      </HeaderDropdownMenu>
    </div>
  );
};

export default HelpDropdown;
