import FormTextField from "../../../../modules/common/FormTextField";
import { Control, FieldErrors } from "react-hook-form";
import { IConnectionFormData } from "../../../../interfaces/Connections";

interface IConnectionAliasProps {
  control: Control;
  index: number;
  errors: FieldErrors<IConnectionFormData>;
  onClickRemove: Function;
  wasValidated?: boolean;
}

const ConnectionAlias = ({
  control,
  errors,
  index,
  onClickRemove,
  wasValidated,
}: IConnectionAliasProps) => {
  return (
    <div className="d-flex connection-alias">
      <FormTextField
        control={control}
        controllerName={`Aliases.${index}.value`}
        error={errors.Aliases && errors.Aliases[index]?.value?.message}
        label={`Alias${index + 1}: `}
        name={`Aliases[${index}].value`}
        placeholder="Enter Alias"
        wasValidated={wasValidated}
      />
      <button
        aria-label="delete-alias-btn"
        className="btn-link btn-delete-alias"
        onClick={(e) => {
          e.preventDefault();
          onClickRemove(index);
        }}
      >
        <i className="fa fa-trash-o" />
      </button>
    </div>
  );
};

export default ConnectionAlias;
