import { Dispatch } from "redux";
import {
  ApproveFooterAction,
  ApproveFooterActionTypes,
} from "../types/approveFooter";

export const showLoading = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.SHOW_LOADING });
  };
};

export const hideLoading = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.HIDE_LOADING });
  };
};

export const openApproveModal = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.OPEN_APPROVE_MODAL });
  };
};

export const openRejectModal = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.OPEN_REJECT_MODAL });
  };
};

export const closeApproveModal = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.CLOSE_APPROVE_MODAL });
  };
};

export const closeRejectModal = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.CLOSE_REJECT_MODAL });
  };
};

export const closeAllModals = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.CLOSE_ALL_MODALS });
  };
};

export const setNewSessionID = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.SET_NEW_SESSION_ID });
  };
};

export const clearSessionID = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.CLEAR_SESSION_ID });
  };
};

export const handleResetApproveFooterData = () => {
  return async (dispatch: Dispatch<ApproveFooterAction>) => {
    dispatch({ type: ApproveFooterActionTypes.RESET_APPROVE_FOOTER_DATA });
  };
};
