import { ITableState } from "./Table";

export interface IPolicy {
  _id: string;
  Description: string;
  IsDefault: "false" | "true";
  Name: string;
  PolicyOwnerUserId: string;
  PolicyOwnerFullName: string;
  PolicyOwnerUserName: string;
  Revision: number;
  RevisionId: string;
  Type: string;
}

export interface IPolicyDataResponse {
  rows: IPolicy[];
  total: number;
}

export enum ePolicyType {
  APPROVAL = "approval-policy",
  RECORD_MANAGEMENT = "records-management-policy",
}

export const ePolicyTypeValue: {
  [key: string]: string;
} = {
  [ePolicyType.APPROVAL]: "Approval Policy",
  [ePolicyType.RECORD_MANAGEMENT]: "Records Management Policy",
};

export interface IPolicyForm {
  description: string;
  name: string;
  policyOwnerUserId?: { label: string; value: string };
}

export interface IEditPolicyForm extends IPolicyForm {
  _id: string;
  isDefault: string;
}

export interface IPolicyRevision {
  _id: string;
  Comment: string;
  PolicyId: string;
  LastUpdated: string;
  UpdatedByUserFullName: string;
  UpdatedByUserUserName: string;
  Revision: number;
}

export interface ILinkedPolicySet {
  _id: string;
  Name: string;
}

export interface IImportNewPolicyModalFormData extends IPolicyForm {
  importedFile: File[];
}

export interface IEditPolicyRequest {
  _id: string;
  description: string;
  name: string;
  policyOwnerUserId?: string;
}

export interface IPolicyState {
  isLoading: boolean;
  policy?: IPolicy;
  networkError: string;
}

export type IPolicyTableState = ITableState<IPolicy>;

export type ILinkedPolicySetTableState = ITableState<ILinkedPolicySet>;

export interface IDeletePolicyConfirmationModalProps {
  id: string;
  name: string;
  visible: boolean;
  onClose: () => void;
}

export interface IPolicyRevisionsTableItemProps {
  name?: string;
  policy: IPolicyRevision;
}

export interface IPoliciesTableItemProps {
  policy: IPolicy;
}
