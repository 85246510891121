import styled from "@emotion/styled";

export const LoginContainer = styled.div`
  display: grid !important;
  grid-template-rows: 20% 1fr 10%;

  & > img {
    justify-self: center;
    margin-top: 0 !important;
  }
`;
