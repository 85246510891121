import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useAppDispatch } from "../../../../../../store/store";
import { setPeriod } from "../../../../../../store/slices/user.slice";
import { ePeriod } from "../../../../../interfaces/User";
import MenuItem from "@mui/material/MenuItem";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";

const PeriodSelect = () => {
  const dispatch = useAppDispatch();

  const period = useTypedSelector((x) => x.concurrentUsersReport.period);

  return (
    <FormControl fullWidth style={{ maxWidth: "200px" }}>
      <InputLabel aria-labelledby="period-select" id="period-label">
        Period
      </InputLabel>
      <Select
        fullWidth
        id="period-select"
        inputProps={{
          id: "period-select-input",
        }}
        label="Period"
        labelId="period-label"
        name="period"
        onChange={(e) => dispatch(setPeriod(e.target.value as ePeriod))}
        placeholder="Period"
        size="small"
        value={period}
      >
        <MenuItem value={ePeriod.LAST_SEVEN_DAYS}>Last 7 Days</MenuItem>
        <MenuItem value={ePeriod.LAST_THIRTY_DAYS}>Last 30 Days</MenuItem>
        <MenuItem value={ePeriod.LAST_YEAR}>Last Year</MenuItem>
        <MenuItem value={ePeriod.CUSTOM_DATE_RANGE}>Custom Date Range</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PeriodSelect;
