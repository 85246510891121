import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { AdminRequestRoutes } from "../../../../constants/general.constants";
import { AdminPageSuccessMessages } from "../../../../constants/admin-page.constants";
import {
  ICreateDeleteDomainResponseData,
  ICreateDomainModalProps,
} from "../../../interfaces/AdminPage";
import TextField from "../../../modules/common/TextField";
import UserSelect from "../../../modules/UserSelect";
import { IDomainDetailsForm } from "../../../interfaces/Domain";
import { IconButton } from "@atlaskit/button/new";

const CreateDomainModal = ({
  isOpen,
  closeModal,
  updateTableData,
}: ICreateDomainModalProps) => {
  const [formData, setFormData] = React.useState<IDomainDetailsForm>({
    domainName: "",
    description: "",
  });
  const [loading, setLoading] = React.useState(false);

  const handleFormChange = (value: any) => {
    setFormData((prevState) => ({ ...prevState, [value.name]: value.value }));
  };

  const handleFormBlur = (value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [value.name]: value.value.trim(),
    }));
  };

  const handleSelectChange = (item: any) => {
    setFormData((prev) => ({
      ...prev,
      domainOwner: item ? item : undefined,
    }));
  };

  const handleCreateDomain = async () => {
    if (!formData.domainName) {
      toast.warning("Please enter new Domain name.");
      return;
    }

    setLoading(true);

    const { data }: ICreateDeleteDomainResponseData = await axios.post(
      AdminRequestRoutes.CREATE_DOMAIN,
      {
        domainName: formData.domainName,
        domainDescription: formData.description,
        domainOwnerUserId: formData.domainOwner?.value,
      },
    );

    if (data.apiStatusCode === 200) {
      toast.success(AdminPageSuccessMessages.CREATE_DOMAIN);
      updateTableData();
      closeModal();
    } else {
      toast.error(data.message);
    }

    setLoading(false);
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="large">
          <ModalHeader>
            <ModalTitle>Create New Domain</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <form className="create-domain-form">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Domain Name:</label>

                    <TextField
                      name={"domainName"}
                      onBlur={handleFormBlur}
                      onChange={handleFormChange}
                      placeholder={"Enter Domain name"}
                      value={formData.domainName}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Description:</label>

                    <TextField
                      name={"description"}
                      onBlur={handleFormBlur}
                      onChange={handleFormChange}
                      placeholder={"Enter Description (optional)"}
                      value={formData.description}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Domain Owner:</label>

                    <UserSelect
                      name="domain-owner"
                      onChange={handleSelectChange}
                      placeholder="Select Domain Owner (Optional)"
                      value={
                        formData.domainOwner?.value
                          ? {
                              value: formData.domainOwner?.value,
                              label: `${formData.domainOwner?.label}`,
                            }
                          : undefined
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  <button
                    aria-label="create-domain-btn"
                    className="btn btn-primary text-white"
                    disabled={loading}
                    onClick={handleCreateDomain}
                    type="button"
                  >
                    Create Domain
                    {loading && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      />
                    )}
                  </button>

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default CreateDomainModal;
