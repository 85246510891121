import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { ISortParams } from "../../components/interfaces/ApprovalQueue";
import {
  IDomainFilterResponse,
  IDomainsAndRolesResponse,
  IDomainsAndRolesUpdateResponse,
} from "../../components/interfaces/DomainsAndRoles";
import { IFilterData } from "../../components/interfaces/LocateRecordsFilter";
import {
  DomainsAndRolesRequestRoutes,
  ErrorMessage,
} from "../../constants/general.constants";
import { RootState } from "../reducers";
import {
  DomainsAndRolesAction,
  DomainsAndRolesActionTypes,
} from "../types/DomainsAndRoles";
import queryParser from "query-string";
import { AdminPageSuccessMessages } from "../../constants/admin-page.constants";

let cancelTokenDomains: CancelTokenSource;
let cancelTokenRoles: CancelTokenSource;
let cancelTokenDomainsAndRoles: CancelTokenSource;
let cancelTokenUpdateRoles: CancelTokenSource;

export const updateSelectedUserRoles =
  (domainId: string, roles: string[], userId: string) =>
  async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    if (typeof cancelTokenUpdateRoles != typeof undefined) {
      cancelTokenUpdateRoles.cancel(ErrorMessage.NEW_REQUEST);
    }

    cancelTokenUpdateRoles = axios.CancelToken.source();

    try {
      dispatch({
        type: DomainsAndRolesActionTypes.UPDATE_SELECTED_USER_ROLES,
        payload: {
          domainId,
          roles,
        },
      });

      const { data } = await axios.post<
        any,
        AxiosResponse<IDomainsAndRolesUpdateResponse>
      >(`${DomainsAndRolesRequestRoutes.USER_ROLES}`, {
        domainId,
        userId,
        roles,
      });

      if (data.apiData.message) {
        toast.warning(data.apiData.message, { autoClose: 8000 });
      }

      toast.success(AdminPageSuccessMessages.ROLES, { autoClose: 8000 });
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: DomainsAndRolesActionTypes.HANDLE_NETWORK_SAVE_DOMAIN_TO_USER,
          payload: error.message,
        });
      }
    }
  };

export const fetchDomains = (searchField: string, offset = 0) => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    if (typeof cancelTokenDomains != typeof undefined) {
      cancelTokenDomains.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelTokenDomains = axios.CancelToken.source();

    const sort = "asc";
    const limit = 10;

    try {
      dispatch({
        type: DomainsAndRolesActionTypes.FETCHING_DOMAINS,
        payload: offset,
      });
      const { data, status } = await axios.get<IDomainFilterResponse>(
        DomainsAndRolesRequestRoutes.DOMAINS,
        {
          params: { sort, offset, limit, name: searchField },
        },
      );

      if (status === 200) {
        if (offset) {
          dispatch({
            type: DomainsAndRolesActionTypes.SHOW_MORE_DOMAINS,
            payload: data,
          });
        } else {
          dispatch({
            type: DomainsAndRolesActionTypes.UPDATE_DOMAINS,
            payload: data,
          });
        }
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_DOMAINS,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchDomainsAndRolesFromUser = (
  userId: string,
  sortParams: ISortParams,
) => {
  return async (
    dispatch: Dispatch<DomainsAndRolesAction>,
    getState: Function,
  ) => {
    if (typeof cancelTokenDomainsAndRoles != typeof undefined) {
      cancelTokenDomainsAndRoles.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelTokenDomainsAndRoles = axios.CancelToken.source();

    const state = getState() as RootState;
    const { showAllDomains, selectedDomains } = state.domainsAndRoles;

    try {
      dispatch({ type: DomainsAndRolesActionTypes.FETCHING_DOMAINS_AND_ROLES });

      const url = getDomainURL(
        selectedDomains,
        userId,
        sortParams,
        showAllDomains,
      );

      const { data, status } = await axios.get<IDomainsAndRolesResponse>(url);

      if (status === 200) {
        dispatch({
          type: DomainsAndRolesActionTypes.UPDATE_DOMAINS_AND_ROLES,
          payload: {
            domainAndRoles: data,
            userId,
          },
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_DOMAINS_AND_ROLES,
          payload: error.message,
        });
      }
    }
  };
};

const getDomainURL = (
  selectedDomains: string[],
  userId: string,
  sortParams: ISortParams,
  showAllDomains: boolean,
) => {
  const queryOptions = { skipEmptyString: true, skipNull: false };

  const params = {
    userId,
    showAllDomains,
    skip: sortParams.offset,
    sort: sortParams.sort,
    order: sortParams.order,
    limit: sortParams.limit,
  };

  const baseUrl = `${DomainsAndRolesRequestRoutes.USER_ROLES}`;

  return queryParser.stringifyUrl(
    {
      url: baseUrl,
      query: {
        ...params,
        domainIds: selectedDomains.join(","),
      },
    },
    queryOptions,
  );
};

export const updateDomainsSelectedPage =
  (selectedPage: number) =>
  async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.UPDATE_DOMAIN_SELECTED_PAGE,
      payload: selectedPage,
    });
  };

export const updateSelectedDomain = (name: string) => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.UPDATE_SELECTED_DOMAINS,
      payload: name,
    });
  };
};

export const updateSearchFieldDomain = (searchField: string) => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.UPDATE_SEARCH_FIELD_DOMAIN,
      payload: { searchField },
    });
  };
};

export const updateSearchFieldRole = (searchField: string) => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.UPDATE_SEARCH_FIELD_ROLE,
      payload: { searchField },
    });
  };
};

export const clearAllDomainsAndRolesFilters = () => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({ type: DomainsAndRolesActionTypes.CLEAR_FILTER });
  };
};

export const clearSelectedDomains = () => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({ type: DomainsAndRolesActionTypes.CLEAR_SELECTED_DOMAINS });
  };
};

export const updateDomainAndRolesSortParams = (
  sort: string,
  order: "asc" | "desc",
) => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.UPDATE_DOMAIN_AND_ROLES_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updateDomainAndRolesPageParams = (
  offset: number,
  limit: number,
) => {
  return async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.UPDATE_DOMAIN_AND_ROLES_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetUserData =
  () => async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({ type: DomainsAndRolesActionTypes.RESET_USER_DATA });
  };

export const handleResetDomainsAndRolesData =
  () => async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({ type: DomainsAndRolesActionTypes.RESET_DOMAINS_AND_ROLES_DATA });
  };

export const handleResetAllDomainsAndRolesData =
  () => async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.RESET_ALL_DOMAINS_AND_ROLES_DATA,
    });
  };

export const handleResetDomainAndRolesSortParams =
  () => async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({
      type: DomainsAndRolesActionTypes.RESET_DOMAIN_AND_ROLES_SORT_PARAMS,
    });
  };

export const toggleShowAllDomains =
  () => async (dispatch: Dispatch<DomainsAndRolesAction>) => {
    dispatch({ type: DomainsAndRolesActionTypes.TOGGLE_SHOW_ALL_DOMAINS });
  };
