import { PropsWithChildren } from "react";

const PrintViewContentWrapper = ({ children }: PropsWithChildren) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>{children}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default PrintViewContentWrapper;
