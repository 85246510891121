import { useForm } from "react-hook-form";
import Form from "../../../../../../modules/common/Form";
import {
  IPoliciesForm,
  IPoliciesProps,
  IPoliciesRequest,
  IPolicySet,
  IPolicySetForm,
} from "../../../../../../interfaces/PolicySet";
import PolicySelect from "./PolicySelect";
import { IPolicy, ePolicyType } from "../../../../../../interfaces/Policy";
import React from "react";
import { toast } from "react-toastify";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../../../../constants/admin-page.constants";
import axios from "axios";
import { ErrorMessage } from "../../../../../../../constants/general.constants";
import { PolicySetsRequestRoutes } from "../../../../../../../constants/policy-set.constants";
import { transformToPoliciesForm } from "../../../../../../../helpers/policy-set.helper";
import { IDefaultResponse } from "../../../../../../interfaces/General";
import { Prompt } from "react-router-dom";

const Policies = ({ policySet }: IPoliciesProps) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm<IPoliciesForm>({
    defaultValues: transformToPoliciesForm(policySet),
    mode: "all",
  });

  const handleOnClickSave = async (policies: IPoliciesForm) => {
    try {
      const policyRequestPayload: IPoliciesRequest = {
        id: policies.id,
        approvalPolicyId: policies.approvalPolicy?.value,
        recordsManagementPolicyId: policies.recordsManagementPolicy?.value,
      };

      setIsLoading(true);
      const { data } = await axios.patch<IDefaultResponse<IPolicySet>>(
        PolicySetsRequestRoutes.LINK_POLICIES,
        policyRequestPayload,
      );
      if (data.apiStatusCode === 200) {
        toast.success(AdminPageSuccessMessages.UPDATE_POLICY_SET);
        reset(policies);
      } else {
        toast.error(AdminPageErrors.UPDATE_POLICY_SET);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Prompt
        message="Changes you made may not be saved. Are you sure?"
        when={isDirty}
      />
      <div className="table-heading-wrapper">
        <b>Policies</b>
      </div>
      <Form className="mt-4">
        <PolicySelect
          control={control}
          isDirty={Boolean(dirtyFields.approvalPolicy)}
          label="Approval Policy:"
          name="approvalPolicy"
          placeholder="Select Approval Policy"
          policyType={ePolicyType.APPROVAL}
        />

        <PolicySelect
          control={control}
          isDirty={Boolean(dirtyFields.recordsManagementPolicy)}
          label="Records Management Policy:"
          name="recordsManagementPolicy"
          placeholder="Select Records Management Policy"
          policyType={ePolicyType.RECORD_MANAGEMENT}
        />
      </Form>
      <div className="form-actions">
        <button
          className="btn btn-primary text-white"
          disabled={isLoading || !isDirty}
          id="btn-save-policies"
          onClick={handleSubmit((data) => handleOnClickSave(data))}
          type="button"
        >
          Save
          {isLoading && (
            <span
              aria-hidden="true"
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            />
          )}
        </button>
        <button
          aria-label="reset-fields"
          className="btn btn-primary text-white"
          disabled={!isDirty}
          id="btn-reset-policies"
          onClick={() => reset()}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default Policies;
