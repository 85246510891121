import { AxiosStatic } from "axios";

const AntiCSRF_HTTP_Methods = ["post", "put", "patch", "delete"];

const antiCSRFInterceptor = (axios: AxiosStatic) => {
  axios.interceptors.request.use(
    async (config) => {
      if (config.method && AntiCSRF_HTTP_Methods.indexOf(config.method) > -1) {
        const csrfResponse = await axios.get("/api/csrf");
        if (config.headers) {
          config.headers["X-CSRF-TOKEN"] = csrfResponse.data;
        }
      }
      return config;
    },
    (error) => {
      console.log("antiCSRFInterceptor ERROR: ", error);
    },
  );
};

export default antiCSRFInterceptor;
