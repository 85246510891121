import { IAuditEntry } from "../../../../../interfaces/AuditHistory";
import GenericModal from "../../../../../modules/GenericModal";

const AuditHistoryModal = ({
  auditEntry,
  onClose,
  visible,
}: {
  onClose: () => void;
  visible: boolean;
  auditEntry: IAuditEntry;
}) => {
  const { action, timestamp, entity, user } = auditEntry;

  const unionRoles = (roles: string[]) => roles.filter((val) => val).join(", ");

  const renderEntityType = (auditEntry: IAuditEntry) => {
    if (auditEntry?.roles && auditEntry?.roles?.length > 0) {
      switch (auditEntry.entity.type) {
        case "Domain":
          return (
            <div className="audit-details">
              <div className="audit-details__header audit-details__row">
                <div className="audit-details__cell">User Name</div>
                <div className="audit-details__cell">Roles</div>
              </div>
              {auditEntry?.roles?.map((role) => {
                if (role.users) {
                  return role.users.map((user) => (
                    <div className="audit-details__row" key={user.id}>
                      <div className="audit-details__cell">{user.userName}</div>
                      <div className="audit-details__cell">
                        {unionRoles(role.roles)}
                      </div>
                    </div>
                  ));
                }
              })}
            </div>
          );

        case "User":
          return (
            <div className="audit-details">
              <div className="audit-details__header audit-details__row">
                <div className="audit-details__cell">Domain Name</div>
                <div className="audit-details__cell">Roles</div>
              </div>
              {auditEntry?.roles?.map((role) => {
                if (role.domain) {
                  return (
                    <div className="audit-details__row">
                      <div className="audit-details__cell">
                        {role.domain.name}
                      </div>
                      <div className="audit-details__cell">
                        {unionRoles(role.roles)}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          );
      }
    }
  };

  return (
    <GenericModal
      labelCancel="Close"
      onCloseModal={onClose}
      size="large"
      title="Audit Details"
      visible={visible}
    >
      <div>
        <h5 className="audit-details__title">
          <b>{action.name}</b> action performed on
          <b>{` ${entity.type} (${entity.name}) `}</b>
          by <b>{user.userName}</b> at <b>{timestamp}</b>
        </h5>
        {auditEntry?.fields?.length > 0 && (
          <div className="audit-details">
            <div className="audit-details__header audit-details__row">
              <div className="audit-details__cell">Field</div>
              <div className="audit-details__cell">Old value</div>
              <div className="audit-details__cell">New Value</div>
            </div>
            {auditEntry.fields.map((x) => {
              return (
                <div className="audit-details__row">
                  <div className="audit-details__cell">{x.name}</div>
                  <div className="audit-details__cell">{x.oldValue}</div>
                  <div className="audit-details__cell">{x.newValue}</div>
                </div>
              );
            })}
          </div>
        )}
        {auditEntry?.projects && auditEntry?.projects?.length > 0 && (
          <div className="audit-details">
            <div className="audit-details__header audit-details__row">
              <div className="audit-details__cell">Project Name</div>
              <div className="audit-details__cell">Connection</div>
            </div>
            {auditEntry?.projects?.map((project) => {
              return (
                <div className="audit-details__row">
                  <div className="audit-details__cell">{project.id}</div>
                  <div className="audit-details__cell">
                    {project.connection.name}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {renderEntityType(auditEntry)}
      </div>
    </GenericModal>
  );
};

export default AuditHistoryModal;
