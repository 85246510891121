import { IPrintViewVeraDetailsProps } from "../../../interfaces/DetailsPage";
import PrintViewAuthors from "./PrintViewAuthors";
import PrintViewDetailField from "./PrintViewDetailField";
import PrintViewRecordLocation from "./PrintViewRecordLocation";

const PrintViewVeraDetails = ({
  record,
  shouldDisplayAuthorField,
}: IPrintViewVeraDetailsProps) => {
  return (
    <div className="pb-3" id="print-view-vera-details">
      <h4>VERA Details</h4>

      <div className="container-fluid row">
        <PrintViewRecordLocation record={record} />

        {shouldDisplayAuthorField && (
          <PrintViewDetailField
            label={
              record.authors && record.authors?.length > 1
                ? "Authors:"
                : "Author"
            }
            value={
              !record.authors || !record.authors?.length ? (
                "Unknown"
              ) : (
                <PrintViewAuthors authors={record.authors} />
              )
            }
          />
        )}

        <PrintViewDetailField label="Record Type:" value={record.record_type} />

        <PrintViewDetailField label="Record Status:" value={record.status} />

        <PrintViewDetailField label="Revision:" value={record.revision} />
      </div>
    </div>
  );
};

export default PrintViewVeraDetails;
