import React from "react";
import { useEffect } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IConnectionTableData } from "../../../interfaces/Connections";
import Loader from "../../../modules/Loader";
import TableHead from "../../../modules/TableHead";
import TablePagination from "../../../modules/TablePagination";
import ConnectionsTableItem from "./ConnectionsTableItem";
import ConnectionsTableSearchField from "./ConnectionsTableSearchField";

const ConnectionsTable = () => {
  const {
    getConnectionsData,
    updateConnectionsSortParams,
    updateConnectionsPageParams,
    updateConnectionsSelectedPage,
  } = useActions();
  const { tableHeaders, sortParams, isLoading, connectionsData, selectedPage } =
    useTypedSelector((x) => x.connections);
  const [tableSearchQuery, setTableSearchQuery] = React.useState<
    Partial<IConnectionTableData>
  >({});

  const fetchTableData = () => {
    getConnectionsData(tableSearchQuery);
  };

  useEffect(() => {
    fetchTableData();
  }, [sortParams, tableSearchQuery]);

  return (
    <>
      <div className="table-search-field-wrapper">
        <ConnectionsTableSearchField fetchTableData={() => fetchTableData()} />
      </div>
      <table className="table table-bordered table-sm">
        <TableHead
          columnFiltrationIsActive={true}
          isLoading={isLoading}
          setPageParams={(offset, limit) =>
            updateConnectionsPageParams(offset, limit)
          }
          setSortParams={(fieldId, sortOrder) =>
            updateConnectionsSortParams(fieldId, sortOrder)
          }
          sortParams={sortParams}
          tableHeaders={tableHeaders}
          updateTableSearchingQuery={setTableSearchQuery}
        />

        <tbody
          className="position-relative"
          style={{
            height: isLoading || !connectionsData.rows.length ? "100px" : 0,
          }}
        >
          {!isLoading && !connectionsData.rows.length && (
            <div className="table-no-records-found">
              No matching connections found
            </div>
          )}

          {isLoading && (
            <Loader message={"Loading, please wait..."} tableMode={true} />
          )}

          {!isLoading &&
            connectionsData?.rows &&
            connectionsData.rows.map((connection) => (
              <ConnectionsTableItem
                connection={connection}
                key={connection._id}
              />
            ))}
        </tbody>
      </table>
      {connectionsData && (
        <TablePagination
          selectedPage={selectedPage}
          tableData={connectionsData}
          updatePageParams={updateConnectionsPageParams}
          updateSelectedPage={updateConnectionsSelectedPage}
        />
      )}
    </>
  );
};

export default ConnectionsTable;
