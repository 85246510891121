import Table from "../../../../../modules/Table";
import PolicyRevisionTableItem from "./PolicyRevisionTableItem";
import CollapseButton from "../../../../../modules/common/CollapseButton";
import CollapseContent from "../../../../../modules/common/CollapseContent";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../../hooks/useActions";
import ImportUpdatedPolicyModal from "../modals/ImportUpdatedPolicyModal";
import useModal from "../../../../../../hooks/useModal";

const PolicyRevisionsTable = () => {
  const { policy } = useTypedSelector((x) => x.policy);
  const policyRevisionsState = useTypedSelector((x) => x.policyRevisions);
  const {
    updatePolicyRevisionsSelectedPage,
    updatePolicyRevisionsSortParams,
    updatePolicyRevisionsPageParams,
    fetchPolicyRevisions,
  } = useActions();

  const { hide, show, visible } = useModal();

  return (
    <>
      <div className="mt-4" id="accordionPolicyRevisions">
        <CollapseButton
          label="Revisions"
          targetId="#collapsePolicies, #collapseAddPolicyRevision"
        />
        <CollapseContent
          id="collapseAddPolicyRevision"
          parentId="accordionPolicyRevisions"
        >
          <div className="table-heading">
            <button
              aria-label="add-revision-btn"
              className="btn btn-primary text-white ml-2"
              id="btn-add-revision"
              onClick={show}
              type="button"
            >
              Add Revision
            </button>
          </div>
        </CollapseContent>
        <CollapseContent
          id="collapsePolicies"
          parentId="accordionPolicyRevisions"
        >
          <Table
            {...policyRevisionsState}
            setPageParams={updatePolicyRevisionsPageParams}
            setSelectedPage={updatePolicyRevisionsSelectedPage}
            setSortParams={updatePolicyRevisionsSortParams}
          >
            {policyRevisionsState.tableData?.rows?.map((policyRevision) => (
              <PolicyRevisionTableItem
                key={policyRevision._id}
                name={policy?.Name}
                policy={policyRevision}
              />
            ))}
          </Table>
        </CollapseContent>
        {visible && policy && (
          <ImportUpdatedPolicyModal
            closeModal={hide}
            onSuccess={() => fetchPolicyRevisions(policy._id)}
            policyId={policy?._id}
            policyName={policy.Name}
            policyType={policy.Type}
            visible={visible}
          />
        )}
      </div>
    </>
  );
};

export default PolicyRevisionsTable;
