import { Dispatch } from "redux";
import {
  ExpandedContentAction,
  ExpandedContentModalActionTypes,
  IContentProps,
} from "../types/expandedContentModal";

export const openExpandedContentModal = (payload: IContentProps) => {
  return async (dispatch: Dispatch<ExpandedContentAction>) => {
    dispatch({
      type: ExpandedContentModalActionTypes.SHOW_EXPANDED_CONTENT_MODAL,
      payload,
    });
  };
};

export const closeExpandedContentModal = () => {
  return async (dispatch: Dispatch<ExpandedContentAction>) => {
    dispatch({
      type: ExpandedContentModalActionTypes.HIDE_EXPANDED_CONTENT_MODAL,
    });
  };
};
