import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import {
  AdminPageRoutes,
  AdminRequestRoutes,
  ErrorMessage,
  ProfileRequestRoutes,
} from "../../../../constants/general.constants";
import {
  AdminPageQueryParams,
  IPasswordPolicyFormData,
  IPasswordPolicyResponse,
} from "../../../interfaces/AdminPage";
import {
  ProfileData,
  UserRolesListResponse,
} from "../../../../store/types/user";
import UserDetailsForm from "../../../modules/UserDetailsForm";
import Loader from "../../../modules/Loader";
import ErrorAlert from "../../../modules/ErrorAlert";

const UserDetailsPage = () => {
  const history = useHistory();
  const { userId } = useParams<AdminPageQueryParams>();
  const [userDetails, setUserDetails] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [passwordPolicyData, setPasswordPolicyData] =
    useState<IPasswordPolicyFormData>({
      minLower: 0,
      minUpper: 0,
      minNumeric: 0,
      minSpecial: 0,
      minLength: 0,
    });
  let cancelToken: CancelTokenSource;

  const getUserDetailsData = async () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();
    try {
      const [
        { data: profileData },
        { data: systemRoles },
        { data: passwordPolicy },
      ] = await Promise.all([
        axios.get<ProfileData>(`${AdminRequestRoutes.USER_DETAILS}/${userId}`, {
          cancelToken: cancelToken.token,
        }),
        axios.get<UserRolesListResponse>(
          ProfileRequestRoutes.USER_SYSTEM_ROLES_LIST,
          { cancelToken: cancelToken.token, params: { veraId: userId } },
        ),
        axios.get<IPasswordPolicyResponse>(AdminRequestRoutes.PASSWORD_POLICY, {
          cancelToken: cancelToken.token,
        }),
      ]);

      if (profileData.errorMessage) {
        toast.error(profileData.errorMessage);
        history.push(AdminPageRoutes.USER_MANAGEMENT);
        return;
      }

      const passwordPolicyData = {
        minLower: passwordPolicy.apiData.min_lower,
        minUpper: passwordPolicy.apiData.min_upper,
        minNumeric: passwordPolicy.apiData.min_numeric,
        minSpecial: passwordPolicy.apiData.min_special,
        minLength: passwordPolicy.apiData.min_length,
      };

      const roles = new Set(profileData.systemRoles);
      if (roles.has("User Administrator")) {
        systemRoles.results.push({
          id: "User Administrator",
          selected: true,
          text: "User Administrator",
        });
      }

      const result = {
        ...profileData,
        systemRolesList: systemRoles.results.map((x) => ({
          ...x,
          selected: profileData.systemRoles.includes(x.text),
        })),
      };
      setPasswordPolicyData(passwordPolicyData);
      setUserDetails(result);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
        history.push(AdminPageRoutes.USER_MANAGEMENT);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        history.push(AdminPageRoutes.USER_MANAGEMENT);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetailsData();
  }, []);

  return (
    <div>
      <div className="table-heading-wrapper">
        <b>User Profile</b>
      </div>

      {loading && (
        <div className="col-10">
          <Loader message={"Loading, please wait..."} />
        </div>
      )}

      {userDetails && (
        <UserDetailsForm
          adminAccess={true}
          passwordPolicyData={passwordPolicyData}
          profileData={userDetails}
        />
      )}
    </div>
  );
};

export default UserDetailsPage;
