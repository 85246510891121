import { IDomainsAndRolesResponse } from "../../components/interfaces/DomainsAndRoles";
import { IDomainFilterResponse } from "../../components/interfaces/DomainsAndRoles";

export enum DomainsAndRolesActionTypes {
  FETCHING_DOMAINS = "FETCHING_DOMAINS",
  UPDATE_DOMAINS = "UPDATE_DOMAINS",
  SHOW_MORE_DOMAINS = "SHOW_MORE_DOMAINS",
  UPDATE_SEARCH_FIELD_DOMAIN = "UPDATE_SEARCH_FIELD_DOMAIN",
  UPDATE_SELECTED_DOMAINS = "UPDATE_SELECTED_DOMAINS",
  CLEAR_SELECTED_DOMAINS = "CLEAR_SELECTED_DOMAINS",
  UPDATE_SEARCH_FIELD_ROLE = "UPDATE_SEARCH_FIELD_ROLE",
  FETCHING_DOMAINS_AND_ROLES = "FETCHING_DOMAINS_AND_ROLES",
  UPDATE_DOMAINS_AND_ROLES = "UPDATE_DOMAINS_AND_ROLES",
  CLEAR_FILTER = "CLEAR_FILTER",
  UPDATE_SELECTED_USER_ROLES = "UPDATE_SELECTED_USER_ROLES",
  UPDATE_DOMAIN_AND_ROLES_SORT_PARAMS = "UPDATE_DOMAIN_AND_ROLES_SORT_PARAMS",
  UPDATE_DOMAIN_AND_ROLES_PAGE_PARAMS = "UPDATE_DOMAIN_AND_ROLES_PAGE_PARAMS",
  RESET_DOMAIN_AND_ROLES_SORT_PARAMS = "RESET_DOMAIN_AND_ROLES_SORT_PARAMS",
  RESET_DOMAINS_AND_ROLES_DATA = "RESET_DOMAINS_AND_ROLES_DATA",
  RESET_USER_DATA = "RESET_USER_DATA",
  UPDATE_DOMAIN_SELECTED_PAGE = "UPDATE_DOMAIN_SELECTED_PAGE",
  TOGGLE_SHOW_ALL_DOMAINS = "TOGGLE_SHOW_ALL_DOMAINS",
  HANDLE_NETWORK_ERROR_DOMAINS = "HANDLE_NETWORK_ERROR_DOMAINS",
  HANDLE_NETWORK_ERROR_ROLES = "HANDLE_NETWORK_ERROR_ROLES",
  HANDLE_NETWORK_ERROR_DOMAINS_AND_ROLES = "HANDLE_NETWORK_ERROR_DOMAINS_AND_ROLES",
  HANDLE_NETWORK_SAVE_DOMAIN_TO_USER = "HANDLE_NETWORK_SAVE_DOMAIN_TO_USER",
  RESET_ALL_DOMAINS_AND_ROLES_DATA = "RESET_ALL_DOMAINS_AND_ROLES_DATA",
}

interface FetchingDomains {
  type: DomainsAndRolesActionTypes.FETCHING_DOMAINS;
  payload: number;
}

interface UpdateDomains {
  type: DomainsAndRolesActionTypes.UPDATE_DOMAINS;
  payload: IDomainFilterResponse;
}

interface ShowMoreDomains {
  type: DomainsAndRolesActionTypes.SHOW_MORE_DOMAINS;
  payload: IDomainFilterResponse;
}

interface UpdateSelectedDomains {
  type: DomainsAndRolesActionTypes.UPDATE_SELECTED_DOMAINS;
  payload: string;
}

interface ClearSelectedDomains {
  type: DomainsAndRolesActionTypes.CLEAR_SELECTED_DOMAINS;
}

interface HandleNetworkErrorDomains {
  type: DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_DOMAINS;
}

interface HandleNetworkErrorRoles {
  type: DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_ROLES;
}

interface HandleNetworkErrorDomainsAndRoles {
  type: DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_DOMAINS_AND_ROLES;
}

interface HandleNetworkErrorSaveDomainToUser {
  type: DomainsAndRolesActionTypes.HANDLE_NETWORK_SAVE_DOMAIN_TO_USER;
}

interface UpdateSearchFieldDomain {
  type: DomainsAndRolesActionTypes.UPDATE_SEARCH_FIELD_DOMAIN;
  payload: {
    searchField: string;
  };
}

interface UpdateSearchFieldRole {
  type: DomainsAndRolesActionTypes.UPDATE_SEARCH_FIELD_ROLE;
  payload: {
    searchField: string;
  };
}

interface ClearFilter {
  type: DomainsAndRolesActionTypes.CLEAR_FILTER;
}

interface FetchingDomainsAndRoles {
  type: DomainsAndRolesActionTypes.FETCHING_DOMAINS_AND_ROLES;
}

interface UpdateDomainsAndRoles {
  type: DomainsAndRolesActionTypes.UPDATE_DOMAINS_AND_ROLES;
  payload: {
    domainAndRoles: IDomainsAndRolesResponse;
    userId: string;
  };
}

interface UpdateUserSelectedRoles {
  type: DomainsAndRolesActionTypes.UPDATE_SELECTED_USER_ROLES;
  payload: {
    domainId: string;
    roles: string[];
  };
}

interface HandleResetDomainAndRolesSortParams {
  type: DomainsAndRolesActionTypes.RESET_DOMAIN_AND_ROLES_SORT_PARAMS;
}

interface HandleResetDomainAndRolesData {
  type: DomainsAndRolesActionTypes.RESET_DOMAINS_AND_ROLES_DATA;
}

interface HandleResetUserData {
  type: DomainsAndRolesActionTypes.RESET_USER_DATA;
}

interface UpdateDomainAndRolesSortParams {
  type: DomainsAndRolesActionTypes.UPDATE_DOMAIN_AND_ROLES_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdateDomainAndRolesPageParams {
  type: DomainsAndRolesActionTypes.UPDATE_DOMAIN_AND_ROLES_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface UpdateSelectedPage {
  type: DomainsAndRolesActionTypes.UPDATE_DOMAIN_SELECTED_PAGE;
  payload: number;
}

interface ToggleShowAllDomains {
  type: DomainsAndRolesActionTypes.TOGGLE_SHOW_ALL_DOMAINS;
}

interface ResetData {
  type: DomainsAndRolesActionTypes.RESET_ALL_DOMAINS_AND_ROLES_DATA;
}

export type DomainsAndRolesAction =
  | FetchingDomains
  | UpdateDomains
  | ShowMoreDomains
  | UpdateSelectedDomains
  | ClearSelectedDomains
  | UpdateSearchFieldDomain
  | UpdateSearchFieldRole
  | ClearFilter
  | FetchingDomainsAndRoles
  | UpdateDomainsAndRoles
  | UpdateUserSelectedRoles
  | HandleResetDomainAndRolesSortParams
  | HandleResetDomainAndRolesData
  | HandleResetUserData
  | UpdateDomainAndRolesSortParams
  | UpdateDomainAndRolesPageParams
  | HandleNetworkErrorDomains
  | HandleNetworkErrorRoles
  | HandleNetworkErrorDomainsAndRoles
  | HandleNetworkErrorSaveDomainToUser
  | UpdateSelectedPage
  | ToggleShowAllDomains
  | ResetData;
