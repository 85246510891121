import Toggle from "@atlaskit/toggle";
import Tooltip from "@atlaskit/tooltip";
import { IRolesByDomain } from "../../interfaces/DomainsAndRoles";
import { useUpdateEmailNotificationsByDomainMutation } from "../../../store/slices/user.slice";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useState } from "react";
import { toast } from "react-toastify";

const DomainsAndRolesListItem = ({
  domainsAndRoles: { domain, notificationTypes, roles },
}: {
  domainsAndRoles: IRolesByDomain;
}) => {
  const { user } = useTypedSelector((x) => x.user);
  const [isChecked, setIsChecked] = useState(
    Object.values(notificationTypes).every((x) => x),
  );

  const [updateEmailNotificationsByDomain] =
    useUpdateEmailNotificationsByDomainMutation();

  const handleOnClickToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!user?.veraUserId) {
      return;
    }

    setIsChecked((x) => !x);

    const response = updateEmailNotificationsByDomain({
      userId: user?.veraUserId,
      domainId: domain.id,
      enableNotifications: e.target.checked,
    });

    response
      .unwrap()
      .then((x: any) => toast.success(x.message))
      .catch((error: any) => {
        setIsChecked((x) => !x);
        toast.error(error.data?.message);
      });
  };

  return (
    <div className="domain-and-roles-item">
      <div className="profile-domain">{domain.name}</div>
      <div className="profile-roles">{roles.join(", ")}</div>
      <div className="profile-email-notifications">
        <Tooltip
          content={isChecked ? "Disable notifications" : "Enable notifications"}
          position="top"
        >
          <Toggle
            id={`toggle-set-domain_${domain.name.replace(" ", "_")}`}
            isChecked={isChecked}
            isDisabled={false}
            onChange={handleOnClickToggle}
            size="regular"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default DomainsAndRolesListItem;
