import React, { useEffect } from "react";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import Tooltip from "@atlaskit/tooltip";
import Button from "@atlaskit/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useActions } from "../../../../../hooks/useActions";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import { ISortParams } from "../../../../interfaces/ApprovalQueue";
import {
  IDomainTableProps,
  IDomainUsersResponse,
  IDomainUsersTableData,
} from "../../../../interfaces/AdminPage";
import TableHead from "../../../../modules/TableHead";
import Loader from "../../../../modules/Loader";
import TablePagination from "../../../../modules/TablePagination";
import { TableHeaderItem } from "../../../../../store/types/approvalQueue";

let domainUsersCancelToken: CancelTokenSource;

const DomainUsersTable = ({ domainId }: IDomainTableProps) => {
  const [tableIsCollapsed, setTableIsCollapsed] =
    React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<IDomainUsersTableData>({
    rows: [],
    total: 0,
    totalNotFiltered: 0,
  });
  const [selectedPage, setSelectedPage] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sortParams, setSortParams] = React.useState<ISortParams>({
    sort: "UserName",
    order: "asc",
    offset: 0,
    limit: 10,
  });
  const tableHeaders: TableHeaderItem[] = [
    { label: "User Name", name: "userName", filterable: true, colSize: 3 },
    { label: "Full Name", name: "fullname", filterable: true, colSize: 3 },
    {
      label: "Roles",
      name: "roles",
      filterable: true,
      colSize: 4,
      notSortable: true,
    },
  ];

  const getDomainUsersListData = async (sortParams: ISortParams) => {
    if (typeof domainUsersCancelToken != typeof undefined) {
      domainUsersCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    domainUsersCancelToken = axios.CancelToken.source();

    try {
      setLoading(true);

      const { data }: IDomainUsersResponse = await axios.get(
        AdminRequestRoutes.DOMAIN_USERS_LIST,
        {
          cancelToken: domainUsersCancelToken.token,
          params: { ...sortParams, domainId },
        },
      );
      setTableData(data);
      setLoading(false);
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        setLoading(false);
      }
    }
  };
  const updateSortParams = (
    sort: string = sortParams.sort,
    order: "asc" | "desc" = sortParams.order,
  ) => {
    setSelectedPage(0);
    setSortParams((prev) => ({
      ...prev,
      sort,
      order,
      offset: 0,
    }));
  };

  const updatePageParams = (
    offset: number = sortParams.offset,
    limit: number = sortParams.limit,
  ) => {
    if (offset === 0) {
      setSelectedPage(0);
    }

    setSortParams((prev) => ({
      ...prev,
      offset,
      limit,
    }));
  };

  useEffect(() => {
    getDomainUsersListData(sortParams);
  }, [sortParams]);

  return (
    <div
      aria-label="domain-management-table"
      className="row pt-3"
      id="accordionUsers"
    >
      <a
        className="text-decoration-none toggleRotate col"
        data-bs-target="#collapseUsers, #collapseUsersToolbar"
        data-bs-toggle="collapse"
        href="#!"
        onClick={() => setTableIsCollapsed(!tableIsCollapsed)}
      >
        <i
          className={`fa ${
            tableIsCollapsed
              ? "fa-chevron-circle-right"
              : "fa-chevron-circle-down"
          }`}
        />
        <h6 className="d-inline"> Domain Users</h6>
      </a>

      <div
        className="collapse show col container-fluid"
        data-bs-parent="#accordionUsers"
        id="collapseUsersToolbar"
      >
        <div className="domain-management-table-heading">
          <Tooltip content="Refresh table" position="top">
            <Button
              appearance="subtle"
              aria-label="refresh-btn"
              onClick={() => getDomainUsersListData(sortParams)}
            >
              <FontAwesomeIcon icon={faSyncAlt} />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div
        className="collapse show container-fluid"
        data-bs-parent="#accordionUsers"
        id="collapseUsers"
      >
        <table className="table table-bordered table-sm">
          <TableHead
            displayActionsColumn={false}
            isLoading={false}
            setPageParams={updatePageParams}
            setSortParams={updateSortParams}
            sortParams={sortParams}
            tableHeaders={tableHeaders}
          />

          <tbody
            className="position-relative"
            style={{ height: loading || !tableData?.rows?.length ? "10vh" : 0 }}
          >
            {loading && (
              <Loader message={"Loading, please wait..."} tableMode={true} />
            )}
            {!loading && !tableData?.rows?.length && (
              <div className="table-no-records-found">
                No matching users found.
              </div>
            )}
            {!loading &&
              tableData?.rows?.map((user) => (
                <tr>
                  <td>{user.userName}</td>

                  <td>{user.fullName}</td>
                  <td>{user.roles?.join(", ")}</td>
                </tr>
              ))}
          </tbody>
        </table>

        {tableData?.rows?.length > 0 && (
          <TablePagination
            initialRowsPerPage={10}
            selectedPage={selectedPage}
            tableData={tableData}
            updatePageParams={updatePageParams}
            updateSelectedPage={setSelectedPage}
          />
        )}
      </div>
    </div>
  );
};

export default DomainUsersTable;
