import { IRoute } from "../../../interfaces/DetailsPage";

const PrintViewNoSignatures = ({ route }: { route?: IRoute }) => {
  return (
    <div className="container-fluid row d-flex flex-column no-signature-info">
      <h6>{route?.name}</h6>
      <div className="details-field">
        No signatures required for this record
      </div>
      {route?.stopDate && (
        <div>
          <span>Date: </span>
          {route?.stopDate ?? ""}
        </div>
      )}
    </div>
  );
};

export default PrintViewNoSignatures;
