import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { PolicyRequestRoutes } from "../../constants/policy.constants";
import { ILinkedPolicySet } from "../../components/interfaces/Policy";
import { RootState } from "../reducers";
import {
  PolicyLinkedPolicySetsAction,
  PolicyLinkedPolicySetsActionTypes,
} from "../types/policyLinkedPolicySets";
import { IDefaultResponse } from "../../components/interfaces/General";
import { ITableData } from "../../components/interfaces/Table";
import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";

let policyRevisionCancelToken: CancelTokenSource;
export const fetchPolicySetsFromPolicy = (policyId: string) => {
  return async (
    dispatch: Dispatch<PolicyLinkedPolicySetsAction>,
    getState: Function,
  ) => {
    if (typeof policyRevisionCancelToken != typeof undefined) {
      policyRevisionCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    policyRevisionCancelToken = axios.CancelToken.source();

    const state = getState() as RootState;
    const { offset, ...rest } = state.policyLinkedPolicies.sortParams;

    const sortParams = { ...rest, skip: offset };
    const params = {
      id: policyId,
      name: state.policyLinkedPolicies.columnSearchField?.name,
      ...sortParams,
    };

    try {
      dispatch({
        type: PolicyLinkedPolicySetsActionTypes.FETCHING_LINKED_POLICY_SETS_TABLE_DATA,
      });

      const { data, status } = await axios.get<
        IDefaultResponse<ITableData<ILinkedPolicySet>>
      >(PolicyRequestRoutes.LINKED_POLICIES, {
        cancelToken: policyRevisionCancelToken.token,
        params,
      });

      if (status === 200) {
        dispatch({
          type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_TABLE_DATA,
          payload: data.apiData,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: PolicyLinkedPolicySetsActionTypes.HANDLE_LINKED_POLICY_SETS_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const updatePolicyLinkedPoliciesSelectedPage = (
  selectedPage: number,
) => {
  return {
    type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const updatePolicyLinkedPoliciesSortParams = (
  sort: string,
  order: "asc" | "desc",
) => {
  return async (dispatch: Dispatch<PolicyLinkedPolicySetsAction>) => {
    dispatch({
      type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updatePolicyLinkedPoliciesPageParams = (
  offset: number,
  limit: number,
) => {
  return async (dispatch: Dispatch<PolicyLinkedPolicySetsAction>) => {
    dispatch({
      type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetPolicyLinkedPoliciesSortParams = () => {
  return async (dispatch: Dispatch<PolicyLinkedPolicySetsAction>) => {
    dispatch({
      type: PolicyLinkedPolicySetsActionTypes.RESET_LINKED_POLICY_SETS_SORT_PARAMS,
    });
  };
};

export const handleResetPolicyLinkedPoliciesData = () => {
  policyRevisionCancelToken &&
    policyRevisionCancelToken.cancel(ErrorMessage.LOGOUT);
  return async (dispatch: Dispatch<PolicyLinkedPolicySetsAction>) => {
    dispatch({
      type: PolicyLinkedPolicySetsActionTypes.RESET_LINKED_POLICY_SETS_DATA,
    });
  };
};

export const updatePolicyLinkedPoliciesColumnSearchField =
  (columnSearchField: IColumnsSearchFieldsFormData) =>
  async (dispatch: Dispatch<PolicyLinkedPolicySetsAction>) => {
    dispatch({
      type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_COLUMN_SEARCH_FIELD,
      payload: columnSearchField,
    });
  };
