import sanitizeHtml from "sanitize-html";
import { stripHtml } from "string-strip-html";

export const getAllowedHtmlTags = () => {
  return [
    "div",
    "strong",
    "em",
    "p",
    "a",
    "i",
    "u",
    "strike",
    "s",
    "del",
    "font",
    "br",
    "table",
    "caption",
    "th",
    "tr",
    "td",
    "thead",
    "tbody",
    "tfoot",
    "label",
    "col",
    "colgroup",
    "span",
    "b",
    "ol",
    "base",
    "h1",
    "h2",
    "ins",
    "sub",
    "sup",
    "kbd",
    "bdo",
    "h3",
    "h4",
    "h5",
    "h6",
    "ol",
    "ul",
    "li",
    "section",
    "article",
    "cite",
    "pre",
    "img",
  ];
};

const convertFormatTagToHtmlTag = (text: string) => {
  return text.replace(/{noformat}([\s\S]*?){noformat}/g, (match, group) => {
    return `<no-format>${group}</no-format>`;
  });
};

const removeXmlTags = (text: string) => {
  return text.replace(/<[^>\s]*:[^>\s]*\s*[^>]*>/g, "");
};

export const escapeOnlyNonHtmlTags = (text: string) => {
  const htmlTagsToBeRemoved = ["html", "body"];

  const removeHtmlTags = ({
    tag,
    deleteFrom,
    deleteTo,
    insert,
    rangesArr,
  }: any) => {
    if (htmlTagsToBeRemoved.includes(tag.name)) {
      deleteFrom != null &&
        deleteTo != null &&
        rangesArr.push(deleteFrom, deleteTo, insert);
    }
  };

  if (text) {
    let html = stripHtml(text, {
      skipHtmlDecoding: true,
      cb: removeHtmlTags,
    }).result;

    html = convertFormatTagToHtmlTag(html);

    html = removeXmlTags(html);
    return sanitizeHtml(html, {
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        "*": ["style", "class"],
        bdo: ["dir"],
        font: ["color"],
        img: ["src"],
      },
      allowedTags:
        sanitizeHtml.defaults.allowedTags.concat(getAllowedHtmlTags()),
      disallowedTagsMode: "recursiveEscape",
      parseStyleAttributes: false,
    }).replace(
      /&lt;no-format&gt;([\s\S]*?)&lt;\/no-format&gt;/g,
      (match, content) => content,
    );
  }

  return "";
};

export const isAnchorLink = (text: string) => {
  return text?.trim()?.toLowerCase().startsWith("<a");
};
