import { DatePicker } from "@mui/x-date-pickers";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { ePeriod } from "../../../../../interfaces/User";
import { useAppDispatch } from "../../../../../../store/store";
import {
  setStartDate,
  setEndDate,
  setErrorEndDate,
  setErrorStartDate,
} from "../../../../../../store/slices/user.slice";
import dayjs from "dayjs";
import { useMemo } from "react";

const ConcurrentUserRangeDate = () => {
  const dispatch = useAppDispatch();
  const { period, startDate, errorStartDate, endDate, errorEndDate } =
    useTypedSelector((x) => x.concurrentUsersReport);

  const errorStartDateMessage = useMemo(() => {
    switch (errorStartDate) {
      case "minDate": {
        return "Date must be greater than or equal to 01/01/1900";
      }

      case "maxDate": {
        return `Date must be today or earlier`;
      }

      case "invalidDate": {
        return "Date is not valid";
      }

      default: {
        return errorStartDate;
      }
    }
  }, [errorStartDate]);

  const errorEndDateMessage = useMemo(() => {
    switch (errorEndDate) {
      case "maxDate": {
        return `Date must be today or earlier`;
      }

      case "minDate": {
        return `Date must be greater than or equal to ${dayjs(startDate).format(
          "MM/DD/YYYY",
        )}`;
      }

      case "invalidDate": {
        return "Date is not valid";
      }

      default: {
        return errorEndDate;
      }
    }
  }, [errorEndDate, startDate]);

  return (
    <>
      {period === ePeriod.CUSTOM_DATE_RANGE && (
        <>
          <DatePicker
            className="concurrent-usage-date"
            maxDate={dayjs(new Date())}
            onChange={(value) => dispatch(setStartDate(value))}
            onError={(error) => dispatch(setErrorStartDate(error))}
            slotProps={{
              textField: {
                id: "concurrent-users-start-date",
                size: "small",
                helperText: errorStartDateMessage,
              },
            }}
            value={startDate ? dayjs(startDate) : null}
          />
          <DatePicker
            className="concurrent-usage-date"
            disabled={!startDate || Boolean(errorStartDate)}
            maxDate={dayjs(new Date())}
            minDate={dayjs(startDate)}
            onChange={(value) => dispatch(setEndDate(value))}
            onError={(error) => dispatch(setErrorEndDate(error))}
            slotProps={{
              textField: {
                id: "concurrent-users-end-date",
                size: "small",
                helperText: errorEndDateMessage,
              },
            }}
            value={endDate ? dayjs(endDate) : null}
          />
        </>
      )}
    </>
  );
};

export default ConcurrentUserRangeDate;
