import { IUserManagementItem } from "../../components/interfaces/AdminPage";
import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";
import { ITableData } from "../../components/interfaces/Table";

export enum UsersActionTypes {
  FETCHING_USERS_TABLE_DATA = "FETCHING_USERS_TABLE_DATA",
  UPDATE_USERS_TABLE_DATA = "UPDATE_USERS_TABLE_DATA",
  UPDATE_USERS_SELECTED_PAGE = "UPDATE_USERS_SELECTED_PAGE",
  UPDATE_USERS_SORT_PARAMS = "UPDATE_USERS_SORT_PARAMS",
  UPDATE_USERS_PAGE_PARAMS = "UPDATE_USERS_PAGE_PARAMS",
  UPDATE_USERS_COLUMN_SEARCH_FIELD = "UPDATE_POLICY_SETS_COLUMN_SEARCH_FIELD",
  RESET_USERS_SORT_PARAMS = "RESET_POLICY_SETS_SORT_PARAMS",
  HANDLE_USERS_NETWORK_ERROR = "HANDLE_POLICY_SETS_NETWORK_ERROR",
  RESET_USERS_DATA = "RESET_POLICY_SETS_DATA",
}

interface FetchingUsersData {
  type: UsersActionTypes.FETCHING_USERS_TABLE_DATA;
}

interface UpdateUsersTableData {
  type: UsersActionTypes.UPDATE_USERS_TABLE_DATA;
  payload: ITableData<IUserManagementItem>;
}

interface UpdateUsersSelectedPage {
  type: UsersActionTypes.UPDATE_USERS_SELECTED_PAGE;
  payload: number;
}

interface HandleUsersNetworkError {
  type: UsersActionTypes.HANDLE_USERS_NETWORK_ERROR;
  payload: string;
}

interface UpdateUsersSortParams {
  type: UsersActionTypes.UPDATE_USERS_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdateUsersPageParams {
  type: UsersActionTypes.UPDATE_USERS_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface HandleResetUsersSortParams {
  type: UsersActionTypes.RESET_USERS_SORT_PARAMS;
}

interface UpdateUsersColumnSearchField {
  type: UsersActionTypes.UPDATE_USERS_COLUMN_SEARCH_FIELD;
  payload: IColumnsSearchFieldsFormData;
}

interface HandleResetUsersData {
  type: UsersActionTypes.RESET_USERS_DATA;
}

export type UsersAction =
  | UpdateUsersTableData
  | FetchingUsersData
  | UpdateUsersSelectedPage
  | HandleUsersNetworkError
  | HandleResetUsersData
  | UpdateUsersSortParams
  | UpdateUsersPageParams
  | UpdateUsersColumnSearchField
  | HandleResetUsersSortParams;
