import { IDefectsModalData } from "../../components/interfaces/DefectsModal";

export enum DefectsModalActionTypes {
  SHOW_DEFECTS_MODAL = "SHOW_DEFECTS_MODAL",
  HIDE_DEFECTS_MODAL = "HIDE_DEFECTS_MODAL",
}

interface showDefectsModal {
  type: DefectsModalActionTypes.SHOW_DEFECTS_MODAL;
  payload: IDefectsModalData;
}

interface hideDefectsModal {
  type: DefectsModalActionTypes.HIDE_DEFECTS_MODAL;
}

export type DefectsModalAction = showDefectsModal | hideDefectsModal;
