import { Dispatch } from "redux";
import {
  LocatePolicyFilterAction,
  LocatePolicyFilterActionTypes,
} from "../types/locatePolicyFilter";

export const updateSelectedPolicyType = (name: string, selected: boolean) => {
  return async (dispatch: Dispatch<LocatePolicyFilterAction>) => {
    dispatch({
      type: LocatePolicyFilterActionTypes.UPDATE_SELECTED_POLICY_TYPE,
      payload: { name, isSelected: selected },
    });
  };
};

export const updatePolicyNameSearchField = (searchField: string) => {
  return async (dispatch: Dispatch<LocatePolicyFilterAction>) => {
    dispatch({
      type: LocatePolicyFilterActionTypes.UPDATE_POLICY_NAME_SEARCH_FIELD,
      payload: { searchField },
    });
  };
};

export const toggleOnlyShowDefaultPolicies = () => {
  return async (dispatch: Dispatch<LocatePolicyFilterAction>) => {
    dispatch({
      type: LocatePolicyFilterActionTypes.TOGGLE_ONLY_SHOW_DEFAULT_POLICIES,
    });
  };
};

export const clearSelectedPolicyType = () => {
  return async (dispatch: Dispatch<LocatePolicyFilterAction>) => {
    dispatch({
      type: LocatePolicyFilterActionTypes.CLEAR_SELECTED_POLICY_TYPE,
    });
  };
};

export const clearPolicyFilters = () => {
  return async (dispatch: Dispatch<LocatePolicyFilterAction>) => {
    dispatch({ type: LocatePolicyFilterActionTypes.CLEAR_POLICY_FILTER });
  };
};
