import { useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Tooltip from "@atlaskit/tooltip";
import Loader from "../../modules/Loader";
import Toggle from "@atlaskit/toggle";
import DomainsAndRolesHeader from "./DomainsAndRolesHeader";
import DomainAndRolesContent from "./DomainsAndRolesContent";

const DomainsAndRolesList = () => {
  return (
    <div className="domains-and-roles-list domains-and-roles-list--profile">
      <DomainsAndRolesHeader />
      <DomainAndRolesContent />
    </div>
  );
};

export default DomainsAndRolesList;
