import { IDomainsTableData } from "../../components/interfaces/AdminPage";

export enum DomainActionTypes {
  FETCHING_DOMAIN_DATA = "FETCHING_DOMAIN_DATA",
  UPDATE_DOMAIN_DATA = "UPDATE_DOMAIN_DATA",
  HANDLE_DOMAIN_ERROR = "HANDLE_DOMAIN_ERROR",
  UPDATE_DOMAIN_SORT_PARAMS = "UPDATE_DOMAIN_SORT_PARAMS",
  UPDATE_DOMAIN_PAGE_PARAMS = "UPDATE_DOMAIN_PAGE_PARAMS",
  UPDATE_DOMAIN_SELECTED_PAGE = "UPDATE_DOMAIN_SELECTED_PAGE",
  RESET_DOMAIN_DATA = "RESET_DOMAIN_DATA",
}

interface FetchingData {
  type: DomainActionTypes.FETCHING_DOMAIN_DATA;
}

interface UpdateTableData {
  type: DomainActionTypes.UPDATE_DOMAIN_DATA;
  payload: IDomainsTableData;
}

interface ResetDomainData {
  type: DomainActionTypes.RESET_DOMAIN_DATA;
}

interface UpdateSelectedPage {
  type: DomainActionTypes.UPDATE_DOMAIN_SELECTED_PAGE;
  payload: number;
}

interface HandleDomainError {
  type: DomainActionTypes.HANDLE_DOMAIN_ERROR;
}

interface UpdateDomainSortParams {
  type: DomainActionTypes.UPDATE_DOMAIN_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdateDomainPageParams {
  type: DomainActionTypes.UPDATE_DOMAIN_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

export type DomainAction =
  | UpdateTableData
  | FetchingData
  | ResetDomainData
  | HandleDomainError
  | UpdateDomainSortParams
  | UpdateDomainPageParams
  | UpdateSelectedPage;
