export enum PolicyRequestRoutes {
  POLICY_LIST = "/api/admin/policies",
  DELETE_POLICY = "/api/admin/delete-policy",
  DOWNLOAD_POLICY = "/api/admin/download-policy",
  UPLOAD_NEW_POLICY = "/api/admin/upload-new-policy",
  UPLOAD_POLICY_REVISION = "/api/admin/upload-policy-revision",
  POLICY_DEFAULT = "/api/admin/default-policy",
  POLICY_REVISIONS = "/api/admin/policy-revisions",
  POLICY = "/api/admin/policy",
  LINKED_POLICIES = "/api/admin/linked-policy-sets",
}
