import { escapeOnlyNonHtmlTags } from "../../../../../helpers/html.helper";
import { IRecord } from "../../../../interfaces/DetailsPage";
import { useActions } from "../../../../../hooks/useActions";

interface ISubStepsTableItemProps {
  record: IRecord;
}

const SubStepsTableItem = ({ record }: ISubStepsTableItemProps) => {
  const { openAttachmentsModal } = useActions();
  const { openDefectsModal } = useActions();

  const { _id, attachments, fields, linked_records, name, status } = record;

  return (
    <tr key={_id}>
      <td>{name}</td>
      <td>{status}</td>
      {fields.map((field) => (
        <td
          className="external-content"
          dangerouslySetInnerHTML={{
            __html: escapeOnlyNonHtmlTags(field.value),
          }}
          key={`${field.label}-${field.value}`}
        />
      ))}
      {attachments.length > 0 && (
        <td>
          <button
            aria-label="sub-record-attachments-modal"
            className="btn btn-light"
            id="sub-table-btn-${i}"
            onClick={() => openAttachmentsModal({ attachments })}
            type="button"
          >
            <i aria-hidden="true" className="fa fa-paperclip" />
          </button>
        </td>
      )}
      {Object.prototype.hasOwnProperty.call(linked_records, "Defects") && (
        <td>
          <button
            className="btn btn-light"
            id="sub-table-btn-${i}"
            onClick={() =>
              openDefectsModal({
                defects: linked_records["Defects"],
                recordId: _id,
              })
            }
            type="button"
          >
            <i aria-hidden="true" className="fa fa-bug" />
          </button>
        </td>
      )}
    </tr>
  );
};

export default SubStepsTableItem;
