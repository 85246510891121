import { useEffect } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Loader from "../../../modules/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import DomainAndRoles from "./DomainAndRoles";

const DomainsAndRolesList = ({ userId }: { userId?: string | null }) => {
  const { domainAndRoles, isLoadingDomainsAndRoles, sortParams } =
    useTypedSelector((state) => state.domainsAndRoles);

  const {
    fetchDomainsAndRolesFromUser,
    updateDomainAndRolesSortParams,
    handleResetAllDomainsAndRolesData,
  } = useActions();

  useEffect(() => {
    userId && fetchDomainsAndRolesFromUser(userId, sortParams);
  }, [sortParams]);

  useEffect(() => {
    return () => {
      handleResetAllDomainsAndRolesData();
    };
  }, []);

  const getDomainsAndRolesListContent = () => {
    if (isLoadingDomainsAndRoles) {
      return <Loader message="Loading, please wait..." />;
    }

    if (domainAndRoles.rows.length == 0) {
      return (
        <div className="empty-list">
          <div>
            The user is not a member of any domain or the user doesn't have any
            role in the selected domains.
          </div>
          <div>
            Click <b>Show all domains</b> to assign the user to roles in
            domains.
          </div>
        </div>
      );
    }

    return domainAndRoles.rows.map((x) => {
      return (
        <DomainAndRoles domain={x.domain} roles={x.roles} userId={userId} />
      );
    });
  };

  const toggleSortCaret = () => {
    return (
      <FontAwesomeIcon
        color={`#336699`}
        icon={sortParams.order === "asc" ? faSortUp : faSortDown}
      />
    );
  };

  const handleSort = () => {
    const sortOrder = sortParams.order === "asc" ? "desc" : "asc";
    updateDomainAndRolesSortParams(sortParams.sort, sortOrder);
  };

  return (
    <div className="domains-and-roles-list">
      <>
        <div className="domains-and-roles-list__header">
          <div className="domains-and-roles-list__domain" onClick={handleSort}>
            <span>Domain</span>
            <span>{toggleSortCaret()}</span>
          </div>
          <div className="domains-and-roles-list__role">Roles</div>
        </div>
        {getDomainsAndRolesListContent()}
      </>
    </div>
  );
};

export default DomainsAndRolesList;
