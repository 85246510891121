import axios, { CancelTokenSource } from "axios";
import {
  ErrorMessage,
  AppRoutes,
  ConnectionRequestRoutes,
} from "../../constants/general.constants";
import { Dispatch } from "redux";
import {
  ConnectionDetailsActionTypes,
  ConnectionDetailsAction,
} from "../types/connection";
import { toast } from "react-toastify";
import {
  IConnection,
  ILinkableConnection,
} from "../../components/interfaces/Connections";

let connectionDetailsCancelToken: CancelTokenSource;
let supportedSystemsCancelToken: CancelTokenSource;
let linkableConnectionsCancelToken: CancelTokenSource;

export const fetchConnectionDetails = (history: any, connectionId: string) => {
  return async (dispatch: Dispatch<ConnectionDetailsAction>) => {
    if (typeof connectionDetailsCancelToken != typeof undefined) {
      connectionDetailsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    connectionDetailsCancelToken = axios.CancelToken.source();

    try {
      dispatch({
        type: ConnectionDetailsActionTypes.FETCHING_CONNECTION_DETAILS,
      });

      const { data, status } = await axios.get<IConnection>(
        ConnectionRequestRoutes.CONNECTION_DETAILS,
        { params: { id: connectionId } },
      );
      if (status === 200) {
        dispatch({
          type: ConnectionDetailsActionTypes.UPDATE_CONNECTION_DETAILS,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.errorText ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: ConnectionDetailsActionTypes.HANDLE_CONNECTION_NETWORK_ERROR,
          payload: error.message,
        });
        history.push(AppRoutes.ApprovalQueue);
      }
    }
  };
};

export const fetchSupportedSystems = () => {
  return async (dispatch: Dispatch<ConnectionDetailsAction>) => {
    if (typeof supportedSystemsCancelToken != typeof undefined) {
      supportedSystemsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    supportedSystemsCancelToken = axios.CancelToken.source();

    try {
      dispatch({
        type: ConnectionDetailsActionTypes.FETCHING_SUPPORTED_SYSTEMS,
      });

      const { data, status } = await axios.get<string[]>(
        ConnectionRequestRoutes.CONNECTION_SUPPORTED_SYSTEMS,
      );

      if (status === 200) {
        dispatch({
          type: ConnectionDetailsActionTypes.UPDATE_SUPPORTED_SYSTEMS,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.message ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: ConnectionDetailsActionTypes.HANDLE_CONNECTION_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchLinkableConnections = () => {
  return async (dispatch: Dispatch<ConnectionDetailsAction>) => {
    if (typeof linkableConnectionsCancelToken != typeof undefined) {
      linkableConnectionsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    linkableConnectionsCancelToken = axios.CancelToken.source();

    try {
      dispatch({
        type: ConnectionDetailsActionTypes.FETCHING_LINKABLE_CONNECTIONS,
      });

      const { data, status } = await axios.get<ILinkableConnection[]>(
        ConnectionRequestRoutes.LINKABLE_CONNECTIONS,
      );

      if (status === 200) {
        dispatch({
          type: ConnectionDetailsActionTypes.UPDATE_LINKABLE_CONNECTIONS,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.message ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: ConnectionDetailsActionTypes.HANDLE_CONNECTION_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};
