import { BaseQueryApi, BaseQueryFn } from "@reduxjs/toolkit/query";
import Axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { overArgs } from "lodash";
import { toast } from "react-toastify";
import { ErrorMessage } from "../constants/general.constants";

export interface AxiosBaseQueryArgs<Meta, Response = { httpStatus: 200 }> {
  meta?: Meta;
  prepareHeaders?: (
    headers: AxiosRequestHeaders,
    api: BaseQueryApi,
  ) => AxiosRequestHeaders;
  transformResponse?: (response: Response) => unknown;
}

export interface ServiceExtraOptions {
  authRequired?: boolean;
}

const getRequestConfig = (args: string | AxiosRequestConfig) => {
  if (typeof args === "string") {
    return { url: args };
  }

  return args;
};

const axiosBaseQuery = <
  Args extends AxiosRequestConfig | string = AxiosRequestConfig,
  Result = unknown,
  DefinitionExtraOptions extends ServiceExtraOptions = Record<string, unknown>,
  Meta = Record<string, unknown>,
>({
  prepareHeaders,
  meta,
  transformResponse,
}: AxiosBaseQueryArgs<Meta> = {}): BaseQueryFn<
  Args,
  Result,
  unknown,
  DefinitionExtraOptions,
  Meta
> => {
  return async (args, api, extraOptions) => {
    try {
      const requestConfig = getRequestConfig(args);
      const result = await Axios({
        baseURL: `${window.origin}/`,
        data: (requestConfig as any).body,
        ...requestConfig,
        headers: prepareHeaders
          ? prepareHeaders(requestConfig.headers || ({} as any), api)
          : requestConfig.headers,
        signal: api.signal,
        ...extraOptions,
      });

      return {
        data: transformResponse ? transformResponse(result.data) : result.data,
      };
    } catch (error: any) {
      if (Axios.isAxiosError(error)) {
        return {
          error: {
            status: error.response?.status,
            data: error.response?.data || error.message,
          },
          meta,
        };
      }

      return {
        error,
        meta,
      };
    }
  };
};

export default axiosBaseQuery;
