import { IAttachmentsModalData } from "../../components/interfaces/AttachmentsModal";

export enum AttachmentsModalActionTypes {
  SHOW_ATTACHMENT_MODAL = "SHOW_ATTACHMENT_MODAL",
  HIDE_ATTACHMENT_MODAL = "HIDE_ATTACHMENT_MODAL",
}

interface showAttachmentsModal {
  type: AttachmentsModalActionTypes.SHOW_ATTACHMENT_MODAL;
  payload: IAttachmentsModalData;
}

interface hideAttachmentsModal {
  type: AttachmentsModalActionTypes.HIDE_ATTACHMENT_MODAL;
}

export type AttachmentsModalAction =
  | showAttachmentsModal
  | hideAttachmentsModal;
