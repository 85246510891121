import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import TableSearchField from "../../../modules/TableSearchField";

interface IConnectionsTableSearchFieldProps {
  fetchTableData: () => void;
}

const ConnectionsTableSearchField = ({
  fetchTableData,
}: IConnectionsTableSearchFieldProps) => {
  const { sortParams } = useTypedSelector((x) => x.connections);

  return (
    <TableSearchField
      fetchTableData={() => fetchTableData()}
      sortParams={sortParams}
    />
  );
};

export default ConnectionsTableSearchField;
