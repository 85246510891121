export enum ProfilePageWarnings {
  EMPTY_IDP_USERNAME = "An Identity Provider username is required when the Identity Provider is not VERA.",
  EMPTY_PASSWORD = "Cannot switch to the VERA Authentication without providing a new password.",
  INVALID_ENTRY = "Invalid entry. Changes will not be saved.",
}

export enum ProfilePageErrors {
  RESET_PASSWORD = "An error occurred when attempting to reset the password.",
}

export enum ProfilePageSuccessMessages {
  UPDATE_PROFILE_DATA = "Profile has been updated successfully.",
  RESET_PASSWORD = "The password has been reset successfully. You may close the dialog.",
}
