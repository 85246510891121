import Tooltip from "@atlaskit/tooltip";
import { Link } from "react-router-dom";
import { IPolicySetDomain } from "../../../../../../interfaces/PolicySet";
import IconButton from "../../../../../../modules/common/IconButton";
import { AdminPageRoutes } from "../../../../../../../constants/general.constants";
import UnlinkDomainConfirmationModal from "../../modals/UnlinkDomainConfirmationModal";
import useModal from "../../../../../../../hooks/useModal";

interface IDomainTableItemProps {
  domain: IPolicySetDomain;
}

const DomainTableItem = ({ domain }: IDomainTableItemProps) => {
  const { hide, show, visible } = useModal();

  return (
    <tr>
      <td>{domain.Name}</td>
      <td>
        <div className="table-actions">
          <Tooltip content="View" position="top">
            <Link
              aria-label="edit-domain-btn"
              to={`${AdminPageRoutes.DOMAIN_DETAILS}/${domain.Id}`}
            >
              <i className="fa fa-edit" />
            </Link>
          </Tooltip>
          <Tooltip content="Unlink" position="top">
            <IconButton
              aria-label="unlink-domain-btn"
              icon="fa-unlink"
              onClick={show}
            />
          </Tooltip>
          {visible && (
            <UnlinkDomainConfirmationModal
              domain={domain}
              onClose={hide}
              visible={visible}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default DomainTableItem;
