export enum ApproveFooterActionTypes {
  OPEN_APPROVE_MODAL = "OPEN_APPROVE_MODAL",
  OPEN_REJECT_MODAL = "OPEN_REJECT_MODAL",
  CLOSE_APPROVE_MODAL = "CLOSE_APPROVE_MODAL",
  CLOSE_REJECT_MODAL = "CLOSE_REJECT_MODAL",
  CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS",
  SHOW_LOADING = "SHOW_LOADING",
  HIDE_LOADING = "HIDE_LOADING",
  SET_NEW_SESSION_ID = "SET_NEW_SESSION_ID",
  CLEAR_SESSION_ID = "CLEAR_SESSION_ID",
  RESET_APPROVE_FOOTER_DATA = "RESET_APPROVE_FOOTER_DATA",
}

interface OpenApproveModal {
  type: ApproveFooterActionTypes.OPEN_APPROVE_MODAL;
}

interface OpenRejectModal {
  type: ApproveFooterActionTypes.OPEN_REJECT_MODAL;
}

interface CloseApproveModal {
  type: ApproveFooterActionTypes.CLOSE_APPROVE_MODAL;
}

interface CloseRejectModal {
  type: ApproveFooterActionTypes.CLOSE_REJECT_MODAL;
}

interface CloseAllModals {
  type: ApproveFooterActionTypes.CLOSE_ALL_MODALS;
}

interface ShowLoading {
  type: ApproveFooterActionTypes.SHOW_LOADING;
}

interface HideLoading {
  type: ApproveFooterActionTypes.HIDE_LOADING;
}

interface SetNewSessionID {
  type: ApproveFooterActionTypes.SET_NEW_SESSION_ID;
}

interface ClearSessionID {
  type: ApproveFooterActionTypes.CLEAR_SESSION_ID;
}

interface ResetApproveFooterData {
  type: ApproveFooterActionTypes.RESET_APPROVE_FOOTER_DATA;
}

export type ApproveFooterAction =
  | OpenApproveModal
  | OpenRejectModal
  | CloseApproveModal
  | CloseRejectModal
  | CloseAllModals
  | ShowLoading
  | HideLoading
  | SetNewSessionID
  | ClearSessionID
  | ResetApproveFooterData;
