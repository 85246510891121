import { IPrintViewAuthorsProps } from "../../../interfaces/DetailsPage";
import AuthorName from "../../../modules/AuthorName";

const PrintViewAuthors = ({ authors }: IPrintViewAuthorsProps) => {
  return (
    <ul>
      {authors.map((author) => (
        <li>
          <AuthorName
            _id={author._id}
            claims={author.claims}
            userFullName={author.userFullName}
            userName={author.userName}
          />
        </li>
      ))}
    </ul>
  );
};

export default PrintViewAuthors;
