import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";
import { ILinkedPolicySet } from "../../components/interfaces/Policy";
import { ITableData } from "../../components/interfaces/Table";

export enum PolicyLinkedPolicySetsActionTypes {
  FETCHING_LINKED_POLICY_SETS_TABLE_DATA = "FETCHING_LINKED_POLICY_SETS_TABLE_DATA",
  UPDATE_LINKED_POLICY_SETS_TABLE_DATA = "UPDATE_LINKED_POLICY_SETS_TABLE_DATA",
  UPDATE_LINKED_POLICY_SETS_SELECTED_PAGE = "UPDATE_LINKED_POLICY_SETS_SELECTED_PAGE",
  UPDATE_LINKED_POLICY_SETS_SORT_PARAMS = "UPDATE_LINKED_POLICY_SETS_SORT_PARAMS",
  UPDATE_LINKED_POLICY_SETS_PAGE_PARAMS = "UPDATE_LINKED_POLICY_SETS_PAGE_PARAMS",
  UPDATE_LINKED_POLICY_SETS_COLUMN_SEARCH_FIELD = "UPDATE_LINKED_POLICY_SETS_COLUMN_SEARCH_FIELD",
  RESET_LINKED_POLICY_SETS_SORT_PARAMS = "RESET_LINKED_POLICY_SETS_SORT_PARAMS",
  HANDLE_LINKED_POLICY_SETS_NETWORK_ERROR = "HANDLE_LINKED_POLICY_SETS_NETWORK_ERROR",
  RESET_LINKED_POLICY_SETS_DATA = "RESET_LINKED_POLICY_SETS_DATA",
}

interface FetchingLinkedPolicySetsData {
  type: PolicyLinkedPolicySetsActionTypes.FETCHING_LINKED_POLICY_SETS_TABLE_DATA;
}

interface UpdateLinkedPolicySetsTableData {
  type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_TABLE_DATA;
  payload: ITableData<ILinkedPolicySet>;
}

interface UpdateLinkedPolicySetsSelectedPage {
  type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_SELECTED_PAGE;
  payload: number;
}

interface HandleLinkedPolicySetsNetworkError {
  type: PolicyLinkedPolicySetsActionTypes.HANDLE_LINKED_POLICY_SETS_NETWORK_ERROR;
  payload: string;
}

interface UpdateLinkedPolicySetsSortParams {
  type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdateLinkedPolicySetsPageParams {
  type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface HandleResetLinkedPoliciesSortParams {
  type: PolicyLinkedPolicySetsActionTypes.RESET_LINKED_POLICY_SETS_SORT_PARAMS;
}

interface HandleResetPolicySetsData {
  type: PolicyLinkedPolicySetsActionTypes.RESET_LINKED_POLICY_SETS_DATA;
}

interface UpdateLinkedPolicySetsColumnSearchField {
  type: PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_COLUMN_SEARCH_FIELD;
  payload: IColumnsSearchFieldsFormData;
}

export type PolicyLinkedPolicySetsAction =
  | UpdateLinkedPolicySetsTableData
  | FetchingLinkedPolicySetsData
  | UpdateLinkedPolicySetsSelectedPage
  | HandleLinkedPolicySetsNetworkError
  | HandleResetPolicySetsData
  | UpdateLinkedPolicySetsSortParams
  | UpdateLinkedPolicySetsPageParams
  | HandleResetLinkedPoliciesSortParams
  | UpdateLinkedPolicySetsColumnSearchField;
