import Select from "@atlaskit/select";

const options = [{ label: "Xray Cloud", value: "Xray Cloud" }];

const LinkedConnectionSystem = () => {
  return (
    <div className="form-with-label form-with-label--grid was-validated">
      <label>System</label>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Select
          className="form-control form-control-select single-select"
          classNamePrefix="react-select"
          id="linked-connection-system"
          isClearable={true}
          isDisabled={true}
          menuPosition="fixed"
          options={options}
          spacing="default"
          value={options[0]}
        />
      </div>
    </div>
  );
};

export default LinkedConnectionSystem;
