import { useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useActions } from "../../../../hooks/useActions";
import {
  AdminPageQueryParams,
  IUserRolesTableItem,
} from "../../../interfaces/AdminPage";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../constants/admin-page.constants";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import Loader from "../../../modules/Loader";
import TableHead from "../../../modules/TableHead";
import TablePagination from "../../../modules/TablePagination";
import TableSearchField from "../../../modules/TableSearchField";
import RoleMembersTableItem from "./RoleMembersTableItem";
import BulkUsersRemoveFromRoleFooter from "./BulkUsersRemoveFromRoleFooter";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  roleManagementApi,
  selectRoleUsers,
  updateColumnSearchField,
  updatePageParams,
  updateSelectedPage,
  updateSortParams,
  useGetRolesUserQuery,
  useRemoveUserMutation,
} from "../../../../store/slices/role-management.slice";
import { userApi } from "../../../../store/slices/user.slice";
import { useAppDispatch } from "../../../../store/store";

const RoleMembersTable = () => {
  const dispatch = useAppDispatch();

  const { domainId } = useParams<AdminPageQueryParams>();

  const user = useTypedSelector((x) => x.user.user);
  const selectedRole = useTypedSelector((x) => x.rolesManagement.selectedRole);
  const {
    columnSearchField,
    selectedPage,
    sortParams,
    tableHeaders,
    selectedUsers,
  } = useTypedSelector((x) => x.rolesManagement.roleUsersTable);

  const {
    data = { rows: [], total: 0 },
    isFetching,
    refetch,
  } = useGetRolesUserQuery(
    {
      columnSearchField,
      domainId,
      selectedRole,
      sortParams,
    },
    {
      skip: !domainId || !selectedRole,
    },
  );

  const [removeUser] = useRemoveUserMutation();

  const handleRemoveUser = async (userIds: string[]) => {
    removeUser({
      domainId,
      role: selectedRole,
      userIds,
    })
      .unwrap()
      .then(() => {
        toast.success(AdminPageSuccessMessages.REMOVE_ROLE_USERS);
        dispatch(
          userApi.endpoints.getUsersByFullName.initiate(
            {
              searchTerm: "",
              skip: 0,
              excludeDomain: domainId,
              excludeRole: selectedRole,
            },
            { forceRefetch: true },
          ),
        );
      })
      .catch((error: any) => {
        toast.error(error.data?.message);
      });
  };

  const userCanBeSelected = (id: string) => {
    return (
      user?.isSystemAdmin ||
      user?.veraUserId !== id ||
      selectedRole !== "Domain Administrator"
    );
  };

  return (
    <div aria-label="user-management-table">
      <div className="role-management-table-heading mb-2">
        Role Members
        <TableSearchField
          fetchTableData={() => refetch()}
          sortParams={sortParams}
        />
      </div>

      <table className="table table-bordered table-sm">
        <TableHead
          columnFiltrationIsActive={true}
          columnsSearchField={columnSearchField}
          handleSelectAllAction={(newUsersData: IUserRolesTableItem[]) =>
            dispatch(
              selectRoleUsers({
                newUsers: newUsersData,
                shouldSelect: userCanBeSelected,
              }),
            )
          }
          isLoading={isFetching}
          rowsData={data.rows}
          selectedRows={selectedUsers}
          setColumnsSearchField={(columns) =>
            dispatch(updateColumnSearchField(columns))
          }
          setPageParams={(offset, limit) =>
            dispatch(updatePageParams({ offset, limit }))
          }
          setSortParams={(sort, order) =>
            dispatch(updateSortParams({ sort, order }))
          }
          sortParams={sortParams}
          tableHeaders={tableHeaders}
        />

        <tbody
          className="position-relative"
          style={{ height: isFetching || !data?.rows?.length ? "30vh" : 0 }}
        >
          {!isFetching && !data?.rows?.length && (
            <div className="table-no-records-found">
              {selectedRole ? "No users were found." : "Please select a role."}
            </div>
          )}

          {isFetching && <Loader tableMode={true} />}

          {!isFetching &&
            selectedRole &&
            data?.rows?.map((userData) => (
              <RoleMembersTableItem
                handleRemoveFromRole={handleRemoveUser}
                key={userData._id}
                user={userData}
                userCanBeSelected={userCanBeSelected}
              />
            ))}
        </tbody>
      </table>

      {data?.rows?.length > 0 && (
        <TablePagination
          initialRowsPerPage={10}
          isCompact={true}
          selectedPage={selectedPage}
          tableData={data}
          updatePageParams={(offset, limit) =>
            dispatch(updatePageParams({ offset, limit }))
          }
          updateSelectedPage={(page) => dispatch(updateSelectedPage(page))}
        />
      )}

      {selectedRole && selectedUsers.length > 0 && (
        <BulkUsersRemoveFromRoleFooter
          handleRemoveFromRole={handleRemoveUser}
          selectedRole={selectedRole}
        />
      )}
    </div>
  );
};

export default RoleMembersTable;
