import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useActions } from "../../../../hooks/useActions";
import { AdminPageRoutes } from "../../../../constants/general.constants";
import TableHead from "../../../modules/TableHead";
import Loader from "../../../modules/Loader";
import TableSearchField from "../../../modules/TableSearchField";
import TablePagination from "../../../modules/TablePagination";
import ImportUsersModal from "./modals/ImportUsersModal";
import UserManagementTableItem from "./UserManagementTableItem";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

const UserManagement = () => {
  const [isOpenImportUsersModal, setOpenImportUsersModal] =
    useState<boolean>(false);

  const {
    columnSearchField,
    isLoading,
    selectedPage,
    sortParams,
    tableData,
    tableHeaders,
  } = useTypedSelector((x) => x.users);

  const {
    fetchUsers,
    updateUsersSelectedPage,
    updateUsersSortParams,
    updateUsersPageParams,
    updateUsersColumnSearchField,
  } = useActions();

  const updateSortParams = (
    sort: string = sortParams.sort,
    order: "asc" | "desc" = sortParams.order,
  ) => {
    updateUsersSortParams(sort, order);
  };

  const updatePageParams = (
    offset: number,
    limit: number = sortParams.limit,
  ) => {
    updateUsersPageParams(offset, limit);
  };

  useEffect(() => {
    fetchUsers();
  }, [sortParams, columnSearchField]);

  return (
    <>
      <div
        aria-label="user-management"
        className="d-flex justify-content-between"
      >
        <div className="table-heading-wrapper">
          <b>User Management</b>
        </div>

        <div>
          <Link
            className="btn btn-primary btn-sm text-white ml-3"
            id="add-user-btn"
            to={AdminPageRoutes.ADD_USER}
          >
            <i className="fa fa-user-plus" /> Add User
          </Link>

          <button
            className="btn btn-primary btn-sm text-white ml-3"
            id="import-users-btn"
            onClick={() => setOpenImportUsersModal(true)}
          >
            <i className="fa fa-upload" /> Import Users
          </button>

          <a
            className="btn btn-primary btn-sm text-white ml-3"
            href={"/api/admin/latest-import-results"}
            id="view-latest-import-results-btn"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa fa-user-circle" /> View Latest Import Results
          </a>
        </div>
      </div>

      <div aria-label="user-management-table">
        <div className="table-search-field-wrapper">
          <TableSearchField
            fetchTableData={() => fetchUsers()}
            sortParams={sortParams}
          />
        </div>

        <table className="table table-bordered table-sm">
          <TableHead
            columnFiltrationIsActive={true}
            columnsSearchField={columnSearchField}
            isLoading={isLoading}
            setColumnsSearchField={updateUsersColumnSearchField}
            setPageParams={updatePageParams}
            setSortParams={updateSortParams}
            sortParams={sortParams}
            tableHeaders={tableHeaders}
          />

          <tbody
            className="position-relative"
            style={{
              height: isLoading || !tableData?.rows?.length ? "40vh" : 0,
            }}
          >
            {!isLoading && !tableData?.rows?.length && (
              <div className="table-no-records-found">
                No matching users found
              </div>
            )}

            {isLoading && (
              <Loader message={"Loading, please wait..."} tableMode={true} />
            )}

            {!isLoading &&
              tableData?.rows?.map((userData) => (
                <UserManagementTableItem key={userData._id} user={userData} />
              ))}
          </tbody>
        </table>

        {tableData?.rows?.length > 0 && (
          <TablePagination
            initialRowsPerPage={15}
            selectedPage={selectedPage}
            tableData={tableData}
            updatePageParams={updatePageParams}
            updateSelectedPage={updateUsersSelectedPage}
          />
        )}
      </div>

      {isOpenImportUsersModal && (
        <ImportUsersModal
          closeModal={() => setOpenImportUsersModal(false)}
          isOpen={isOpenImportUsersModal}
        />
      )}
    </>
  );
};

export default UserManagement;
