import React, { useEffect } from "react";
import {
  RecordField,
  SubStepsTableDataState,
  SystemDetailsProps,
} from "../../../../interfaces/DetailsPage";
import fileImage from "../../../../../images/file.png";
import SubStepsTable from "./SubStepsTable";
import LinkedRecords from "./LinkedRecords";
import { escapeOnlyNonHtmlTags } from "../../../../../helpers/html.helper";
import { downloadFile } from "../../../../../helpers/file.helper";
import { useHistory } from "react-router-dom";
import { useActions } from "../../../../../hooks/useActions";
import AttachmentsModal from "./AttachmentsModal";
import DefectsModal from "./DefectsModal";

const SystemDetails = ({ record }: SystemDetailsProps) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [subRecordsCollapsed, setSubRecordsCollapsed] = React.useState(false);
  const [subStepsTableData, setSubStepsTableData] =
    React.useState<SubStepsTableDataState>({ title: "", subRecord: [] });

  const { openAttachmentsModal } = useActions();
  const { openDefectsModal } = useActions();

  const longItems: RecordField[] = [];
  const shortItems: RecordField[] = [];

  const history = useHistory();
  const { logout } = useActions();

  record.fields.forEach((item) => {
    if (item.value.length < 60 && item.value.indexOf("<p>")) {
      shortItems.push(item);
    } else {
      longItems.push(item);
    }
  });

  const getTitle = (prompt: string) => {
    switch (prompt) {
      case "test-log":
        return "Execution Log";
      case "test-log-step":
        return "Step";
      case "test-step":
        return "Step";
      case "test-step-log":
        return "Step";
      default:
        return prompt;
    }
  };

  const handleOnClickDownload = async (e: any) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLAnchorElement;

    downloadFile({ name: target.download, url: target.href }).catch(
      (response) => {
        if (response.status === 401) {
          logout(history);
        }
      },
    );
  };

  useEffect(() => {
    if (record?.sub_records[0]?.sub_records.length) {
      const item = record.sub_records[0];
      setSubStepsTableData({ title: item.name, subRecord: item.sub_records });
    }
  }, []);

  useEffect(() => {
    const anchorLinks = document.querySelectorAll("a[download]");

    anchorLinks.forEach((x) =>
      x.addEventListener("click", handleOnClickDownload),
    );
    return () =>
      anchorLinks.forEach((x) =>
        x.removeEventListener("click", handleOnClickDownload),
      );
  }, []);

  return (
    <div className="pb-3" id="accordionDetail">
      <a
        className="text-decoration-none toggleRotate"
        data-bs-target="#collapseDetail"
        data-bs-toggle="collapse"
        href="#!"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <i
          className={
            isCollapsed
              ? "fa fa-chevron-circle-right"
              : "fa fa-chevron-circle-down"
          }
          style={{ fontSize: ".8rem" }}
        />
        <h6 className="d-inline details-title">
          {" "}
          {record.original_location?.system} Details
        </h6>
      </a>

      <div
        className="container-fluid collapse show pt-2"
        data-bs-parent="#accordionDetail"
        id="collapseDetail"
      >
        <div className="row" style={{ fontSize: ".8rem" }}>
          {shortItems.map((item) => (
            <div
              className="col-xs-12 col-md-6 details-field"
              key={`${item.label}`}
            >
              <label>{item.label}:</label>
              <span>{item.value}</span>
            </div>
          ))}

          {longItems &&
            longItems.map((item: any) => (
              <React.Fragment key={item.label}>
                <br />
                <br />
                <div className="col-xs-12 col-md-12 details-field d-flex">
                  <label>{item.label}:</label>
                  <div
                    className="wrap-text-content external-content"
                    dangerouslySetInnerHTML={{
                      __html: escapeOnlyNonHtmlTags(item.value),
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
        </div>

        {record.attachments.length > 0 && (
          <div className="pb-3" id="accordionAttachments">
            <a
              className="text-decoration-none toggleRotate"
              data-bs-target="#collapseAttachments"
              data-bs-toggle="collapse"
              href="#!"
            >
              <i className="fa fa-chevron-circle-down" />
              <h6 className="d-inline details-title"> Attachments</h6>
            </a>
            <div
              className="container-fluid collapse show"
              data-bs-parent="#accordionAttachments"
              id="collapseAttachments"
            >
              <section>
                <section className="attachment__container">
                  {record.attachments.slice(0, 5).map((attachment) => {
                    const isImage = attachment.FileType === "image";

                    return (
                      <a
                        className="btn-icon attachment__item"
                        download={attachment.name}
                        href={attachment.url}
                        id="thumbnail-row"
                        key={attachment._id}
                      >
                        <div className="attachment__name">
                          {attachment.name}
                        </div>
                        <div className="thumbnail">
                          <img
                            alt={attachment.name}
                            aria-label="attachment-modal-btn"
                            className="attachments-hover-shadow cursor-pointer"
                            src={isImage ? attachment.url : fileImage}
                          />
                        </div>
                      </a>
                    );
                  })}
                </section>
                {record.attachments.length > 5 && (
                  <button
                    aria-label="see-all-attachments"
                    className="btn-icon attachment__see-all"
                    onClick={() =>
                      openAttachmentsModal({ attachments: record.attachments })
                    }
                  >
                    See all attachments
                  </button>
                )}
              </section>
            </div>
          </div>
        )}

        {record.sub_records.length > 0 && (
          <div className="pt-3" id={`accordionLog${record.sub_records[0]._id}`}>
            <a
              aria-label="sub-records-collapse"
              className="text-decoration-none toggleRotate"
              data-bs-target={`#collapseDetail${record.sub_records[0]._id}`}
              data-bs-toggle="collapse"
              href="#!"
              onClick={() => setSubRecordsCollapsed(!subRecordsCollapsed)}
            >
              <i
                className={
                  subRecordsCollapsed
                    ? "fa fa-chevron-circle-right"
                    : "fa fa-chevron-circle-down"
                }
              />
              <h6 className="d-inline details-title">
                {` ${getTitle(
                  record.sub_records[0].original_location.item_type,
                )}s`}
              </h6>
            </a>
            <div
              className="collapse show pt-2"
              data-bs-parent={`#accordionLog${record.sub_records[0]._id}`}
              id={`collapseDetail${record.sub_records[0]._id}`}
            >
              <div className="table-responsive">
                <table className="table table-sm table-hover">
                  <thead>
                    <tr>
                      <th>
                        {getTitle(
                          record.sub_records[0].original_location.item_type,
                        )}
                      </th>

                      {record.sub_records[0].fields.map((item) => (
                        <th
                          className="text-nowrap"
                          key={item.label}
                          scope="col"
                        >
                          {item.label}
                        </th>
                      ))}

                      <th className="text-center" scope="col">
                        <i
                          aria-hidden="true"
                          className="fa fa-paperclip"
                          style={{ width: "35px", textAlign: "center" }}
                        />
                      </th>

                      <th className="text-center" scope="col">
                        <i
                          aria-hidden="true"
                          className="fa fa-bug"
                          style={{ width: "35px", textAlign: "center" }}
                        />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {record.sub_records.map((item) => (
                      <tr
                        aria-label="sub-record-steps-table"
                        id="subRecordRow"
                        key={item._id}
                        onClick={() => {
                          item.sub_records.length &&
                            setSubStepsTableData({
                              title: item.name,
                              subRecord: item.sub_records,
                            });
                        }}
                      >
                        <td className="text-nowrap">{item.name}</td>

                        {item.fields.map((field) => (
                          <td
                            className="external-content"
                            dangerouslySetInnerHTML={{
                              __html: escapeOnlyNonHtmlTags(field.value),
                            }}
                            key={field.label}
                          />
                        ))}

                        <td style={{ textAlign: "center" }}>
                          {item.attachments.length > 0 && (
                            <button
                              aria-label="open-sub-attachment-modal"
                              className="btn btn-light btn-sm"
                              onClick={() =>
                                openAttachmentsModal({
                                  attachments: item.attachments,
                                })
                              }
                              type="button"
                            >
                              <i
                                aria-hidden="true"
                                className="fa fa-paperclip"
                              />
                            </button>
                          )}
                        </td>

                        <td style={{ width: "35px" }}>
                          {item.linked_records?.Defects &&
                            item.linked_records?.Defects?.length > 0 && (
                              <button
                                aria-label="open-sub-defects-modal"
                                className="btn btn-light btn-sm"
                                onClick={() =>
                                  openDefectsModal({
                                    defects: record.linked_records["Defects"],
                                    recordId: record._id,
                                  })
                                }
                                type="button"
                              >
                                <i aria-hidden="true" className="fa fa-bug" />
                              </button>
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {subStepsTableData.title &&
                subStepsTableData.subRecord.length && (
                  <SubStepsTable data={subStepsTableData} />
                )}
            </div>
          </div>
        )}

        {record.linked_records && (
          <LinkedRecords
            linkedRecords={record.linked_records}
            recordId={record._id}
          />
        )}

        <AttachmentsModal />
        <DefectsModal />
      </div>
    </div>
  );
};

export default SystemDetails;
