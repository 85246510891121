import React, { useEffect } from "react";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { Checkbox } from "@atlaskit/checkbox";
import { useActions } from "../../../../hooks/useActions";
import { validateMailSettingsForm } from "../../../../validators/mailSettingsFormValidator";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../constants/admin-page.constants";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import {
  IMailSettingsFormData,
  IMailSettingsFormErrors,
  IMailSettingsResponseData,
  IMailSettingsTestConnectionData,
  ISendTestEmailFormData,
} from "../../../interfaces/AdminPage";
import TextField from "../../../modules/common/TextField";
import Loader from "../../../modules/Loader";
import SendTestEmailModal from "./SendTestEmailModal";
import { useForm } from "react-hook-form";
import { validateTestEmailFormScheme } from "../../../../validators/testEmailFormValidator";
import { yupResolver } from "@hookform/resolvers/yup";

let mailSettingsCancelToken: CancelTokenSource;

const MailSettingsTab = () => {
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState({
    testConnection: false,
    saveChanges: false,
  });
  const [isPasswordFieldDisabled, setPasswordFieldDisabled] =
    React.useState<boolean>(true);
  const [isSendTestEmailModalOpened, setSendTestEmailModalOpened] =
    React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<IMailSettingsFormData>({
    from: "",
    smtpServer: "",
    smtpPort: "",
    useTls: false,
    smtpUsername: "",
    smtpPassword: "",
  });
  const [errors, setErrors] = React.useState<IMailSettingsFormErrors>({});
  const initialState = React.useRef(formData);

  const formProps = useForm<ISendTestEmailFormData>({
    mode: "all",
    resolver: yupResolver(validateTestEmailFormScheme()),
  });

  const handleFormChange = (newData: any) =>
    setFormData((prev) => ({ ...prev, [newData.name]: newData.value }));
  const getMailSettingsData = async () => {
    if (typeof mailSettingsCancelToken != typeof undefined) {
      mailSettingsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    mailSettingsCancelToken = axios.CancelToken.source();

    try {
      const { data, status }: IMailSettingsResponseData = await axios.get(
        AdminRequestRoutes.SMTP_CONFIGS,
        { cancelToken: mailSettingsCancelToken.token },
      );
      if (status === 200) {
        const receivedFormData = {
          from: data?.from,
          smtpServer: data?.server,
          smtpPort: String(data?.port),
          useTls: data?.useTls,
          smtpUsername: data?.username,
          smtpPassword: "",
        };
        initialState.current = receivedFormData;
        setFormData(receivedFormData);
        setInitialLoading(false);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
  const handleTestConnection = async () => {
    if (typeof mailSettingsCancelToken != typeof undefined) {
      mailSettingsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    mailSettingsCancelToken = axios.CancelToken.source();

    try {
      setIsLoading((prev) => ({ ...prev, testConnection: true }));
      const { data }: IMailSettingsTestConnectionData = await axios.post(
        AdminRequestRoutes.SMTP_CONFIGS_TEST,
        {
          ...formData,
          useTls: formData.useTls ? "on" : "off",
        },
        { cancelToken: mailSettingsCancelToken.token },
      );

      if (data.apiStatusCode === 200) {
        toast.success(data.message);
      } else if (data.message) {
        toast.error(data.message);
      } else {
        toast.error(AdminPageErrors.SMTP_CONFIGS_TEST);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading((prev) => ({ ...prev, testConnection: false }));
    }
  };
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateMailSettingsForm(formData, setErrors);
    if (typeof mailSettingsCancelToken != typeof undefined) {
      mailSettingsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    mailSettingsCancelToken = axios.CancelToken.source();
    if (Object.keys(errors).length) {
      return;
    }

    try {
      setIsLoading((prev) => ({ ...prev, saveChanges: true }));
      const { data }: IMailSettingsTestConnectionData = await axios.post(
        AdminRequestRoutes.SMTP_CONFIGS,
        {
          ...formData,
          useTls: formData.useTls ? "on" : "off",
        },
        { cancelToken: mailSettingsCancelToken.token },
      );
      if (data.apiStatusCode === 200) {
        initialState.current = formData;
        toast.success(AdminPageSuccessMessages.SMTP_CONFIGS);
      } else {
        toast.error(data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading((prev) => ({ ...prev, saveChanges: false }));
    }
  };
  const checkIfFormInInitialState = () =>
    JSON.stringify(formData) === JSON.stringify(initialState.current);

  useEffect(() => {
    getMailSettingsData();
  }, []);

  useEffect(() => {
    !checkIfFormInInitialState() &&
      validateMailSettingsForm(formData, setErrors);
  }, [formData]);

  return (
    <>
      <div className="table-heading-wrapper">
        <b>Mail Server Settings</b>
      </div>

      {initialLoading ? (
        <div className="col-9" style={{ fontSize: "1rem" }}>
          <Loader message="Loading, please wait..." />
        </div>
      ) : (
        <div className="mt-4">
          <form
            aria-label="mail-settings-form"
            className={`needs-validation mail-settings-form`}
            noValidate
            onSubmit={handleFormSubmit}
          >
            <TextField
              error={errors.from}
              label={"From: "}
              name={"from"}
              onChange={handleFormChange}
              placeholder={"Enter the default 'from' email address."}
              value={formData?.from}
            />

            <TextField
              label={"Server: "}
              name={"smtpServer"}
              onChange={handleFormChange}
              placeholder={"Enter the SMTP server address."}
              value={formData?.smtpServer}
            />

            <TextField
              error={errors.smtpPort}
              label={"Port: "}
              name={"smtpPort"}
              onChange={handleFormChange}
              placeholder={"Enter the SMTP server port."}
              type="number"
              value={formData?.smtpPort}
            />

            <div className="form-with-label form-with-label--grid">
              <label className="m-0" style={{ fontWeight: 500 }}>
                Use TLS:
              </label>

              <div className="mail-checkbox">
                <Checkbox
                  isChecked={formData?.useTls}
                  name="useTls"
                  onChange={({ target }) =>
                    handleFormChange({
                      name: target.name,
                      value: target.checked,
                    })
                  }
                />
              </div>
            </div>

            <TextField
              label={"User Name: "}
              name={"smtpUsername"}
              onChange={handleFormChange}
              placeholder={"Enter the user name for the SMTP server."}
              value={formData?.smtpUsername}
            />

            <div className="form-with-label form-with-label--grid">
              <label className="m-0" style={{ fontWeight: 500 }}>
                Password:
              </label>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input
                      onClick={() =>
                        setPasswordFieldDisabled(!isPasswordFieldDisabled)
                      }
                      title="Check to update the Password"
                      type="checkbox"
                    />
                  </div>
                </div>
                <input
                  className="form-control common-text-field"
                  disabled={isPasswordFieldDisabled}
                  name="smtpPassword"
                  onChange={({ target }) =>
                    handleFormChange({
                      name: [target.name],
                      value: target.value,
                    })
                  }
                  placeholder="Enter the password for the SMTP server."
                  type="password"
                  value={formData.smtpPassword}
                />
              </div>
            </div>

            <div className="col">
              <span className="float-right">
                <button
                  className="btn btn-primary text-white mr-2"
                  id="send-test-email-btn"
                  onClick={() => setSendTestEmailModalOpened(true)}
                  type="button"
                >
                  Send Test Email
                </button>

                <button
                  className="btn btn-primary text-white mr-2"
                  disabled={isLoading.testConnection}
                  id="test-connection-btn"
                  onClick={handleTestConnection}
                  type="button"
                >
                  Test Connection{" "}
                  {isLoading.testConnection && (
                    <span
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                    />
                  )}
                </button>

                <button
                  className="btn btn-primary text-white"
                  disabled={
                    !!Object.keys(errors).length || checkIfFormInInitialState()
                  }
                  id="save-mail-settings-btn"
                >
                  Save Changes{" "}
                  {isLoading.saveChanges && (
                    <span
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                    />
                  )}
                </button>
              </span>
            </div>
          </form>

          {isSendTestEmailModalOpened && (
            <SendTestEmailModal
              closeModal={() => setSendTestEmailModalOpened(false)}
              formProps={formProps}
              isOpen={isSendTestEmailModalOpened}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MailSettingsTab;
