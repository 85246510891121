import { useEffect } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IDropdownOption } from "../../../interfaces/DropDown";
import Dropdown from "../../../modules/common/DropDown";

const DropdownDomains = () => {
  const {
    fetchDomains,
    updateSearchFieldDomain,
    clearSelectedDomains,
    updateSelectedDomain,
  } = useActions();

  const {
    domains,
    domainsSearchField,
    isLoadingDomains,
    selectedDomains,
    skip,
  } = useTypedSelector((state) => state.domainsAndRoles);

  const handleDomainSelectChange = (
    checked: boolean,
    option: IDropdownOption,
  ) => {
    updateSelectedDomain(option.id);
  };

  const showMoreDomains = () => {
    fetchDomains(domainsSearchField, domains.items.length);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchDomains(domainsSearchField);
    }, 500);

    return () => clearTimeout(timeout);
  }, [domainsSearchField]);

  return (
    <Dropdown
      clearSelection={clearSelectedDomains}
      displayShowMore={true}
      id="Domain"
      isFirstPage={!skip}
      isLoading={isLoadingDomains}
      items={domains.items.map((x) => ({
        label: x.name,
        id: x.id,
        isSelected: x.isSelected,
      }))}
      label="Domains"
      onChangeCheckbox={handleDomainSelectChange}
      onChangeSearchField={updateSearchFieldDomain}
      searchField={domainsSearchField}
      selectedItems={selectedDomains}
      showMore={showMoreDomains}
      totalItems={domains.total}
    />
  );
};

export default DropdownDomains;
