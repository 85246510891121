import * as yup from "yup";
import { ISendTestEmailFormData } from "../components/interfaces/AdminPage";

export const validateTestEmailFormScheme = () =>
  yup.object().shape({
    body: yup.string().required("Please enter some text."),
    subject: yup.string().required("Please enter a subject line."),
    recipient: yup
      .string()
      .required("Please enter a valid email address.")
      .email("Please enter a valid email address."),
  });

export const validateTestEmailForm = (
  data: ISendTestEmailFormData,
  setErrorHandler: any,
) => {
  validateTestEmailFormScheme()
    .validate(data)
    .then(() => setErrorHandler({}))
    .catch((err) => setErrorHandler({ [err.path]: err.message || "" }));
};
