import { useState } from "react";
import { useActions } from "../../../../../hooks/useActions";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../../constants/admin-page.constants";
import { ErrorMessage } from "../../../../../constants/general.constants";
import GenericModal from "../../../../modules/GenericModal";
import { IDefaultResponse } from "../../../../interfaces/General";
import { IChangeUserStatusConfirmationModalProps } from "../../../../interfaces/AdminPage";
import { UsersRequestRoutes } from "../../../../../constants/users.constants";

const ChangeUserStatusConfirmationModal = ({
  activate,
  fullName,
  id,
  onClose,
  userName,
  visible,
}: IChangeUserStatusConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { fetchUsers } = useActions();

  const handleOnClickActivate = async () => {
    setLoading(true);
    try {
      const { status } = await axios.patch<IDefaultResponse<any>>(
        UsersRequestRoutes.USER_ACTIVATE,
        {
          id,
        },
      );

      if (status === 200) {
        toast.success(AdminPageSuccessMessages.USER_ACTIVATE);
        fetchUsers();
      } else {
        toast.error(AdminPageErrors.USER_ACTIVATE);
      }
      onClose();
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const handleOnClickDeactivate = async () => {
    setLoading(true);

    try {
      const { status } = await axios.patch<IDefaultResponse<any>>(
        UsersRequestRoutes.USER_DEACTIVATE,
        {
          id,
        },
      );

      if (status === 200) {
        toast.success(AdminPageSuccessMessages.USER_DEACTIVATE);
        fetchUsers();
      } else {
        toast.error(AdminPageErrors.USER_DEACTIVATE);
      }
      onClose();
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericModal
      labelConfirm={activate ? "Activate" : "Deactivate"}
      loading={loading}
      onClickConfirm={
        activate ? handleOnClickActivate : handleOnClickDeactivate
      }
      onCloseModal={onClose}
      size="medium"
      title={activate ? "Activate User" : "Deactivate User"}
      visible={visible}
    >
      {`Are you sure you want to ${
        activate ? "activate" : "deactivate"
      } this user: ${fullName} (${userName})?`}
    </GenericModal>
  );
};

export default ChangeUserStatusConfirmationModal;
