import { Author } from "../interfaces/DetailsPage";

const AuthorName = ({ userName, userFullName }: Author) => {
  let returnName = "";

  if (userFullName && userName) {
    returnName = `${userFullName} (${userName})`;
  } else if (userFullName && !userName) {
    returnName = userFullName;
  } else if (!userFullName && userName) {
    returnName = userName;
  } else {
    returnName = "Unknown";
  }

  return <>{returnName}</>;
};

export default AuthorName;
