import DropdownAddPolicy from "./DropdownAddPolicy";
import DropdownPolicyType from "./DropDownPolicyType";
import Checkbox from "@atlaskit/checkbox";
import Textfield from "@atlaskit/textfield";
import { useActions } from "../../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { useEffect } from "react";

const LocatePolicyFilter = () => {
  const {
    clearPolicyFilters,
    fetchPolicies,
    handleResetPolicyData,
    handleResetPolicySortParams,
    toggleOnlyShowDefaultPolicies,
    updatePolicyNameSearchField,
  } = useActions();

  const { onlyShowDefaultPolicies, policyName } = useTypedSelector(
    (x) => x.locatePolicyFilter,
  );

  const handleOnClickSearch = () => {
    handleResetPolicySortParams();
  };

  const handleOnClickReset = () => {
    clearPolicyFilters();
    handleResetPolicySortParams();
  };

  useEffect(
    () => () => {
      handleOnClickReset();
      handleResetPolicyData();
    },
    [],
  );

  return (
    <div className="table-action-bar">
      <DropdownPolicyType />
      <Textfield
        className="locate-records-search-input search-record-name"
        id="search-by-policy-name"
        isCompact={true}
        onChange={(e) => updatePolicyNameSearchField(e.currentTarget.value)}
        onKeyUp={(e) => e.key === "Enter" && fetchPolicies()}
        placeholder="Search by Policy Name"
        value={policyName}
      />

      <div className="checkbox only-show-default-policies">
        <Checkbox
          aria-label="bulk-checkbox"
          id="chk-show-default-policies"
          isChecked={onlyShowDefaultPolicies}
          isDisabled={false}
          label="Only show default policies"
          name="onlyShowDefaultPolicies"
          onChange={toggleOnlyShowDefaultPolicies}
        />
      </div>

      <div className="d-flex locate-policy-filter-buttons">
        <button
          className="btn btn-primary btn-sm text-white"
          id="btn-search-policy"
          onClick={(e) => handleOnClickSearch()}
        >
          Search
        </button>
        <button
          className="btn btn-primary btn-sm text-white"
          id="btn-reset-policies"
          onClick={(e) => handleOnClickReset()}
        >
          Reset
        </button>
      </div>
      <div className="dropdown-add-policy-container">
        <DropdownAddPolicy />
      </div>
    </div>
  );
};

export default LocatePolicyFilter;
