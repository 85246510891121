import { IUserManagementItem } from "../../components/interfaces/AdminPage";
import { ITableState } from "../../components/interfaces/Table";
import { UsersAction, UsersActionTypes } from "../types/users";

export const initialState: ITableState<IUserManagementItem> = {
  columnSearchField: {},
  isLoading: false,
  selectedPage: 0,
  sortParams: {
    limit: 15,
    offset: 0,
    order: "asc",
    sort: "name",
  },
  tableData: {
    rows: [],
    total: 0,
  },
  tableHeaders: [
    { label: "User Name", name: "userName", filterable: true, colSize: 1 },
    { label: "Full Name", name: "name", filterable: true, colSize: 2 },
    { label: "Email Address", name: "email", filterable: true, colSize: 1 },
    {
      label: "System Roles",
      name: "system-roles",
      notSortable: true,
      filterable: true,
      colSize: 2,
    },
    { label: "Login Info", name: "login-info", notSortable: true, colSize: 4 },
    { label: "Status", name: "active", notSortable: true, colSize: 1 },
  ],
};

export const usersReducer = (
  state = initialState,
  action: UsersAction,
): ITableState<IUserManagementItem> => {
  switch (action.type) {
    case UsersActionTypes.FETCHING_USERS_TABLE_DATA:
      return { ...state, isLoading: true };
    case UsersActionTypes.UPDATE_USERS_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload,
      };
    case UsersActionTypes.UPDATE_USERS_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case UsersActionTypes.UPDATE_USERS_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case UsersActionTypes.UPDATE_USERS_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case UsersActionTypes.RESET_USERS_DATA:
      return initialState;
    case UsersActionTypes.RESET_USERS_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case UsersActionTypes.UPDATE_USERS_COLUMN_SEARCH_FIELD:
      return {
        ...state,
        columnSearchField: action.payload,
      };
    case UsersActionTypes.HANDLE_USERS_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
