import { Control, FieldErrors } from "react-hook-form";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { IConnectionFormData } from "../../../../interfaces/Connections";
import FormSelect from "../../../../modules/common/FormSelect";
import LinkedConnectionSystem from "./LinkedConnectionSystem";

interface ILinkedConnectionProps {
  control: Control;
  errors: FieldErrors<IConnectionFormData>;
  onClickRemove: Function;
  wasValidated?: boolean;
}

const LinkedConnection = ({
  control,
  errors,
  onClickRemove,
  wasValidated,
}: ILinkedConnectionProps) => {
  const { linkableConnections } = useTypedSelector((x) => x.connection);

  return (
    <div className="connection-linked-connection__container">
      <div className="connection-linked-connection__header">
        <h6 className="mb-0">
          <b>Linked Connection</b>
        </h6>
        <button
          aria-label="delete-linked-connection-btn"
          className="btn-link"
          id="btn-delete-connection"
          onClick={(e) => {
            e.preventDefault();
            onClickRemove();
          }}
        >
          <i className="fa fa-trash-o" />
        </button>
      </div>
      <LinkedConnectionSystem />
      <FormSelect
        control={control}
        error={errors.LinkedConnections?.message}
        label="Name:"
        name="LinkedConnections"
        options={linkableConnections.map((x) => ({
          label: x.Name,
          value: x._id,
        }))}
        placeholder="Select Name"
        wasValidated={wasValidated}
      />
    </div>
  );
};

export default LinkedConnection;
