import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { approvalQueueReducer } from "./approvalQueueReducer";
import { locateRecordsReducer } from "./locateRecordsFilterReducer";
import { recordReducer } from "./recordReducer";
import { recordDetailsReducer } from "./recordDetailsReducer";
import { sidebarReducer } from "./sidebarReducer";
import { domainsAndRolesReducer } from "./domainAndRolesReducer";
import { domainReducer } from "./domainReducer";
import { approveFooterReducer } from "./approveFooterReducer";
import { expandedContentModalReducer } from "./expandedContentModalReducer";
import { connectionsReducer } from "./connectionsReducer";
import { connectionReducer } from "./connectionReducer";
import { policiesReducer } from "./policiesReducer";
import { policyReducer } from "./policyReducer";
import { policyRevisionsReducer } from "./policyRevisionsReducer";
import { locatePolicyReducer } from "./locatePolicyFilterReducer";
import { policySetsReducer } from "./policySetsReducer";
import { policySetReducer } from "./policySetReducer";
import { policySetLinkedDomainsReducer } from "./policySetLinkedDomainsReducer";
import { policySetDomainsReducer } from "./policySetDomainsReducer";
import { policyLinkedPolicySetsReducer } from "./policyLinkedPolicySets";
import { attachmentsModalReducer } from "./attachmentsModalReducer";
import { defectsModalReducer } from "./defectsModalReducer";
import rolesManagementReducer, {
  roleManagementApi,
} from "../slices/role-management.slice";
import { usersReducer } from "./usersReducer";
import { versionApi } from "./../slices/version.slice";
import { userApi } from "../slices/user.slice";
import concurrentUsersReportReducer from "../slices/user.slice";
import auditHistoryReducer, {
  auditHistoryApi,
} from "../slices/audit-history.slice";
import userSessionsReducer, {
  userSessionsApi,
} from "../slices/user-session.slice";
import loginReducer, { loginApi } from "../slices/login.slice";

const combinedReducers = combineReducers({
  approvalQueue: approvalQueueReducer,
  approveFooter: approveFooterReducer,
  auditHistory: auditHistoryReducer,
  attachmentsModal: attachmentsModalReducer,
  concurrentUsersReport: concurrentUsersReportReducer,
  connection: connectionReducer,
  connections: connectionsReducer,
  defectsModal: defectsModalReducer,
  domain: domainReducer,
  domainsAndRoles: domainsAndRolesReducer,
  expandedContentModal: expandedContentModalReducer,
  locatePolicyFilter: locatePolicyReducer,
  locateRecordsFilter: locateRecordsReducer,
  login: loginReducer,
  policies: policiesReducer,
  policy: policyReducer,
  policyLinkedPolicies: policyLinkedPolicySetsReducer,
  policyRevisions: policyRevisionsReducer,
  policySets: policySetsReducer,
  policySet: policySetReducer,
  policySetLinkedDomains: policySetLinkedDomainsReducer,
  policySetDomains: policySetDomainsReducer,
  recordDetails: recordDetailsReducer,
  records: recordReducer,
  rolesManagement: rolesManagementReducer,
  sideBar: sidebarReducer,
  user: userReducer,
  users: usersReducer,
  userSessions: userSessionsReducer,
  [versionApi.reducerPath]: versionApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [auditHistoryApi.reducerPath]: auditHistoryApi.reducer,
  [userSessionsApi.reducerPath]: userSessionsApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [roleManagementApi.reducerPath]: roleManagementApi.reducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
