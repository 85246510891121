import { IPolicySetDomain } from "../../components/interfaces/PolicySet";
import { ITableState } from "../../components/interfaces/Table";
import {
  PolicySetLinkedDomainsAction,
  PolicySetLinkedDomainsActionTypes,
} from "../types/policySetLinkedDomains";

export const initialState: ITableState<IPolicySetDomain> = {
  columnSearchField: {},
  isLoading: false,
  selectedPage: 0,
  sortParams: {
    limit: 15,
    offset: 0,
    order: "asc",
    sort: "Name",
  },
  tableData: {
    rows: [],
    total: 0,
  },
  tableHeaders: [
    { name: "Name", label: "Name", colSize: 12, filterable: true },
  ],
};

export const policySetLinkedDomainsReducer = (
  state = initialState,
  action: PolicySetLinkedDomainsAction,
): ITableState<IPolicySetDomain> => {
  switch (action.type) {
    case PolicySetLinkedDomainsActionTypes.FETCHING_POLICY_SET_DOMAINS_TABLE_DATA:
      return { ...state, isLoading: true };
    case PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload,
      };
    case PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case PolicySetLinkedDomainsActionTypes.RESET_POLICY_SET_DOMAINS_DATA:
      return initialState;
    case PolicySetLinkedDomainsActionTypes.RESET_POLICY_SET_DOMAINS_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case PolicySetLinkedDomainsActionTypes.HANDLE_POLICY_SET_DOMAINS_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_COLUMN_SEARCH_FIELD:
      return {
        ...state,
        columnSearchField: action.payload,
      };
    default:
      return state;
  }
};
