import axios from "axios";
import React from "react";
import { IDomainAndRolesProps } from "../../../interfaces/DomainsAndRoles";
import MultiSelectFieldInline from "../../../modules/common/MultiSelectFieldInline";
import { IOptionType } from "../../../interfaces/MultiSelectFieldInline";
import { useActions } from "../../../../hooks/useActions";
import {
  DomainsAndRolesRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import { toast } from "react-toastify";

const DomainAndRoles = ({
  domain,
  roles: selectedRoles,
  userId,
}: IDomainAndRolesProps) => {
  const { updateSelectedUserRoles } = useActions();

  const [roles, setRoles] = React.useState<IOptionType[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchRoles = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await axios.get<string[]>(
        DomainsAndRolesRequestRoutes.ROLES,
        {
          params: {
            domainId: domain.id,
          },
        },
      );

      if (status === 200) {
        setRoles(data.map((x) => ({ label: x, value: x })));
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MultiSelectFieldInline
      editValue={selectedRoles.map((x) => ({ label: x, value: x }))}
      isLoading={isLoading}
      key={domain.id}
      label={domain.name}
      loadOptions={fetchRoles}
      options={roles}
      setEditValue={(roles) =>
        updateSelectedUserRoles(domain.id, roles, userId!)
      }
    />
  );
};

export default DomainAndRoles;
