import React from "react";
import Pagination from "@atlaskit/pagination";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { ITablePaginationProps } from "../interfaces/ApprovalQueue";
import { ITableData } from "../interfaces/Table";

const TablePagination = ({
  updatePageParams,
  updateSelectedPage,
  tableData,
  selectedPage,
  isCompact,
  initialRowsPerPage = 15,
  maxPagesToDisplay = 7,
}: ITablePaginationProps) => {
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(initialRowsPerPage);

  const { total }: ITableData<any> = tableData!;
  let numberOfPages = rowsPerPage ? Math.ceil(total / Number(rowsPerPage)) : 0;

  const pages = Array.from(Array(numberOfPages + 1).keys()).splice(1);

  const getTo = (from: number) => {
    if (selectedPage + 1 === pages.length) {
      return total;
    }

    return +from + +rowsPerPage - 1;
  };

  const getRowsState = () => {
    const from = selectedPage * +rowsPerPage + 1;
    return `${from} to ${getTo(from)}`;
  };

  const handleChange = (
    tablePage: number = selectedPage,
    limit: number = rowsPerPage,
  ) => {
    numberOfPages = Math.ceil(total / limit);
    const page =
      numberOfPages <= 1
        ? 0
        : tablePage >= numberOfPages
          ? numberOfPages - 1
          : tablePage;
    limit && setRowsPerPage(limit);
    updateSelectedPage(
      limit === total ? 0 : page > numberOfPages ? numberOfPages - 1 : page,
    );
    updatePageParams(page * limit, limit);
  };

  const handleItemSelect = (e: any) => {
    let value = e.target.outerText;
    if (value === "All") {
      value = total;
    }
    handleChange(selectedPage, value);
  };

  const handlePageChange = (
    event: React.SyntheticEvent<Element, Event>,
    page: number,
  ) => {
    page && handleChange(page - 1);
  };

  return (
    <div className={`pagination-wrapper${isCompact ? "-compact" : ""}`}>
      <div className={`rows-data-wrapper${isCompact ? "-compact" : ""}`}>
        <div className={isCompact ? "mb-2" : "mr-4"}>
          Showing {getRowsState()} of {total} rows
        </div>
        <DropdownMenu
          placement="top-start"
          trigger={`${rowsPerPage} rows per page`}
        >
          <DropdownItemGroup>
            <DropdownItem
              isSelected={rowsPerPage === 10}
              onClick={handleItemSelect}
            >
              10
            </DropdownItem>
            <DropdownItem
              isSelected={rowsPerPage === 15}
              onClick={handleItemSelect}
            >
              15
            </DropdownItem>
            <DropdownItem
              isSelected={rowsPerPage === 25}
              onClick={handleItemSelect}
            >
              25
            </DropdownItem>
            <DropdownItem
              isSelected={rowsPerPage === 50}
              onClick={handleItemSelect}
            >
              50
            </DropdownItem>
            <DropdownItem
              isSelected={rowsPerPage === 100}
              onClick={handleItemSelect}
            >
              100
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </div>

      <Pagination
        max={maxPagesToDisplay}
        onChange={handlePageChange}
        pages={pages}
        selectedIndex={selectedPage}
      />
    </div>
  );
};

export default TablePagination;
