import { useEffect } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useConfirmTabClose } from "../../../../hooks/useConfirmTabClose";
import { AdminPageQueryParams } from "../../../interfaces/AdminPage";
import Loader from "../../../modules/Loader";
import RoleMembersTable from "./RoleMembersTable";
import RoleSelector from "./RoleSelector";
import { Prompt, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import RoleUsersSelector from "./RoleUsersSelector";
import {
  resetData,
  useGetDomainNameQuery,
  useGetRolesFromDomainQuery,
} from "../../../../store/slices/role-management.slice";
import { useAppDispatch } from "../../../../store/store";
import { toast } from "react-toastify";

const RoleManagement = () => {
  const { domainId } = useParams<AdminPageQueryParams>();
  const dispatch = useAppDispatch();

  const { selectedUsers } = useTypedSelector((x) => x.rolesManagement);

  const {
    data: roles = [],
    isFetching,
    error,
    isError,
  } = useGetRolesFromDomainQuery(domainId ?? "", { skip: !domainId });
  const { data: domains = { rows: [], total: 0 } } = useGetDomainNameQuery(
    domainId ?? "",
    { skip: !domainId },
  );

  useEffect(() => {
    isError && toast.error(error as string);
  }, [isError]);

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  useConfirmTabClose(!!selectedUsers?.length);

  return (
    <>
      <Prompt
        message="Changes you made may not be saved. Are you sure?"
        when={!!selectedUsers?.length}
      />
      <div aria-label="role-management" className="table-heading-wrapper">
        <b>Role Management</b>
      </div>
      <small>
        {domains && domains?.rows.length > 0 ? domains.rows[0].name : ""}
      </small>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          gap: "12px",
          width: "100%",
        }}
      >
        <RoleUsersSelector />
      </div>
      <div className="row mt-4">
        {isFetching ? (
          <Loader message="Loading, please wait..." />
        ) : (
          <>
            <div className="col-2 overflow-auto">
              <div className="role-management-title mb-2">
                <h6>User Roles</h6>
              </div>

              <RoleSelector roles={roles} />
            </div>

            <div className="col-10 overflow-auto">
              <RoleMembersTable />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RoleManagement;
