import React from "react";
import Spinner from "@atlaskit/spinner";
import { LoaderProps } from "../interfaces/Loader";

const Loader: React.FC<LoaderProps> = ({ message = "", tableMode = false }) => {
  return (
    <div aria-label="loader" className={tableMode ? "loader-table" : "loader"}>
      <p>
        <Spinner size={"large"} /> <b className="ml-3">{message}</b>
      </p>
    </div>
  );
};

export default Loader;
