import {
  IPoliciesTableItemProps,
  ePolicyTypeValue,
} from "../../../../interfaces/Policy";
import ImportUpdatedPolicyModal from "./modals/ImportUpdatedPolicyModal";
import useModal from "../../../../../hooks/useModal";
import Lozenge from "@atlaskit/lozenge";
import { Link } from "react-router-dom";
import IconButton from "../../../../modules/common/IconButton";
import DeletePolicyConfirmationModal from "./modals/DeletePolicyConfirmationModal";
import { AdminPageRoutes } from "../../../../../constants/general.constants";
import { PolicyService } from "../../../../services/policyService";
import { useActions } from "../../../../../hooks/useActions";
import Tooltip from "@atlaskit/tooltip";

const PoliciesTableItem = ({ policy }: IPoliciesTableItemProps) => {
  const {
    hide: hideImportPolicyModal,
    show: showImportPolicyModal,
    visible: importPolicyModalVisible,
  } = useModal();

  const {
    hide: hideDeletePolicyModal,
    show: showDeletePolicyModal,
    visible: deletePolicyModalVisible,
  } = useModal();

  const { fetchPolicies } = useActions();

  const {
    _id,
    IsDefault,
    Name,
    PolicyOwnerFullName,
    Revision,
    RevisionId,
    Type,
  } = policy;

  const handleOnClickExportData = async () => {
    await PolicyService.export(Name, Revision, RevisionId);
  };

  const handleClickOnDeletePolicy = async () => {
    showDeletePolicyModal();
  };

  return (
    <tr className="table-item">
      <td>{ePolicyTypeValue[Type]}</td>
      <td>
        <Link to={`${AdminPageRoutes.POLICY_DETAILS}/${_id}`}>{Name}</Link>{" "}
        &nbsp;
        {IsDefault === "true" && <Lozenge>Default</Lozenge>}
      </td>
      <td>{Revision}</td>
      <td>{PolicyOwnerFullName}</td>

      <td>
        <div className="table-actions">
          <Tooltip content="Import Policy Revision" position="top">
            <IconButton
              aria-label="import-policy-revision-btn"
              icon="fa-upload"
              id={`btn-upload-policy-${_id}`}
              onClick={() => showImportPolicyModal()}
            />
          </Tooltip>
          <Tooltip content="Download" position="top">
            <IconButton
              aria-label="download-policy-btn"
              className="btn-link__download"
              icon="fa-download"
              id={`btn-download-policy-${_id}`}
              onClick={handleOnClickExportData}
            />
          </Tooltip>

          {IsDefault !== "true" ? (
            <Tooltip content="Delete" position="top">
              <IconButton
                aria-label="delete-policy-btn"
                icon="fa-trash-o"
                id={`btn-delete-policy-${_id}`}
                onClick={handleClickOnDeletePolicy}
              />
            </Tooltip>
          ) : (
            <Tooltip content="Cannot delete the default policy" position="top">
              <i className="fa fa-trash-o" id={`icon-delete-policy-${_id}`} />
            </Tooltip>
          )}

          {importPolicyModalVisible && (
            <ImportUpdatedPolicyModal
              closeModal={() => hideImportPolicyModal()}
              onSuccess={fetchPolicies}
              policyId={_id}
              policyName={Name}
              policyType={Type}
              visible={importPolicyModalVisible}
            />
          )}

          {deletePolicyModalVisible && (
            <DeletePolicyConfirmationModal
              id={_id}
              name={Name}
              onClose={() => hideDeletePolicyModal()}
              visible={deletePolicyModalVisible}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default PoliciesTableItem;
