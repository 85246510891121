import * as yup from "yup";
import { IPasswordPolicyFormData } from "../components/interfaces/AdminPage";

function createSchema(minLength: number) {
  return yup.object().shape({
    minSpecial: yup
      .number()
      .min(0, "Value cannot be lower than 0 or higher than 5.")
      .max(5, "Value cannot be lower than 0 or higher than 5."),
    minNumeric: yup
      .number()
      .min(0, "Value cannot be lower than 0 or higher than 5.")
      .max(5, "Value cannot be lower than 0 or higher than 5."),
    minUpper: yup
      .number()
      .min(0, "Value cannot be lower than 0 or higher than 5.")
      .max(5, "Value cannot be lower than 0 or higher than 5."),
    minLower: yup
      .number()
      .min(0, "Value cannot be lower than 0 or higher than 5.")
      .max(5, "Value cannot be lower than 0 or higher than 5."),
    minLength: yup
      .number()
      .min(
        minLength,
        `Value cannot be less than the total number of required characters (${minLength}) and must be greater than zero, but less than 129.`,
      )
      .max(
        128,
        `Value cannot be less than the total number of required characters (${minLength}) and must be greater than zero, but less than 129.`,
      ),
  });
}

export const validatePasswordPolicyForm = (
  data: IPasswordPolicyFormData,
  setErrorHandler: any,
  minLength: number,
) => {
  const schema = createSchema(minLength);
  schema
    .validate(data)
    .then(() => setErrorHandler({}))
    .catch((err) => setErrorHandler({ [err.path]: err.message || "" }));
};
