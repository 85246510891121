import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useAppDispatch } from "../../../../../../store/store";
import { setSummarizeBy } from "../../../../../../store/slices/user.slice";
import { eSummarizeBy } from "../../../../../interfaces/User";
import MenuItem from "@mui/material/MenuItem";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";

const SummarizeSelect = () => {
  const dispatch = useAppDispatch();
  const summarizeBy = useTypedSelector(
    (x) => x.concurrentUsersReport.summarizeBy,
  );

  return (
    <FormControl fullWidth style={{ maxWidth: "250px" }}>
      <InputLabel id="summarize-by-label">Summarize by</InputLabel>
      <Select
        fullWidth
        id="summarize-by-select"
        label="Summarize by"
        labelId="summarize-by-label"
        name="summarize"
        onChange={(e) =>
          dispatch(setSummarizeBy(e.target.value as eSummarizeBy))
        }
        placeholder="Summarize By"
        size="small"
        value={summarizeBy}
      >
        <MenuItem value={eSummarizeBy.MAXIMUM}>
          Maximum Concurrent Users
        </MenuItem>
        <MenuItem value={eSummarizeBy.MINIMUM}>
          Minimum Concurrent Users
        </MenuItem>
        <MenuItem value={eSummarizeBy.AVERAGE}>
          Average Concurrent Users
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SummarizeSelect;
