import { ErrorMessage } from "../../../../../../constants/general.constants";
import { createSchema } from "../../../../../../validators/policySetFormValidator";
import Form from "../../../../../modules/common/Form";
import FormTextField from "../../../../../modules/common/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { PolicySetsRequestRoutes } from "../../../../../../constants/policy-set.constants";
import { toast } from "react-toastify";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../../../constants/admin-page.constants";
import React from "react";
import {
  IPolicySet,
  IPolicySetForm,
} from "../../../../../interfaces/PolicySet";
import { transformPolicySet } from "../../../../../../helpers/policy-set.helper";
import { IDefaultResponse } from "../../../../../interfaces/General";
import { Prompt } from "react-router-dom";

const PolicySetForm = ({ policySet }: { policySet: IPolicySet }) => {
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IPolicySetForm>({
    mode: "all",
    defaultValues: transformPolicySet(policySet),
    resolver: yupResolver(createSchema()),
  });

  const handleOnClickSave = async (policySet: IPolicySetForm) => {
    try {
      setLoading(true);
      const { data } = await axios.patch<IDefaultResponse<IPolicySet>>(
        PolicySetsRequestRoutes.POLICY_SETS,
        policySet,
      );
      if (data.apiStatusCode === 200) {
        toast.success(AdminPageSuccessMessages.UPDATE_POLICY_SET);
        reset(transformPolicySet(data.apiData));
      } else {
        toast.error(AdminPageErrors.UPDATE_POLICY_SET);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Prompt
        message="Changes you made may not be saved. Are you sure?"
        when={isDirty}
      />
      <div className="table-heading-wrapper">
        <b>Policy Set Details</b>
      </div>
      <Form className="mt-4">
        <FormTextField
          control={control}
          error={errors.name?.message}
          label={"Name:"}
          name="name"
          placeholder="Enter Name"
          size="medium"
          wasValidated={!errors.name}
        />
        <FormTextField
          control={control}
          label={"Description:"}
          name="description"
          placeholder="Enter Description"
          size="medium"
        />
      </Form>
      <div className="form-actions">
        <button
          aria-label="btn-save-policy-set"
          className="btn btn-primary text-white"
          disabled={loading || !isDirty}
          id="btn-save-policy-set"
          onClick={handleSubmit((data) => handleOnClickSave(data))}
          type="button"
        >
          Save
          {loading && (
            <span
              aria-hidden="true"
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            />
          )}
        </button>
        <button
          aria-label="btn-reset-policy-set"
          className="btn btn-primary text-white"
          disabled={!isDirty}
          id="btn-reset-policy-set"
          onClick={() => reset()}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default PolicySetForm;
