import queryParser from "query-string";
import {
  ILocateRecordsFilterState,
  ILocateRecordsSearchParams,
} from "../store/types/locateRecordsFilter";

export const objectToQueryString = (object: any) => {
  const queryOptions = { skipEmptyString: false, skipNull: true };

  return queryParser.stringify(object, queryOptions);
};

export const updateUrlWithRecordsParams = (
  filterData: ILocateRecordsSearchParams,
) => {
  const selectedProjects = filterData.selectedProjects;
  const selectedRecordTypes = filterData.selectedRecordTypes;
  const selectedRecordStatus = filterData.recordStatus.items
    .filter((x) => x.isSelected)
    .map((x) => x.name);

  const baseUrl = `${window.location.origin}/records`;
  const params = {
    projects: selectedProjects,
    recordTypes: selectedRecordTypes,
    recordStatuses: selectedRecordStatus,
    recordName: filterData.recordNameSearchField
      ? filterData.recordNameSearchField
      : "",
  };

  const queryOptions = { skipEmptyString: true, skipNull: false };

  const url = queryParser.stringifyUrl(
    { url: baseUrl, query: params },
    queryOptions,
  );

  window.history.replaceState(null, document.title, url);
};

export const tryParse = (value: any): any => {
  try {
    const obj = JSON.parse(value);
    return typeof obj === "object" ? obj : null;
  } catch {
    return null;
  }
};

export function copyObject<T>(data: T): T {
  return JSON.parse(JSON.stringify(data)) as T;
}

export const dotNotate = (
  obj: { [key: string]: any },
  target?: any,
  prefix?: any,
) => {
  target = target || {};
  prefix = prefix || "";

  Object.keys(obj).forEach(function (key) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      dotNotate(obj[key], target, prefix + key + ".");
    } else {
      return (target[prefix + key] = obj[key]);
    }
  });

  return target;
};

export const getPropertiesName = (obj: any) => {
  return Object.keys(dotNotate(copyObject(obj)));
};

export const getNextNumber = (ids?: number[]) => {
  return Math.max(...(ids?.length ? ids : [0])) + 1;
};
