import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import {
  PolicySetDomainsAction,
  PolicySetDomainsActionTypes,
} from "../types/policySetDomains";
import {
  DomainsAndRolesRequestRoutes,
  ErrorMessage,
} from "../../constants/general.constants";
import { IDomainFilterItem } from "../../components/interfaces/DomainsAndRoles";
import { ITableData } from "../../components/interfaces/Table";
import { PolicySetsRequestRoutes } from "../../constants/policy-set.constants";
import { RootState } from "../reducers";
import { IDefaultResponse } from "../../components/interfaces/General";
import { ISelectDomain } from "../../components/interfaces/PolicySet";

let domainCancelToken: CancelTokenSource;
export const fetchMorePolicySetDomains = (searchTerm: string) => {
  return async (
    dispatch: Dispatch<PolicySetDomainsAction>,
    getState: Function,
  ) => {
    if (typeof domainCancelToken != typeof undefined) {
      domainCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    domainCancelToken = axios.CancelToken.source();

    const state = getState() as RootState;
    const { domains, totalDomains } = state.policySetDomains;

    if (domains.length >= totalDomains) {
      return;
    }

    try {
      const params = {
        limit: 25,
        offset: domains.length,
        name: searchTerm,
      };

      dispatch({
        type: PolicySetDomainsActionTypes.FETCH_POLICY_SET_DOMAINS,
      });

      const { data, status } = await axios.get<ITableData<IDomainFilterItem>>(
        DomainsAndRolesRequestRoutes.DOMAINS,
        { cancelToken: domainCancelToken.token, params },
      );

      if (status === 200) {
        const domains = data.rows.map((domain) => ({
          label: domain.name,
          value: domain.id,
        }));

        dispatch({
          type: PolicySetDomainsActionTypes.LOAD_MORE_POLICY_SET_DOMAINS,
          payload: {
            domains,
            skip: params.offset,
            totalDomains: data.total,
          },
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
};

export const fetchPolicySetDomains = (searchTerm: string) => {
  return async (dispatch: Dispatch<PolicySetDomainsAction>) => {
    if (typeof domainCancelToken != typeof undefined) {
      domainCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    domainCancelToken = axios.CancelToken.source();

    try {
      const params = {
        limit: 25,
        offset: 0,
        name: searchTerm,
      };

      dispatch({
        type: PolicySetDomainsActionTypes.FETCH_POLICY_SET_DOMAINS,
      });

      const { data, status } = await axios.get<ITableData<IDomainFilterItem>>(
        DomainsAndRolesRequestRoutes.DOMAINS,
        { cancelToken: domainCancelToken.token, params },
      );

      if (status === 200) {
        const domains = data.rows.map((domain) => ({
          label: domain.name,
          value: domain.id,
        }));

        dispatch({
          type: PolicySetDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS,
          payload: { domains, totalDomains: data.total },
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
};

export const setSelectedDomains = (selectedDomains: ISelectDomain[]) => {
  return async (dispatch: Dispatch<PolicySetDomainsAction>) => {
    dispatch({
      type: PolicySetDomainsActionTypes.UPDATE_POLICY_SET_SELECTED_DOMAINS,
      payload: selectedDomains,
    });
  };
};

let domainsLinkedCancelToken: CancelTokenSource;
export const fetchDomainsLinkedToAnotherPolicySet = (policySetId: string) => {
  return async (
    dispatch: Dispatch<PolicySetDomainsAction>,
    getState: Function,
  ) => {
    if (typeof domainsLinkedCancelToken != typeof undefined) {
      domainsLinkedCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    domainsLinkedCancelToken = axios.CancelToken.source();

    try {
      const state = getState() as RootState;

      const { selectedDomains } = state.policySetDomains;
      const params = {
        policySetId,
        domainIds: selectedDomains
          .filter((x) => !x.wasValidated)
          .map((x) => x.value)
          .join(","),
      };

      const { data, status } = await axios.get<IDefaultResponse<string[]>>(
        PolicySetsRequestRoutes.LINKED_DOMAINS,
        { cancelToken: domainsLinkedCancelToken.token, params },
      );

      if (status === 200) {
        dispatch({
          type: PolicySetDomainsActionTypes.UPDATE_POLICY_SET_LINKED_DOMAINS,
          payload: data.apiData,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
};

export const resetPolicySetDomainsData = () => {
  return async (dispatch: Dispatch<PolicySetDomainsAction>) => {
    dispatch({
      type: PolicySetDomainsActionTypes.RESET_POLICY_SET_DOMAINS_DATA,
    });
  };
};
