import { toast } from "react-toastify";
import { useDeleteUserSessionMutation } from "../../../../../store/slices/user-session.slice";
import { IUserSession } from "../../../../interfaces/UserSession";
import GenericModal from "../../../../modules/GenericModal";
import { AdminPageSuccessMessages } from "../../../../../constants/admin-page.constants";

const DeleteUserSessionConfirmationModal = ({
  onClose,
  userSession,
  visible,
}: {
  onClose: () => void;
  userSession: IUserSession;
  visible: boolean;
}) => {
  const [deleteUserSession] = useDeleteUserSessionMutation();

  const handleOnConfirm = () => {
    deleteUserSession(userSession._id)
      .unwrap()
      .then(() => {
        toast.success(AdminPageSuccessMessages.DELETE_USER_SESSION);
        onClose();
      });
  };

  return (
    <GenericModal
      labelConfirm="Delete"
      loading={false}
      onClickConfirm={handleOnConfirm}
      onCloseModal={onClose}
      size="medium"
      title="Delete User Session"
      visible={visible}
    >
      {`Are you sure you want to delete the user session for this user: '${
        userSession.idpUsername ?? ""
      }'?`}
    </GenericModal>
  );
};

export default DeleteUserSessionConfirmationModal;
