import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AdminPageSuccessMessages,
  AdminPageWarnings,
} from "../../../../constants/admin-page.constants";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import {
  ISendTestEmailFormData,
  ISendTestEmailModalProps,
  ISendTestEmailResponseData,
} from "../../../interfaces/AdminPage";
import GenericModal from "../../../modules/GenericModal";
import Form from "../../../modules/common/Form";
import FormTextField from "../../../modules/common/FormTextField";
import FormTextArea from "../../../modules/common/FormTextArea";

const SendTestEmailModal = ({
  formProps: {
    control,
    formState: { errors },
    handleSubmit,
  },
  isOpen,
  closeModal,
}: ISendTestEmailModalProps) => {
  const [loading, setLoading] = useState(false);
  const handleSendTestEmail = async (data: ISendTestEmailFormData) => {
    try {
      setLoading(true);
      const { data: response }: ISendTestEmailResponseData = await axios.post(
        AdminRequestRoutes.SEND_TEST_EMAIL,
        { ...data },
      );
      if (response.apiStatusCode === 200) {
        toast.success(AdminPageSuccessMessages.SEND_TEST_EMAIL);
      } else {
        toast.error(response.message);
      }
      setLoading(false);
      closeModal();
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  return (
    <GenericModal
      labelCancel="Close"
      labelConfirm="Send"
      loading={loading}
      onClickCancel={closeModal}
      onClickConfirm={handleSubmit((data) => handleSendTestEmail(data))}
      onCloseModal={closeModal}
      size="large"
      title="Send Test Email"
      visible={isOpen}
    >
      <Form>
        <b>Please enter the email details below:</b>
        <FormTextField
          control={control}
          error={errors.recipient?.message}
          label={"To Address:"}
          name="recipient"
          placeholder="Enter a valid email address"
          size="small"
          wasValidated={!errors.recipient}
        />

        <FormTextField
          control={control}
          error={errors.subject?.message}
          label={"Subject:"}
          name="subject"
          placeholder="Enter a subject line"
          size="small"
          wasValidated={!errors.subject}
        />
        <FormTextArea
          control={control}
          error={errors.body?.message}
          label={"Body:"}
          name="body"
          placeholder="Enter some text"
          size="small"
          wasValidated={!errors.body}
        />
      </Form>
    </GenericModal>
  );
};

export default SendTestEmailModal;
