import Button from "@atlaskit/button";
import DropdownMenu from "@atlaskit/dropdown-menu";
import { PropsWithChildren } from "react";
import { IHeaderDropdownMenuProps } from "../../interfaces/HeaderDropdown";

const HeaderDropdownMenu: React.FC<
  PropsWithChildren<IHeaderDropdownMenuProps>
> = ({ children, buttonChildren, iconBefore }) => {
  return (
    <DropdownMenu
      placement="bottom-end"
      trigger={({ triggerRef, ...props }) => (
        <Button
          className="dropdown-button"
          {...props}
          iconBefore={iconBefore}
          ref={triggerRef}
        >
          {buttonChildren}
        </Button>
      )}
    >
      {children}
    </DropdownMenu>
  );
};

export default HeaderDropdownMenu;
