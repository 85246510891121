import { ISortParams } from "../../components/interfaces/ApprovalQueue";
import {
  IRecordData,
  IRecordTableData,
} from "../../components/interfaces/Record";
import { TableHeaderItem } from "./approvalQueue";

export interface IRecordFilter {
  project: string[];
  recordType: string[];
  recordStatus: string[];
  recordName?: string;
}

export interface RecordState {
  tableData: IRecordTableData | null;
  rowsData: IRecordData[] | null;
  sortParams: ISortParams;
  selectedPage: number;
  isLoading: boolean;
  tableHeaders: TableHeaderItem[];
  networkError: string;
  filter: IRecordFilter;
}

export enum RecordActionTypes {
  FETCHING_RECORD_TABLE_DATA = "FETCHING_RECORD_TABLE_DATA",
  UPDATE_RECORD_TABLE_DATA = "UPDATE_RECORD_TABLE_DATA",
  UPDATE_RECORD_SELECTED_PAGE = "UPDATE_RECORD_SELECTED_PAGE",
  UPDATE_RECORD_SORT_PARAMS = "UPDATE_RECORD_SORT_PARAMS",
  UPDATE_RECORD_PAGE_PARAMS = "UPDATE_RECORD_PAGE_PARAMS",
  UPDATE_RECORD_FILTER = "UPDATE_RECORD_FILTER",
  RESET_RECORD_SORT_PARAMS = "RESET_RECORD_SORT_PARAMS",
  HANDLE_RECORD_NETWORK_ERROR = "HANDLE_RECORD_NETWORK_ERROR",
  RESET_RECORD_TABLE_DATA = "RESET_RECORD_TABLE_DATA",
  RESET_RECORD_DATA = "RESET_RECORD_DATA",
}

interface FetchingRecordData {
  type: RecordActionTypes.FETCHING_RECORD_TABLE_DATA;
}

interface UpdateRecordTableData {
  type: RecordActionTypes.UPDATE_RECORD_TABLE_DATA;
  payload: {
    tableData: IRecordTableData;
    rowsData: IRecordData[];
  };
}

interface UpdateRecordSelectedPage {
  type: RecordActionTypes.UPDATE_RECORD_SELECTED_PAGE;
  payload: number;
}

interface HandleRecordNetworkError {
  type: RecordActionTypes.HANDLE_RECORD_NETWORK_ERROR;
  payload: string;
}

interface UpdateSortParams {
  type: RecordActionTypes.UPDATE_RECORD_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdatePageParams {
  type: RecordActionTypes.UPDATE_RECORD_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface UpdateRecordFilter {
  type: RecordActionTypes.UPDATE_RECORD_FILTER;
  payload: IRecordFilter;
}

interface HandleResetRecordSortParams {
  type: RecordActionTypes.RESET_RECORD_SORT_PARAMS;
}

interface HandleResetRecordTableData {
  type: RecordActionTypes.RESET_RECORD_TABLE_DATA;
}

interface HandleResetRecordData {
  type: RecordActionTypes.RESET_RECORD_DATA;
}

export type RecordAction =
  | UpdateRecordTableData
  | FetchingRecordData
  | UpdateRecordSelectedPage
  | HandleRecordNetworkError
  | HandleResetRecordTableData
  | HandleResetRecordData
  | UpdateSortParams
  | UpdatePageParams
  | UpdateRecordFilter
  | HandleResetRecordSortParams;
