import axios from "axios";
import { useEffect } from "react";
import React from "react";
import FormSelect from "../../../../../../modules/common/FormSelect";
import {
  IApprovalPolicySelectProps,
  IPolicySetTableDataResponse,
} from "../../../../../../interfaces/PolicySet";
import { ErrorMessage } from "../../../../../../../constants/general.constants";
import { toast } from "react-toastify";
import { PolicyRequestRoutes } from "../../../../../../../constants/policy.constants";
import { IOptionType } from "../../../../../../interfaces/MultiSelectFieldInline";
import { IPolicyDataResponse } from "../../../../../../interfaces/Policy";
import { useTypedSelector } from "../../../../../../../hooks/useTypedSelector";

const PolicySelect = ({
  control,
  isDirty,
  label,
  name,
  placeholder,
  policyType,
}: IApprovalPolicySelectProps) => {
  const [policiesOptionsList, setPoliciesOptionsList] = React.useState<
    IOptionType[]
  >([]);
  const [inputValue, setInputValue] = React.useState("");

  const { tableData } = useTypedSelector((x) => x.policySetLinkedDomains);

  const fetchPolicies = async (searchTerm: string) => {
    if (!searchTerm.length || searchTerm.length >= 3) {
      const params = {
        limit: 25,
        order: "asc",
        skip: 0,
        sort: "Name",
        name: searchTerm,
        policyType,
      };
      try {
        const { data, status } = await axios.get<IPolicyDataResponse>(
          PolicyRequestRoutes.POLICY_LIST,
          {
            params,
          },
        );

        if (status === 200) {
          const policiesOptionsList = data.rows.map((policy) => ({
            label: policy.Name,
            value: policy._id,
          }));

          setPoliciesOptionsList(policiesOptionsList);
        }
      } catch (error: any) {
        if (
          error.message !== ErrorMessage.NEW_REQUEST &&
          error.message !== ErrorMessage.LOGOUT
        ) {
          toast.error(error.message);
        }
      }
    } else {
      setPoliciesOptionsList([]);
    }
  };

  const getWarningMessage = () => {
    if (tableData.rows.length && isDirty) {
      return "This policy set is linked to at least one domain. Updating this Policy will affect all linked domains.";
    }

    return "";
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchPolicies(inputValue);
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  return (
    <FormSelect
      control={control}
      label={label}
      name={name}
      onInputChange={setInputValue}
      options={policiesOptionsList}
      placeholder={placeholder}
      warning={getWarningMessage()}
      wasValidated={isDirty}
    />
  );
};

export default PolicySelect;
