import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { AdminPageErrors } from "../../../../constants/admin-page.constants";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import {
  ICreateDeleteDomainResponseData,
  IDeleteDomainModalProps,
} from "../../../interfaces/AdminPage";
import { IconButton } from "@atlaskit/button/new";

const DeleteDomainModal = ({
  isOpen,
  closeModal,
  domainData,
  updateTableData,
}: IDeleteDomainModalProps) => {
  const [loading, setLoading] = React.useState(false);

  const handleDeleteDomain = async () => {
    try {
      setLoading(true);
      const { data }: ICreateDeleteDomainResponseData = await axios.post(
        AdminRequestRoutes.DELETE_DOMAIN,
        {
          deleteDomainId: domainData._id,
        },
      );
      if (data.apiStatusCode === 204) {
        toast.success(data.message);
        updateTableData();
      } else {
        toast.error(AdminPageErrors.DELETE_DOMAIN);
      }
      closeModal();
      setLoading(false);
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="large">
          <ModalHeader>
            <ModalTitle>Delete Domain</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <div className="remove-from-role-modal-body">
              <p style={{ fontWeight: "500" }}>
                The following domain will be deleted:
              </p>

              {domainData.Name}
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  <button
                    className="btn btn-primary text-white"
                    disabled={loading}
                    onClick={handleDeleteDomain}
                    type="button"
                  >
                    Delete
                    {loading && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      />
                    )}
                  </button>

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default DeleteDomainModal;
