import axios, { CancelTokenSource } from "axios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import {
  IConnectionsTableData,
  IConnectionTableData,
} from "../../components/interfaces/Connections";
import {
  ConnectionRequestRoutes,
  ErrorMessage,
} from "../../constants/general.constants";
import { RootState } from "../reducers";
import {
  ConnectionsAction,
  ConnectionsActionTypes,
} from "../types/connections";

let connectionsCancelToken: CancelTokenSource;

export const getConnectionsData = (
  searchQuery?: Partial<IConnectionTableData>,
) => {
  return async (dispatch: Dispatch<ConnectionsAction>, getState: Function) => {
    if (typeof connectionsCancelToken != typeof undefined) {
      connectionsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    connectionsCancelToken = axios.CancelToken.source();

    const state = getState() as RootState;

    try {
      dispatch({ type: ConnectionsActionTypes.FETCHING_CONNECTIONS_DATA });

      const { data, status } = await axios.get<IConnectionsTableData>(
        ConnectionRequestRoutes.CONNECTIONS,
        {
          cancelToken: connectionsCancelToken.token,
          params: { ...state.connections.sortParams, ...searchQuery },
        },
      );

      if (status === 200) {
        dispatch({
          type: ConnectionsActionTypes.UPDATE_CONNECTIONS_DATA,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({ type: ConnectionsActionTypes.HANDLE_CONNECTIONS_ERROR });
      }
    }
  };
};

export const updateConnectionsSortParams = (
  sort: string,
  order: "asc" | "desc",
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ConnectionsActionTypes.UPDATE_CONNECTIONS_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updateConnectionsPageParams = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ConnectionsActionTypes.UPDATE_CONNECTIONS_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const updateConnectionsSelectedPage = (selectedPage: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ConnectionsActionTypes.UPDATE_CONNECTIONS_SELECTED_PAGE,
      payload: selectedPage,
    });
  };
};

export const resetConnectionsData = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ConnectionsActionTypes.RESET_CONNECTIONS_DATA });
  };
};
