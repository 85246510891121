import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminLoginPage from "./AdminLoginPage";
import { AdminPageRoutes } from "../../../constants/general.constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import UserManagement from "./userManagement/UserManagement";
import UserDetailsPage from "./userManagement/UserDetailsPage";
import AddUserPage from "./userManagement/AddUserPage";
import RoleManagement from "./roleManagement/RoleManagement";
import SignatureVerification from "./signatureVerification/SignatureVerification";
import DomainManagement from "./domainManagement/DomainManagement";
import DomainDetailsPage from "./domainManagement/domainDetails/DomainDetailsPage";
import MailSettingsTab from "./mailSettings/MailSettingsTab";
import PasswordPolicyTab from "./PasswordPolicyTab";
import Policies from "./policyManagement/policies/Policies";
import PolicySets from "./policyManagement/policySets/PolicySets";
import DomainsAndRolesPage from "./domainsAndRolesManagement/DomainsAndRolesPage";
import ConnectionsTable from "./connectionSettings/ConnectionsPage";
import AddConnectionPage from "./connectionSettings/connectionDetails/AddConnectionsPage";
import EditConnectionPage from "./connectionSettings/connectionDetails/EditConnectionsPage";
import PolicyDetailsPage from "./policyManagement/policies/policyDetails/PolicyDetailsPage";
import PolicySetDetailsPage from "./policyManagement/policySets/policySetDetails/PolicySetDetailsPage";
import ConcurrentUserReport from "./reports/concurrentUsageReport/ConcurrentUsageReport";
import AuditHistory from "./reports/auditHistory/AuditHistory";
import UserSessions from "./licenseManagement/userSessions/UserSessions";

const AdminPage = () => {
  const adminIsAuthenticated = useTypedSelector(
    (state) => state.user.adminIsAuthenticated,
  );

  return (
    <>
      {adminIsAuthenticated ? (
        <Switch>
          <Route
            component={UserManagement}
            exact
            path={AdminPageRoutes.USER_MANAGEMENT}
          />
          <Route
            component={UserDetailsPage}
            exact
            path={`${AdminPageRoutes.EDIT_USER}/:userId`}
          />
          <Route
            component={DomainsAndRolesPage}
            exact
            path={`${AdminPageRoutes.DOMAINS_AND_ROLES}/:userId`}
          />
          <Route
            component={AddUserPage}
            exact
            path={AdminPageRoutes.ADD_USER}
          />
          <Route
            component={RoleManagement}
            exact
            path={`${AdminPageRoutes.ROLE_MANAGEMENT}/:domainId`}
          />
          <Route
            component={SignatureVerification}
            exact
            path={AdminPageRoutes.SIGNATURE_VERIFICATION}
          />
          <Route
            component={DomainManagement}
            exact
            path={AdminPageRoutes.DOMAIN_MANAGEMENT}
          />
          <Route
            component={DomainDetailsPage}
            exact
            path={`${AdminPageRoutes.DOMAIN_DETAILS}/:domainId`}
          />
          <Route component={Policies} exact path={AdminPageRoutes.POLICIES} />
          <Route
            component={PolicyDetailsPage}
            exact
            path={`${AdminPageRoutes.POLICY_DETAILS}/:policyId`}
          />
          <Route
            component={PolicySets}
            exact
            path={AdminPageRoutes.POLICY_SETS}
          />
          <Route
            component={PolicySetDetailsPage}
            exact
            path={`${AdminPageRoutes.POLICY_SET_DETAILS}/:policySetId`}
          />
          <Route
            component={MailSettingsTab}
            exact
            path={AdminPageRoutes.MAIL_SETTINGS}
          />
          <Route
            component={PasswordPolicyTab}
            exact
            path={AdminPageRoutes.PASSWORD_POLICY}
          />
          <Route
            component={ConnectionsTable}
            exact
            path={AdminPageRoutes.CONNECTIONS}
          />
          <Route
            component={AddConnectionPage}
            exact
            path={AdminPageRoutes.ADD_CONNECTION}
          />
          <Route
            component={EditConnectionPage}
            exact
            path={`${AdminPageRoutes.EDIT_CONNECTION}/:connectionId`}
          />
          <Route
            component={ConcurrentUserReport}
            exact
            path={AdminPageRoutes.CONCURRENT_USAGE}
          />
          <Route
            component={AuditHistory}
            exact
            path={AdminPageRoutes.AUDIT_HISTORY}
          />
          <Route
            component={UserSessions}
            exact
            path={AdminPageRoutes.USER_SESSIONS}
          />

          <Redirect to={AdminPageRoutes.USER_MANAGEMENT} />
        </Switch>
      ) : (
        <Switch>
          <Route
            component={AdminLoginPage}
            exact
            path={AdminPageRoutes.LOGIN_PAGE}
          />
          <Redirect to={AdminPageRoutes.LOGIN_PAGE} />
        </Switch>
      )}
    </>
  );
};

export default AdminPage;
