import { Controller, useForm } from "react-hook-form";
import FormTextField from "../../../../../modules/common/FormTextField";
import UserSelect from "../../../../../modules/UserSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSchema } from "../../../../../../validators/policyFormValidator";
import {
  IEditPolicyForm,
  IEditPolicyRequest,
  IPolicy,
} from "../../../../../interfaces/Policy";
import Form from "../../../../../modules/common/Form";
import React, { useEffect } from "react";
import Loader from "../../../../../modules/common/Loader";
import axios from "axios";
import { PolicyRequestRoutes } from "../../../../../../constants/policy.constants";
import { toast } from "react-toastify";
import { ErrorMessage } from "../../../../../../constants/general.constants";
import { IDefaultResponse } from "../../../../../interfaces/General";
import { AdminPageSuccessMessages } from "../../../../../../constants/admin-page.constants";
import Toggle from "@atlaskit/toggle";
import useModal from "../../../../../../hooks/useModal";
import SetDefaultConfirmationModal from "../SetDefaultPolicyConfirmationModal";

interface IPolicyDetailsFormProps {
  policy: IEditPolicyForm;
}

const PolicyDetailsForm = ({ policy }: IPolicyDetailsFormProps) => {
  const { hide, show, visible } = useModal();

  const {
    control,
    handleSubmit,
    watch,
    register,
    reset,
    setValue,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<IEditPolicyForm>({
    defaultValues: policy,
    mode: "all",
    resolver: yupResolver(createSchema()),
  });

  const policyOwner = watch("policyOwnerUserId.value");
  const isDefault = watch("isDefault");
  const policyData = watch();

  const handleOnSubmit = async (data: IEditPolicyForm) => {
    const policy: IEditPolicyRequest = {
      _id: data._id,
      description: data.description,
      name: data.name,
      policyOwnerUserId: data.policyOwnerUserId?.value,
    };

    try {
      const { status } = await axios.patch<IDefaultResponse<IPolicy>>(
        PolicyRequestRoutes.POLICY,
        policy,
      );
      if (status === 200) {
        reset(policyData);
        toast.success(AdminPageSuccessMessages.SAVE_POLICY);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  const handleOnClickReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    reset();
  };

  const handleOnClickSetDefault = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValue("isDefault", "true");
    show();
  };

  useEffect(() => {
    reset(policy);
  }, [policy]);

  return (
    <>
      <Form
        className="mt-4"
        onSubmit={handleSubmit((data) => handleOnSubmit(data))}
      >
        <FormTextField
          control={control}
          error={errors?.name?.message}
          label="Policy Name:"
          name="name"
          placeholder="Enter Policy Name"
          wasValidated={!errors?.name}
        />

        <FormTextField
          control={control}
          error={errors?.description?.message}
          label="Description:"
          name="description"
          placeholder="Enter Description"
          wasValidated={!errors?.description}
        />

        <Controller
          control={control}
          name="policyOwnerUserId"
          render={({ field: { onChange, onBlur, value, name } }) => (
            <UserSelect
              error={errors?.policyOwnerUserId?.message as string}
              label="Owner"
              multiple={false}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              onlyAdmin={true}
              size="medium"
              value={value}
              wasValidated={!!policyOwner && !errors?.policyOwnerUserId}
            />
          )}
        />
        {policy.isDefault !== "true" && (
          <div
            aria-label="set-default-policy-container"
            className="form-with-label form-with-label--medium"
          >
            Set as Default Policy
            <Toggle
              {...register("isDefault")}
              id="toggle-set-as-default-policy"
              isChecked={isDefault === "true"}
              isDisabled={false}
              onChange={handleOnClickSetDefault}
              size="large"
            />
          </div>
        )}
        <div className="text-right">
          <button
            aria-label="save-changes-btn"
            className="btn btn-primary text-white"
            disabled={isSubmitting || !isDirty}
            id="btn-save-policy"
          >
            Save Changes
            <Loader isLoading={isSubmitting} />
          </button>

          <button
            aria-label="reset-btn"
            className="btn btn-primary text-white ml-2"
            disabled={!isDirty}
            id="btn-reset-policy"
            onClick={handleOnClickReset}
            type="button"
          >
            Reset
          </button>
        </div>
      </Form>
      <SetDefaultConfirmationModal
        id={policy._id}
        onCancel={() => setValue("isDefault", "false")}
        onClose={hide}
        visible={visible}
      />
    </>
  );
};

export default PolicyDetailsForm;
