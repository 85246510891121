import React from "react";
import DomainsAndRolesFilter from "./DomainsAndRolesFilter";
import DomainsAndRolesPagination from "./DomainsAndRolesPagination";
import DomainsAndRolesList from "./DomainsAndRolesList";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useParams } from "react-router-dom";
import { AdminPageQueryParams } from "../../../interfaces/AdminPage";

const DomainsAndRolesPage: React.FC = () => {
  const fullname = useTypedSelector(
    (state) => state.domainsAndRoles.domainAndRoles.fullname,
  );
  const { userId } = useParams<AdminPageQueryParams>();

  return (
    <div>
      <div className="table-heading-wrapper">
        <b>Domains & Roles</b>
      </div>
      <small>{fullname}</small>
      <div className="mt-4">
        <DomainsAndRolesFilter />
        <DomainsAndRolesList userId={userId} />
        <DomainsAndRolesPagination />
      </div>
    </div>
  );
};

export default DomainsAndRolesPage;
