import { rootReducer } from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
import { versionApi } from "./slices/version.slice";
import { userApi } from "./slices/user.slice";
import { useDispatch } from "react-redux";
import { auditHistoryApi } from "./slices/audit-history.slice";
import { userSessionsApi } from "./slices/user-session.slice";
import { roleManagementApi } from "./slices/role-management.slice";

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(versionApi.middleware)
        .concat(userApi.middleware)
        .concat(auditHistoryApi.middleware)
        .concat(userSessionsApi.middleware)
        .concat(roleManagementApi.middleware),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const store = setupStore();
