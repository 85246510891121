import { PropsWithChildren } from "react";

interface ICollapseContentProps {
  id: string;
  parentId: string;
}

const CollapseContent = ({
  children,
  id,
  parentId,
}: PropsWithChildren<ICollapseContentProps>) => {
  return (
    <div
      className="collapse show container-fluid"
      data-bs-parent={`#${parentId}`}
      id={id}
    >
      {children}
    </div>
  );
};

export default CollapseContent;
