import { useCallback } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useLazyGetUsersByFullNameQuery } from "../../../../store/slices/user.slice";
import { AdminPageQueryParams } from "../../../interfaces/AdminPage";
import DropdownUsers from "../../../modules/DropdownUsers";
import { useParams } from "react-router-dom";
import { useAddUsersToRoleMutation } from "../../../../store/slices/role-management.slice";
import { toast } from "react-toastify";

const RoleUsersSelector = () => {
  const { domainId } = useParams<AdminPageQueryParams>();

  const { searchTerm, selectedRole, selectedUsers } = useTypedSelector(
    (x) => x.rolesManagement,
  );

  const [
    getUsers,
    { data = { total: 0, rows: [] }, isError, isFetching, originalArgs },
  ] = useLazyGetUsersByFullNameQuery();

  const [addUsersToRole, { isLoading }] = useAddUsersToRoleMutation();

  const getUsersByFullName = useCallback(
    (skip: number) => {
      getUsers({
        excludeRole: selectedRole,
        excludeDomain: domainId,
        searchTerm,
        skip,
      });
    },
    [domainId, searchTerm, selectedRole],
  );

  const handleAddUsersToRole = async () => {
    addUsersToRole({ domainId, selectedRole, selectedUsers })
      .unwrap()
      .then((x) => {
        if (x.apiStatusCode !== 200) {
          toast.error(x.message);
        } else {
          toast.success(`User(s) added to ${selectedRole} successfully.`);
          getUsersByFullName(0);
        }
      })
      .catch((error: any) => toast.error(error.data?.message));
  };

  return (
    <>
      <DropdownUsers
        data={data}
        getUsers={getUsersByFullName}
        isError={isError}
        isFetching={isFetching}
        isFirstPage={!originalArgs?.skip}
      />
      <span>
        <button
          className="btn btn-primary text-white"
          disabled={!selectedUsers?.length || isLoading}
          id="add-users-to-role-btn"
          onClick={handleAddUsersToRole}
          type="button"
        >
          <i className="fa fa-floppy-o" />{" "}
          {`Add Users to ${selectedRole || ""} Role`}
          {isLoading && (
            <span
              aria-hidden="true"
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            />
          )}
        </button>
      </span>
    </>
  );
};

export default RoleUsersSelector;
