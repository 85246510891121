import * as yup from "yup";

const regexValidURL = /^https?:\/\/.+$/;

const getBrowserURLMessage = (system: string): string => {
  switch (system) {
    case "JIRA":
      return ', ex.: "https://<server>/browse/{Key}"';
    case "qTest":
      return ', ex.: "https://<server>/p/{Project ID}/portal/project#key={Key}"';
    case "Tosca":
      return ', ex.: "https://<server>/rest/toscacommander"';
    case "Xray Cloud":
      return ', ex.: "https://xray.cloud.getxray.app/api/v2/"';
  }

  return "";
};

const getAPIURLMessage = (system: string): string => {
  switch (system) {
    case "JIRA":
      return ', ex.: "https://<server>/rest/api/2/issue/{Issue ID}"';
    case "qTest":
      return ', ex.: "https://<server>/api/v3/projects/{Project ID}/{Item Type}s/{Entity ID}"';
    case "Tosca":
      return ', ex.: "https://<server>/rest/toscacommander/{workspace}/treeview/{unique id}';
    case "Xray Cloud":
      return ', ex.: "https://xray.cloud.getxray.app/api/v2/"';
  }

  return "";
};

export function createSchema() {
  return yup.object().shape({
    System: yup.lazy(() => {
      return yup.object().nullable().required("Please select a system type.");
    }),
    Name: yup.lazy(() => {
      return yup
        .string()
        .nullable()
        .required("Please enter a name.")
        .matches(
          /^[ da-zA-Z0-9-.+_@]*$/,
          "Valid characters only include: alphanumeric, @, -, _, +, and .",
        );
    }),
    Server: yup.lazy(() => {
      return yup
        .string()
        .nullable()
        .required("Please enter server's URL.")
        .matches(regexValidURL, "Please enter a valid server's URL");
    }),
    ServiceAccount: yup.lazy((_, { parent }) => {
      if (parent.System?.value === "VERA") {
        return yup.string().nullable().notRequired();
      }

      return yup
        .string()
        .nullable()
        .required("Please enter the service account username.");
    }),
    ServicePassword: yup.lazy((_, { parent }) => {
      const { _id, System } = parent;

      if (_id || System?.value === "VERA") {
        return yup.string().nullable().notRequired();
      }

      return yup
        .string()
        .nullable()
        .required("Please enter the service account's password.");
    }),
    Urls: yup.lazy((_, parentProps) => {
      return yup.object().shape({
        Browser: yup.lazy(() => {
          const urlExample = getBrowserURLMessage(
            parentProps.parent.System?.value,
          );

          if (urlExample) {
            return yup
              .string()
              .nullable()
              .matches(regexValidURL, `Please enter a valid URL${urlExample}`);
          }

          return yup
            .string()
            .nullable()
            .required("Please enter the browser URL.");
        }),
        API: yup.lazy(() => {
          const urlExample = getAPIURLMessage(parentProps.parent.System?.value);

          if (urlExample) {
            return yup
              .string()
              .nullable()
              .matches(regexValidURL, `Please enter a valid URL${urlExample}`);
          }

          return yup.string().nullable().required("Please enter the API URL.");
        }),
      });
    }),
    Aliases: yup.array(
      yup.object({
        value: yup
          .string()
          .nullable()
          .required("Please enter the alias")
          .matches(regexValidURL, "Please enter a valid alias."),
      }),
    ),
    AuthMode: yup.lazy((_, { parent }) => {
      if (parent.System?.value === "Tosca") {
        return yup.object().nullable().required("Please select the auth mode.");
      }

      return yup.object().nullable().notRequired();
    }),
    LinkedConnections: yup.lazy((value) => {
      if (value === undefined) {
        return yup.object().nullable().notRequired();
      }

      return yup
        .object()
        .nullable()
        .required("Please enter the linked connection.");
    }),
    qTestName: yup.lazy((_, { parent }) => {
      if (parent.System?.value === "qTest") {
        return yup.string().nullable().required("Please enter the qTest Name.");
      }

      return yup.string().nullable().notRequired();
    }),
  });
}
