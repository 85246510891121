import { RootState } from "../reducers";
import { ITableData } from "../../components/interfaces/Table";
import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";
import { Dispatch } from "react";
import {
  ErrorMessage,
  ProfileRequestRoutes,
} from "../../constants/general.constants";
import { toast } from "react-toastify";
import axios, { CancelTokenSource } from "axios";
import { UsersAction, UsersActionTypes } from "../types/users";
import { IUserManagementItem } from "../../components/interfaces/AdminPage";

let cancelToken: CancelTokenSource;
export const fetchUsers = () => {
  return async (dispatch: Dispatch<UsersAction>, getState: Function) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();

    const state = getState() as RootState;
    const sortParams = state.users.sortParams;
    const columnSearchField = state.users.columnSearchField;

    const params = {
      ...sortParams,
      filter: JSON.stringify(columnSearchField),
    };

    try {
      dispatch({ type: UsersActionTypes.FETCHING_USERS_TABLE_DATA });

      const { data, status } = await axios.get<ITableData<IUserManagementItem>>(
        ProfileRequestRoutes.USERS_LIST,
        { cancelToken: cancelToken.token, params },
      );

      if (status === 200) {
        dispatch({
          type: UsersActionTypes.UPDATE_USERS_TABLE_DATA,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: UsersActionTypes.HANDLE_USERS_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const updateUsersSelectedPage = (selectedPage: number) => {
  return {
    type: UsersActionTypes.UPDATE_USERS_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const updateUsersSortParams = (sort: string, order: "asc" | "desc") => {
  return async (dispatch: Dispatch<UsersAction>) => {
    dispatch({
      type: UsersActionTypes.UPDATE_USERS_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updateUsersPageParams = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<UsersAction>) => {
    dispatch({
      type: UsersActionTypes.UPDATE_USERS_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetUsersSortParams = () => {
  return async (dispatch: Dispatch<UsersAction>) => {
    dispatch({ type: UsersActionTypes.RESET_USERS_SORT_PARAMS });
  };
};

export const updateUsersColumnSearchField =
  (columnSearchField: IColumnsSearchFieldsFormData) =>
  async (dispatch: Dispatch<UsersAction>) => {
    dispatch({
      type: UsersActionTypes.UPDATE_USERS_COLUMN_SEARCH_FIELD,
      payload: columnSearchField,
    });
  };
