import { IDomainUpdateResponse } from "../components/interfaces/AdminPage";
import { IDomainDetailsForm } from "../components/interfaces/Domain";

export const transformDomainResponse = (
  domainResponse: IDomainUpdateResponse,
): IDomainDetailsForm => {
  return domainResponse
    ? {
        description: domainResponse.Description,
        domainName: domainResponse.Name,
        domainOwner: domainResponse.DomainOwnerUserId
          ? {
              value: domainResponse.DomainOwnerUserId,
              label: `${domainResponse.DomainOwner?.FullName} (${domainResponse.DomainOwner?.UserName})`,
            }
          : null,
        isDefault: domainResponse.isDefault,
        policySet: domainResponse.PolicySet,
      }
    : {};
};
