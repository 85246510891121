import Lozenge from "@atlaskit/lozenge";
import PolicyDetailsForm from "./PolicyDetailsForm";
import PolicyRevisionsTable from "./PolicyRevisionsTable";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { IEditPolicyForm } from "../../../../../interfaces/Policy";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useActions } from "../../../../../../hooks/useActions";
import Loader from "../../../../../modules/Loader";
import PolicySetsTable from "./policySets/PolicySetsTable";

const PolicyDetailsPage = () => {
  const { policyId } = useParams<{ policyId: string }>();

  const { policy, isLoading } = useTypedSelector((x) => x.policy);
  const { sortParams } = useTypedSelector((x) => x.policyRevisions);
  const { sortParams: sortParamsLinkedPolicies } = useTypedSelector(
    (x) => x.policyLinkedPolicies,
  );

  const { fetchPolicy, fetchPolicyRevisions, fetchPolicySetsFromPolicy } =
    useActions();

  const editPolicyForm: IEditPolicyForm | undefined = policy
    ? {
        _id: policy._id,
        description: policy.Description,
        isDefault: policy.IsDefault,
        name: policy.Name,
        policyOwnerUserId: policy.PolicyOwnerUserId
          ? {
              label: `${policy.PolicyOwnerFullName} (${policy.PolicyOwnerUserName})`,
              value: policy.PolicyOwnerUserId,
            }
          : undefined,
      }
    : undefined;

  useEffect(() => {
    fetchPolicy(policyId);
  }, [policyId]);

  useEffect(() => {
    fetchPolicySetsFromPolicy(policyId);
  }, [policyId, sortParamsLinkedPolicies]);

  useEffect(() => {
    fetchPolicyRevisions(policyId);
  }, [policyId, sortParams]);

  return (
    <div>
      <div className="table-heading-wrapper">
        <b>Policy Details {policy?.IsDefault && <Lozenge>Default</Lozenge>}</b>
      </div>
      {isLoading && <Loader message={"Loading, please wait..."} />}
      {!isLoading && editPolicyForm && (
        <div className="mt-4">
          <PolicyDetailsForm policy={editPolicyForm} />
          <PolicyRevisionsTable />
          <PolicySetsTable />
        </div>
      )}
    </div>
  );
};

export default PolicyDetailsPage;
