import React, { useEffect } from "react";
import {
  escapeOnlyNonHtmlTags,
  isAnchorLink,
} from "../../../../../helpers/html.helper";
import {
  IRecordList,
  SubDefectsModalProps,
} from "../../../../interfaces/DetailsPage";
import Loader from "../../../../modules/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import {
  DetailsPageRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";

const SubDefectsModal = ({ defects, recordId }: SubDefectsModalProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [records, setRecords] = React.useState<IRecordList>({
    rows: defects ?? [],
    total: defects?.length ?? 0,
  });

  const { data } = useTypedSelector((x) => x.recordDetails);
  const { closeDefectsModal } = useActions();

  useEffect(() => {
    isLoading && loadDefects();
  }, [isLoading]);

  const handleOnClickShowMore = () => setIsLoading(true);

  const handleOnClose = () => {
    closeDefectsModal();
  };

  const loadDefects = async () => {
    try {
      if (records.rows.length < records.total || records.total === 0) {
        const { data: response, status } = await axios.get(
          `${DetailsPageRequestRoutes.LINKED_RECORDS}/${recordId}`,
          {
            params: {
              linkName: "defects",
              limit: 5,
              parentRecordId: data?.record?._id,
              skip: records.rows.length,
              version: data?.record?.version,
            },
          },
        );

        if (status === 200) {
          setRecords((records) => ({
            ...records,
            rows: [...records.rows, ...response.rows],
            total: response.total,
          }));
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      aria-modal="true"
      className="modal modal-show"
      id="defectsModal"
      role="dialog"
      style={{ display: "block", paddingRight: "15px" }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div>
            <div className="modal-header">
              <h4 className="modal-title">Defects</h4>
              <button
                aria-label="close-defects-modal"
                className="close"
                data-dismiss="modal"
                onClick={handleOnClose}
                type="button"
              >
                ×
              </button>
            </div>
            <div className="modal-body modal-body-defects-modal co-md-12">
              <table className="table" id="defects-table">
                <thead>
                  {records?.rows?.some((x) => x.name) && <th>Name</th>}
                  {records?.rows?.length > 0 &&
                    Object.keys(records.rows[0].fields).map((key: any) => (
                      <th key={key}>{key}</th>
                    ))}
                </thead>

                <tbody>
                  {records?.rows?.map((defect: any) => (
                    <tr key={defect._id}>
                      {records?.rows?.some((x) => x.name) && (
                        <td>
                          {defect.locations[0].local_id_fields["Entity ID"] !==
                            0 && !isAnchorLink(defect.name) ? (
                            <a
                              href={`${defect.locations[0].urls["Browser"]}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {defect.name}
                            </a>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: escapeOnlyNonHtmlTags(defect.name),
                              }}
                            ></div>
                          )}
                        </td>
                      )}

                      {Object.values(defect.fields).length > 0 ? (
                        Object.values(defect.fields).map(
                          (value: any, index) => (
                            <td
                              dangerouslySetInnerHTML={{
                                __html: escapeOnlyNonHtmlTags(value),
                              }}
                              key={index}
                            ></td>
                          ),
                        )
                      ) : (
                        <td align="center" colSpan={100}>
                          <em>See integrated defect tracker for details.</em>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {isLoading && <Loader message="Loading, please wait..." />}
              {records?.rows?.length !== records?.total && !isLoading && (
                <div className="w-100 text-right">
                  <button
                    className="btn btn-primary text-white"
                    onClick={handleOnClickShowMore}
                  >
                    Show More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubDefectsModal;
