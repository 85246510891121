import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ApprovalPageRequestRoutes,
  ErrorMessage,
} from "../../../constants/general.constants";
import { DetailsQueryParams } from "../../interfaces/DetailsPage";
import { TaskApproveRejectResponse } from "../../interfaces/ApprovalQueue";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import Breadcrumbs from "./Breadcrumbs";
import EntityDetails from "./entityDetails/EntityDetails";
import RecordHistory from "./recordHistory/RecordHistory";
import ApprovalRoutes from "./approvalRoutes/ApprovalRoutes";
import PrintView from "./printView/PrintView";
import ApproveFooter from "../approval-queue/ApproveFooter";
import RejectionReason from "./RejectionReason";
import { DetailsRoutes } from "../../../constants/details-page.constants";
import RevisionHistory from "./revisionHistory/RevisionHistory";
import RevisionWarningFooter from "./revisionHistory/RevisionWarningFooter";
import AuthorWarningFooter from "./AuthorWarningFooter";

const DetailsPage = () => {
  const { routeId, recordId, revision, menuSelector } =
    useParams<DetailsQueryParams>();
  const { user, veraIdp } = useTypedSelector((state) => state.user);
  const { approveSessionID } = useTypedSelector((state) => state.approveFooter);
  const { fetchRecordDetails, openApproveModal, resetRecordDetailsData } =
    useActions();
  const { data, isLoading } = useTypedSelector((state) => state.recordDetails);

  const initialPath = routeId
    ? `/details/${routeId}/${recordId}`
    : `/records/${recordId}/${revision}`;
  const history = useHistory();

  async function getRecordDetails(refetch?: boolean) {
    fetchRecordDetails(
      history,
      routeId,
      recordId,
      revision,
      menuSelector,
      refetch,
    );
  }

  const handleOnCloseApproveModal = (shouldRefresh: boolean) => {
    shouldRefresh && getRecordDetails(true);
  };

  const handleTaskSubmit = async (
    password: string,
    reason: string,
    taskId: string,
    taskRejection = false,
  ) => {
    let response: TaskApproveRejectResponse;
    if (taskRejection) {
      response = await axios.post(ApprovalPageRequestRoutes.TASK_REJECT, {
        rejectionReason: reason,
        rejectionTaskId: taskId,
      });
    } else {
      if (!veraIdp) {
        const { data }: TaskApproveRejectResponse = await axios.post(
          ApprovalPageRequestRoutes.SAML_LOGIN,
          {
            approvalTaskId: taskId,
            redirectTo: window.location.pathname,
            sessionID: approveSessionID,
          },
        );

        if (data.message) {
          toast.error(data.message);
          return false;
        }

        data.url && window.location.replace(data.url);
        return false;
      } else {
        try {
          response = await axios.post(ApprovalPageRequestRoutes.TASK_APPROVE, {
            username: user?.username,
            password: password,
            approvalTaskId: taskId,
            sessionID: approveSessionID,
          });
        } catch (error: any) {
          if (
            error.message !== ErrorMessage.NEW_REQUEST &&
            error.message !== ErrorMessage.LOGOUT
          ) {
            const errorMessage =
              error?.response?.data?.message ?? error.message;
            toast.error(errorMessage);
          }

          return false;
        }
      }
    }

    if (
      response.data.error ||
      [400, 500].includes(response.data.apiStatusCode)
    ) {
      toast.error(response.data.error || response.data.message);
      return false;
    }

    if (taskRejection) {
      toast.success(response.data.message);
      getRecordDetails(true);
    }

    return true;
  };

  useEffect(() => {
    return () => {
      resetRecordDetailsData();
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("approve") !== null && !veraIdp) {
      openApproveModal();
    }
  }, []);

  const {
    fullRecord,
    record,
    assignedTask,
    rejectionReason,
    userIsInAuthorsList,
    authorExclusionEnabled,
  } = data || {};

  const recordData = record ?? fullRecord;

  const showApprovalFooter =
    routeId && assignedTask && recordData?.status?.toLowerCase() !== "draft";

  const showAuthorWarning = userIsInAuthorsList && authorExclusionEnabled;

  return (
    <div className="container-fluid pt-2" style={{ fontSize: ".8rem" }}>
      <div className="row pl-3">
        <div className="col-12">
          {data && <Breadcrumbs data={data} />}

          <Switch>
            <Route
              exact
              path={`${initialPath}/${DetailsRoutes.ENTITY_DETAILS}`}
              render={() => (
                <EntityDetails
                  handleTaskSubmit={handleTaskSubmit}
                  onCloseApproveModal={handleOnCloseApproveModal}
                />
              )}
            />

            <Route
              component={RecordHistory}
              exact
              path={`${initialPath}/${DetailsRoutes.RECORD_HISTORY}`}
            />

            <Route
              component={ApprovalRoutes}
              exact
              path={`${initialPath}/${DetailsRoutes.APPROVAL_ROUTES}`}
            />

            <Route
              component={PrintView}
              exact
              path={`${initialPath}/${DetailsRoutes.PRINT_VIEW}`}
            />

            {rejectionReason && (
              <Route
                component={RejectionReason}
                exact
                path={`${initialPath}/${DetailsRoutes.REJECTION_REASON}`}
              />
            )}

            <Route
              component={RevisionHistory}
              exact
              path={`${initialPath}/${DetailsRoutes.RECORD_REVISIONS}`}
            />

            <Redirect to={`${initialPath}/${DetailsRoutes.ENTITY_DETAILS}`} />
          </Switch>

          {showApprovalFooter && (
            <ApproveFooter
              assignedTask={assignedTask}
              onCloseApproveModal={handleOnCloseApproveModal}
              onTaskSubmit={handleTaskSubmit}
            />
          )}

          {recordData?.status === "Superseded" && <RevisionWarningFooter />}

          {showAuthorWarning && <AuthorWarningFooter />}
        </div>
      </div>
    </div>
  );
};

export default DetailsPage;
