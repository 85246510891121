import React from "react";
import { Link } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import headerLogo from "../../images/company-logo-white.png";
import { AppRoutes } from "../../constants/general.constants";
import HeaderActionBar from "./HeaderActionBar";

const Header: React.FC = () => {
  const user = useTypedSelector((state) => state.user.user);
  const getUrl = () => (user ? AppRoutes.ApprovalQueue : AppRoutes.LoginPage);

  return (
    <>
      {user && (
        <header className="navbar navbar-expand fixed-top vera-navbar no-print">
          <div className="container-fluid">
            <Link className="navbar-brand text-white" to={getUrl()}>
              <img
                alt="Company logo"
                className="d-inline-block align-top"
                src={headerLogo}
                style={{ height: "26px" }}
              />
            </Link>

            {user && <HeaderActionBar />}
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
