import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { IRemoveUserFromRoleModalProps } from "../../../interfaces/AdminPage";
import { useState } from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IconButton } from "@atlaskit/button/new";

const RemoveFromRoleModal = ({
  isOpen,
  closeModal,
  deleteUser,
  userData,
}: IRemoveUserFromRoleModalProps) => {
  const [loading, setLoading] = useState(false);
  const selectedRole = useTypedSelector((x) => x.rolesManagement.selectedRole);

  const handleDeleteUser = async () => {
    setLoading(true);
    await deleteUser(userData.map((x) => x._id));
    setLoading(false);
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="large">
          <ModalHeader>
            <ModalTitle>Remove Users From Role</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <div className="remove-from-role-modal-body">
              <p style={{ fontWeight: "500" }}>
                The following users will be removed from the {selectedRole}{" "}
                role:
              </p>

              <p className="m-0">
                <ul className="m-0 remove-from-role-modal__users">
                  {userData.map(({ userName, fullName }) => (
                    <li key={userName}>{`${fullName} (${userName})`}</li>
                  ))}
                </ul>
              </p>
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  <button
                    className="btn btn-primary text-white"
                    disabled={loading}
                    onClick={handleDeleteUser}
                    type="button"
                  >
                    Remove
                    {loading && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      />
                    )}
                  </button>

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default RemoveFromRoleModal;
