import { IDomainState } from "../../components/interfaces/AdminPage";
import { DomainAction, DomainActionTypes } from "../types/domain";

const initialState: IDomainState = {
  tableData: null,
  isLoading: false,
  selectedPage: 0,
  sortParams: {
    sort: "Name",
    order: "asc",
    offset: 0,
    limit: 10,
  },
  tableHeaders: [
    { label: "Domain Name", name: "Name", filterable: true, colSize: 5 },
    { label: "Description", name: "Description", filterable: true, colSize: 5 },
    {
      label: "Domain Owner",
      name: "DomainOwnerFullName",
      filterable: true,
      colSize: 5,
    },
  ],
};

export const domainReducer = (
  state = initialState,
  action: DomainAction,
): IDomainState => {
  switch (action.type) {
    case DomainActionTypes.FETCHING_DOMAIN_DATA:
      return { ...state, isLoading: true };
    case DomainActionTypes.UPDATE_DOMAIN_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload,
      };
    case DomainActionTypes.UPDATE_DOMAIN_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case DomainActionTypes.UPDATE_DOMAIN_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case DomainActionTypes.UPDATE_DOMAIN_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case DomainActionTypes.RESET_DOMAIN_DATA:
      return initialState;
    default:
      return state;
  }
};
