import AtlasSelect, { StylesConfig } from "@atlaskit/select";
import { ISelectItem } from "../../interfaces/UserSelect";

interface ISelectProps {
  disabled?: boolean;
  error?: string;
  inputValue?: string;
  isLoading?: boolean;
  label?: string;
  menuPosition?: "absolute" | "fixed";
  multiple?: boolean;
  name: string;
  onBlur?: () => void;
  onChange: (newValue: any, action: any) => void;
  onMenuClose?: () => void;
  onInputChange?: any;
  onMenuScrollToBottom?: (e: WheelEvent | TouchEvent) => void;
  options: ISelectItem[];
  placeholder: string;
  size?: "small" | "medium" | "large";
  styles?: StylesConfig<ISelectItem, boolean>;
  value?: ISelectItem | ISelectItem[] | null;
  warning?: string;
  wasValidated?: boolean;
}

const Select = ({
  disabled,
  error,
  inputValue,
  isLoading,
  label,
  menuPosition = "fixed",
  multiple,
  name,
  onBlur,
  onChange,
  onMenuClose,
  onInputChange,
  onMenuScrollToBottom,
  options,
  placeholder,
  size,
  styles,
  value,
  warning,
  wasValidated,
}: ISelectProps) => {
  const getInputClassName = () => {
    let className = "form-control form-control-select single-select";

    if (error) {
      className += " is-invalid";
    }

    if (warning) {
      className += " has-warning";
    }

    if (wasValidated && !error && !warning) {
      className += " is-valid";
    }

    return className;
  };

  const getContainerClassName = () => {
    if (!label) {
      return "";
    }

    if (size === "medium") {
      return "form-with-label form-with-label--medium";
    }

    if (size === "small") {
      return "form-with-label form-with-label--small";
    }

    return "form-with-label form-with-label--large";
  };

  const getValidationMessage = () => {
    if (error) {
      return (
        <div className="invalid-feedback" role="alert">
          {error}
        </div>
      );
    }

    if (warning) {
      return (
        <div
          className="invalid-feedback invalid-feedback--warning"
          role="alert"
        >
          {warning}
        </div>
      );
    }

    return <></>;
  };

  return (
    <div
      className={`${getContainerClassName()} ${
        wasValidated ? "was-validated" : ""
      }`}
    >
      {label && <label>{label}</label>}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <AtlasSelect
          aria-label="multiselect-field"
          className={getInputClassName()}
          classNamePrefix="react-select"
          closeMenuOnSelect={!multiple}
          id={name}
          inputId={`select-${name}`}
          inputValue={inputValue}
          isClearable={true}
          isDisabled={disabled}
          isLoading={isLoading}
          isMulti={multiple}
          menuPosition={menuPosition}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onInputChange={onInputChange}
          onMenuClose={onMenuClose}
          onMenuScrollToBottom={onMenuScrollToBottom}
          options={options}
          placeholder={placeholder}
          spacing={"default"}
          styles={styles}
          value={value}
        />
        {getValidationMessage()}
      </div>
    </div>
  );
};

export default Select;
