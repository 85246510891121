import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Select from "@atlaskit/select";
import { validateNewUserForm } from "../../../../validators/newUserFormValidator";
import {
  IIdpSelectOption,
  INewUserFormData,
  INewUserFormValidationErrors,
  INewUserGetResponse,
  INewUserPostResponse,
  IPasswordPolicyFormData,
  IPasswordPolicyResponseData,
} from "../../../interfaces/AdminPage";
import {
  AdminPageRoutes,
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import {
  AdminPageWarnings,
  SelectFieldValidation,
} from "../../../../constants/admin-page.constants";
import TextField from "../../../modules/common/TextField";
import Loader from "../../../modules/Loader";

const AddUserPage = () => {
  const history = useHistory();
  const initialFormState: INewUserFormData = {
    email: undefined,
    fullName: undefined,
    identityProvider: undefined,
    idpUsername: undefined,
    password: undefined,
    userName: undefined,
  };
  const [loading, setLoading] = useState(false);
  const [identityProvidersList, setIdentityProvidersList] = useState<
    IIdpSelectOption[] | null
  >(null);
  const [validationErrors, setValidationErrors] =
    useState<INewUserFormValidationErrors>({});
  const [formData, setFormData] = useState<INewUserFormData>(initialFormState);
  const [passwordPolicyData, setPasswordPolicyData] =
    useState<IPasswordPolicyFormData>({
      minLower: 0,
      minUpper: 0,
      minNumeric: 0,
      minSpecial: 0,
      minLength: 0,
    });

  const getPasswordPolicyData = async () => {
    try {
      const { data }: IPasswordPolicyResponseData = await axios.get(
        AdminRequestRoutes.PASSWORD_POLICY,
      );

      if (data.apiStatusCode === 200) {
        const receivedFormData = {
          minLower: data.apiData.min_lower,
          minUpper: data.apiData.min_upper,
          minNumeric: data.apiData.min_numeric,
          minSpecial: data.apiData.min_special,
          minLength: data.apiData.min_length,
        };
        setPasswordPolicyData(receivedFormData);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
  const getIdentityProviders = async () => {
    try {
      const { data }: INewUserGetResponse = await axios.get(
        AdminRequestRoutes.ADD_USER,
      );
      data &&
        setIdentityProvidersList(
          data.idps.map((idp) => ({
            label: idp.name ?? idp.id,
            value: idp.id,
          })),
        );
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        history.push(AdminPageRoutes.USER_MANAGEMENT);
      }
    }
  };
  const handleFormChange = (newData: any) =>
    setFormData((prev) => ({
      ...prev,
      [newData.name]: newData.value,
    }));

  const handleFormBlur = (newData: any) =>
    setFormData((prev) => ({
      ...prev,
      [newData.name]: newData.value?.trim() ?? "",
    }));
  const handleSelectChange = (item: any) =>
    setFormData((prev) => ({ ...prev, identityProvider: item.value }));

  const validateFormData = () => {
    const copyFormData = JSON.parse(JSON.stringify(formData));
    Object.keys(copyFormData).forEach((key) => {
      (copyFormData as any)[key] = (copyFormData as any)[key] || "";
    });
    validateNewUserForm(copyFormData, setValidationErrors, passwordPolicyData);
  };

  const handleAddUser = async () => {
    try {
      setLoading(true);

      validateFormData();
      if (
        JSON.stringify(formData) === JSON.stringify(initialFormState) ||
        Object.keys(validationErrors).length
      ) {
        toast.warning(AdminPageWarnings.FILL_FORM);
        return;
      }
      if (formData.identityProvider !== "VERA" && !formData.idpUsername) {
        toast.warning(AdminPageWarnings.NO_IDP_USERNAME);
        return;
      }
      if (formData.identityProvider === "VERA" && !formData.password) {
        toast.warning(AdminPageWarnings.NO_PASSWORD);
        return;
      }
      const { data }: INewUserPostResponse = await axios.post(
        AdminRequestRoutes.ADD_EDIT_USER,
        formData,
      );

      toast.success(data.message);
      const newUserId = data.apiData._id;
      history.push(`${AdminPageRoutes.EDIT_USER}/${newUserId}`);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error;

        toast.error(response?.data as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const validateSelectField = () => {
    if (validationErrors.identityProvider) {
      return SelectFieldValidation.ERROR;
    }
    if (formData.identityProvider && !validationErrors.identityProvider) {
      return SelectFieldValidation.SUCCESS;
    } else {
      return SelectFieldValidation.DEFAULT;
    }
  };

  useEffect(() => {
    getIdentityProviders();
    getPasswordPolicyData();
  }, []);

  useEffect(() => {
    formData !== initialFormState &&
      validateNewUserForm(formData, setValidationErrors, passwordPolicyData);
  }, [formData]);
  return (
    <>
      <div className="table-heading-wrapper">
        <b>Add User Profile</b>
      </div>

      {!identityProvidersList || !passwordPolicyData ? (
        <Loader />
      ) : (
        <form
          aria-label="profile-form"
          className={`needs-validation add-user-wrapper mt-4`}
          noValidate
        >
          <TextField
            error={validationErrors.userName}
            label={"User Name: "}
            name={"userName"}
            onBlur={handleFormBlur}
            onChange={handleFormChange}
            placeholder={"Enter the username"}
            value={formData.userName}
            wasValidated={!!(formData.userName && !validationErrors.userName)}
          />

          <TextField
            error={validationErrors.fullName}
            label={"Full Name: "}
            name={"fullName"}
            onBlur={handleFormBlur}
            onChange={handleFormChange}
            placeholder={"Enter the user's full name"}
            value={formData.fullName}
            wasValidated={!!(formData.fullName && !validationErrors.fullName)}
          />

          <TextField
            error={validationErrors.email}
            label={"Email Address: "}
            name={"email"}
            onBlur={handleFormBlur}
            onChange={handleFormChange}
            placeholder={"Enter the user's email address"}
            value={formData.email}
            wasValidated={!!(formData.email && !validationErrors.email)}
          />

          <div className="form-with-label form-with-label--grid">
            <label className="m-0" style={{ fontWeight: 500 }}>
              Login Identity Provider:
            </label>

            <div className="input-group">
              <Select
                aria-label="search-select"
                className="single-select"
                classNamePrefix="react-select"
                inputId="single-select-example"
                onChange={handleSelectChange}
                options={identityProvidersList}
                spacing={"default"}
                validationState={validateSelectField()}
                value={{
                  label:
                    identityProvidersList?.find(
                      (x) => x.value === formData.identityProvider,
                    )?.label ?? formData.identityProvider,
                  value: formData.identityProvider,
                }}
              />

              {validationErrors.identityProvider && (
                <div
                  className="invalid-feedback d-block"
                  style={{ marginLeft: "1rem" }}
                >
                  {validationErrors.identityProvider}
                </div>
              )}
            </div>
          </div>

          {formData.identityProvider === "VERA" ? (
            <TextField
              error={validationErrors.password}
              label={"Password:"}
              name={"password"}
              onBlur={handleFormBlur}
              onChange={handleFormChange}
              placeholder={"New User's Password"}
              showPasswordFunction={true}
              type="password"
              value={formData.password}
              wasValidated={!!(formData.password && !validationErrors.password)}
            />
          ) : (
            <TextField
              error={validationErrors.idpUsername}
              label={"IdP Username:"}
              name={"idpUsername"}
              onBlur={handleFormBlur}
              onChange={handleFormChange}
              placeholder={"Enter the IdP Username"}
              value={formData.idpUsername}
              wasValidated={
                !!(formData.idpUsername && !validationErrors.idpUsername)
              }
            />
          )}

          <div className="col">
            <span className="float-right">
              <button
                className="btn btn-primary text-white ml-2"
                id="submit-add-user-btn"
                onClick={handleAddUser}
                type="button"
              >
                Add User
                {loading && (
                  <span
                    aria-hidden="true"
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                  />
                )}
              </button>

              <button
                className="btn btn-primary text-white ml-2"
                onClick={() => setFormData(initialFormState)}
                type="button"
              >
                Reset Fields
              </button>
            </span>
          </div>
        </form>
      )}
    </>
  );
};

export default AddUserPage;
