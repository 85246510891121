import { PrintViewRejectionReasonProps } from "../../../interfaces/DetailsPage";

const PrintViewRejectionReason = ({
  rejectionReason,
}: PrintViewRejectionReasonProps) => {
  const convertDate = function (isoDate: string) {
    const lastIndex =
      isoDate.lastIndexOf("+") !== -1
        ? isoDate.lastIndexOf("+")
        : isoDate.lastIndexOf("-");
    const milliseconds = isoDate.substring(isoDate.lastIndexOf("."), lastIndex);
    return isoDate.replace(/T/, " ").replace(milliseconds, " ");
  };

  return (
    <>
      <h4>Rejection Reason</h4>

      <table className="table table-sm table-bordered" id="tblRejections">
        <thead>
          <tr>
            <th>Rejection Reason</th>
            <th>Reviewer</th>
            <th>Meaning</th>
            <th>Date</th>
          </tr>
        </thead>

        <tbody>
          {rejectionReason.map((rejection) => (
            <tr>
              <td>{rejection.reason}</td>
              <td>
                {rejection.fullName} ({rejection.userName})
              </td>
              <td>{rejection.meaning}</td>
              <td>{convertDate(rejection.timeStamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PrintViewRejectionReason;
