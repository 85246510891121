import uuid from "react-uuid";
import {
  ApproveFooterAction,
  ApproveFooterActionTypes,
} from "../types/approveFooter";

interface ApproveFooterState {
  approveSessionID?: string;
  isOpenApprove: boolean;
  isOpenReject: boolean;
  isLoading: boolean;
  meaning: string;
  selectedNumber: number;
}

const initialState: ApproveFooterState = {
  isOpenApprove: false,
  isOpenReject: false,
  isLoading: false,
  meaning: "",
  selectedNumber: 0,
};

export const approveFooterReducer = (
  state = initialState,
  action: ApproveFooterAction,
): ApproveFooterState => {
  switch (action.type) {
    case ApproveFooterActionTypes.OPEN_APPROVE_MODAL:
      return {
        ...state,
        isOpenApprove: true,
        approveSessionID: uuid(),
      };
    case ApproveFooterActionTypes.OPEN_REJECT_MODAL:
      return {
        ...state,
        isOpenReject: true,
      };
    case ApproveFooterActionTypes.SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ApproveFooterActionTypes.HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ApproveFooterActionTypes.CLOSE_APPROVE_MODAL:
      return {
        ...state,
        isOpenApprove: false,
        approveSessionID: undefined,
      };
    case ApproveFooterActionTypes.CLOSE_REJECT_MODAL:
      return {
        ...state,
        isOpenReject: false,
      };
    case ApproveFooterActionTypes.CLOSE_ALL_MODALS:
      return {
        ...state,
        isOpenApprove: false,
        isOpenReject: false,
      };
    case ApproveFooterActionTypes.SET_NEW_SESSION_ID:
      return {
        ...state,
        approveSessionID: uuid(),
      };
    case ApproveFooterActionTypes.CLEAR_SESSION_ID:
      return {
        ...state,
        approveSessionID: undefined,
      };
    case ApproveFooterActionTypes.RESET_APPROVE_FOOTER_DATA:
      return initialState;
    default:
      return state;
  }
};
