import { useEffect, useState } from "react";
import { ISelectItem, IUserSelectProps } from "../interfaces/UserSelect";
import axios, { CancelTokenSource } from "axios";
import { ErrorMessage } from "../../constants/general.constants";
import { UserService } from "../services/userService";
import { toast } from "react-toastify";
import Select from "./common/Select";

const UserSelect = ({
  disabled = false,
  error,
  label,
  multiple = false,
  onBlur,
  onChange,
  onlyAdmin,
  name,
  placeholder = "Select...",
  size = "large",
  value,
  wasValidated,
}: IUserSelectProps) => {
  const [usersOptionsList, setUsersOptionsList] = useState<ISelectItem[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  let uniqueUsersListCancelToken: CancelTokenSource;

  const getUniqueUsersList = async () => {
    if (typeof uniqueUsersListCancelToken !== typeof undefined) {
      uniqueUsersListCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    uniqueUsersListCancelToken = axios.CancelToken.source();

    if (inputValue.length === 0 || inputValue.length >= 3) {
      try {
        const { data, status } = await UserService.getAll(
          inputValue,
          uniqueUsersListCancelToken.token,
          onlyAdmin,
        );
        if (status === 200) {
          const usersOptionsList = data.rows.map((user) => ({
            label: `${user.fullName} (${user.userName})`,
            value: user._id,
          }));
          setUsersOptionsList(usersOptionsList);
        }
      } catch (error: any) {
        if (
          error.message !== ErrorMessage.NEW_REQUEST &&
          error.message !== ErrorMessage.LOGOUT
        ) {
          toast.error(error.message);
        }
      }
    } else {
      setUsersOptionsList([]);
    }
  };

  const onInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getUniqueUsersList();
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  return (
    <Select
      disabled={disabled}
      error={error}
      label={label}
      multiple={multiple}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={onInputChange}
      options={usersOptionsList}
      placeholder={placeholder}
      size={size}
      value={value}
      wasValidated={wasValidated}
    />
  );
};

export default UserSelect;
