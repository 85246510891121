import { IPolicy } from "../../components/interfaces/Policy";
import { ITableData } from "../../components/interfaces/Table";

export enum PoliciesActionTypes {
  FETCHING_POLICY_TABLE_DATA = "FETCHING_POLICY_TABLE_DATA",
  UPDATE_POLICY_TABLE_DATA = "UPDATE_POLICY_TABLE_DATA",
  UPDATE_POLICY_SELECTED_PAGE = "UPDATE_POLICY_SELECTED_PAGE",
  UPDATE_POLICY_SORT_PARAMS = "UPDATE_POLICY_SORT_PARAMS",
  UPDATE_POLICY_PAGE_PARAMS = "UPDATE_POLICY_PAGE_PARAMS",
  RESET_POLICY_SORT_PARAMS = "RESET_POLICY_SORT_PARAMS",
  HANDLE_POLICIES_NETWORK_ERROR = "HANDLE_POLICIES_NETWORK_ERROR",
  RESET_POLICY_DATA = "RESET_POLICY_DATA",
}

interface FetchingPolicyData {
  type: PoliciesActionTypes.FETCHING_POLICY_TABLE_DATA;
}

interface UpdatePolicyTableData {
  type: PoliciesActionTypes.UPDATE_POLICY_TABLE_DATA;
  payload: ITableData<IPolicy>;
}

interface UpdatePolicySelectedPage {
  type: PoliciesActionTypes.UPDATE_POLICY_SELECTED_PAGE;
  payload: number;
}

interface HandlePoliciesNetworkError {
  type: PoliciesActionTypes.HANDLE_POLICIES_NETWORK_ERROR;
  payload: string;
}

interface UpdateSortParams {
  type: PoliciesActionTypes.UPDATE_POLICY_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdatePageParams {
  type: PoliciesActionTypes.UPDATE_POLICY_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface HandleResetPolicySortParams {
  type: PoliciesActionTypes.RESET_POLICY_SORT_PARAMS;
}

interface HandleResetPolicyData {
  type: PoliciesActionTypes.RESET_POLICY_DATA;
}

export type PoliciesAction =
  | UpdatePolicyTableData
  | FetchingPolicyData
  | UpdatePolicySelectedPage
  | HandlePoliciesNetworkError
  | HandleResetPolicyData
  | UpdateSortParams
  | UpdatePageParams
  | HandleResetPolicySortParams;
