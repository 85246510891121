import { useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IDropdownOption } from "../interfaces/DropDown";
import Dropdown from "./common/DropDown";
import { IUser } from "../interfaces/AdminPage";
import { ITableData } from "../interfaces/Table";
import { useAppDispatch } from "../../store/store";
import {
  clearSelectedUsers,
  selectUsers,
  updateSearchTerm,
} from "../../store/slices/role-management.slice";

interface IDropdownUsersProps {
  getUsers: (skip: number) => void;
  data: ITableData<IUser>;
  isError: boolean;
  isFetching: boolean;
  isFirstPage: boolean;
}

const DropdownUsers = ({
  getUsers,
  data,
  isError,
  isFetching,
  isFirstPage,
}: IDropdownUsersProps) => {
  const dispatch = useAppDispatch();
  const { searchTerm, selectedRole, selectedUsers } = useTypedSelector(
    (state) => state.rolesManagement,
  );

  const handleUsersSelectChange = (
    checked: boolean,
    option: IDropdownOption,
  ) => {
    dispatch(selectUsers(option.id));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getUsers(0);
    }, 500);

    return () => clearTimeout(timeout);
  }, [getUsers]);

  const showMore = () => {
    getUsers(data.rows.length);
  };

  return (
    <Dropdown
      clearSelection={() => dispatch(clearSelectedUsers())}
      disabled={!selectedRole}
      displayShowMore={true}
      id="users"
      isError={isError}
      isFirstPage={isFirstPage}
      isLoading={isFetching}
      items={data.rows.map((user) => ({
        label: `${user.fullName} (${user.userName})`,
        id: user._id,
        isSelected: selectedUsers.includes(user._id),
      }))}
      label={selectedUsers.length ? "Users" : "Select Users"}
      onChangeCheckbox={handleUsersSelectChange}
      onChangeSearchField={(searchTerm) =>
        dispatch(updateSearchTerm(searchTerm))
      }
      placement="bottom-end"
      searchField={searchTerm}
      selectedItems={selectedUsers}
      showMore={() => showMore()}
      totalItems={data.total}
    />
  );
};

export default DropdownUsers;
