import { useMemo, useState } from "react";
import { IBulkUsersRemoveFromRoleFooterProps } from "../../../interfaces/AdminPage";
import RemoveFromRoleModal from "./RemoveFromRoleModal";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

const BulkUsersRemoveFromRoleFooter = ({
  selectedRole,
  handleRemoveFromRole,
}: IBulkUsersRemoveFromRoleFooterProps) => {
  const [isOpenRemoveUserModal, setIsOpenRemoveUserModal] =
    useState<boolean>(false);
  const selectedUsers = useTypedSelector(
    (x) => x.rolesManagement.roleUsersTable.selectedUsers,
  );

  const onRemoveUser = async (userId: string[]) => {
    await handleRemoveFromRole(userId);
    setIsOpenRemoveUserModal(false);
  };

  return (
    <>
      {isOpenRemoveUserModal && (
        <RemoveFromRoleModal
          closeModal={() => setIsOpenRemoveUserModal(false)}
          deleteUser={onRemoveUser}
          isOpen={isOpenRemoveUserModal}
          userData={selectedUsers}
        />
      )}

      <div className="approve-footer mx-auto w-50 align-middle text-white fixed-bottom font-size-lg">
        <div className="my-auto" style={{ fontSize: "19px" }}>
          {`You have selected ${selectedUsers.length} user${
            selectedUsers.length > 1 ? "s" : ""
          }`}
        </div>

        <button
          aria-label="open-remove-user-modal"
          className="btn btn-primary btn-sm text-white"
          onClick={() => setIsOpenRemoveUserModal(true)}
          role="button"
        >
          <i className="fa fa-minus-circle" />{" "}
          {`Remove Users From Role (${selectedRole})`}
        </button>
      </div>
    </>
  );
};

export default BulkUsersRemoveFromRoleFooter;
