import ListCheckIcon from "./../../../images/bx-list-check.svg";
import ListIcon from "./../../../images/bx-list-ul.svg";
import CogIcon from "./../../../images/bxs-cog.svg";
import PinIcon from "./../../../images/pin-fill.svg";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "../../../constants/general.constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import SidebarItem from "./SidebarItem";
import SidebarSystemAdmin from "./SidebarSystemAdmin";
import SidebarDomainAdmin from "./SidebarDomainAdmin";
import SidebarRecordDetails from "./SidebarRecordDetails";
import Footer from "../Footer";

const Sidebar = () => {
  const { user, adminIsAuthenticated } = useTypedSelector(
    (state) => state.user,
  );
  const location = useLocation();

  const isCollapsed = useTypedSelector((state) => state.sideBar.isCollapsed);

  const { toggleSidebar } = useActions();

  return (
    <>
      {" "}
      {user && (
        <div
          className={`side-bar show no-print ${isCollapsed ? "collapse" : ""}`}
        >
          <div>
            <div className="pin-wrapper">
              <button
                className="pin__button no-focus"
                onClick={() => toggleSidebar()}
              >
                <img
                  className="white-icon"
                  src={PinIcon}
                  style={{ height: "17px" }}
                />
              </button>
            </div>

            <div className="features">
              <SidebarRecordDetails />

              <ul className="features-list">
                <SidebarItem
                  icon={<img className="white-icon" src={ListCheckIcon} />}
                  label="Approval Queue"
                  to={AppRoutes.ApprovalQueue}
                />

                <SidebarItem
                  icon={<img className="white-icon" src={ListIcon} />}
                  label="Records"
                  to={AppRoutes.Records}
                />
              </ul>

              {user && user.isDomainAdmin && !user.isSystemAdmin && (
                <SidebarDomainAdmin />
              )}

              {user &&
                user.isSystemAdmin &&
                adminIsAuthenticated &&
                location.pathname.startsWith("/admin") && (
                  <SidebarSystemAdmin />
                )}

              {user?.isSystemAdmin &&
                !location.pathname.startsWith("/admin") && <SidebarAdminLink />}
            </div>
          </div>
          {!isCollapsed && <Footer />}
        </div>
      )}
    </>
  );
};

const SidebarAdminLink = () => {
  return (
    <ul className="features-list">
      <SidebarItem
        icon={<img className="white-icon" src={CogIcon} />}
        label="VERA Administration"
        to={AppRoutes.AdminPage}
      />
    </ul>
  );
};

export default Sidebar;
