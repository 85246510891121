import { useEffect } from "react";
import { useActions } from "../../../../../hooks/useActions";
import Loader from "../../../../modules/Loader";
import TableHead from "../../../../modules/TableHead";
import PoliciesTableItem from "./PoliciesTableItem";
import LocatePolicyFilter from "./locatePolicyFilter/LocatePolicyFilter";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import TablePagination from "../../../../modules/TablePagination";

const PoliciesTable = () => {
  const {
    fetchPolicies,
    updatePolicySortParams,
    updatePolicyPageParams,
    updatePolicySelectedPage,
  } = useActions();

  const { isLoading, selectedPage, sortParams, tableData, tableHeaders } =
    useTypedSelector((x) => x.policies);

  const updateSortParams = (
    sort: string = sortParams.sort,
    order: "asc" | "desc" = sortParams.order,
  ) => {
    updatePolicySortParams(sort, order);
  };

  const updatePageParams = (
    offset: number = sortParams.offset,
    limit: number = sortParams.limit,
  ) => {
    updatePolicyPageParams(offset, limit);
  };

  useEffect(() => {
    fetchPolicies();
  }, [sortParams]);

  return (
    <>
      <LocatePolicyFilter />

      <table className="table table-bordered table-sm">
        <TableHead
          isLoading={isLoading}
          setPageParams={updatePageParams}
          setSortParams={updateSortParams}
          sortParams={sortParams}
          tableHeaders={tableHeaders}
        />

        <tbody
          className="position-relative"
          style={{
            height:
              isLoading || !tableData || !tableData.rows?.length ? "100px" : 0,
          }}
        >
          {!isLoading && (!tableData || !tableData.rows?.length) && (
            <div className="table-no-records-found">
              No matching policies found
            </div>
          )}

          {isLoading && (
            <Loader message={"Loading, please wait..."} tableMode={true} />
          )}

          {!isLoading &&
            tableData &&
            tableData.rows?.length > 0 &&
            tableData.rows.map((policy) => (
              <PoliciesTableItem policy={policy} />
            ))}
        </tbody>
      </table>
      {tableData && (
        <TablePagination
          selectedPage={selectedPage}
          tableData={tableData}
          updatePageParams={updatePolicyPageParams}
          updateSelectedPage={updatePolicySelectedPage}
        />
      )}
    </>
  );
};

export default PoliciesTable;
