import DomainsTable from "./DomainsTable";
import LinkDomainToPolicySet from "./LinkDomainToPolicySet";

const Domains = () => {
  return (
    <div>
      <div className="policy-sets-domain">
        <DomainsTable />
        <LinkDomainToPolicySet />
      </div>
    </div>
  );
};

export default Domains;
