import { IDefectsModalState } from "../../components/interfaces/DefectsModal";
import {
  DefectsModalAction,
  DefectsModalActionTypes,
} from "../types/defectsModal";

const initialState: IDefectsModalState = {
  defects: [],
  recordId: null,
  showModal: false,
};

export const defectsModalReducer = (
  state = initialState,
  action: DefectsModalAction,
): IDefectsModalState => {
  switch (action.type) {
    case DefectsModalActionTypes.SHOW_DEFECTS_MODAL:
      return {
        ...state,
        showModal: true,
        ...action.payload,
      };
    case DefectsModalActionTypes.HIDE_DEFECTS_MODAL:
      return initialState;
    default:
      return state;
  }
};
