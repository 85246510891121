import { PropsWithChildren } from "react";
import { ITableActions, ITableState } from "../interfaces/Table";
import TableHead from "./TableHead";
import Loader from "./Loader";
import TablePagination from "./TablePagination";

export const Table = <T, K extends keyof T>({
  children,
  columnFiltrationIsActive,
  columnSearchField,
  displayActionsColumn,
  fetchTableData,
  isLoading,
  selectedPage,
  setColumnsSearchField,
  setPageParams,
  setSelectedPage,
  setSortParams,
  sortParams,
  tableData,
  tableHeaders,
}: PropsWithChildren<ITableState<T>> & ITableActions) => {
  const colspan = tableHeaders.length + (displayActionsColumn ? 1 : 0);

  return (
    <>
      <table className="table table-bordered table-sm">
        <TableHead
          columnFiltrationIsActive={columnFiltrationIsActive}
          columnsSearchField={columnSearchField}
          displayActionsColumn={displayActionsColumn}
          isLoading={isLoading}
          setColumnsSearchField={setColumnsSearchField}
          setPageParams={setPageParams}
          setSortParams={setSortParams}
          sortParams={sortParams}
          tableHeaders={tableHeaders}
        />

        <tbody
          className="position-relative"
          style={{ height: isLoading || !tableData.rows?.length ? "40vh" : 0 }}
        >
          {!isLoading && !tableData?.rows?.length && (
            <tr className="table-no-records">
              <td colSpan={colspan}>No matching records found</td>
            </tr>
          )}

          {isLoading && (
            <tr key="loading">
              <th className="loading-column" colSpan={colspan}>
                {
                  <Loader
                    message={"Loading, please wait..."}
                    tableMode={true}
                  />
                }
              </th>
            </tr>
          )}
          {!isLoading && <>{children}</>}
        </tbody>
      </table>
      {tableData && (
        <TablePagination
          selectedPage={selectedPage}
          tableData={tableData}
          updatePageParams={setPageParams}
          updateSelectedPage={setSelectedPage}
        />
      )}
    </>
  );
};

export default Table;
