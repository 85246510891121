import { Link, matchPath, useHistory, useLocation } from "react-router-dom";
import { unescape } from "lodash";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Loader from "../../../modules/Loader";
import { DetailsQueryParams } from "../../../interfaces/DetailsPage";
import { useActions } from "../../../../hooks/useActions";
import { useEffect } from "react";
import { AppRoutes } from "../../../../constants/general.constants";

const RevisionHistory = () => {
  const { fetchRecordDetails, fetchRecordRevisions } = useActions();
  const { data, isLoading, isLoadingRecordRevisions, recordRevisions } =
    useTypedSelector((state) => state.recordDetails);
  const history = useHistory();
  const location = useLocation();

  const match = matchPath<DetailsQueryParams>(location.pathname, {
    path: [AppRoutes.DetailsPage, AppRoutes.RevisionPage],
  });

  const { routeId, recordId, revision, menuSelector } = match?.params ?? {};

  useEffect(() => {
    fetchRecordDetails(history, routeId, recordId, revision, menuSelector);
  }, []);

  useEffect(() => {
    !recordRevisions &&
      data?.record?._id &&
      fetchRecordRevisions(data?.record?._id);
  }, [data?.record?._id]);

  if (isLoading || !data || isLoadingRecordRevisions) {
    return <Loader message={"Loading, please wait..."} />;
  }

  const { latestRevision, latestRouteId, latestRouteStatus, record } = data;

  return (
    <>
      <h3>{unescape(data?.record?.name)}</h3>

      <div aria-label="revision-history-tab" id="recordRevisionsTable">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Revision</th>
              <th>Status</th>
              <th>Approval Date</th>
            </tr>
          </thead>
          <tbody>
            {!recordRevisions?.some(
              (item) => item.revision === latestRevision,
            ) && (
              <tr>
                <td>
                  {record?.revision === latestRevision ? (
                    `${latestRevision} (current)`
                  ) : (
                    <a href={`/details/${latestRouteId}`}>
                      {latestRevision} (current)
                    </a>
                  )}
                </td>

                <td>{latestRouteStatus}</td>

                <td />
              </tr>
            )}

            {recordRevisions?.map((recordRevision) => (
              <tr>
                <td>
                  {record?.revision === recordRevision.revision ? (
                    `${recordRevision.revision} (this revision)`
                  ) : (
                    <Link
                      target={"_self"}
                      to={`/records/${recordRevision.recordId}/${recordRevision.revision}`}
                    >
                      {recordRevision.revision}
                    </Link>
                  )}
                </td>

                <td>{recordRevision.record.status}</td>

                <td>
                  {recordRevision.approvalDate?.startsWith("0001-01-01")
                    ? ""
                    : recordRevision.approvalDate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RevisionHistory;
