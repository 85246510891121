import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IPolicyImportProps } from "../../../../../interfaces/AdminPage";
import GenericModal from "../../../../../modules/GenericModal";
import TextArea from "@atlaskit/textarea";
import { FileUploadService } from "../../../../../services/fileUploadService";
import { PolicyRequestRoutes } from "../../../../../../constants/policy.constants";

const ImportUpdatedPolicyModal = ({
  closeModal,
  onSuccess,
  policyType,
  policyId,
  visible,
}: IPolicyImportProps) => {
  const [loading, setLoading] = React.useState(false);
  const [importedFile, setImportedFile] = React.useState<File | null>(null);
  const [comment, setComment] = React.useState("");

  const uploadPolicyRevision = async () => {
    const formData = new FormData();
    formData.append("importFile", importedFile!);
    formData.append("comment", comment);
    formData.append("policyType", policyType);
    formData.append("policyId", policyId);

    return await axios.patch(
      `${PolicyRequestRoutes.UPLOAD_POLICY_REVISION}`,
      formData,
    );
  };

  const handleFileUpload = async () => {
    setLoading(true);
    FileUploadService.uploadPolicy(
      importedFile,
      policyType,
      uploadPolicyRevision,
    )
      .then((response) => {
        const message = response as string;
        message && toast.success(message);
        closeModal();
        onSuccess && onSuccess();
      })
      .catch((response) => {
        if (response) {
          switch (response.type) {
            case "error":
              toast.error(response.message);
              break;
            case "warning":
              toast.warning(response.message);
              break;
          }
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <GenericModal
      labelConfirm="Import"
      loading={loading}
      onClickConfirm={handleFileUpload}
      onCloseModal={closeModal}
      size="medium"
      title="Import Policy Revision"
      visible={visible}
    >
      <div aria-label="import-policy-modal" className="p-3 import-policy-modal">
        <input
          accept=".json"
          aria-label="import-file"
          name="importFile"
          onChange={(event) => setImportedFile(event.target.files![0])}
          type="file"
        />
        <div className="import-policy-comment">
          <label>Revision Comments</label>
          <TextArea
            className="import-policy-textarea"
            maxHeight="20vh"
            minimumRows={7}
            name="area"
            onBlur={() => setComment((x) => x.trim())}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter comment"
            resize="auto"
            style={{ resize: "none" }}
            value={comment}
          />
        </div>
      </div>
    </GenericModal>
  );
};

export default ImportUpdatedPolicyModal;
