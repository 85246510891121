import ConnectionsActionBar from "./ConnectionsActionBar";
import ConnectionsTable from "./ConnectionsTable";

const ConnectionsPage = () => {
  return (
    <>
      <div aria-label="connections" className="d-flex justify-content-between">
        <div className="table-heading-wrapper">
          <b>Connections </b>
        </div>
        <ConnectionsActionBar />
      </div>
      <div>
        <ConnectionsTable />
      </div>
    </>
  );
};

export default ConnectionsPage;
