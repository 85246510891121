import React, { FormEvent, useEffect, useState } from "react";
import { Checkbox } from "@atlaskit/checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  IColumnsSearchFieldsFormData,
  IRecordData,
  ITableHead,
} from "../interfaces/ApprovalQueue";
import { TableHeaderItem } from "../../store/types/approvalQueue";
import {
  IDomainUsersTableItem,
  IUserRolesTableItem,
} from "../interfaces/AdminPage";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";

const TableHead = ({
  setSortParams,
  setPageParams,
  tableHeaders,
  rowsData,
  sortParams,
  isLoading,
  handleSelectAllAction,
  displayActionsColumn = true,
  columnFiltrationIsActive,
  updateTableSearchingQuery,
  columnsSearchField,
  setColumnsSearchField,
  selectedRows,
}: ITableHead) => {
  const [selectAll, setSelectAll] = useState<boolean>(true);
  const [columnsSearchFieldsData, setColumnsSearchFieldsData] =
    useState<IColumnsSearchFieldsFormData>(columnsSearchField ?? {});
  let emptyFieldTimerId: TimeoutId;
  let handleSelectAll: () => void;

  const handleSort = (fieldId: string) => {
    let sortOrder: "asc" | "desc";
    if (sortParams.sort === fieldId) {
      sortOrder = sortParams.order === "asc" ? "desc" : "asc";
    } else {
      sortOrder = "asc";
    }
    setSortParams(fieldId, sortOrder);
  };

  const getUpdateColumn = (
    target: EventTarget & HTMLInputElement,
    searchFields: {},
  ) => {
    let columns = JSON.parse(JSON.stringify(searchFields));
    if (!target.value && columns[target.name]) {
      delete columns[target.name];
    } else {
      columns = { ...columns, [`${target.name}`]: target.value };
    }

    return columns;
  };

  const handleFormChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setColumnsSearchFieldsData((prev) => getUpdateColumn(target, prev));

    if (setColumnsSearchField && columnsSearchField) {
      setColumnsSearchField(getUpdateColumn(target, columnsSearchField));
    }
  };

  const toggleSortCaret = (fieldId: string) => {
    if (fieldId === sortParams.sort) {
      return (
        <FontAwesomeIcon
          color={`#336699`}
          icon={sortParams.order === "asc" ? faSortUp : faSortDown}
        />
      );
    } else {
      return <FontAwesomeIcon color={"lightgrey"} icon={faSort} />;
    }
  };

  if (handleSelectAllAction) {
    handleSelectAll = () => {
      const newRowsData =
        rowsData?.map((item: any) => ({ ...item, isSelected: selectAll })) ||
        [];
      setSelectAll(!selectAll);
      handleSelectAllAction(newRowsData);
    };
  }

  const getCheckedChildrenCount = (items: any) => {
    return (
      items.filter((item: any) => item.isSelected).length ||
      selectedRows?.length
    );
  };

  const getIsParentIndeterminate = (
    rowsData:
      | IRecordData[]
      | IUserRolesTableItem[]
      | IDomainUsersTableItem[]
      | undefined,
  ) => {
    const checkedChildrenCount = getCheckedChildrenCount(rowsData);
    return checkedChildrenCount > 0 && checkedChildrenCount < 3;
  };

  const getCurrentColumnSearchField = () => {
    if (setColumnsSearchField) {
      return columnsSearchField;
    }

    return columnsSearchFieldsData;
  };

  const updateSearchingQuery = () => {
    const currentColumnSearchField = getCurrentColumnSearchField();
    if (
      currentColumnSearchField &&
      Object.values(currentColumnSearchField).length
    ) {
      updateTableSearchingQuery &&
        updateTableSearchingQuery({
          filter: JSON.stringify(currentColumnSearchField),
        });
    }
  };

  const handleSearchByColumns = async (event: FormEvent) => {
    event.preventDefault();
    setPageParams(0, sortParams.limit);
    updateSearchingQuery();
  };

  useEffect(() => {
    updateSearchingQuery();
  }, []);

  const hasSearchField = () => {
    const currentColumnSearchField = getCurrentColumnSearchField();

    return (
      currentColumnSearchField &&
      !Object.values(currentColumnSearchField).length
    );
  };

  const isFirstRun = React.useRef(true);

  useEffect(() => {
    if (!isFirstRun.current) {
      if (hasSearchField()) {
        emptyFieldTimerId = setTimeout(() => {
          updateTableSearchingQuery && updateTableSearchingQuery("");
        }, 1500);
      } else {
        clearTimeout(emptyFieldTimerId);
      }
    }

    isFirstRun.current = false;
  }, [columnsSearchFieldsData]);

  return (
    <thead>
      <tr>
        {handleSelectAllAction && (
          <th key="checkbox" scope="col">
            <Checkbox
              aria-label="bulk-checkbox"
              isChecked={
                rowsData?.some((item) => item.isSelected) ||
                Boolean(selectedRows?.length) ||
                false
              }
              isDisabled={isLoading}
              isIndeterminate={
                rowsData ? getIsParentIndeterminate(rowsData) : false
              }
              onChange={handleSelectAll!}
            />
          </th>
        )}

        {tableHeaders.map((item: TableHeaderItem, index: number) =>
          item.filterable ? (
            <th
              className={`col-${item.colSize}`}
              key={item.label}
              scope="col"
              style={{ height: columnFiltrationIsActive ? "75px" : "38px" }}
            >
              {item.notSortable ? (
                <div className="table-head-field" id="table-sortable-field">
                  <div className={"text-nowrap mr-2"}>{item.label}</div>
                </div>
              ) : (
                <div
                  className="table-head-field cursor-pointer"
                  id="table-sortable-field"
                  onClick={() => handleSort(item.name)}
                >
                  <div className={"text-nowrap mr-2"}>{item.label}</div>

                  {toggleSortCaret(item.name)}
                </div>
              )}

              {!item.notSearchable && columnFiltrationIsActive && (
                <div aria-label="search input" className="search-input-wrapper">
                  <form onSubmit={handleSearchByColumns}>
                    <input
                      className="form-control search-input mt-1"
                      name={item.name}
                      onChange={handleFormChange}
                      placeholder="Type to search..."
                      type="search"
                      value={
                        (columnsSearchField
                          ? columnsSearchField[item.name]
                          : columnsSearchFieldsData[item.name]) ?? ""
                      }
                    />
                  </form>
                </div>
              )}
            </th>
          ) : (
            <th className={`col-${item.colSize}`} key={item.label} scope="col">
              <div className="table-head-field">
                <div className={"text-nowrap mr-2"}>{item.label}</div>
              </div>
            </th>
          ),
        )}

        {displayActionsColumn && (
          <th className="text-center" key="actions" scope="col">
            Actions
          </th>
        )}
      </tr>
    </thead>
  );
};

export default TableHead;
