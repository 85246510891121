import { useState } from "react";
import { Checkbox } from "@atlaskit/checkbox";
import Tooltip from "@atlaskit/tooltip";
import {
  IRoleManagementTableItemProps,
  IUserRolesTableItem,
} from "../../../interfaces/AdminPage";
import RemoveFromRoleModal from "./RemoveFromRoleModal";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { toggleRoleUserSelection } from "../../../../store/slices/role-management.slice";
import { useAppDispatch } from "../../../../store/store";

const RoleMembersTableItem = ({
  user,
  handleRemoveFromRole,
  userCanBeSelected,
}: IRoleManagementTableItemProps) => {
  const dispatch = useAppDispatch();
  const [isOpenRemoveUserModal, setIsOpenRemoveUserModal] =
    useState<boolean>(false);

  const selectedUsers = useTypedSelector(
    (x) => x.rolesManagement.roleUsersTable.selectedUsers,
  );

  const handleCheckBoxChange = (selectedUser: IUserRolesTableItem) => {
    dispatch(toggleRoleUserSelection(selectedUser));
  };

  const onRemoveUser = async (userId: string[]) => {
    await handleRemoveFromRole(userId);
    setIsOpenRemoveUserModal(false);
  };

  const isSelected = selectedUsers.find((x) => x._id == user._id);

  return (
    <tr className={isSelected ? "selected-item" : "table-item"}>
      <th scope="row" style={{ width: "35px" }}>
        {userCanBeSelected(user._id) && (
          <Checkbox
            aria-label="checkbox"
            isChecked={!!isSelected}
            onChange={() => handleCheckBoxChange(user)}
          />
        )}
      </th>

      <td>{user.fullName}</td>

      <td>{user.userName}</td>

      <td>
        <div className="table-actions">
          {userCanBeSelected(user._id) && isOpenRemoveUserModal && (
            <RemoveFromRoleModal
              closeModal={() => setIsOpenRemoveUserModal(false)}
              deleteUser={onRemoveUser}
              isOpen={isOpenRemoveUserModal}
              userData={[user]}
            />
          )}

          {userCanBeSelected(user._id) && (
            <Tooltip content="Remove" position="right">
              <button
                aria-label="remove-from-role-btn"
                className="btn-icon"
                onClick={() => setIsOpenRemoveUserModal(true)}
              >
                <i className="fa fa-minus-circle" />
              </button>
            </Tooltip>
          )}
        </div>
      </td>
    </tr>
  );
};

export default RoleMembersTableItem;
