import * as yup from "yup";

export function createSchema() {
  return yup.object().shape({
    name: yup.lazy(() => {
      return yup.string().nullable().required("Please enter policy name.");
    }),
    description: yup.lazy(() => {
      return yup.string().nullable().required("Please enter a description.");
    }),
    policyOwnerUserId: yup.lazy(() => {
      return yup.object().nullable().required("Please select an owner.");
    }),
  });
}
