import { useEffect } from "react";
import axios from "axios";
import {
  Route,
  useLocation,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTypedSelector } from "./hooks/useTypedSelector";
import { useActions } from "./hooks/useActions";
import { ErrorMessage, AppRoutes } from "./constants/general.constants";
import ApprovalQueuePage from "./components/pages/approval-queue/ApprovalQueuePage";
import ProfilePage from "./components/pages/profile/ProfilePage";
import ChangePasswordPage from "./components/pages/change-password/ChangePasswordPage";
import VersionsPage from "./components/pages/versions/VersionsPage";
import DetailsPage from "./components/pages/details/DetailsPage";
import LoginPage from "./components/pages/login/LoginPage/LoginPage";
import Loader from "./components/modules/Loader";
import BrowserNotSupportedPage from "./components/pages/BrowserNotSupportedPage";
import RecordSearchResultsPage from "./components/pages/record/RecordSearchResultsPage";
import AdminPage from "./components/pages/admin/AdminPage";
import DomainAdminPage from "./components/pages/admin/DomainAdminPage";
import antiCSRFInterceptor from "./interceptors/anti-csrf.interceptor";
import ExpandedContentModal from "./components/modules/ExpandedContentModal";
import ErrorPage from "./components/pages/errorPage";
import IdleModal from "./components/modules/IdleModal/IdleModal";
import UserAccountsPage from "./components/pages/login/UserAccountsPage";
import AccountReuseNotAllowedPage from "./components/pages/login/AccountReuseNotAllowedPage";

const App = () => {
  const {
    initialization,
    loading,
    hasActiveSession,
    user,
    browserNotSupported,
  } = useTypedSelector((state) => state.user);
  const isCollapsed = useTypedSelector((state) => state.sideBar.isCollapsed);
  const { checkActiveSession, logout } = useActions();
  const { pathname } = useLocation();
  const history = useHistory();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.data?.initiateLogout) {
        error?.response?.data?.message &&
          toast.error(error?.response?.data?.message);
        logout(history);
        throw new Error(ErrorMessage.LOGOUT);
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    checkActiveSession();
    antiCSRFInterceptor(axios);
  }, []);

  useEffect(() => {
    pathname !== AppRoutes.LoginPage && loading && checkActiveSession();
  }, [loading]);

  if (initialization) {
    return <Loader />;
  }

  return (
    <>
      {browserNotSupported ? (
        <Switch>
          <Route
            component={BrowserNotSupportedPage}
            exact
            path={AppRoutes.BrowserNotSupported}
          />
          <Redirect to={AppRoutes.BrowserNotSupported} />
        </Switch>
      ) : hasActiveSession ? (
        <div
          className={`vera-content ${
            isCollapsed ? "vera-content--collapsed" : ""
          }`}
        >
          <IdleModal />
          <Switch>
            {
              <Route
                exact
                path={AppRoutes.AdminPage}
                render={() => {
                  if (user?.isSystemAdmin) {
                    return <AdminPage />;
                  }

                  if (user?.isDomainAdmin) {
                    return <DomainAdminPage />;
                  }

                  toast.error("You don't have access to this page");

                  return <DefaultRoutes />;
                }}
              />
            }
            <Route component={DefaultRoutes} />
          </Switch>
        </div>
      ) : (
        <Switch>
          <Route
            component={UserAccountsPage}
            exact
            path={AppRoutes.UserAccountsPage}
          />
          <Route
            component={AccountReuseNotAllowedPage}
            exact
            path={AppRoutes.AccountReuseNotAllowedPage}
          />
          <Route component={ErrorPage} exact path={AppRoutes.ErrorPage} />
          <Route component={LoginPage} exact path={AppRoutes.LoginPage} />
          <Redirect to={AppRoutes.LoginPage} />
        </Switch>
      )}

      <ToastContainer
        autoClose={5000}
        className="toast-container"
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
      />
      <ExpandedContentModal />
    </>
  );
};

const DefaultRoutes = () => {
  const { veraIdp } = useTypedSelector((state) => state.user);

  return (
    <>
      <Switch>
        <Route component={ProfilePage} exact path={AppRoutes.ProfilePage} />
        <Route component={DetailsPage} exact path={AppRoutes.DetailsPage} />
        <Route component={DetailsPage} exact path={AppRoutes.RevisionPage} />
        <Route component={VersionsPage} exact path={AppRoutes.VersionsPage} />
        <Route
          component={ApprovalQueuePage}
          exact
          path={AppRoutes.ApprovalQueue}
        />
        <Route
          component={RecordSearchResultsPage}
          exact
          path={AppRoutes.Records}
        />
        {veraIdp && (
          <Route
            component={ChangePasswordPage}
            exact
            path={AppRoutes.ChangePasswordPage}
          />
        )}
        <Redirect to={AppRoutes.ApprovalQueue} />
      </Switch>
    </>
  );
};
export default App;
