import React from "react";
import { IRoute, TaskGroup } from "../../../interfaces/DetailsPage";
import { VerificationStatus } from "../../../../constants/approval-page.constants";

const TaskGroups = ({ route }: { route: IRoute }) => {
  return (
    <>
      {route.taskGroups.map((task) => (
        <React.Fragment key={`${task.name}-${task.status}`}>
          <h6>{task.name}</h6>

          <table className="table">
            <thead>
              <tr>
                <th className="text-nowrap" scope="col">
                  Approval Task
                </th>
                <th scope="col">Status</th>
                <th scope="col">Reviewer</th>
                <th scope="col">Date</th>
              </tr>
            </thead>

            <tbody>
              {task.tasks.map((subTask) => (
                <tr className="table-item" key={subTask._id}>
                  <td>
                    {subTask.status === VerificationStatus.Pending ? (
                      <a href={`/details/${route._id}/${subTask._id}`}>
                        {subTask.meaning}
                      </a>
                    ) : (
                      subTask.meaning
                    )}
                  </td>

                  <td>{subTask.status}</td>

                  {subTask.status === VerificationStatus.Complete ||
                  subTask.status === VerificationStatus.Rejected ? (
                    <td>{subTask.reviewerName}</td>
                  ) : (
                    <td>{subTask.group}</td>
                  )}

                  {subTask.status === VerificationStatus.Complete ||
                  subTask.status === VerificationStatus.Rejected ? (
                    <td>{subTask.timestamp}</td>
                  ) : (
                    <td />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      ))}
      {route.routeRejectionReason && (
        <h6>
          <span className="text-red">
            Rejection Reason: {route.routeRejectionReason}
          </span>
        </h6>
      )}
    </>
  );
};

export default TaskGroups;
