import { IConnectionState } from "../../components/interfaces/Connections";
import {
  ConnectionDetailsAction,
  ConnectionDetailsActionTypes,
} from "../types/connection";

const initialState: IConnectionState = {
  data: undefined,
  isLoading: true,
  supportedSystems: [],
  linkableConnections: [],
};

export const connectionReducer = (
  state = initialState,
  action: ConnectionDetailsAction,
): IConnectionState => {
  switch (action.type) {
    case ConnectionDetailsActionTypes.FETCHING_CONNECTION_DETAILS:
      return { ...state, isLoading: true };
    case ConnectionDetailsActionTypes.UPDATE_CONNECTION_DETAILS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case ConnectionDetailsActionTypes.UPDATE_SUPPORTED_SYSTEMS:
      return {
        ...state,
        supportedSystems: action.payload,
      };
    case ConnectionDetailsActionTypes.UPDATE_LINKABLE_CONNECTIONS:
      return {
        ...state,
        linkableConnections: action.payload,
      };
    default:
      return state;
  }
};
