import axios, { CancelTokenSource } from "axios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import { IDomainsTableData } from "../../components/interfaces/AdminPage";
import { ISortParams } from "../../components/interfaces/ApprovalQueue";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../constants/general.constants";
import { DomainAction, DomainActionTypes } from "../types/domain";

let domainsTableCancelToken: CancelTokenSource;

export const getDomainsData = (sortParams: ISortParams, searchQuery: {}) => {
  return async (dispatch: Dispatch<DomainAction>) => {
    if (typeof domainsTableCancelToken != typeof undefined) {
      domainsTableCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    domainsTableCancelToken = axios.CancelToken.source();

    try {
      dispatch({ type: DomainActionTypes.FETCHING_DOMAIN_DATA });

      const { data, status } = await axios.get<IDomainsTableData>(
        AdminRequestRoutes.DOMAINS_LIST,
        {
          cancelToken: domainsTableCancelToken.token,
          params: {
            ...sortParams,
            ...searchQuery,
          },
        },
      );
      if (status === 200) {
        dispatch({ type: DomainActionTypes.UPDATE_DOMAIN_DATA, payload: data });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({ type: DomainActionTypes.HANDLE_DOMAIN_ERROR });
      }
    }
  };
};

export const updateDomainSortParams = (sort: string, order: "asc" | "desc") => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: DomainActionTypes.UPDATE_DOMAIN_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updateDomainPageParams = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: DomainActionTypes.UPDATE_DOMAIN_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const updateDomainSelectedPage = (selectedPage: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: DomainActionTypes.UPDATE_DOMAIN_SELECTED_PAGE,
      payload: selectedPage,
    });
  };
};

export const resetDomainData = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: DomainActionTypes.RESET_DOMAIN_DATA });
  };
};
