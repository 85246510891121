import { useEffect } from "react";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import {
  resetData,
  setColumnsSearchField,
  setPageParams,
  setSelectedPage,
  setSortParams,
  useGetAuditEntriesQuery,
} from "../../../../../store/slices/audit-history.slice";
import { useAppDispatch } from "../../../../../store/store";
import Table from "../../../../modules/Table";
import AuditHistoryTableItem from "./AuditHistoryTableItem";
import { toast } from "react-toastify";
import AuditEntryDateFilter from "./AuditEntryDateFilter";
import dayjs from "dayjs";
import ExportAuditHistoryButton from "./ExportAuditHistoryButton";
import { Box } from "@mui/material";

const AuditHistory = () => {
  const dispatch = useAppDispatch();
  const {
    startDate,
    endDate,
    errorStartDate,
    errorEndDate,
    columnSearchField,
    selectedPage,
    sortParams,
    tableHeaders,
  } = useTypedSelector((x) => x.auditHistory);

  const shouldSkipFetch =
    (startDate !== null && !dayjs(startDate).isValid()) ||
    (endDate !== null && !dayjs(endDate).isValid()) ||
    !!errorStartDate ||
    !!errorEndDate ||
    dayjs(startDate).isAfter(endDate);

  const {
    data: auditEntries = { rows: [], total: 0 },
    isFetching,
    error,
  } = useGetAuditEntriesQuery(
    {
      limit: sortParams.limit,
      order: sortParams.order === "asc" ? "1" : "-1",
      skip: sortParams.offset,
      sort: sortParams.sort,
      filter: JSON.stringify(columnSearchField),
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: shouldSkipFetch,
    },
  );

  useEffect(() => {
    if (error) {
      toast.error((error as any)?.data?.message);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  return (
    <div>
      <div className="table-heading-wrapper mb-3">
        <b>Audit History</b>
      </div>

      <Box style={{ display: "flex", gap: "10px" }}>
        <AuditEntryDateFilter />
        <ExportAuditHistoryButton />
      </Box>
      <Table
        columnFiltrationIsActive={true}
        columnSearchField={columnSearchField}
        displayActionsColumn={false}
        isLoading={isFetching}
        selectedPage={selectedPage}
        setColumnsSearchField={(columns) =>
          dispatch(setColumnsSearchField(columns))
        }
        setPageParams={(offset, limit) =>
          dispatch(setPageParams({ offset, limit }))
        }
        setSelectedPage={(page: number) => dispatch(setSelectedPage(page))}
        setSortParams={(sort, order) =>
          dispatch(setSortParams({ sort, order }))
        }
        sortParams={sortParams}
        tableData={auditEntries}
        tableHeaders={tableHeaders}
      >
        {auditEntries?.rows?.map((auditEntry) => (
          <AuditHistoryTableItem auditEntry={auditEntry} />
        ))}
      </Table>
    </div>
  );
};

export default AuditHistory;
