import {
  IConnectionFormData,
  IConnectionRequest,
  IConnectionResponse,
} from "../components/interfaces/Connections";

export const transformConnectionResponse = (
  connection?: IConnectionResponse,
): IConnectionFormData => {
  return connection
    ? {
        _id: connection._id,
        Aliases: connection.Aliases?.map((value, index) => ({
          value,
          id: index + 1,
        })),
        AuthMode: connection.AuthMode
          ? authModeOptions.find((x) => x.value === connection.AuthMode)
          : undefined,
        LinkedConnections: connection.LinkedConnections?._id
          ? {
              label: connection.LinkedConnections?.Name,
              value: connection.LinkedConnections?._id,
            }
          : undefined,
        Name: connection.Name,
        qTestName: connection.qTestName,
        Server: connection.Server,
        ServiceAccount: connection.ServiceAccount,
        ServicePassword: "*******",
        System: connection.System
          ? { label: connection.System, value: connection.System }
          : undefined,
        Urls: connection.Urls,
      }
    : {};
};

export const transformConnectionForm = (
  connection: IConnectionFormData,
): IConnectionRequest => {
  return {
    _id: connection._id,
    Aliases: connection?.Aliases?.map((x: any) => x.value),
    AuthMode: connection.AuthMode?.value,
    LinkedConnections: connection.LinkedConnections?.value,
    Name: connection.Name,
    qTestName: connection.qTestName,
    Server: connection.Server,
    ServiceAccount: connection.ServiceAccount,
    ServicePassword: connection.ServicePassword,
    System: connection.System?.value,
    Urls: connection.Urls,
  };
};

export const authModeOptions = [
  { label: "None", value: "none" },
  { label: "Client Credentials", value: "clientCredentials" },
];
