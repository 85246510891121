import React from "react";
import { useActions } from "../../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { IDropdownOption } from "../../../../../interfaces/DropDown";
import { IFilterItem } from "../../../../../interfaces/LocateRecordsFilter";
import Dropdown from "../../../../../modules/common/DropDown";

const DropdownPolicyType = () => {
  const { policyType } = useTypedSelector((state) => state.locatePolicyFilter);

  const items: IDropdownOption[] = React.useMemo(
    () => policyType.items.map((x: IFilterItem) => ({ ...x, label: x.name })),
    [policyType],
  );

  const { clearSelectedPolicyType, updateSelectedPolicyType } = useActions();

  const handlePolicyTypeSelectChange = (
    checked: boolean,
    option: IDropdownOption,
  ) => {
    updateSelectedPolicyType(option.id, checked);
  };

  return (
    <Dropdown
      clearSelection={clearSelectedPolicyType}
      displaySearchField={false}
      id="policy-type-dropdown"
      isLoading={false}
      items={items}
      label="Type"
      onChangeCheckbox={handlePolicyTypeSelectChange}
      totalItems={policyType.total}
    />
  );
};

export default DropdownPolicyType;
