import Textfield from "@atlaskit/textfield";
import DropdownProject from "./DropdownProject";
import DropdownRecordType from "./DropdownRecordType";
import DropdownRecordStatus from "./DropdownRecordStatus";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Tooltip from "@atlaskit/tooltip";
import { isFilterApplied } from "../../../../store/actions/record";

const LocateRecordFilter = () => {
  const {
    clearFilters,
    handleResetRecordData,
    updateSearchFieldRecordName,
    updateRecordFilter,
  } = useActions();

  const recordNameSearchField = useTypedSelector(
    (state) => state.locateRecordsFilter.recordNameSearchField,
  );
  const recordData = useTypedSelector((state) => state.records.tableData);
  const filterApplied = useTypedSelector(isFilterApplied);

  const handleOnClickCopyFilter = () => {
    copy(`${window.location.href}`);

    toast.info("Copied to clipboard!");
  };

  const handleOnClickSearch = () => {
    updateRecordFilter();
  };

  const handleOnClickReset = () => {
    clearFilters();
    handleResetRecordData();
  };

  return (
    <div className="d-flex align-items-center locate-record-filter">
      <DropdownProject />
      <DropdownRecordType />
      <DropdownRecordStatus />
      <Textfield
        className="locate-records-search-input search-record-name"
        id="record-name-txt-search"
        isCompact={true}
        onChange={(e) => updateSearchFieldRecordName(e.currentTarget.value)}
        placeholder="Search by Record Name"
        value={recordNameSearchField}
      />
      <div className="d-flex align-items-center locate-record-icons">
        <Tooltip content="Copy Filter" position="top">
          <button
            className="btn btn-primary btn-sm text-white btn-copy-filter"
            onClick={() => handleOnClickCopyFilter()}
          >
            <FontAwesomeIcon icon={faCopy} inverse />
          </button>
        </Tooltip>
        {!filterApplied && recordData && (
          <Tooltip
            content="Filter has changed. Click Search to update displayed records."
            position="top"
          >
            <i
              className="fa fa-lg fa-exclamation-triangle mr-1"
              style={{ color: "#ed6c02" }}
            />
          </Tooltip>
        )}
      </div>
      <div className="d-flex locate-record-filter-buttons">
        <button
          className="btn btn-primary btn-sm text-white"
          id="search-records-btn"
          onClick={(e) => handleOnClickSearch()}
        >
          Search
        </button>
        <button
          className="btn btn-primary btn-sm text-white"
          id="reset-records-btn"
          onClick={(e) => handleOnClickReset()}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default LocateRecordFilter;
