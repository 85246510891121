import Toggle from "@atlaskit/toggle";
import {
  useDisableEmailNotificationsMutation,
  useEnableEmailNotificationsMutation,
} from "../../../store/slices/user.slice";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../constants/admin-page.constants";

const ToggleEmailNotifications = () => {
  const { user, profileData } = useTypedSelector((x) => x.user);

  const [isChecked, setIsChecked] = useState(
    profileData?.userDetails?.emailNotificationsEnabled ?? true,
  );
  const [enableEmailNotifications, { isLoading: isEnabling }] =
    useEnableEmailNotificationsMutation();
  const [disableEmailNotifications, { isLoading: isDisabling }] =
    useDisableEmailNotificationsMutation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!user?.veraUserId || isEnabling || isDisabling) {
      e.preventDefault();
      return;
    }

    setIsChecked((x) => !x);

    const response = e.target.checked
      ? enableEmailNotifications(user?.veraUserId)
          .unwrap()
          .then(() =>
            toast.success(AdminPageSuccessMessages.ENABLE_EMAIL_NOTIFICATIONS),
          )
      : disableEmailNotifications(user?.veraUserId)
          .unwrap()
          .then(() =>
            toast.success(AdminPageSuccessMessages.DISABLE_EMAIL_NOTIFICATIONS),
          );

    response.catch(() => {
      setIsChecked((x) => !x);
      toast.error(AdminPageErrors.UPDATE_EMAIL_SETTINGS);
    });
  };

  return (
    <div
      className="toggle-notifications-container"
      id="toggle-notifications-container"
    >
      <label>Notifications</label>
      <Toggle
        id="toggle-email-notifications"
        isChecked={isChecked}
        label="Activate"
        name="active"
        onChange={onChange}
        size="large"
      />
    </div>
  );
};

export default ToggleEmailNotifications;
