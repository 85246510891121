import { useEffect, useRef } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IDropdownOption } from "../../../interfaces/DropDown";
import Dropdown from "../../../modules/common/DropDown";

const DropdownProject = () => {
  const isFirstRun = useRef(true);
  const {
    projects,
    projectSearchField,
    isLoadingProjects,
    selectedProjects,
    skipProjects,
  } = useTypedSelector((state) => state.locateRecordsFilter);

  const {
    updateSelectedProject,
    fetchProjects,
    clearSelectedProjects,
    updateSearchFieldProject,
  } = useActions();

  const handleProjectSelectChange = (
    checked: boolean,
    option: IDropdownOption,
  ) => {
    updateSelectedProject(option.id);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      const timeout = setTimeout(() => {
        fetchProjects(projectSearchField);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [projectSearchField]);

  const showMore = () => {
    fetchProjects(projectSearchField, projects.items.length);
  };

  return (
    <Dropdown
      clearSelection={() => clearSelectedProjects()}
      displayShowMore={true}
      id="projects"
      isFirstPage={!skipProjects}
      isLoading={isLoadingProjects}
      items={projects.items.map((x) => ({
        label: x.name,
        id: x.name,
        isSelected: x.isSelected,
      }))}
      label="Projects"
      onChangeCheckbox={handleProjectSelectChange}
      onChangeSearchField={updateSearchFieldProject}
      searchField={projectSearchField}
      selectedItems={selectedProjects}
      showMore={() => showMore()}
      totalItems={projects.total}
    />
  );
};

export default DropdownProject;
