export enum AppRoutes {
  LoginPage = "/login",
  ApprovalQueue = "/approval-queue",
  Records = "/records",
  ProfilePage = "/profile",
  ChangePasswordPage = "/change-password",
  VersionsPage = "/versions",
  DetailsPage = "/details/:routeId/:recordId/:menuSelector?",
  RevisionPage = "/records/:recordId/:revision/:menuSelector?",
  AdminPage = "/admin/:subRoute?/:subjectId?",
  BrowserNotSupported = "/not-supported",
  ErrorPage = "/error",
  UserAccountsPage = "/user-accounts",
  AccountReuseNotAllowedPage = "/account-reuse-not-allowed",
}

export enum LoginPageRequestRoutes {
  LOGIN = "/api/login",
  LOGIN_VERA = "/api/login-vera",
  LOGOUT = "/api/standard/logout",
  SAML_LOGIN_BY_SELECTED_USERNAME = "/saml/login-by-selected-username",
}

export enum ApprovalPageRequestRoutes {
  TASKS_LIST = "/api/ajax/tasks-list",
  TASK_APPROVE = "/api/ajax/task-approve",
  TASK_REJECT = "/api/ajax/task-reject",
  SAML_LOGIN = "/saml/login",
  SAML_TASK_APPROVE = "/saml/task-approve",
  SAML_TASK_APPROVE_DATA = "/saml/task-approve-data",
}

export enum LocateRecordsFilterRequestRoutes {
  PROJECTS = "/api/ajax/projects",
  RECORD_TYPES = "/api/ajax/record-types",
  RECORD_STATUS = "/api/ajax/record-status",
}

export enum DomainsAndRolesRequestRoutes {
  DOMAINS = "/api/ajax/domains",
  ROLES = "/api/ajax/approval-roles",
  USER_ROLES = "/api/ajax/user-roles",
}

export enum DetailsPageRequestRoutes {
  DETAILS = "/api/standard/details",
  RECORDS = "/api/standard/records",
  HISTORY = "/api/standard/record-history",
  LINKED_RECORDS = "/api/standard/linked-records",
  APPROVAL_ROUTES = "/api/standard/approval-routes",
  RECORD_REVISIONS = "/api/standard/record-revisions",
}

export enum ConnectionRequestRoutes {
  ADD_CONNECTION = "/api/admin/add-connection",
  CONNECTIONS = "/api/admin/connections",
  CONNECTION_DETAILS = "/api/admin/connection",
  CONNECTION_SUPPORTED_SYSTEMS = "/api/admin/supported-systems",
  DELETE_CONNECTION = "/api/admin/delete-connection",
  LINKABLE_CONNECTIONS = "/api/admin/linkable-connections",
  UPDATE_CONNECTION = "/api/admin/update-connection",
  TEST_CONNECTION = "/api/admin/test-connection",
}

export enum ProfileRequestRoutes {
  PROFILE = "/api/standard/profile",
  USERS_LIST = "/api/ajax/users-list",
  USER_ROLES_LIST = "/api/ajax/user-roles-list",
  USER_SYSTEM_ROLES_LIST = "/api/ajax/system-roles-list",
  USER_DELETE = "/api/ajax/user-delete",
  CHANGE_PASSWORD = "/api/change-password",
  ADD_EDIT_USER = "/api/ajax/add-edit-user",
  ENABLE_EMAIL_NOTIFICATIONS = "/api/ajax/user/enable-email-notifications",
  DISABLE_EMAIL_NOTIFICATIONS = "/api/ajax/user/disable-email-notifications",
  UPDATE_EMAIL_NOTIFICATIONS_BY_DOMAIN = "/api/ajax/domains/email-notifications",
}

export enum AdminPageRoutes {
  LOGIN_PAGE = "/admin/admin-login",
  USER_MANAGEMENT = "/admin/user-management",
  DOMAINS_AND_ROLES = "/admin/domains-and-roles",
  EDIT_USER = "/admin/edit-user",
  ADD_USER = "/admin/add-user",
  ROLE_MANAGEMENT = "/admin/role-management",
  SIGNATURE_VERIFICATION = "/admin/signature-verification",
  DOMAIN_MANAGEMENT = "/admin/domain-management",
  MAIL_SETTINGS = "/admin/mail-settings",
  PASSWORD_POLICY = "/admin/password-policy",
  DOMAIN_DETAILS = "/admin/domain-details",
  DOMAIN_USERS = "/admin/domain-users",
  POLICIES = "/admin/policies",
  POLICY_SETS = "/admin/policy-sets",
  CONNECTIONS = "/admin/connections",
  ADD_CONNECTION = "/admin/add-connection",
  EDIT_CONNECTION = "/admin/edit-connection",
  POLICY_DETAILS = "/admin/policy-details",
  POLICY_SET_DETAILS = "/admin/policy-set-details",
  CONCURRENT_USAGE = "/admin/concurrent-usage",
  AUDIT_HISTORY = "/admin/audit-history",
  USER_SESSIONS = "/admin/user-sessions",
}

export enum AdminRequestRoutes {
  ADMIN = "/api/admin",
  ADMIN_LOGIN = "/api/login/admin-login",
  USER_DETAILS = "/api/admin/userDetails",
  ADD_USER = "/api/admin/add-user",
  ADD_EDIT_USER = "/api/ajax/add-edit-user",
  ROLE = "/api/ajax/role",
  RESET_PASSWORD = "/api/admin/reset-password",
  IMPORT_USERS = "/api/admin/import-users",
  ROLES = "/api/admin/roles",
  REMOVE_ROLE_USERS = "/api/ajax/remove-role-users",
  REVERIFY_SIGNATURE = "/api/ajax/reverify-signature",
  DOMAINS_LIST = "/api/admin/domains-list",
  UPDATE_DOMAIN = "/api/ajax/domain-update",
  CREATE_DOMAIN = "/api/admin/create-domain",
  DELETE_DOMAIN = "/api/ajax/domain-delete",
  DOMAINS = "/api/admin/domains",
  USERS = "/api/admin/users",
  USERS_BY_FULLNAME = "/api/ajax/users-by-fullname",
  CONNECTIONS = "/api/ajax/connections",
  DOMAIN_PROJECTS_LIST = "/api/ajax/domain-projects-list",
  DOMAIN_USERS_LIST = "/api/ajax/domains-user-list",
  DOMAIN_USERS = "/api/ajax/domain-users",
  ADD_PROJECTS_TO_DOMAIN = "/api/ajax/add-projects-to-domain",
  REMOVE_PROJECT_FROM_DOMAIN = "/api/ajax/remove-project-from-domain",
  ASSOCIATED_TASKS_CHECK = "/api/ajax/associated-tasks-check",
  SMTP_CONFIGS = "/api/ajax/smtp-configs",
  SMTP_CONFIGS_TEST = "/api/ajax/smtp-configs-test",
  SEND_TEST_EMAIL = "/api/ajax/send-test-email",
  PASSWORD_POLICY = "/api/ajax/password-policy",
  DEFAULT_POLICIES = "/api/admin/default-policies",
  ADD_USER_DOMAIN = "/api/ajax/add-user-to-domain",
  REMOVE_USER_DOMAIN = "/api/ajax/remove-user-from-domain",
  CONCURRENT_USERS = "/api/admin/user/concurrent-users",
  AUDIT_HISTORY = "/api/admin/audit-history/audit-entries",
  USER_SESSIONS = "/api/admin/user/user-sessions",
  SINGLE_SIGN_ON = "/SAML/InitiateSingleSignOn",
}

export const VersionsPageRequestRoute = "/api/standard/versions";

export enum PublicRequestRoute {
  VERSIONS = "/api/public/versions",
  PROVIDERS = "/api/public/providers",
}

export const generatePasswordRegExp = (
  minLower: number,
  minUpper: number,
  minSpecial: number,
  minNumeric: number,
  minLength: number,
) =>
  new RegExp(
    `^(?=(.*[A-Z]){${minUpper},})(?=(.*[a-z]){${minLower},})(?=(.*[0-9]){${minNumeric},})(?=(.*[^a-zA-Z0-9]){${minSpecial},}).{${minLength},128}$`,
  );

export const DEFAULT_PASSWORD_POLICY_SETTINGS = {
  minLower: 1,
  minUpper: 1,
  minNumeric: 1,
  minSpecial: 1,
  minLength: 8,
};

export enum ErrorMessage {
  NEW_REQUEST = "new_request",
  LOGOUT = "logout",
}
