import {
  IPolicySetDomainsState,
  ISelectDomain,
} from "../../components/interfaces/PolicySet";
import {
  PolicySetDomainsAction,
  PolicySetDomainsActionTypes,
} from "../types/policySetDomains";

const initialState: IPolicySetDomainsState = {
  domains: [],
  isLoading: false,
  selectedDomains: [],
  totalDomains: 0,
};

export const policySetDomainsReducer = (
  state = initialState,
  action: PolicySetDomainsAction,
): IPolicySetDomainsState => {
  switch (action.type) {
    case PolicySetDomainsActionTypes.FETCH_POLICY_SET_DOMAINS:
      return {
        ...state,
        isLoading: true,
      };
    case PolicySetDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS:
      return {
        ...state,
        domains: action.payload.domains,
        isLoading: false,
        totalDomains: action.payload.totalDomains,
      };
    case PolicySetDomainsActionTypes.LOAD_MORE_POLICY_SET_DOMAINS:
      return {
        ...state,
        domains: state.domains.concat(action.payload.domains),
        isLoading: false,
      };
    case PolicySetDomainsActionTypes.UPDATE_POLICY_SET_SELECTED_DOMAINS:
      return {
        ...state,
        selectedDomains: action.payload,
      };
    case PolicySetDomainsActionTypes.UPDATE_POLICY_SET_LINKED_DOMAINS: {
      const domains: ISelectDomain[] = state.selectedDomains.map((domain) => {
        if (domain.wasValidated) {
          return {
            ...domain,
          };
        }

        return {
          ...domain,
          validated: true,
          hasPolicySet: !!domain.value && action.payload.includes(domain.value),
        };
      });

      return {
        ...state,
        selectedDomains: domains,
      };
    }
    case PolicySetDomainsActionTypes.RESET_POLICY_SET_DOMAINS_DATA:
      return initialState;
    default:
      return state;
  }
};
