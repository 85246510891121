import { Link } from "react-router-dom";
import { IPolicySetTableData } from "../../../../interfaces/PolicySet";
import { AdminPageRoutes } from "../../../../../constants/general.constants";
import Tooltip from "@atlaskit/tooltip";
import IconButton from "../../../../modules/common/IconButton";
import useModal from "../../../../../hooks/useModal";
import DeletePolicySetConfirmationModal from "./modals/DeletePolicySetConfirmationModal";
import TruncatedText from "../../../../modules/TruncatedText";

interface IPolicySetTableItemProps {
  policySet: IPolicySetTableData;
}

const PolicySetTableItem = ({ policySet }: IPolicySetTableItemProps) => {
  const {
    hide: hideDeletePolicySetModal,
    show: showDeletePolicySetModal,
    visible: deletePolicySetModalVisible,
  } = useModal();

  const { _id, ApprovalPolicyId, Domains, Name, RecordsManagementPolicyId } =
    policySet;

  return (
    <tr>
      <td>
        <Link to={`${AdminPageRoutes.POLICY_SET_DETAILS}/${policySet._id}`}>
          {Name}
        </Link>
        {!ApprovalPolicyId && !RecordsManagementPolicyId && (
          <div>
            <small className="warning-text">
              No policies in policy set, set is using default policies
            </small>
          </div>
        )}
      </td>
      <td>
        <TruncatedText content={Domains ?? ""} maxLines={4} title="Domains" />
      </td>
      <td>
        <div className="table-actions">
          <Tooltip content="Edit" position="top">
            <Link
              aria-label="edit-policy-set-btn"
              id={`link-policy-set-${_id}`}
              to={`${AdminPageRoutes.POLICY_SET_DETAILS}/${policySet._id}`}
            >
              <i className="fa fa-edit" />
            </Link>
          </Tooltip>
          <Tooltip content="Delete" position="top">
            <IconButton
              aria-label="delete-policy-set-btn"
              icon="fa-trash-o"
              id={`btn-delete-policy-set-${_id}`}
              onClick={showDeletePolicySetModal}
            />
          </Tooltip>

          {deletePolicySetModalVisible && (
            <DeletePolicySetConfirmationModal
              hasDomains={Boolean(Domains)}
              hasPolicies={Boolean(
                ApprovalPolicyId || RecordsManagementPolicyId,
              )}
              id={_id}
              name={Name}
              onClose={() => hideDeletePolicySetModal()}
              visible={deletePolicySetModalVisible}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default PolicySetTableItem;
