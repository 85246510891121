import { IFilterData } from "../../components/interfaces/LocateRecordsFilter";

export interface ILocatePolicyFilterState {
  policyType: IFilterData;
  policyName?: string;
  onlyShowDefaultPolicies: boolean;
}

export interface ILocatePolicyState extends ILocatePolicyFilterState {
  isLoadingOwners: boolean;
}

export enum LocatePolicyFilterActionTypes {
  UPDATE_POLICY_NAME_SEARCH_FIELD = "UPDATE_POLICY_NAME_SEARCH_FIELD",
  UPDATE_SELECTED_POLICY_TYPE = "UPDATE_SELECTED_POLICY_TYPE",
  TOGGLE_ONLY_SHOW_DEFAULT_POLICIES = "TOGGLE_ONLY_SHOW_DEFAULT_POLICIES",
  CLEAR_SELECTED_POLICY_TYPE = "CLEAR_SELECTED_POLICY_TYPE",
  CLEAR_POLICY_FILTER = "CLEAR_POLICY_FILTER",
  HANDLE_POLICY_FILTER_NETWORK_ERROR = "HANDLE_POLICY_FILTER_NETWORK_ERROR",
}

interface HandlePolicyFilterNetworkError {
  type: LocatePolicyFilterActionTypes.HANDLE_POLICY_FILTER_NETWORK_ERROR;
}

interface UpdatePolicyNameSearchField {
  type: LocatePolicyFilterActionTypes.UPDATE_POLICY_NAME_SEARCH_FIELD;
  payload: {
    searchField: string;
  };
}

interface UpdateSelectedPolicyType {
  type: LocatePolicyFilterActionTypes.UPDATE_SELECTED_POLICY_TYPE;
  payload: {
    name: string;
    isSelected: boolean;
  };
}

interface ToggleShowDefaultPolicies {
  type: LocatePolicyFilterActionTypes.TOGGLE_ONLY_SHOW_DEFAULT_POLICIES;
}

interface ClearSelectedPolicyType {
  type: LocatePolicyFilterActionTypes.CLEAR_SELECTED_POLICY_TYPE;
}

interface ClearPolicyFilter {
  type: LocatePolicyFilterActionTypes.CLEAR_POLICY_FILTER;
}

export type LocatePolicyFilterAction =
  | HandlePolicyFilterNetworkError
  | UpdateSelectedPolicyType
  | ToggleShowDefaultPolicies
  | ClearPolicyFilter
  | ClearSelectedPolicyType
  | UpdatePolicyNameSearchField;
