import { useMemo } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IDropdownOption } from "../../../interfaces/DropDown";
import Dropdown from "../../../modules/common/DropDown";

const DropdownRecordType = () => {
  const {
    recordTypes,
    recordTypeSearchField,
    isLoadingRecordTypes,
    selectedRecordTypes,
    skipRecordTypes,
  } = useTypedSelector((state) => state.locateRecordsFilter);

  const {
    updateSelectedRecordType,
    fetchRecordTypes,
    clearSelectedRecordTypes,
    updateSearchFieldRecordType,
  } = useActions();

  const handleRecordTypeSelectChange = (
    checked: boolean,
    option: IDropdownOption,
  ) => {
    updateSelectedRecordType(option.id);
  };

  const showMore = () => {
    fetchRecordTypes(recordTypeSearchField, recordTypes.items.length);
  };
  const items = useMemo(() => {
    const filteredItems = recordTypeSearchField
      ? recordTypes.items.filter(
          (x) => x.name && x.name.toLowerCase().includes(recordTypeSearchField),
        )
      : recordTypes.items;

    return filteredItems.map((x) => ({
      label: x.name,
      id: x.name,
      isSelected: x.isSelected,
    }));
  }, [recordTypes.items, recordTypeSearchField]);
  return (
    <Dropdown
      clearSelection={() => clearSelectedRecordTypes()}
      displayShowMore={false}
      id="record-type"
      isFirstPage={!skipRecordTypes}
      isLoading={isLoadingRecordTypes}
      items={items}
      label="Record Type"
      onChangeCheckbox={handleRecordTypeSelectChange}
      onChangeSearchField={updateSearchFieldRecordType}
      searchField={recordTypeSearchField}
      selectedItems={selectedRecordTypes}
      showMore={() => showMore()}
      totalItems={recordTypes.total}
    />
  );
};

export default DropdownRecordType;
