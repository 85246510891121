import React from "react";

const BrowserNotSupportedPage: React.FC = () => {
  return (
    <main className="container text-center login-body" role="main">
      <div className="alert alert-danger">Your browser is not supported</div>
    </main>
  );
};

export default BrowserNotSupportedPage;
