import { useTypedSelector } from "../../../hooks/useTypedSelector";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { IModalFailedApprovalsProps } from "../../interfaces/ApprovalQueue";
import { IconButton } from "@atlaskit/button/new";

const ModalFailedApprovals = ({
  isOpen,
  data,
  closeModal,
}: IModalFailedApprovalsProps) => {
  const { rowsData } = useTypedSelector((state) => state.approvalQueue);
  const { data: detailsData } = useTypedSelector(
    (state) => state.recordDetails,
  );

  const handleOnClickCopyErrors = () => {
    const message =
      data?.result
        .map((x) => {
          const record = rowsData?.find((row) => row._id === x.id);
          return `${record?.RecordName}: ${x.message}`;
        })
        .join("\n") ?? "";

    copy(message);

    toast.info("Copied to clipboard!");
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal width="large">
          <ModalHeader>
            <ModalTitle>Failed Approvals</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>
          <ModalBody>
            <div className="failed-approvals__container" id="failed-approvals">
              <div className="failed-approvals__header">
                <div className="d-flex align-items-center">Record name</div>
                <div className="d-flex align-items-center">
                  <div>Error message</div>
                  <div>
                    <button
                      className="btn btn-primary btn-sm text-white ml-2 btn-copy-filter"
                      onClick={() => handleOnClickCopyErrors()}
                      title="Copy text to clipboard"
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="failed-approvals__error-list">
                {data?.result
                  .filter((x) => !x.isSuccess)
                  .map((x) => {
                    const recordName = rowsData
                      ? rowsData?.find((row) => row._id === x.id)?.RecordName
                      : detailsData?.record?.name;
                    return (
                      <div className="failed-approvals__error">
                        <div>{recordName}</div>
                        <div>{x.message}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div style={{ zIndex: 2 }}>
              <button
                className="btn btn-primary text-white"
                id="btnCancel"
                onClick={closeModal}
                type="button"
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ModalFailedApprovals;
