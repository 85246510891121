import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IUserManagementTableItemProps } from "../../../interfaces/AdminPage";
import DeleteUserModal from "./modals/DeleteUserModal";
import { AdminPageRoutes } from "../../../../constants/general.constants";
import Tooltip from "@atlaskit/tooltip";
import ChangeUserStatusConfirmationModal from "./modals/ChangeUserStatusConfirmModal";
import useModal from "../../../../hooks/useModal";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

const UserManagementTableItem = ({ user }: IUserManagementTableItemProps) => {
  const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
  const roles = user.claims.filter((claim) => claim.type === "system-role");

  const { user: loggedUser } = useTypedSelector((x) => x.user);

  const {
    _id,
    active,
    claims,
    email,
    failedLogins,
    lastSuccessfulLogin,
    locked,
    lockedTime,
    userName,
  } = user;

  const { hide, show, visible } = useModal();

  return (
    <tr className="table-item">
      <td>{userName}</td>

      <td className="table-item__fullname">
        <Link
          aria-label="user-details-btn"
          className="user-details-btn"
          to={`${AdminPageRoutes.EDIT_USER}/${_id}`}
        >
          {claims[0].value}
        </Link>
      </td>

      <td className="table-item__email">{email}</td>

      <td>
        {roles.map((role, index) => (
          <React.Fragment key={role.type}>
            {role.value}
            {index + 1 === roles.length ? "" : ", "}
          </React.Fragment>
        ))}
      </td>
      <td>
        <div className="d-flex" style={{ flexDirection: "column" }}>
          {failedLogins > 0 && <div>{`${failedLogins} Failed Logins`}</div>}
          <div>
            <strong>Last Success: </strong>
            <span>
              {lastSuccessfulLogin ? lastSuccessfulLogin : <em>Never</em>}
            </span>
          </div>
          {locked && (
            <div>
              <strong>Locked At </strong>
              <span>{lockedTime}</span>
            </div>
          )}
        </div>
      </td>
      <td>{active ? "Active" : "Deactivated"}</td>
      <td>
        <div className="table-actions">
          {isOpenDeleteUserModal && (
            <DeleteUserModal
              closeModal={() => setIsOpenDeleteUserModal(false)}
              isOpen={isOpenDeleteUserModal}
              userData={user}
            />
          )}

          <Tooltip content="Edit" position="top">
            <Link aria-label="edit-user-btn" to={`/admin/edit-user/${_id}`}>
              <i className="fa fa-edit" />
            </Link>
          </Tooltip>
          <Tooltip content="Delete" position="top">
            <a
              aria-label="delete-user-btn"
              href="#"
              onClick={() => setIsOpenDeleteUserModal(true)}
              role="button"
            >
              <i className="fa fa-trash-o" />
            </a>
          </Tooltip>
          {loggedUser?.veraUserId !== _id && (
            <Tooltip
              content={active ? "Deactivate" : "Activate"}
              position="top"
            >
              <a
                aria-label={
                  active ? "deactivate-user-btn" : "activate-user-btn"
                }
                href="#"
                onClick={() => show()}
                role="button"
              >
                <i
                  className={`fa ${active ? "fa-toggle-on" : "fa-toggle-off"}`}
                />
              </a>
            </Tooltip>
          )}
          {visible && (
            <ChangeUserStatusConfirmationModal
              activate={!active}
              fullName={claims[0].value}
              id={_id}
              onClose={() => hide()}
              userName={userName}
              visible={visible}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default UserManagementTableItem;
