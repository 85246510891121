import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";
import { IPolicySetDomain } from "../../components/interfaces/PolicySet";
import { ITableData } from "../../components/interfaces/Table";

export enum PolicySetLinkedDomainsActionTypes {
  FETCHING_POLICY_SET_DOMAINS_TABLE_DATA = "FETCHING_POLICY_SET_DOMAINS_TABLE_DATA",
  UPDATE_POLICY_SET_DOMAINS_TABLE_DATA = "UPDATE_POLICY_SET_DOMAINS_TABLE_DATA",
  UPDATE_POLICY_SET_DOMAINS_SELECTED_PAGE = "UPDATE_POLICY_SET_DOMAINS_SELECTED_PAGE",
  UPDATE_POLICY_SET_DOMAINS_SORT_PARAMS = "UPDATE_POLICY_SET_DOMAINS_SORT_PARAMS",
  UPDATE_POLICY_SET_DOMAINS_PAGE_PARAMS = "UPDATE_POLICY_SET_DOMAINS_PAGE_PARAMS",
  UPDATE_POLICY_SET_DOMAINS_COLUMN_SEARCH_FIELD = "UPDATE_POLICY_SET_DOMAINS_COLUMN_SEARCH_FIELD",
  RESET_POLICY_SET_DOMAINS_SORT_PARAMS = "RESET_POLICY_SET_DOMAINS_SORT_PARAMS",
  HANDLE_POLICY_SET_DOMAINS_NETWORK_ERROR = "HANDLE_POLICY_SET_DOMAINS_NETWORK_ERROR",
  RESET_POLICY_SET_DOMAINS_DATA = "RESET_POLICY_SET_DOMAINS_DATA",
}

interface FetchingPolicySetDomainsData {
  type: PolicySetLinkedDomainsActionTypes.FETCHING_POLICY_SET_DOMAINS_TABLE_DATA;
}

interface UpdatePolicySetDomainsTableData {
  type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_TABLE_DATA;
  payload: ITableData<IPolicySetDomain>;
}

interface UpdatePolicySetDomainsSelectedPage {
  type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_SELECTED_PAGE;
  payload: number;
}

interface HandlePolicySetDomainsNetworkError {
  type: PolicySetLinkedDomainsActionTypes.HANDLE_POLICY_SET_DOMAINS_NETWORK_ERROR;
  payload: string;
}

interface UpdatePolicySetDomainsSortParams {
  type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdatePolicySetDomainsPageParams {
  type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface HandleResetPolicySetDomainsSortParams {
  type: PolicySetLinkedDomainsActionTypes.RESET_POLICY_SET_DOMAINS_SORT_PARAMS;
}

interface HandleResetPolicySetDomainsData {
  type: PolicySetLinkedDomainsActionTypes.RESET_POLICY_SET_DOMAINS_DATA;
}

interface UpdatePolicySetDomainsColumnSearchField {
  type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_COLUMN_SEARCH_FIELD;
  payload: IColumnsSearchFieldsFormData;
}

export type PolicySetLinkedDomainsAction =
  | UpdatePolicySetDomainsTableData
  | FetchingPolicySetDomainsData
  | UpdatePolicySetDomainsSelectedPage
  | HandlePolicySetDomainsNetworkError
  | HandleResetPolicySetDomainsData
  | UpdatePolicySetDomainsSortParams
  | UpdatePolicySetDomainsPageParams
  | HandleResetPolicySetDomainsSortParams
  | UpdatePolicySetDomainsColumnSearchField;
