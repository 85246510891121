import Select from "@atlaskit/select";
import {
  IArrayOptionItem,
  IMultiSelectProps,
  IOptionItem,
} from "../../interfaces/MultiSelectField";

const MultiSelectField = ({
  options,
  onChange,
  name,
  placeholder = "Select...",
  label,
  disabled = false,
  fullWidth = false,
}: IMultiSelectProps) => {
  const optionsArray: IArrayOptionItem[] = options.map((item: IOptionItem) => ({
    label: item.text,
    value: item.id,
    selected: item.selected,
  }));

  const defaultArray = optionsArray.filter((item) => item.selected);

  const handleChange = (value: any) => {
    const values = value.map((item: IArrayOptionItem) => ({
      id: item.value,
      text: item.label,
      selected: true,
    }));
    const result = options.map(
      (obj) =>
        values.find((o: any) => o.id === obj.id) || { ...obj, selected: false },
    );
    onChange({ name, value: result });
  };
  const getClassName = () => `basic-multi-select ${fullWidth ? "w-100" : ""}`;

  return (
    <div className={`form-with-label ${label ? "form-with-label--grid" : ""}`}>
      {label && (
        <label className="m-0 font-weight-500" htmlFor={name}>
          {label}
        </label>
      )}
      <Select
        aria-label="multiselect-field"
        className={getClassName()}
        classNamePrefix="select"
        closeMenuOnSelect={false}
        isDisabled={disabled}
        isMulti
        name={name || ""}
        onChange={handleChange}
        options={optionsArray}
        placeholder={placeholder}
        value={defaultArray || []}
      />
    </div>
  );
};

export default MultiSelectField;
