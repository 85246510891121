import React, { useMemo } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { IDropdownOption } from "../../../interfaces/DropDown";
import Dropdown from "../../../modules/common/DropDown";

const DropdownRecordStatus = () => {
  const { recordStatus, recordStatusSearchField, isLoadingRecordStatuses } =
    useTypedSelector((state) => state.locateRecordsFilter);

  const {
    updateSelectedRecordStatus,
    clearSelectedRecordStatus,
    updateSearchFieldRecordStatus,
  } = useActions();

  const handleRecordStatusSelectChange = (
    checked: boolean,
    option: IDropdownOption,
  ) => {
    updateSelectedRecordStatus(option.id, checked);
  };

  const items = useMemo(() => {
    const filteredItems = recordStatusSearchField
      ? recordStatus.items.filter(
          (x) =>
            x.name && x.name.toLowerCase().includes(recordStatusSearchField),
        )
      : recordStatus.items;

    return filteredItems.map((x) => ({
      label: x.name,
      id: x.name,
      isSelected: x.isSelected,
    }));
  }, [recordStatus.items, recordStatusSearchField]);

  return (
    <Dropdown
      clearSelection={() => clearSelectedRecordStatus()}
      displayShowMore={false}
      id="record-status"
      isLoading={isLoadingRecordStatuses}
      items={items}
      label="Record Status"
      onChangeCheckbox={handleRecordStatusSelectChange}
      onChangeSearchField={updateSearchFieldRecordStatus}
      searchField={recordStatusSearchField}
      totalItems={recordStatus.total}
    />
  );
};

export default DropdownRecordStatus;
