import React, { useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import Loader from "../../modules/Loader";
import AdminLoginForm from "../login/AdminLoginForm";
import { Alert } from "@mui/material";

const AdminLoginPage = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { getAdminData } = useActions();

  const getData = async () => {
    await getAdminData();
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main
      className="container text-center login-body admin-login-body"
      role="main"
    >
      <div className="container text-center d-flex flex-column align-items-center">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="card-container">
              <Alert severity="info">
                You are required to authenticate when requesting administrator
                access.
              </Alert>

              <AdminLoginForm />
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default AdminLoginPage;
