import {
  ExpandedContentAction,
  ExpandedContentModalActionTypes,
} from "../types/expandedContentModal";

interface IExpandedContentModalState {
  content: string;
  renderAsHtml: boolean;
  showModal: boolean;
  title: React.ReactNode;
}

const initialState: IExpandedContentModalState = {
  showModal: false,
  content: "",
  title: "",
  renderAsHtml: true,
};

export const expandedContentModalReducer = (
  state = initialState,
  action: ExpandedContentAction,
): IExpandedContentModalState => {
  switch (action.type) {
    case ExpandedContentModalActionTypes.SHOW_EXPANDED_CONTENT_MODAL:
      return {
        ...state,
        showModal: true,
        ...action.payload,
      };
    case ExpandedContentModalActionTypes.HIDE_EXPANDED_CONTENT_MODAL:
      return initialState;
    default:
      return state;
  }
};
