import React, { useEffect } from "react";
import Select from "../../../../../../modules/common/Select";
import { ErrorMessage } from "../../../../../../../constants/general.constants";
import { toast } from "react-toastify";
import axios from "axios";
import { useActions } from "../../../../../../../hooks/useActions";
import { useParams } from "react-router-dom";
import { PolicySetsRequestRoutes } from "../../../../../../../constants/policy-set.constants";
import { IDefaultResponse } from "../../../../../../interfaces/General";
import { AdminPageSuccessMessages } from "../../../../../../../constants/admin-page.constants";
import { useTypedSelector } from "../../../../../../../hooks/useTypedSelector";
import Form from "../../../../../../modules/common/Form";
import { ISelectDomain } from "../../../../../../interfaces/PolicySet";
import { InputActionMeta } from "@atlaskit/select";

const LinkDomainToPolicySet = () => {
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const { policySetId } = useParams<{ policySetId: string }>();
  const { domains, selectedDomains, isLoading } = useTypedSelector(
    (x) => x.policySetDomains,
  );
  const {
    fetchMorePolicySetDomains,
    fetchPolicySetDomains,
    fetchPolicySetLinkedDomains,
    fetchDomainsLinkedToAnotherPolicySet,
    resetPolicySetDomainsData,
    setSelectedDomains,
  } = useActions();

  const fetchDomains = async (searchTerm: string) => {
    if (!searchTerm.length || searchTerm.length >= 3) {
      fetchPolicySetDomains(searchTerm);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchDomains(inputValue);
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  const handOnClickLinkDomains = async () => {
    const params = {
      id: policySetId,
      domains: selectedDomains.map((x) => x.value),
    };

    try {
      setLoading(true);
      const { status } = await axios.post<IDefaultResponse<any>>(
        PolicySetsRequestRoutes.LINK_DOMAINS,
        params,
      );

      if (status === 200) {
        toast.success(AdminPageSuccessMessages.LINK_DOMAIN_POLICY_SET);
        setSelectedDomains([]);
        fetchPolicySetLinkedDomains(policySetId);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnMenuClose = async () => {
    setInputValue("");
    const notValidated = selectedDomains.filter((x) => !x.wasValidated);
    if (notValidated.length) {
      fetchDomainsLinkedToAnotherPolicySet(policySetId);
    }
  };

  const getMultiValueStyles = (item: ISelectDomain) => {
    return item.hasPolicySet
      ? {
          background: "rgb(255, 240, 219)",
          border: "1px dashed rgb(102, 60, 0)",
          color: "rgb(102, 60, 0)",
        }
      : {};
  };

  useEffect(
    () => () => {
      resetPolicySetDomainsData();
    },
    [],
  );

  return (
    <div>
      <div className="table-heading-wrapper mb-3">
        <b>Domains</b>
      </div>
      <Form>
        <Select
          inputValue={inputValue}
          isLoading={isLoading}
          menuPosition="absolute"
          multiple={true}
          name="domains"
          onChange={(newValue) => setSelectedDomains(newValue)}
          onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
            if (actionMeta.action === "input-change" || newValue) {
              setInputValue(newValue);
            }
          }}
          onMenuClose={handleOnMenuClose}
          onMenuScrollToBottom={(e) => {
            fetchMorePolicySetDomains(inputValue);
          }}
          options={domains}
          placeholder="Select domain"
          styles={{
            multiValue: (currentCSS, props) => ({
              ...currentCSS,
              ...getMultiValueStyles(props.data as ISelectDomain),
            }),
          }}
          value={selectedDomains}
          warning={
            selectedDomains.some((x) => x.hasPolicySet)
              ? "A selected domain is already associated to another policy set, click add to confirm this change."
              : ""
          }
        />
        <button
          aria-label="btn-add-domains-to-policy-set"
          className="btn btn-primary text-white"
          disabled={loading || selectedDomains.length === 0}
          id="btn-add-domains-to-policy-set"
          onClick={handOnClickLinkDomains}
          style={{ alignSelf: "end" }}
          type="button"
        >
          Add Domains to Policy Set
          {loading && (
            <span
              aria-hidden="true"
              className="spinner-border spinner-border-sm ml-2"
              role="status"
            />
          )}
        </button>
      </Form>
    </div>
  );
};

export default LinkDomainToPolicySet;
