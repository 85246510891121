import Select, { ValueType } from "@atlaskit/select";
import { SimpleTag as Tag } from "@atlaskit/tag";
import Group from "@atlaskit/tag-group";
import { gridSize as getGridSize } from "@atlaskit/theme/constants";
import InlineEdit from "@atlaskit/inline-edit";
import {
  IOptionType,
  IMultiSelectFieldInlineProps,
} from "../../interfaces/MultiSelectFieldInline";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";

const gridSize = getGridSize();

const MultiSelectFieldInline = ({
  editValue,
  isLoading,
  label,
  loadOptions,
  options,
  setEditValue,
}: IMultiSelectFieldInlineProps) => {
  const onConfirm = (value: ValueType<IOptionType, true>) => {
    if (!value) {
      return;
    }
    if (JSON.stringify(value) === JSON.stringify(editValue)) {
      return;
    }

    setEditValue(value.map((x: any) => x.value));
  };

  const editView = (fieldProps: any) => {
    return (
      <div className="multiselect-editview">
        <Select
          {...fieldProps}
          autoFocus
          closeMenuOnSelect={false}
          isLoading={isLoading}
          isMulti
          onMenuOpen={loadOptions}
          openMenuOnFocus
          options={options}
        />
      </div>
    );
  };

  const readView = () => (
    <div
      className="multiselect-readview"
      style={{ padding: `${gridSize / 2}px` }}
    >
      {editValue && editValue.length ? (
        <>
          <Group>
            {editValue.map((option: IOptionType) => (
              <Tag key={option.label} text={option.label} />
            ))}
          </Group>
          <span className="dropdown-icon__container">
            <ChevronDownIcon
              label="chevron icon"
              primaryColor="#42526E"
              secondaryColor="white"
              size="medium"
            />
          </span>
        </>
      ) : (
        <>
          <span className="multiselect-readview-empty">Select...</span>
          <span className="dropdown-icon__container">
            <ChevronDownIcon
              label="chevron icon"
              primaryColor="#42526E"
              secondaryColor="white"
              size="medium"
            />
          </span>
        </>
      )}
    </div>
  );

  return (
    <div className="multiselect-confirm">
      <InlineEdit<ValueType<IOptionType, true>>
        defaultValue={editValue}
        editView={editView}
        label={label}
        onConfirm={onConfirm}
        readView={readView}
      />
    </div>
  );
};

export default MultiSelectFieldInline;
