import { PropsWithChildren, useEffect, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import UserDetailsForm from "../../modules/UserDetailsForm";
import Loader from "../../modules/Loader";
import DomainsAndRolesProfilePage from "./DomainsAndRolesProfilePage";
import ToggleEmailNotifications from "./ToggleEmailNotifications";

const ProfilePage = () => {
  const { loading, profileData, user } = useTypedSelector(
    (state) => state.user,
  );
  const [tableIsCollapsed, setTableIsCollapsed] = useState<boolean>(false);
  const { getProfileData } = useActions();

  useEffect(() => {
    getProfileData(user?.veraUserId ?? "");
  }, []);

  return (
    <div className="profile-page">
      <div className="table-heading-wrapper">
        <b>User Profile</b>
      </div>
      {loading && <Loader message={"Loading, please wait..."} />}

      {profileData && <UserDetailsForm profileData={profileData} />}
      <div
        aria-label="domain-and-roles-table"
        className="pt-3"
        id="accordionDomainsAndRoles"
      >
        <a
          aria-label="collapse-domains-and-roles-table"
          className="text-decoration-none toggleRotate col p-0"
          data-bs-target="#collapseDomainsAndRoles"
          data-bs-toggle="collapse"
          href="#!"
          onClick={() => setTableIsCollapsed(!tableIsCollapsed)}
        >
          <i
            className={`fa ${
              tableIsCollapsed
                ? "fa-chevron-circle-right"
                : "fa-chevron-circle-down"
            }`}
          />
          <h6 className="d-inline"> Domains and Roles</h6>
        </a>
        {profileData && <ToggleEmailNotifications />}
        <div
          className="collapse show col container-fluid p-0"
          data-bs-parent="#accordionDomainsAndRoles"
          id="collapseDomainsAndRoles"
        >
          <DomainsAndRolesProfilePage />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
