import React from "react";
import { escapeOnlyNonHtmlTags } from "../../../../helpers/html.helper";
import {
  PrintViewSystemDetailsProps,
  RecordField,
} from "../../../interfaces/DetailsPage";

const PrintViewSystemDetails = ({
  record,
  getAttachmentsList,
}: PrintViewSystemDetailsProps) => {
  const longItems: RecordField[] = [];
  const shortItems: RecordField[] = [];

  record.fields.forEach((field) => {
    if (field.value.length < 60 && field.value.indexOf("<p>")) {
      shortItems.push(field);
    } else {
      longItems.push(field);
    }
  });

  return (
    <div className="pb-3" id="print-view-record-details">
      <div className="container-fluid row">
        <h4> {record.original_location.system} Details</h4>

        <div className="container-fluid">
          <div className="row">
            {shortItems.map((item) => (
              <div
                className="col-xs-12 col-md-6 details-field"
                key={`${item.label}-${item.value}`}
              >
                <label>{item.label}:</label>
                <span>{item.value}</span>
              </div>
            ))}

            {longItems &&
              longItems.map((item) => (
                <React.Fragment key={`${item.label}-${item.value}`}>
                  <br />
                  <br />
                  <div className="col-xs-12 col-md-12 details-field d-flex">
                    <label>{item.label}:</label>
                    <div
                      className="wrap-text-content external-content"
                      dangerouslySetInnerHTML={{
                        __html: escapeOnlyNonHtmlTags(item.value),
                      }}
                    />
                  </div>
                </React.Fragment>
              ))}

            {record.attachments.length > 0 && (
              <div className="col-xs-12 col-md-12 details-field">
                <label>Attachments:</label>
                <span>{getAttachmentsList(record.attachments)}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintViewSystemDetails;
