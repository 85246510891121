import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { PolicyRequestRoutes } from "../../constants/policy.constants";
import { IPolicyRevision } from "../../components/interfaces/Policy";
import { RootState } from "../reducers";
import {
  PolicyRevisionsAction,
  PolicyRevisionsActionTypes,
} from "../types/policyRevisions";
import { IDefaultResponse } from "../../components/interfaces/General";
import { ITableData } from "../../components/interfaces/Table";

let policyRevisionCancelToken: CancelTokenSource;
export const fetchPolicyRevisions = (policyId: string) => {
  return async (
    dispatch: Dispatch<PolicyRevisionsAction>,
    getState: Function,
  ) => {
    if (typeof policyRevisionCancelToken != typeof undefined) {
      policyRevisionCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    policyRevisionCancelToken = axios.CancelToken.source();

    const state = getState() as RootState;
    const { offset, ...rest } = state.policyRevisions.sortParams;

    const sortParams = { ...rest, skip: offset };

    const params = {
      id: policyId,
      ...sortParams,
    };

    try {
      dispatch({
        type: PolicyRevisionsActionTypes.FETCHING_POLICY_REVISIONS_TABLE_DATA,
      });

      const { data, status } = await axios.get<
        IDefaultResponse<ITableData<IPolicyRevision>>
      >(PolicyRequestRoutes.POLICY_REVISIONS, {
        cancelToken: policyRevisionCancelToken.token,
        params,
      });

      if (status === 200) {
        dispatch({
          type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_TABLE_DATA,
          payload: data.apiData,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: PolicyRevisionsActionTypes.HANDLE_POLICY_REVISIONS_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const updatePolicyRevisionsSelectedPage = (selectedPage: number) => {
  return {
    type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const updatePolicyRevisionsSortParams = (
  sort: string,
  order: "asc" | "desc",
) => {
  return async (dispatch: Dispatch<PolicyRevisionsAction>) => {
    dispatch({
      type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updatePolicyRevisionsPageParams = (
  offset: number,
  limit: number,
) => {
  return async (dispatch: Dispatch<PolicyRevisionsAction>) => {
    dispatch({
      type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetPolicyRevisionsSortParams = () => {
  return async (dispatch: Dispatch<PolicyRevisionsAction>) => {
    dispatch({
      type: PolicyRevisionsActionTypes.RESET_POLICY_REVISIONS_SORT_PARAMS,
    });
  };
};

export const handleResetPolicyRevisionsData = () => {
  policyRevisionCancelToken &&
    policyRevisionCancelToken.cancel(ErrorMessage.LOGOUT);
  return async (dispatch: Dispatch<PolicyRevisionsAction>) => {
    dispatch({ type: PolicyRevisionsActionTypes.RESET_POLICY_REVISIONS_DATA });
  };
};
