import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useAppDispatch } from "../../../../../../store/store";
import { setGroupBy } from "../../../../../../store/slices/user.slice";
import { eGroupBy } from "../../../../../interfaces/User";
import MenuItem from "@mui/material/MenuItem";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";

const GroupBySelect = () => {
  const dispatch = useAppDispatch();

  const groupBy = useTypedSelector((x) => x.concurrentUsersReport.groupBy);

  return (
    <FormControl fullWidth style={{ maxWidth: "110px" }}>
      <InputLabel id="groupBy-label">Group by</InputLabel>
      <Select
        fullWidth
        id="group-by-select"
        label="Group By"
        labelId="groupBy-label"
        name="groupBy"
        onChange={(e) => dispatch(setGroupBy(e.target.value as eGroupBy))}
        placeholder="Group By"
        size="small"
        value={groupBy}
      >
        <MenuItem value={eGroupBy.DAY}>Daily</MenuItem>
        <MenuItem value={eGroupBy.MONTH}>Monthly</MenuItem>
        <MenuItem value={eGroupBy.YEAR}>Yearly</MenuItem>
      </Select>
    </FormControl>
  );
};

export default GroupBySelect;
