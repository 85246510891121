import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PublicRequestRoute } from "../../constants/general.constants";

export const versionApi = createApi({
  reducerPath: "versionApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${window.location.origin}/` }),
  tagTypes: ["version"],
  endpoints: (builder) => ({
    getVersion: builder.query<{ version?: string | null }, void>({
      query: () => PublicRequestRoute.VERSIONS,
      providesTags: ["version"],
    }),
  }),
});

export const { useGetVersionQuery } = versionApi;

export default versionApi.reducer;
