import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../interceptors/axiosBaseQuery";
import { AdminRequestRoutes } from "../../constants/general.constants";
import {
  ITableData,
  ITableRequestParams,
} from "../../components/interfaces/Table";
import {
  IUserSession,
  IUserSessionState,
} from "../../components/interfaces/UserSession";

const initialState: IUserSessionState = {
  columnSearchField: {},
  sortParams: {
    sort: "lastAccess",
    order: "desc",
    offset: 0,
    limit: 15,
  },
  selectedPage: 0,
  tableHeaders: [
    {
      label: "User Name",
      name: "idpUsername",
      filterable: true,
      colSize: 4,
      notSortable: false,
    },
    {
      label: "Full Name",
      name: "fullName",
      filterable: true,
      colSize: 4,
      notSortable: false,
    },
    {
      label: "Last Access",
      name: "lastAccess",
      filterable: true,
      colSize: 4,
      notSortable: false,
    },
  ],
};

export const userSessionsApi = createApi({
  reducerPath: "user-sessions",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["userSessions"],
  endpoints: (builder) => ({
    getUserSessions: builder.query<
      ITableData<IUserSession>,
      ITableRequestParams
    >({
      providesTags: ["userSessions"],
      query: (params) => ({
        url: AdminRequestRoutes.USER_SESSIONS,
        params,
      }),
    }),
    deleteUserSession: builder.mutation<any, string>({
      query: (sessionID) => ({
        body: { sessionID },
        method: "DELETE",
        url: AdminRequestRoutes.USER_SESSIONS,
      }),
      invalidatesTags: ["userSessions"],
    }),
  }),
});

const userSessionsSlice = createSlice({
  name: "user-sessions",
  initialState,
  reducers: {
    setSelectedPage(state, action: PayloadAction<number>) {
      state.selectedPage = action.payload;
    },
    setSortParams(
      state,
      action: PayloadAction<{ sort: string; order: "asc" | "desc" }>,
    ) {
      state.sortParams = {
        ...state.sortParams,
        ...action.payload,
      };
    },
    setPageParams(
      state,
      action: PayloadAction<{ offset: number; limit: number }>,
    ) {
      state.sortParams = {
        ...state.sortParams,
        ...action.payload,
      };
    },
    setColumnsSearchField(state, action: PayloadAction<{}>) {
      state.columnSearchField = action.payload;
    },
    resetData: () => {
      return initialState;
    },
  },
});

export const { useGetUserSessionsQuery, useDeleteUserSessionMutation } =
  userSessionsApi;

export const {
  resetData,
  setColumnsSearchField,
  setPageParams,
  setSelectedPage,
  setSortParams,
} = userSessionsSlice.actions;

export default userSessionsSlice.reducer;
