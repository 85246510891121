import { IUserManagementItem } from "../../components/interfaces/AdminPage";

export interface UserState {
  user: UserData | null;
  username: string | null;
  enteredUsername?: string | undefined;
  loading: boolean;
  error: string | null;
  hasActiveSession: boolean;
  initialization: boolean;
  profileData: ProfileData | null;
  adminIsAuthenticated: boolean;
  veraIdp: boolean | null;
  browserNotSupported: boolean;
  idleTimeoutMinutes: number;
  idlePromptSeconds: number;
}

export interface LoginVeraResponse {
  config: any;
  data: UserDataResponse;
  headers: any;
  request: any;
  status: number;
  statusText: string;
  gainsightAccId?: string;
  gainsightAccName?: string;
  gainsightEnv?: string;
}

export interface GetAdminDataResponse {
  config: any;
  data: AdminUsername;
  headers: any;
  request: any;
  status: number;
  statusText: string;
}

export interface AdminLoginResponse {
  config: any;
  data: AdminData;
  headers: any;
  request: any;
  status: number;
  statusText: string;
}

export interface LoginResponse {
  data: {
    username?: string;
    veraIdp?: boolean;
    url?: string;
    message?: string;
    gainsightAccId?: string;
    gainsightAccName?: string;
    gainsightEnv?: string;
  };
}

export interface IActiveSessionProps {
  user: UserData | null;
  userFullName: string | null;
  veraIdp: boolean;
  hasActiveSession: boolean;
  browserNotSupported: boolean;
  idleTimeoutMinutes: number;
  idlePromptSeconds: number;
}

export interface UserData {
  username: string | null;
  fullName: string | null;
  emailAddress: string | null;
  veraUserId: string | null;
  isSystemAdmin: boolean;
  isDomainAdmin: boolean;
  groupMembership: string[] | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  tokenHasSuperScope?: boolean;
  message?: string;
  url?: string;
}

export interface UserDataResponse extends UserData {
  idlePromptSeconds: number;
  idleTimeoutMinutes: number;
}

export interface ILogoutRespone {
  data: {
    status: boolean;
    url?: string;
    message?: string;
  };
}

export interface AdminUsername {
  username: string | undefined;
  url?: string;
}

export interface AdminData {
  username: string | null;
  fullName: string | null;
  emailAddress: string | null;
  veraUserId: string | null;
  isSystemAdmin: boolean;
  isDomainAdmin: boolean;
  adminRefreshToken: string;
  groupMembership: string[] | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  tokenHasSuperScope?: boolean;
  message?: string;
  idleTimeoutMinutes: number;
  idlePromptSeconds: number;
}

export interface ProfileData {
  domainPageEnabled: boolean;
  emailAddress: string;
  hasSignatureIdp: boolean;
  idps: { id: string; name: string }[];
  isAdminPage: boolean;
  userDefaultIdp: {
    providerName: string;
    userName: string;
    isDefault: boolean;
    isSignatureDefault: boolean;
  };
  userDetails: IUserManagementItem;
  userSignatureIdpName: string | null;
  systemRoles: string[];
  systemRolesList: any;
  errorMessage?: string;
  userFullName: string;
}

export interface UserDomainsResponse {
  results: { id: string; text: string; selected: boolean }[];
}

export interface UserRolesListResponse {
  results: { id: string; text: string; selected: boolean }[];
}

export enum UserActionTypes {
  FETCHING_DATA = "FETCHING_DATA",
  LOGIN = "LOGIN",
  LOGIN_VERA = "LOGIN_VERA",
  STOP_LOADING = "STOP_LOADING",
  RESET_FORM = "RESET_FORM",
  LOGOUT = "LOGOUT",
  CHECK_ACTIVE_SESSION = "CHECK_ACTIVE_SESSION",
  GET_VERSIONS = "GET_VERSIONS",
  GET_PROFILE_DATA = "GET_PROFILE_DATA",
  UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA",
  GET_ADMIN_DATA = "GET_ADMIN_DATA",
  ADMIN_LOGIN = "ADMIN_LOGIN",
  REVERIFY_ADMIN = "REVERIFY_ADMIN",
  INITIATE_LOGOUT = "INITIATE_LOGOUT",
}

interface FetchingData {
  type: UserActionTypes.FETCHING_DATA;
}

interface LoginAction {
  type: UserActionTypes.LOGIN;
  payload: {
    username: string;
    veraIdp: boolean;
  };
}

interface LoginVeraAction {
  type: UserActionTypes.LOGIN_VERA;
  payload: UserDataResponse;
}

interface LoginVeraStopLoadingAction {
  type: UserActionTypes.STOP_LOADING;
}

interface ResetFormAction {
  type: UserActionTypes.RESET_FORM;
}

interface LogoutAction {
  type: UserActionTypes.LOGOUT;
}

interface GetProfileDataAction {
  type: UserActionTypes.GET_PROFILE_DATA;
  payload: ProfileData;
}

interface UpdateProfileDataAction {
  type: UserActionTypes.UPDATE_PROFILE_DATA;
  payload: string;
}

interface CheckActiveSessionAction {
  type: UserActionTypes.CHECK_ACTIVE_SESSION;
  payload: IActiveSessionProps;
}

interface GetAdminDataAction {
  type: UserActionTypes.GET_ADMIN_DATA;
  payload: AdminUsername;
}

interface AdminLoginAction {
  type: UserActionTypes.ADMIN_LOGIN;
  payload: AdminData;
}

interface ReVerifyAdminAction {
  type: UserActionTypes.REVERIFY_ADMIN;
  payload: IActiveSessionProps;
}

interface InitiateLogout {
  type: UserActionTypes.INITIATE_LOGOUT;
}

export type UserAction =
  | LoginAction
  | LoginVeraAction
  | LoginVeraStopLoadingAction
  | FetchingData
  | ResetFormAction
  | LogoutAction
  | CheckActiveSessionAction
  | GetProfileDataAction
  | UpdateProfileDataAction
  | AdminLoginAction
  | GetAdminDataAction
  | ReVerifyAdminAction
  | InitiateLogout;
