import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import Table from "../../../../modules/Table";
import PolicySetTableItem from "./PolicySetTableItem";

const PolicySetsTable = () => {
  const policySetState = useTypedSelector((x) => x.policySets);

  const {
    updatePolicySetsSelectedPage,
    updatePolicySetsSortParams,
    updatePolicySetsPageParams,
    updatePolicySetsColumnSearchField,
  } = useActions();

  return (
    <Table
      {...policySetState}
      columnFiltrationIsActive={true}
      setColumnsSearchField={updatePolicySetsColumnSearchField}
      setPageParams={updatePolicySetsPageParams}
      setSelectedPage={updatePolicySetsSelectedPage}
      setSortParams={updatePolicySetsSortParams}
    >
      {policySetState.tableData?.rows?.map((policySet) => (
        <PolicySetTableItem key={policySet._id} policySet={policySet} />
      ))}
    </Table>
  );
};

export default PolicySetsTable;
