import { Controller, useForm } from "react-hook-form";
import { IDomainDetailsForm } from "../../../../interfaces/Domain";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "../../../../modules/common/Form";
import FormTextField from "../../../../modules/common/FormTextField";
import UserSelect from "../../../../modules/UserSelect";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { Link, Prompt, useParams } from "react-router-dom";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AdminPageQueryParams,
  IDomainUpdateResponse,
} from "../../../../interfaces/AdminPage";
import { useState } from "react";
import { AdminPageSuccessMessages } from "../../../../../constants/admin-page.constants";
import { transformDomainResponse } from "../../../../../helpers/domain.helper";
import { createSchema } from "../../../../../validators/domainFormValidator";

interface IDomainDetailsFormProps {
  domain: IDomainDetailsForm;
}

const DomainDetailsForm = ({ domain }: IDomainDetailsFormProps) => {
  const { user } = useTypedSelector((state) => state.user);
  const { domainId } = useParams<AdminPageQueryParams>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<IDomainDetailsForm>({
    defaultValues: domain,
    mode: "all",
    resolver: yupResolver(createSchema()),
  });
  const policySet = watch("policySet");

  const handleDomainUpdate = async (domainDetailsForm: IDomainDetailsForm) => {
    try {
      setLoading(true);
      const { data } = await axios.post<IDomainUpdateResponse>(
        AdminRequestRoutes.UPDATE_DOMAIN,
        {
          domainDetailsDomainId: domainId,
          domainName: domainDetailsForm.domainName,
          domainDescription: domainDetailsForm.description,
          domainOwnerUserId: domainDetailsForm.domainOwner?.value,
        },
      );
      if (data) {
        toast.success(AdminPageSuccessMessages.DOMAIN_UPDATE);
        reset(
          transformDomainResponse({
            ...data,
            PolicySet: domainDetailsForm.policySet,
          }),
        );
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Prompt
        message="Changes you made may not be saved. Are you sure?"
        when={isDirty}
      />

      <Form aria-label="profile-form" className="domain-details-form">
        <FormTextField
          control={control}
          disabled={!user?.isSystemAdmin}
          error={errors?.domainName?.message}
          label="Domain Name:"
          name="domainName"
          placeholder="Enter Domain Name"
          wasValidated={!errors?.domainName}
        />

        <FormTextField
          control={control}
          disabled={!user?.isSystemAdmin}
          label="Description:"
          name="description"
          placeholder="Enter Description (optional)"
        />

        <Controller
          control={control}
          name="domainOwner"
          render={({ field: { onChange, onBlur, value, name } }) => (
            <UserSelect
              disabled={!user?.isSystemAdmin}
              label="Domain Owner:"
              multiple={false}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder="Select Domain Owner (Optional)"
              size="medium"
              value={value}
            />
          )}
        />

        <div className="form-with-label form-with-label--large">
          <label>Policy Set:</label>
          {user?.isSystemAdmin && policySet ? (
            <Link to={`/admin/policy-set-details/${policySet._id}`}>
              {policySet.Name}
            </Link>
          ) : (
            <span>{policySet ? policySet.Name : ""}</span>
          )}
        </div>
      </Form>
      {user?.isSystemAdmin && (
        <div className="form-actions">
          <button
            className="btn btn-primary text-white"
            disabled={loading || !isDirty}
            id="save-domain-details-btn"
            onClick={handleSubmit((data) => handleDomainUpdate(data))}
            type="button"
          >
            Save
            {loading && (
              <span
                aria-hidden="true"
                className="spinner-border spinner-border-sm ml-2"
                role="status"
              />
            )}
          </button>

          <button
            className="btn btn-primary text-white ml-2"
            disabled={!isDirty}
            id="reset-domain-details-btn"
            onClick={() => reset()}
            type="button"
          >
            Reset
          </button>
        </div>
      )}
    </>
  );
};

export default DomainDetailsForm;
