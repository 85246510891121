import { faPenAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { IApproveFooterQueueProps } from "../../interfaces/ApprovalQueue";
import ModalApprove from "./ModalApprove";
import ModalReject from "./ModalReject";
import { useActions } from "../../../hooks/useActions";
import { useEffect } from "react";

const ApproveFooter = ({
  onBulkApprove,
  onTaskSubmit,
  onCloseApproveModal,
  assignedTask = undefined,
}: IApproveFooterQueueProps) => {
  const { selectedItems, selectedMeaningsAreUnique, isLoading } =
    useTypedSelector((state) => state.approvalQueue);
  const {
    isOpenApprove,
    isOpenReject,
    isLoading: loading,
  } = useTypedSelector((x) => x.approveFooter);

  const {
    openApproveModal,
    openRejectModal,
    closeRejectModal,
    closeAllModals,
    handleResetApproveFooterData,
    showLoading,
    hideLoading,
  } = useActions();

  const handleApprove = async (
    password: string,
    reason: string,
    taskId: string,
    taskRejection = false,
  ) => {
    showLoading();
    const success = onTaskSubmit
      ? await onTaskSubmit(password, reason, taskId, taskRejection)
      : await onBulkApprove!(password);
    if (!success) {
      hideLoading();
    }
  };

  const handleReject = async (
    password: string,
    reason: string,
    taskId: string,
    taskRejection = false,
  ) => {
    showLoading();
    onTaskSubmit &&
      (await onTaskSubmit(password, reason, taskId, taskRejection));
    closeRejectModal();
    hideLoading();
  };

  const closeModal = (hasApprovalRecords: boolean) => {
    onCloseApproveModal && onCloseApproveModal(hasApprovalRecords);
    closeAllModals();
  };

  useEffect(() => {
    return () => {
      handleResetApproveFooterData();
    };
  }, []);

  const meaning = selectedItems.length
    ? selectedItems[0].Meaning
    : assignedTask?.meaning || "";
  return (
    <>
      {!isLoading && (selectedItems.length > 0 || assignedTask) ? (
        <div
          className="approve-footer mx-auto w-50 align-middle text-white fixed-bottom font-size-lg no-print"
          id="approve-footer"
        >
          {selectedMeaningsAreUnique ? (
            <>
              <div className="my-auto" style={{ fontSize: "19px" }}>
                {meaning}{" "}
                {selectedItems.length > 0 &&
                  `(${selectedItems.length} selected)`}
              </div>

              <div
                className={`col-sm-${
                  assignedTask ? "6" : "3"
                } p-2 approve-footer-buttons`}
              >
                <button
                  aria-label="approve-button"
                  className="btn btn-primary btn-sm text-white"
                  disabled={loading || isLoading || isOpenApprove}
                  onClick={openApproveModal}
                >
                  <FontAwesomeIcon color="white" icon={faPenAlt} />
                  Approve
                </button>

                {assignedTask?._id && (
                  <button
                    aria-label="reject-button"
                    className="btn btn-primary btn-sm text-white"
                    disabled={loading || isLoading}
                    onClick={openRejectModal}
                  >
                    <FontAwesomeIcon color="white" icon={faTimesCircle} />{" "}
                    Reject
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="my-auto" style={{ fontSize: "19px" }}>
              You have selected multiple roles. Please select only one role.
            </div>
          )}

          {isOpenApprove && (
            <ModalApprove
              approveId={assignedTask?._id || ""}
              handleTaskSubmit={handleApprove}
              hideLoading={hideLoading}
              isLoading={loading}
              isOpen={isOpenApprove}
              meaning={meaning}
              onCloseModal={closeModal}
              showLoading={showLoading}
            />
          )}
          {assignedTask?._id && (
            <ModalReject
              handleTaskSubmit={handleReject}
              isLoading={loading}
              isOpen={isOpenReject}
              onCloseModal={() => closeModal(false)}
              rejectId={assignedTask?._id}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default ApproveFooter;
