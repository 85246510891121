import {
  IConnection,
  ILinkableConnection,
} from "../../components/interfaces/Connections";

export enum ConnectionDetailsActionTypes {
  FETCHING_CONNECTION_DETAILS = "FETCHING_CONNECTION_DETAILS",
  UPDATE_CONNECTION_DETAILS = "UPDATE_CONNECTION_DETAILS",
  FETCHING_SUPPORTED_SYSTEMS = "FETCHING_SUPPORTED_SYSTEMS",
  UPDATE_SUPPORTED_SYSTEMS = "UPDATE_SUPPORTED_SYSTEMS",
  FETCHING_LINKABLE_CONNECTIONS = "FETCHING_LINKABLE_CONNECTIONS",
  UPDATE_LINKABLE_CONNECTIONS = "UPDATE_LINKABLE_CONNECTIONS",
  HANDLE_CONNECTION_NETWORK_ERROR = "HANDLE_CONNECTION_NETWORK_ERROR",
}

interface HandleNetworkError {
  type: ConnectionDetailsActionTypes.HANDLE_CONNECTION_NETWORK_ERROR;
  payload: string;
}

interface FetchingConnectionDetails {
  type: ConnectionDetailsActionTypes.FETCHING_CONNECTION_DETAILS;
}

interface UpdateConnectionDetails {
  type: ConnectionDetailsActionTypes.UPDATE_CONNECTION_DETAILS;
  payload: IConnection;
}

interface FetchingSupportedSystems {
  type: ConnectionDetailsActionTypes.FETCHING_SUPPORTED_SYSTEMS;
}

interface UpdateSupportedSystems {
  type: ConnectionDetailsActionTypes.UPDATE_SUPPORTED_SYSTEMS;
  payload: string[];
}

interface FetchingLinkableConnections {
  type: ConnectionDetailsActionTypes.FETCHING_LINKABLE_CONNECTIONS;
}

interface UpdateLinkableConnections {
  type: ConnectionDetailsActionTypes.UPDATE_LINKABLE_CONNECTIONS;
  payload: ILinkableConnection[];
}

export type ConnectionDetailsAction =
  | FetchingConnectionDetails
  | UpdateConnectionDetails
  | HandleNetworkError
  | FetchingSupportedSystems
  | UpdateSupportedSystems
  | FetchingLinkableConnections
  | UpdateLinkableConnections;
