import { ISelectDomain } from "../../components/interfaces/PolicySet";
import { ISelectItem } from "../../components/interfaces/UserSelect";

export enum PolicySetDomainsActionTypes {
  FETCH_POLICY_SET_DOMAINS = "FETCH_POLICY_SET_DOMAINS",
  UPDATE_POLICY_SET_DOMAINS = "UPDATE_POLICY_SET_DOMAINS",
  LOAD_MORE_POLICY_SET_DOMAINS = "LOAD_MORE_POLICY_SET_DOMAINS",
  UPDATE_POLICY_SET_SELECTED_DOMAINS = "UPDATE_POLICY_SET_SELECTED_DOMAINS",
  UPDATE_POLICY_SET_LINKED_DOMAINS = "UPDATE_POLICY_SET_LINKED_DOMAINS",
  RESET_POLICY_SET_DOMAINS_DATA = "RESET_POLICY_SET_DOMAINS_DATA",
}

interface FetchPolicySetDomains {
  type: PolicySetDomainsActionTypes.FETCH_POLICY_SET_DOMAINS;
}

interface UpdatePolicySetDomains {
  type: PolicySetDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS;
  payload: {
    domains: ISelectItem[];
    totalDomains: number;
  };
}

interface LoadMorePolicySetDomains {
  type: PolicySetDomainsActionTypes.LOAD_MORE_POLICY_SET_DOMAINS;
  payload: {
    domains: ISelectItem[];
    totalDomains: number;
  };
}

interface UpdatePolicySetSelectedDomains {
  type: PolicySetDomainsActionTypes.UPDATE_POLICY_SET_SELECTED_DOMAINS;
  payload: ISelectDomain[];
}

interface UpdatePolicySetLinkedDomains {
  type: PolicySetDomainsActionTypes.UPDATE_POLICY_SET_LINKED_DOMAINS;
  payload: string[];
}

interface ResetPolicySetDomainsData {
  type: PolicySetDomainsActionTypes.RESET_POLICY_SET_DOMAINS_DATA;
}

export type PolicySetDomainsAction =
  | FetchPolicySetDomains
  | UpdatePolicySetDomains
  | LoadMorePolicySetDomains
  | UpdatePolicySetLinkedDomains
  | UpdatePolicySetSelectedDomains
  | ResetPolicySetDomainsData;
