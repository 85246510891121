import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../reducers";
import { PolicySetsAction, PolicySetsActionTypes } from "../types/policySets";
import { PolicySetsRequestRoutes } from "../../constants/policy-set.constants";
import { ITableData } from "../../components/interfaces/Table";
import { IPolicySetTableDataResponse } from "../../components/interfaces/PolicySet";
import { IDefaultResponse } from "../../components/interfaces/General";
import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";

let cancelToken: CancelTokenSource;
export const fetchPolicySets = () => {
  return async (dispatch: Dispatch<PolicySetsAction>, getState: Function) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();

    const state = getState() as RootState;
    const { offset, ...rest } = state.policySets.sortParams;
    const columnSearchField = state.policySets.columnSearchField;

    const params = {
      ...rest,
      skip: offset,
      name: columnSearchField?.Name,
      domainName: columnSearchField?.Domain,
    };

    try {
      dispatch({ type: PolicySetsActionTypes.FETCHING_POLICY_SETS_TABLE_DATA });

      const { data, status } = await axios.get<
        IDefaultResponse<ITableData<IPolicySetTableDataResponse>>
      >(PolicySetsRequestRoutes.POLICY_SETS, {
        cancelToken: cancelToken.token,
        params,
      });

      if (status === 200) {
        dispatch({
          type: PolicySetsActionTypes.UPDATE_POLICY_SETS_TABLE_DATA,
          payload: {
            ...data.apiData,
            rows: data.apiData.rows.map((row) => ({
              ...row,
              Domains: row.Domains?.map((x) => x.Name)?.join(", "),
            })),
          },
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: PolicySetsActionTypes.HANDLE_POLICY_SETS_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const updatePolicySetsSelectedPage = (selectedPage: number) => {
  return {
    type: PolicySetsActionTypes.UPDATE_POLICY_SETS_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const updatePolicySetsSortParams = (
  sort: string,
  order: "asc" | "desc",
) => {
  return async (dispatch: Dispatch<PolicySetsAction>) => {
    dispatch({
      type: PolicySetsActionTypes.UPDATE_POLICY_SETS_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updatePolicySetsPageParams = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<PolicySetsAction>) => {
    dispatch({
      type: PolicySetsActionTypes.UPDATE_POLICY_SETS_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetPolicySetsSortParams = () => {
  return async (dispatch: Dispatch<PolicySetsAction>) => {
    dispatch({ type: PolicySetsActionTypes.RESET_POLICY_SETS_SORT_PARAMS });
  };
};

export const updatePolicySetsColumnSearchField =
  (columnSearchField: IColumnsSearchFieldsFormData) =>
  async (dispatch: Dispatch<PolicySetsAction>) => {
    dispatch({
      type: PolicySetsActionTypes.UPDATE_POLICY_SETS_COLUMN_SEARCH_FIELD,
      payload: columnSearchField,
    });
  };

export const handleResetPolicySetsData = () => {
  cancelToken && cancelToken.cancel(ErrorMessage.LOGOUT);
  return async (dispatch: Dispatch<PolicySetsAction>) => {
    dispatch({ type: PolicySetsActionTypes.RESET_POLICY_SETS_DATA });
  };
};
