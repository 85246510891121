import * as yup from "yup";
import { generatePasswordRegExp } from "../constants/general.constants";
import {
  INewUserFormData,
  IPasswordPolicyFormData,
} from "../components/interfaces/AdminPage";

function createSchema(
  minLower: number,
  minUpper: number,
  minSpecial: number,
  minNumeric: number,
  minLength: number,
) {
  const newPasswordRules = () => (
    <>
      Your password must contain the following:
      <br />
      <ul className="text-left">
        <li>Between {minLength} and 128 characters</li>
        {minSpecial > 0 && <li>At least {minSpecial} special character</li>}
        {minUpper > 0 && <li>At least {minUpper} uppercase letter</li>}
        {minLower > 0 && <li>At least {minLower} lowercase letter</li>}
        {minNumeric > 0 && <li>At least {minNumeric} number</li>}
      </ul>
    </>
  );
  return yup.object().shape({
    password: yup.lazy((value, props) => {
      if (props.parent?.identityProvider !== "VERA" || value === undefined) {
        return yup.mixed().notRequired();
      } else if (!value) {
        return yup.string().required("Please enter user password.");
      } else {
        return yup
          .string()
          .matches(
            generatePasswordRegExp(
              minLower,
              minUpper,
              minSpecial,
              minNumeric,
              minLength,
            ),
            newPasswordRules,
          );
      }
    }),
    idpUsername: yup.lazy((value, props) => {
      if (props.parent?.identityProvider === "VERA" || value === undefined) {
        return yup.string().notRequired();
      }

      return yup
        .string()
        .required(
          "An IdP username is required when the Identity Provider is not VERA.",
        )
        .matches(
          /^[\da-zA-Z0-9-.+_@]*$/,
          "Valid characters only include: alphanumeric, @, -, _, +, and .",
        );
    }),
    identityProvider: yup.lazy((value) => {
      if (value === undefined) {
        return yup.string().notRequired();
      }

      return yup.string().required("Please select an Identity Provider.");
    }),
    email: yup.lazy((value) => {
      if (value === undefined) {
        return yup.string().notRequired();
      }

      return yup
        .string()
        .required("Please enter a valid email address.")
        .email("Please enter a valid email address.");
    }),
    fullName: yup.lazy((value) => {
      if (value === undefined) {
        return yup.string().notRequired();
      }

      return yup.string().required("Please enter the Full Name.");
    }),
    userName: yup.lazy((value) => {
      if (value === undefined) {
        return yup.string().notRequired();
      }

      return yup
        .string()
        .required("Please enter the username.")
        .matches(
          /^[\da-zA-Z0-9-.+_@]*$/,
          "Valid characters only include: alphanumeric, @, -, _, +, and .",
        );
    }),
  });
}

export const validateNewUserForm = (
  data: INewUserFormData,
  setErrorHandler: any,
  passwordPolicyData: IPasswordPolicyFormData,
) => {
  const { minLower, minUpper, minSpecial, minNumeric, minLength } =
    passwordPolicyData;
  const validateNewUserFormScheme = createSchema(
    minLower,
    minUpper,
    minSpecial,
    minNumeric,
    minLength,
  );

  validateNewUserFormScheme
    .validate(data, { abortEarly: false })
    .then(() => setErrorHandler({}))
    .catch((err) => {
      const inner = err.inner as [];
      const validationErrors = inner.reduce((total, previous: any) => {
        total[previous.path] = previous.message || "";
        return total;
      }, {} as any);
      setErrorHandler(validationErrors);
    });
};
