import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";
import {
  AdminRequestRoutes,
  ProfileRequestRoutes,
  PublicRequestRoute,
} from "../../constants/general.constants";
import {
  IConcurrentUsersRequestParams,
  IConcurrentUsersResponse,
  IConcurrentUsersState,
  IProvider,
  IUserListRequestParams,
  eGroupBy,
  ePeriod,
  eSummarizeBy,
} from "../../components/interfaces/User";
import {
  getLastSevenDays,
  getLastThirtyDays,
  getLastYear,
} from "../../helpers/concurrent-users.helper";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";
import axiosBaseQuery from "../../interceptors/axiosBaseQuery";
import { IUser } from "../../components/interfaces/AdminPage";
import { ITableData } from "../../components/interfaces/Table";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    enableEmailNotifications: builder.mutation<any, string>({
      query: (userId) => ({
        method: "PATCH",
        url: ProfileRequestRoutes.ENABLE_EMAIL_NOTIFICATIONS,
        body: {
          userId,
        },
      }),
    }),
    disableEmailNotifications: builder.mutation<string, string>({
      query: (userId) => ({
        method: "PATCH",
        url: ProfileRequestRoutes.DISABLE_EMAIL_NOTIFICATIONS,
        body: {
          userId,
        },
      }),
    }),
    updateEmailNotificationsByDomain: builder.mutation<
      any,
      { userId: string; domainId: string; enableNotifications: boolean }
    >({
      query: ({ enableNotifications, userId, domainId }) => ({
        method: "PATCH",
        url: ProfileRequestRoutes.UPDATE_EMAIL_NOTIFICATIONS_BY_DOMAIN,
        body: {
          enableNotifications,
          userId,
          domainId,
        },
      }),
    }),
    getConcurrentUsers: builder.query<
      IConcurrentUsersResponse[],
      IConcurrentUsersRequestParams
    >({
      query: (params) => ({
        params,
        url: AdminRequestRoutes.CONCURRENT_USERS,
      }),
    }),
    getProviders: builder.query<IProvider[], void>({
      query: () => ({
        url: PublicRequestRoute.PROVIDERS,
      }),
    }),
    singleSignOn: builder.query<{ url: string }, { idp: string }>({
      query: ({ idp }) => ({
        url: `${AdminRequestRoutes.SINGLE_SIGN_ON}?IdP=${idp}`,
      }),
    }),
    getUsersByFullName: builder.query<
      ITableData<IUser>,
      IUserListRequestParams
    >({
      query: (params) => ({
        url: AdminRequestRoutes.USERS_BY_FULLNAME,
        params: {
          ...params,
          fullName: params.searchTerm,
          limit: 25,
        },
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { skip, ...args } = queryArgs;

        return args;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.skip === 0) {
          return newItems;
        }

        currentCache.rows.push(...newItems.rows);
      },
    }),
  }),
});

const getInitialState = (): IConcurrentUsersState => {
  const date = getLastThirtyDays();

  return {
    groupBy: eGroupBy.DAY,
    period: ePeriod.LAST_THIRTY_DAYS,
    summarizeBy: eSummarizeBy.MAXIMUM,
    ...date,
    errorStartDate: null,
    errorEndDate: null,
    lastData: [],
  };
};

const getDays = (period: ePeriod) => {
  switch (period) {
    case ePeriod.LAST_SEVEN_DAYS:
      return getLastSevenDays();
    case ePeriod.LAST_THIRTY_DAYS:
      return getLastThirtyDays();
    case ePeriod.LAST_YEAR:
      return getLastYear();
    default:
      return null;
  }
};

const userSlice = createSlice({
  name: "user",
  initialState: getInitialState(),
  reducers: {
    setSummarizeBy(state, action: PayloadAction<eSummarizeBy>) {
      state.summarizeBy = action.payload;
    },
    setPeriod(state, action: PayloadAction<ePeriod>) {
      state.period = action.payload;
      const days = getDays(state.period);

      if (days) {
        state.startDate = days.startDate;
        state.endDate = days.endDate;
      }

      state.errorStartDate = null;
      state.errorEndDate = null;
    },
    setStartDate(state, action: PayloadAction<Dayjs | null>) {
      state.startDate = action.payload?.format("YYYY-MM-DD") ?? null;
    },
    setErrorStartDate(state, action: PayloadAction<string | null>) {
      state.errorStartDate = action.payload;
      state.errorEndDate = null;
      state.endDate = null;
    },
    setEndDate(state, action: PayloadAction<Dayjs | null>) {
      state.endDate = action.payload?.format("YYYY-MM-DD") ?? null;
    },
    setErrorEndDate(state, action: PayloadAction<string | null>) {
      state.errorEndDate = action.payload;
    },
    setGroupBy(state, action: PayloadAction<eGroupBy>) {
      state.groupBy = action.payload;
    },
    resetConcurrentUsageData() {
      return getInitialState();
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getConcurrentUsers.matchFulfilled,
      (state, data: PayloadAction<IConcurrentUsersResponse[]>) => {
        state.lastData = data.payload;
      },
    );
  },
});

export const {
  useEnableEmailNotificationsMutation,
  useDisableEmailNotificationsMutation,
  useGetConcurrentUsersQuery,
  useLazyGetUsersByFullNameQuery,
  useGetProvidersQuery,
  useLazySingleSignOnQuery,
  useUpdateEmailNotificationsByDomainMutation,
} = userApi;

export const {
  resetConcurrentUsageData,
  setStartDate,
  setErrorStartDate,
  setEndDate,
  setErrorEndDate,
  setGroupBy,
  setPeriod,
  setSummarizeBy,
} = userSlice.actions;

export default userSlice.reducer;
