import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useActions } from "../../../../hooks/useActions";
import Loader from "../../../modules/Loader";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import { ISignatureVerificationData } from "../../../interfaces/AdminPage";
import SignatureVerificationTable from "./SignatureVerificationTable";

const SignatureVerification = () => {
  const [signatureVerificationData, setSignatureVerificationData] =
    React.useState<ISignatureVerificationData | null>(null);

  const getVerificationsData = async () => {
    try {
      const { data } = await axios.get<ISignatureVerificationData>(
        AdminRequestRoutes.ADMIN,
      );
      if (data.lastVerification && data.nextVerification) {
        setSignatureVerificationData(data);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    getVerificationsData();
  }, []);

  return (
    <div className="mr-4">
      <div
        aria-label="signature-verification"
        className="table-heading-wrapper"
      >
        <b>Signature Verification</b>
      </div>

      {signatureVerificationData ? (
        <>
          <div>
            <b>Last Scan</b>:
            {signatureVerificationData.lastVerification.startTime} (
            {signatureVerificationData.lastVerification.status})
          </div>
          <div>
            <b>Next Scan</b>:
            {signatureVerificationData.nextVerification.startTime} (
            {signatureVerificationData.nextVerification.status})
          </div>

          <SignatureVerificationTable
            failedVerifications={signatureVerificationData.failedVerifications}
          />
        </>
      ) : (
        <Loader message="Loading, please wait..." />
      )}
    </div>
  );
};

export default SignatureVerification;
