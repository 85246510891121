import { UserAction, UserActionTypes, UserState } from "../types/user";

const initialState: UserState = {
  user: null,
  username: null,
  enteredUsername: "",
  loading: false,
  error: null,
  hasActiveSession: false,
  initialization: true,
  profileData: null,
  adminIsAuthenticated: false,
  veraIdp: null,
  browserNotSupported: false,
  idleTimeoutMinutes: 10,
  idlePromptSeconds: 120,
};

export const userReducer = (
  state = initialState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case UserActionTypes.FETCHING_DATA:
      return {
        ...state,
        loading: true,
        profileData: null,
      };

    case UserActionTypes.LOGIN:
      return {
        ...state,
        loading: false,
        error: null,
        enteredUsername: action.payload.username,
        veraIdp: action.payload.veraIdp,
      };

    case UserActionTypes.LOGIN_VERA: {
      const { idlePromptSeconds, idleTimeoutMinutes, ...payload } =
        action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        hasActiveSession: true,
        user: payload,
        username: payload.fullName,
        enteredUsername: "",
        idlePromptSeconds,
        idleTimeoutMinutes,
      };
    }
    case UserActionTypes.RESET_FORM:
      return {
        ...state,
        error: null,
        user: null,
        enteredUsername: "",
      };

    case UserActionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case UserActionTypes.CHECK_ACTIVE_SESSION:
      return {
        ...state,
        initialization: false,
        hasActiveSession: action.payload.hasActiveSession,
        user: action.payload.user,
        username: action.payload.userFullName,
        adminIsAuthenticated: !!action.payload.user?.tokenHasSuperScope,
        veraIdp: action.payload.veraIdp,
        browserNotSupported: action.payload.browserNotSupported,
        idleTimeoutMinutes: action.payload.idleTimeoutMinutes,
        idlePromptSeconds: action.payload.idlePromptSeconds,
      };

    case UserActionTypes.GET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
        loading: false,
      };

    case UserActionTypes.UPDATE_PROFILE_DATA:
      return {
        ...state,
        loading: false,
        username: action.payload,
      };

    case UserActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        loading: false,
        hasActiveSession: false,
        adminIsAuthenticated: false,
        initialization: false,
        username: null,
        enteredUsername: "",
        veraIdp: null,
      };

    case UserActionTypes.GET_ADMIN_DATA:
      return {
        ...state,
        enteredUsername: action.payload.username,
        loading: false,
      };

    case UserActionTypes.ADMIN_LOGIN: {
      const { idlePromptSeconds, idleTimeoutMinutes, ...payload } =
        action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        hasActiveSession: true,
        user: payload,
        username: action.payload.fullName,
        adminIsAuthenticated: true,
      };
    }
    case UserActionTypes.REVERIFY_ADMIN:
      return {
        ...state,
        initialization: false,
        hasActiveSession: action.payload.hasActiveSession,
        user: action.payload.user,
        username: action.payload.userFullName,
        adminIsAuthenticated: false,
        browserNotSupported: action.payload.browserNotSupported,
        veraIdp: action.payload.veraIdp,
        idleTimeoutMinutes: action.payload.idleTimeoutMinutes,
        idlePromptSeconds: action.payload.idlePromptSeconds,
      };

    default:
      return state;
  }
};
