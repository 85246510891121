import { escapeOnlyNonHtmlTags } from "../../../../helpers/html.helper";
import { PrintLinkedRecordsProps } from "../../../interfaces/DetailsPage";
import { downloadFile } from "../../../../helpers/file.helper";
import { useHistory } from "react-router-dom";
import { useActions } from "../../../../hooks/useActions";

const PrintVIewLinkedRecord = ({ linkedRecords }: PrintLinkedRecordsProps) => {
  const history = useHistory();

  const { logout } = useActions();

  const handleOnClickDownload = async (e: any) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLAnchorElement;

    downloadFile({ name: target.download, url: target.href }).catch(
      (response) => {
        if (response.status === 401) {
          logout(history);
        }
      },
    );
  };

  return (
    <>
      {Object.entries(linkedRecords).map((linkedEntries) =>
        linkedEntries[1].map((linkedRecord) => (
          <>
            <h4>
              <span>{linkedEntries[0]}: </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: escapeOnlyNonHtmlTags(linkedRecord.name),
                }}
              ></span>
            </h4>

            <table className="table table-bordered table-sm">
              {Object.entries(linkedRecord.fields).map((field: any) => (
                <tr className="d-flex">
                  <td className="col-2">
                    <b>{field[0]}:</b>
                  </td>
                  <td className="col">
                    <div
                      className="external-content"
                      dangerouslySetInnerHTML={{
                        __html: escapeOnlyNonHtmlTags(field[1]),
                      }}
                    />
                  </td>
                </tr>
              ))}

              {linkedRecord.attachments.length > 0 && (
                <tr className="d-flex">
                  <td className="col" colSpan={2}>
                    <b>Attachments:</b>
                    {linkedRecord.attachments.map((attachment, i) =>
                      attachment.FileType !== "image" ? (
                        <table className="container-fluid mb-2">
                          <tr>
                            <td>
                              <a
                                download={attachment.name}
                                href={attachment.url}
                                onClick={handleOnClickDownload}
                              >
                                {attachment.name}
                              </a>
                            </td>
                          </tr>
                        </table>
                      ) : (
                        <table className="container-fluid">
                          <tr>
                            <td>
                              <img
                                alt={attachment.name}
                                className="mw-100"
                                src={attachment.url}
                              />
                            </td>
                          </tr>
                          <caption>{attachment.name}</caption>
                        </table>
                      ),
                    )}
                  </td>
                </tr>
              )}

              {Object.keys(linkedRecord.linked_records).length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <PrintVIewLinkedRecord
                      linkedRecords={linkedRecord.linked_records}
                    />
                  </td>
                </tr>
              )}
            </table>
          </>
        )),
      )}
    </>
  );
};

export default PrintVIewLinkedRecord;
