import { escapeOnlyNonHtmlTags } from "../../helpers/html.helper";
import { useActions } from "../../hooks/useActions";
import { ITruncatedTextProps } from "../interfaces/TruncatedText";
import Clamp from "react-multiline-clamp";

const TruncatedText = ({
  content,
  title,
  maxLines = 5,
  renderAsHtml = true,
}: ITruncatedTextProps) => {
  const { openExpandedContentModal } = useActions();

  const handleOnClickShowMore = () => {
    openExpandedContentModal({ content, renderAsHtml, title });
  };

  return (
    <Clamp
      lines={maxLines}
      showMoreElement={() => (
        <button
          className="btn-icon btn-show-more-text"
          onClick={handleOnClickShowMore}
        >
          Show more
        </button>
      )}
      withToggle
      withTooltip={false}
    >
      {renderAsHtml ? (
        <div
          className="truncated-content external-content"
          dangerouslySetInnerHTML={{ __html: escapeOnlyNonHtmlTags(content) }}
        ></div>
      ) : (
        <div className="truncated-content">{content}</div>
      )}
    </Clamp>
  );
};

export default TruncatedText;
