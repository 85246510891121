import { IPolicyState } from "../../components/interfaces/Policy";
import { IPolicySetState } from "../../components/interfaces/PolicySet";
import { PolicySetAction, PolicySetActionTypes } from "../types/PolicySet";

export const initialState: IPolicyState = {
  isLoading: false,
  networkError: "",
};

export const policySetReducer = (
  state = initialState,
  action: PolicySetAction,
): IPolicySetState => {
  switch (action.type) {
    case PolicySetActionTypes.FETCHING_POLICY_SET:
      return { ...state, isLoading: true };
    case PolicySetActionTypes.UPDATE_POLICY_SET:
      return {
        ...state,
        isLoading: false,
        policySet: action.payload,
      };
    case PolicySetActionTypes.HANDLE_POLICY_SET_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        networkError: action.payload,
      };
    default:
      return state;
  }
};
