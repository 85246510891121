import { useCallback, useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import dayjs from "dayjs";
import GenericModal from "../GenericModal";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const IdleModal = () => {
  const history = useHistory();
  const { hide, show, visible } = useModal();
  const { logout } = useActions();

  const [isTimedOut, setIsTimedOut] = useState(false);
  const [remaining, setRemaining] = useState<number | undefined>();
  const { hasActiveSession, idleTimeoutMinutes, idlePromptSeconds } =
    useTypedSelector((x) => x.user);

  const timeout = 1000 * idleTimeoutMinutes * 60;
  const promptBeforeIdle = 1000 * idlePromptSeconds;

  const onAction = () => {
    !visible && setIsTimedOut(false);
  };

  const onActive = () => {
    setRemaining(undefined);
    !visible && setIsTimedOut(false);
  };

  const onPrompt = () => {
    if (!isTimedOut && remaining === undefined) {
      show();
      setIsTimedOut(true);
    }
  };

  const onIdle = () => {
    if (isTimedOut) {
      handleLogout();
    }
  };

  const padWithZero = (value: number) => {
    return value.toString().padStart(2, "0");
  };

  const intervalToDuration = (start: number, end: number) => {
    const startTime = dayjs.unix(start / 1000);
    const endTime = dayjs.unix(end / 1000);

    const duration = endTime.diff(startTime, "second");

    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    return {
      minutes: padWithZero(minutes),
      seconds: padWithZero(seconds),
    };
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    crossTab: true,
    timeout,
    promptBeforeIdle,
    onAction,
    onActive,
    onIdle,
    onPrompt,
    startManually: true,
    throttle: 50,
  });

  const handleLogout = () => {
    hide();
    setIsTimedOut(false);
    logout(history, true);
  };

  const handleConfirm = () => {
    hide();
    setIsTimedOut(false);
    setRemaining(0);
    activate();
  };

  const getPromptRemainingTime = useCallback(() => {
    const { minutes, seconds } = intervalToDuration(0, remaining || 0);

    return `${minutes}:${seconds}`;
  }, [remaining]);

  useEffect(() => {
    const interval = setInterval(() => {
      isPrompted() &&
        setRemaining(Math.floor(getRemainingTime() / 1000) * 1000);
    }, 500);

    return () => clearInterval(interval);
  }, [getRemainingTime, isPrompted, remaining]);

  useEffect(() => {
    hasActiveSession && activate();
  }, [hasActiveSession]);

  const promptRemainingTime = getPromptRemainingTime();

  const getTimeUnit = () => {
    return /^([1-9]\d?|0[1-9]):/.test(promptRemainingTime)
      ? "minutes"
      : "seconds";
  };

  return (
    <GenericModal
      labelCancel="I'm Still Here"
      labelConfirm="Log Out Now"
      loading={false}
      onClickConfirm={handleLogout}
      onCloseModal={handleConfirm}
      size="medium"
      title="Inactivity Prompt"
      visible={visible}
    >
      <h6>
        You will be automatically logged out due to inactivity. Click below to
        stay logged in.
      </h6>
      {remaining && remaining > 0 && (
        <p className="idle-modal__remaining-time-message">
          Logout in {promptRemainingTime} {getTimeUnit()}
        </p>
      )}
    </GenericModal>
  );
};

export default IdleModal;
