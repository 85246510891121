import React from "react";
import { SystemDetailsProps } from "../../../interfaces/DetailsPage";

const DraftStatus = ({ record }: SystemDetailsProps) => {
  return (
    <div aria-label="draft-status" className="alert alert-warning" role="alert">
      <span>
        This record is in Draft and will be updated when the record is routed
        for approval. For more information,
        <a
          href={record.locations[0].urls.Browser}
          rel="noreferrer"
          target="_blank"
        >
          click here{" "}
        </a>
        see the record in {record.original_location.system}.
      </span>
    </div>
  );
};

export default DraftStatus;
