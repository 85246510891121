import { useHistory } from "react-router-dom";
import { AdminPageRoutes } from "../../../../constants/general.constants";

const ConnectionsActionBar = () => {
  const history = useHistory();

  const handleOnClickAddConnection = () => {
    history.push(AdminPageRoutes.ADD_CONNECTION);
  };

  return (
    <div>
      <div className="d-flex">
        <button
          className="btn btn-primary btn-sm text-white"
          id="btn-add-connection"
          onClick={(e) => handleOnClickAddConnection()}
        >
          Add Connection
        </button>
      </div>
    </div>
  );
};

export default ConnectionsActionBar;
