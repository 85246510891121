const RevisionWarningFooter = () => {
  return (
    <div
      className="card mx-auto w-50 text-center align-middle fixed-bottom no-print"
      style={{ background: "#E9E653" }}
    >
      <div className="superseded-footer align-middle text-black">
        <i aria-hidden="true" className="fa fa-exclamation-triangle mr-1" />
        This revision has been superseded.
      </div>
    </div>
  );
};

export default RevisionWarningFooter;
