import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { ILoginForm } from "../../../interfaces/LoginPage";
import { LoginPageWarnings } from "../../../../constants/login-page.constants";
import {
  useGetProvidersQuery,
  useLazySingleSignOnQuery,
} from "../../../../store/slices/user.slice";
import { IProvider } from "../../../interfaces/User";
import { Button, TextField } from "@mui/material";
import PasswordField from "../../../modules/PasswordField/PasswordField";
import { LoadingButton } from "@mui/lab";
import { LoginFormContainer, ProvidersContainer } from "./style";
import ButtonDivider from "../ButtonDivider";

const LoginForm = () => {
  const { enteredUsername, loading, error } = useTypedSelector(
    (state) => state.user,
  );
  const [formData, setFormData] = useState<ILoginForm>({
    username: enteredUsername ?? "",
    password: "",
  });

  const { login, loginVera, resetForm } = useActions();

  const { data: providers } = useGetProvidersQuery();
  const [singleSignOn] = useLazySingleSignOnQuery();

  useEffect(() => {
    error &&
      !loading &&
      setFormData((prevState) => ({ ...prevState, password: "" }));
  }, [loading]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!formData.username) {
      toast.warning(LoginPageWarnings.EMPTY_USERNAME_FIELD);
      return;
    } else if (enteredUsername && !formData.password) {
      toast.warning(LoginPageWarnings.EMPTY_PASSWORD_FIELD);
      return;
    }
    enteredUsername
      ? loginVera(enteredUsername, formData.password)
      : login(formData.username);
  };

  const handleProviderLogin = (
    e: React.MouseEvent<HTMLButtonElement>,
    providerId: string,
  ) => {
    e.preventDefault();

    singleSignOn({ idp: providerId })
      .unwrap()
      .then((x) => window.location.replace(x.url));
  };

  const handleReset = () => {
    setFormData({ username: "", password: "" });
    resetForm();
  };

  const getProviderButton = (provider: IProvider) => {
    const buttonDescription = `Login via ${
      provider.name
        ? provider.name
        : providers?.length === 1
          ? "SSO"
          : provider.id
    }`;

    return (
      <Button
        focusRipple={false}
        id={`btn-${buttonDescription.replaceAll(" ", "_")}`}
        onClick={(e) => handleProviderLogin(e, provider.id)}
        variant="outlined"
      >
        {buttonDescription}
      </Button>
    );
  };

  return (
    <LoginFormContainer
      aria-label="login-form"
      className="form-signin login-form"
    >
      <TextField
        disabled={!!enteredUsername}
        id="txt-username"
        label="Username"
        name="username"
        onChange={handleFormChange}
        placeholder="Username"
        size="small"
        value={formData.username || enteredUsername}
      />
      {enteredUsername && (
        <PasswordField
          autoFocus
          id="txt-password"
          label="Password"
          name="password"
          onChange={handleFormChange}
          placeholder="Password"
          size="small"
          type="password"
          value={formData.password}
        />
      )}

      <LoadingButton
        disabled={loading}
        id="btn-login"
        loading={loading}
        onClick={handleLogin}
        type="submit"
        variant="contained"
      >
        {enteredUsername ? "Login" : "Continue"}
      </LoadingButton>

      {enteredUsername && (
        <Button focusRipple={false} id="btn-cancel" onClick={handleReset}>
          Cancel
        </Button>
      )}
      {!enteredUsername && providers && providers?.length > 0 && (
        <>
          <ButtonDivider />
          <ProvidersContainer>
            {providers?.map((x) => getProviderButton(x))}
          </ProvidersContainer>
        </>
      )}
    </LoginFormContainer>
  );
};

export default LoginForm;
