import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { IResetPasswordModalProps } from "../../../../interfaces/AdminPage";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import {
  AdminPageSuccessMessages,
  AdminPageWarnings,
} from "../../../../../constants/admin-page.constants";
import { IconButton } from "@atlaskit/button/new";

const ResetPasswordModal = ({
  isOpen,
  closeModal,
  userData,
  resetPassword,
}: IResetPasswordModalProps) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [clipboardButton, setClipboardButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSecurePassword = async () => {
    try {
      const { data } = await axios.get(AdminRequestRoutes.RESET_PASSWORD);
      setNewPassword(data);
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        closeModal();
      }
    }
  };
  const handleResetPassword = async () => {
    if (newPassword) {
      setLoading(true);
      const isResetPasswordSuccessful = await resetPassword(
        userData._id,
        userData.claims[0].value,
        newPassword,
      );
      if (isResetPasswordSuccessful) {
        setClipboardButton(true);
      }
      setLoading(false);
    } else {
      toast.warning(AdminPageWarnings.PASSWORD_IS_GENERATING);
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(newPassword!);
    toast.success(AdminPageSuccessMessages.CLIPBOARD, {
      autoClose: 8000,
    });
  };

  useEffect(() => {
    getSecurePassword();
  }, []);

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="medium">
          <ModalHeader>
            <ModalTitle>Reset Password</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col">
                <p>The following user's password will be reset:</p>
              </div>
            </div>

            <form>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Name:</label>

                    <input
                      className="form-control"
                      readOnly
                      value={userData.claims[0].value}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>New Password Will Be:</label>

                    <input
                      className="form-control"
                      readOnly
                      value={newPassword}
                    />
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  {!clipboardButton && (
                    <button
                      className="btn btn-primary text-white"
                      onClick={handleResetPassword}
                      type="button"
                    >
                      Complete Reset Password
                      {loading && (
                        <span
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ml-2"
                          role="status"
                        />
                      )}
                    </button>
                  )}

                  {clipboardButton && (
                    <button
                      className="btn btn-primary text-white"
                      onClick={handleCopyToClipboard}
                      type="button"
                    >
                      Copy password to Clipboard
                    </button>
                  )}

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ResetPasswordModal;
