import React, { useEffect } from "react";
import { SystemDetailsProps } from "../../../interfaces/DetailsPage";
import AuthorsModal from "./AuthorsModal";
import AuthorName from "../../../modules/AuthorName";
import { RecordInfo } from "../../../../constants/record.constants";
import { toast } from "react-toastify";
import { shouldDisplayAuthor } from "../../../../helpers/entity-details.helper";

const VeraDetails = ({ record }: SystemDetailsProps) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [isAuthorsModalOpen, setAuthorsModalOpen] = React.useState(false);

  useEffect(() => {
    if (!record.domain) {
      toast.info(RecordInfo.NO_ASSOCIATED_DOMAIN_WARNING);
    }
  }, [record]);

  const shouldDisplayAuthorField = shouldDisplayAuthor(record);

  return (
    <div className="pb-3" id="accordionDetailvera">
      <a
        className="text-decoration-none toggleRotate"
        data-bs-target="#collapseDetailVera"
        data-bs-toggle="collapse"
        href="#!"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <i
          className={
            isCollapsed
              ? "fa fa-chevron-circle-right"
              : "fa fa-chevron-circle-down"
          }
        />
        <h6 className="d-inline details-title"> VERA Details</h6>
      </a>

      <div
        className="collapse show container-fluid pt-2"
        data-bs-parent="#accordionDetailvera"
        id="collapseDetailVera"
      >
        <div className="row" style={{ fontSize: ".8rem" }}>
          <div className="col-xs-12 col-md-6 details-field">
            <label>System:</label>
            <span>{record.original_location?.system}</span>
          </div>

          <div className="col-xs-12 col-md-6 details-field">
            <label>Item Type:</label>
            <span>{record.locations[0].item_type}</span>
          </div>

          <div className="col-xs-12 col-md-6 details-field">
            <label>Record ID:</label>
            <a
              href={record.locations[0].urls.Browser}
              rel="noreferrer"
              target="_blank"
            >
              {record.locations[0].local_id_fields.Key}
            </a>
          </div>

          <div className="col-xs-12 col-md-6 details-field">
            {record.authors && record.authors?.length > 1 ? (
              <>
                {isAuthorsModalOpen && (
                  <AuthorsModal
                    authorsList={record.authors}
                    closeModal={() => setAuthorsModalOpen(false)}
                    isOpen={isAuthorsModalOpen}
                  />
                )}

                <label>Authors:</label>
                <span>
                  <a
                    href="#"
                    onClick={() => setAuthorsModalOpen(true)}
                    role="button"
                  >
                    Show
                  </a>
                </span>
              </>
            ) : (
              <>
                {shouldDisplayAuthorField && (
                  <>
                    <label>Author:</label>
                    <span>
                      {record.authors?.length ? (
                        <AuthorName {...record.authors[0]} />
                      ) : (
                        "Unknown"
                      )}
                    </span>
                  </>
                )}
              </>
            )}
          </div>

          <div className="col-xs-12 col-md-6 details-field">
            <label>Record Type:</label>
            <span>{record.record_type}</span>
          </div>

          <div className="col-xs-12 col-md-6 details-field">
            <label>Record Status:</label>
            <span>{record.status}</span>
          </div>

          <div className="col-xs-12 col-md-6 details-field">
            <label>Revision:</label>
            <span>{record.revision}</span>
          </div>

          <div className="col-xs-12 col-md-6 details-field">
            <strong>
              <label>Domain Name:</label>
            </strong>
            <span>
              {record.domain ? record.domain : "No Associated Domain"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VeraDetails;
