import { Control, Controller } from "react-hook-form";
import Select from "./Select";
import { ISelectItem } from "../../interfaces/UserSelect";

interface IFormSelectProps {
  control: Control<any>;
  disabled?: boolean;
  error?: string;
  inputValue?: string;
  label: string;
  multiple?: boolean;
  name: string;
  onInputChange?: (searchValue: string) => void;
  options: ISelectItem[];
  placeholder: string;
  size?: "medium" | "large";
  warning?: string;
  wasValidated?: boolean;
}

const FormSelect = ({
  control,
  disabled,
  error,
  inputValue,
  label,
  multiple,
  name,
  onInputChange,
  options,
  size = "large",
  placeholder,
  warning,
  wasValidated,
}: IFormSelectProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <Select
          disabled={disabled}
          error={error}
          inputValue={inputValue}
          label={label}
          multiple={multiple}
          name={name}
          onChange={(newValue, action) => {
            onChange(action.name === "clear" ? null : newValue ?? null);
          }}
          onInputChange={onInputChange}
          options={options}
          placeholder={placeholder}
          size={size}
          value={value ?? null}
          warning={warning}
          wasValidated={wasValidated}
        />
      )}
    />
  );
};

export default FormSelect;
