import TextField from "./TextField";
import { Control, Controller } from "react-hook-form";

interface IFormTextFieldProps {
  control: Control<any>;
  controllerName?: string;
  disabled?: boolean;
  error?: string;
  label: string;
  name: string;
  placeholder: string;
  size?: "small" | "medium" | "large";
  type?: "text" | "password";
  wasValidated?: boolean;
}

const FormTextField = ({
  control,
  controllerName,
  disabled,
  error,
  name,
  label,
  placeholder,
  size = "large",
  type = "text",
  wasValidated,
}: IFormTextFieldProps) => {
  return (
    <Controller
      control={control}
      name={controllerName ?? name}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <TextField
          disabled={disabled}
          error={error}
          label={label}
          name={name}
          onBlur={(data: any) => {
            onBlur();
            onChange(data.value?.trim());
          }}
          onChange={(data: any) => onChange(data.value)}
          placeholder={placeholder}
          size={size}
          type={type}
          value={value}
          wasValidated={value && wasValidated}
        />
      )}
    />
  );
};

export default FormTextField;
