import React, { useEffect, useRef } from "react";
import uuid from "react-uuid";
import LinkedRecordTableItem from "./LinkedRecordTableItem";
import { ILinkedRecord } from "../../../../interfaces/DetailsPage";
import axios from "axios";
import {
  DetailsPageRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import { toast } from "react-toastify";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import Loader from "../../../../modules/Loader";

interface ILinkedRecordProps {
  name: string;
  linked_records: ILinkedRecord[];
  recordId: string;
}

const LinkedRecord = ({
  name,
  linked_records,
  recordId,
}: ILinkedRecordProps) => {
  const [isCollapsed, setCollapsed] = React.useState(
    Boolean(!linked_records.length),
  );
  const [records, setRecords] = React.useState({
    rows: linked_records,
    total: linked_records.length,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const targetId = `linkedRecordsGroupDetail_${uuid()}`.replaceAll("-", "_");

  const { data } = useTypedSelector((x) => x.recordDetails);

  const loadRecords = async () => {
    try {
      const { data: response, status } = await axios.get(
        `${DetailsPageRequestRoutes.LINKED_RECORDS}/${recordId}`,
        {
          params: {
            linkName: name,
            limit: 10,
            parentRecordId: data?.record?._id,
            skip: records.rows.length,
            version: data?.record?.version,
          },
        },
      );

      if (status === 200) {
        setRecords((records) => ({
          ...records,
          rows: [...records.rows, ...response.rows],
          total: response.total,
        }));
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClickOnCollapse = () => {
    if (isCollapsed && !records.rows?.length) {
      setIsLoading(true);
    }

    setCollapsed((collapsed) => !collapsed);
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    isLoading && loadRecords();
  }, [isLoading]);

  const handleOnClickShowMore = () => setIsLoading(true);

  const className = React.useMemo(
    () => `container-fluid collapse${records.rows.length ? " show" : ""} pt-2`,
    [],
  );

  return (
    <>
      <a
        className="text-decoration-none toggleRotate"
        data-bs-target={`#${targetId}`}
        data-bs-toggle="collapse"
        href={"#!"}
        onClick={handleOnClickOnCollapse}
      >
        <i
          className={
            isCollapsed
              ? "fa fa-chevron-circle-right"
              : "fa fa-chevron-circle-down"
          }
        />
        <h6 className="d-inline details-title"> {name}</h6>
      </a>

      <div className={className} id={targetId}>
        <div className="table-responsive">
          {Boolean(records.rows?.length) && (
            <>
              <table
                className="table table-sm table-hover linked-table"
                id="table"
              >
                <thead>
                  <tr>
                    <th />
                    {records.rows[0].name && <th>Name</th>}
                    {Object.keys(records.rows[0].fields).map((item) => (
                      <th className="text-nowrap" key={item} scope="col">
                        {item}
                      </th>
                    ))}

                    <th
                      scope="col"
                      style={{ width: "35px", textAlign: "center" }}
                    >
                      <i aria-hidden="true" className="fa fa-paperclip" />
                    </th>

                    <th
                      scope="col"
                      style={{ width: "35px", textAlign: "center" }}
                    >
                      <i aria-hidden="true" className="fa fa-bug" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {records.rows.map((linkedRecord) => (
                    <LinkedRecordTableItem
                      key={linkedRecord._id}
                      linkedRecord={linkedRecord}
                    />
                  ))}
                </tbody>
              </table>
              {records.rows.length !== records.total && !isLoading && (
                <div className="w-100 text-right">
                  <button
                    className="btn btn-primary text-white"
                    onClick={handleOnClickShowMore}
                  >
                    Show More
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {isLoading && <Loader message={"Loading, please wait..."} />}
      </div>
    </>
  );
};

export default LinkedRecord;
