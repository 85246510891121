import * as UserActionCreators from "../actions/user";
import * as ApprovalQueueActionCreators from "../actions/approvalQueue";
import * as LocateRecordFilterActionCreators from "./locateRecordsFilter";
import * as RecordCreators from "./record";
import * as RecordDetailsCreators from "./recordDetails";
import * as SidebarCreators from "./sidebar";
import * as DomainsAndRolesCreators from "./domainsAndRoles";
import * as DomainCreators from "./domain";
import * as ApproveFooterActionCreators from "./approveFooter";
import * as ExpandedContentModalActionCreators from "./expandedContentModal";
import * as ConnectionsCreators from "./connections";
import * as ConnectionCreators from "./connection";
import * as PoliciesCreators from "./policies";
import * as PolicyCreators from "./policy";
import * as LocatePolicyFilterActionCreators from "./locatePolicyFilter";
import * as PolicyRevisionsActionCreators from "./policyRevisions";
import * as PolicySetsActionCreators from "./policySets";
import * as PolicySetLinkedDomainsActionCreators from "./policySetLinkedDomains";
import * as PolicySetDomainsActionCreators from "./policySetDomains";
import * as PolicySetActionCreators from "./policySet";
import * as PolicyLinkedPoliciesCreators from "./policyPolicySets";
import * as AttachmentsModalCreators from "./attachmentsModal";
import * as DefectsModalCreators from "./defectsModal";
import * as UsersCreators from "./users";

const ActionCreators = {
  ...AttachmentsModalCreators,
  ...DefectsModalCreators,
  ...UserActionCreators,
  ...ApprovalQueueActionCreators,
  ...LocateRecordFilterActionCreators,
  ...RecordCreators,
  ...RecordDetailsCreators,
  ...SidebarCreators,
  ...DomainsAndRolesCreators,
  ...DomainCreators,
  ...ApproveFooterActionCreators,
  ...ExpandedContentModalActionCreators,
  ...ConnectionsCreators,
  ...ConnectionCreators,
  ...PoliciesCreators,
  ...PolicyCreators,
  ...LocatePolicyFilterActionCreators,
  ...PolicyRevisionsActionCreators,
  ...PolicySetsActionCreators,
  ...PolicySetActionCreators,
  ...PolicySetLinkedDomainsActionCreators,
  ...PolicySetDomainsActionCreators,
  ...PolicyLinkedPoliciesCreators,
  ...UsersCreators,
};

export default ActionCreators;
