import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../interceptors/axiosBaseQuery";
import {
  AdminRequestRoutes,
  DomainsAndRolesRequestRoutes,
} from "../../constants/general.constants";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDomainFilterResponse } from "../../components/interfaces/DomainsAndRoles";
import {
  IUserRolesTableData,
  IUserRolesTableItem,
} from "../../components/interfaces/AdminPage";
import {
  IColumnsSearchFieldsFormData,
  ISortParams,
} from "../../components/interfaces/ApprovalQueue";
import { TableHeaderItem } from "../types/approvalQueue";
import { IDefaultResponse } from "../../components/interfaces/General";

interface IAddUsersToRoleRequestParams {
  domainId: string | undefined;
  selectedUsers: string[];
  selectedRole: string | undefined;
}

export const roleManagementApi = createApi({
  reducerPath: "roleManagementApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["roleUsers"],
  endpoints: (builder) => ({
    addUsersToRole: builder.mutation<
      IDefaultResponse<any>,
      IAddUsersToRoleRequestParams
    >({
      query: ({ domainId, selectedRole, selectedUsers }) => ({
        method: "POST",
        url: `${AdminRequestRoutes.ADD_USER_DOMAIN}/${domainId}`,
        body: {
          role: selectedRole,
          userIds: selectedUsers,
        },
      }),
      invalidatesTags: ["roleUsers"],
    }),
    getRolesFromDomain: builder.query<string[], string>({
      query: (domainId) => ({
        url: DomainsAndRolesRequestRoutes.ROLES,
        params: {
          domainId,
        },
      }),
    }),
    getRolesUser: builder.query<
      IUserRolesTableData,
      {
        domainId: string | undefined;
        selectedRole: string | undefined;
        sortParams: ISortParams;
        columnSearchField: IColumnsSearchFieldsFormData;
      }
    >({
      query: ({ domainId, selectedRole, columnSearchField, sortParams }) => ({
        url: `${AdminRequestRoutes.ROLE}/${encodeURIComponent(
          selectedRole ?? "",
        )}/domain/${domainId}`,
        params: {
          ...sortParams,
          filter: JSON.stringify(columnSearchField),
        },
      }),
      providesTags: ["roleUsers"],
    }),
    getDomainName: builder.query<IDomainFilterResponse, string>({
      query: (domainId) => ({
        url: DomainsAndRolesRequestRoutes.DOMAINS,
        params: {
          id: domainId,
          limit: 1,
        },
      }),
    }),
    removeUser: builder.mutation<
      void,
      {
        domainId: string | undefined;
        role: string | undefined;
        userIds: string[];
      }
    >({
      query: ({ domainId, role, userIds }) => ({
        method: "POST",
        url: `${AdminRequestRoutes.REMOVE_USER_DOMAIN}/${domainId}`,
        body: {
          role,
          userIds,
        },
      }),
      invalidatesTags: ["roleUsers"],
    }),
  }),
});

interface IRoleUsersTable {
  columnSearchField: IColumnsSearchFieldsFormData;
  selectedPage: number;
  selectedUsers: IUserRolesTableItem[];
  sortParams: ISortParams;
  tableHeaders: TableHeaderItem[];
}

interface IRoleManagementState {
  domainName?: string;
  roles: string[];
  selectedRole?: string;
  searchTerm: string;
  selectedUsers: string[];
  roleUsersTable: IRoleUsersTable;
}

export const initialState: IRoleManagementState = {
  roles: [],
  roleUsersTable: {
    columnSearchField: {},
    selectedPage: 0,
    selectedUsers: [],
    sortParams: {
      sort: "fullName",
      order: "asc",
      offset: 0,
      limit: 10,
    },
    tableHeaders: [
      { label: "Full Name", name: "fullName", filterable: true, colSize: 6 },
      { label: "User ID", name: "userName", filterable: true, colSize: 4 },
    ],
  },
  searchTerm: "",
  selectedUsers: [],
};

const roleManagementSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearSelectedUsers(state) {
      state.selectedUsers = [];
    },
    resetData: () => {
      return initialState;
    },
    selectUsers(state, action: PayloadAction<string>) {
      const set = new Set(state.selectedUsers);
      if (set.has(action.payload)) {
        set.delete(action.payload);
      } else {
        set.add(action.payload);
      }
      state.selectedUsers = Array.from(set);
    },
    selectRoleUsers(
      state,
      action: PayloadAction<{
        newUsers: IUserRolesTableItem[];
        shouldSelect: (id: string) => boolean;
      }>,
    ) {
      const { newUsers, shouldSelect } = action.payload;

      state.roleUsersTable.selectedUsers = newUsers.filter(
        (x) => shouldSelect(x._id) && x.isSelected,
      );
    },
    toggleRoleUserSelection(state, action: PayloadAction<IUserRolesTableItem>) {
      if (
        state.roleUsersTable.selectedUsers.find(
          (x) => x._id === action.payload._id,
        )
      ) {
        state.roleUsersTable.selectedUsers =
          state.roleUsersTable.selectedUsers.filter(
            (x) => x._id !== action.payload._id,
          );
      } else {
        state.roleUsersTable.selectedUsers.push(action.payload);
      }
    },
    updateColumnSearchField(state, action: PayloadAction<{}>) {
      state.roleUsersTable.columnSearchField = action.payload;
      state.roleUsersTable.selectedUsers = [];
    },
    updatePageParams(
      state,
      action: PayloadAction<{ offset: number; limit: number }>,
    ) {
      state.roleUsersTable.sortParams.offset = action.payload.offset;
      state.roleUsersTable.sortParams.limit = action.payload.limit;
      state.roleUsersTable.selectedUsers = [];
    },
    updateSortParams(
      state,
      action: PayloadAction<{ sort: string; order: "asc" | "desc" }>,
    ) {
      state.roleUsersTable.sortParams.sort = action.payload.sort;
      state.roleUsersTable.sortParams.order = action.payload.order;
      state.roleUsersTable.selectedUsers = [];
    },
    updateSelectedPage(state, action: PayloadAction<number>) {
      state.roleUsersTable.selectedPage = action.payload;
    },
    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateSelectedRole(state, action: PayloadAction<string>) {
      state.selectedRole = action.payload;
      state.roleUsersTable = initialState.roleUsersTable;
      state.searchTerm = "";
      state.selectedUsers = [];
    },
    updateDomainName(state, action: PayloadAction<string>) {
      state.domainName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      roleManagementApi.endpoints.addUsersToRole.matchFulfilled,
      (state) => {
        state.selectedUsers = [];
      },
    );
    builder.addMatcher(
      roleManagementApi.endpoints.removeUser.matchFulfilled,
      (state) => {
        state.roleUsersTable.selectedUsers = [];
      },
    );
  },
});

export const {
  useGetDomainNameQuery,
  useGetRolesFromDomainQuery,
  useGetRolesUserQuery,
  useAddUsersToRoleMutation,
  useRemoveUserMutation,
} = roleManagementApi;

export const {
  clearSelectedUsers,
  resetData,
  selectUsers,
  updateColumnSearchField,
  updatePageParams,
  updateSortParams,
  updateSelectedPage,
  updateDomainName,
  updateSearchTerm,
  updateSelectedRole,
  selectRoleUsers,
  toggleRoleUserSelection,
} = roleManagementSlice.actions;

export default roleManagementSlice.reducer;
