import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const VersionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const VersionFooterText = styled.small`
  font-family: "Inter";
`;
