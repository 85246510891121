import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { RecordAttachment } from "../../../../interfaces/DetailsPage";
import { downloadFile } from "../../../../../helpers/file.helper";
import { useHistory } from "react-router-dom";
import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { IconButton } from "@atlaskit/button/new";

const SubAttachmentModal = () => {
  const history = useHistory();
  const { logout } = useActions();
  const { attachments, showModal } = useTypedSelector(
    (x) => x.attachmentsModal,
  );
  const { closeAttachmentsModal } = useActions();

  const handleOnClose = () => {
    closeAttachmentsModal();
  };

  const handleOnClickDownload = async (
    e: any,
    attachment: RecordAttachment,
  ) => {
    e.preventDefault();
    downloadFile(attachment).catch((response) => {
      if (response.status === 401) {
        logout(history);
      }
    });
  };

  return (
    <ModalTransition>
      {showModal && (
        <Modal
          autoFocus={false}
          onClose={handleOnClose}
          shouldScrollInViewport={false}
          width="x-large"
        >
          <ModalHeader>
            <ModalTitle>Attachments</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={handleOnClose}
            />
          </ModalHeader>

          <ModalBody>
            <table className="table" id="attach-table">
              <tbody>
                {attachments.map((attachment) => {
                  if (attachment.FileType === "image") {
                    return (
                      <tr key={attachment._id}>
                        <td>{attachment.name}</td>
                        <td>
                          <a
                            href={attachment.url}
                            onClick={(e) =>
                              handleOnClickDownload(e, attachment)
                            }
                          >
                            <div className="text-center">
                              <img
                                alt={attachment.name}
                                className="img-fluid img-thumbnail"
                                src={attachment.url}
                              />
                              <br />
                              Click here to download.
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={attachment._id}>
                        <td>{attachment.name}</td>
                        <td className="text-center">
                          <a
                            href={attachment.url}
                            onClick={(e) =>
                              handleOnClickDownload(e, attachment)
                            }
                          >
                            Click here to download.
                          </a>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </ModalBody>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default SubAttachmentModal;
