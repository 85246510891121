import dayjs, { Dayjs } from "dayjs";
import utcPlugin from "dayjs/plugin/utc";

dayjs.extend(utcPlugin);

export const getChartOptions = () => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: "Concurrent Users",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        min: 0,
        ticks: {
          precision: 0,
          stepSize: 1,
        },
        title: {
          display: true,
          text: "Concurrent Users",
        },
      },
    },
  };
};

export const getLastSevenDays = () => {
  return getLastDays((endDate: Dayjs) => endDate.subtract(7, "day"));
};

export const getLastThirtyDays = () => {
  return getLastDays((endDate: Dayjs) => endDate.subtract(30, "day"));
};

export const getLastYear = () => {
  return getLastDays((endDate: Dayjs) => endDate.subtract(1, "year"));
};

const getLastDays = (getStartDate: (endDate: Dayjs) => Dayjs) => {
  const endDate = dayjs.utc();

  const startDate = getStartDate(endDate);

  const formattedStartDate = startDate.toISOString();
  const formattedEndDate = endDate.toISOString();

  return { startDate: formattedStartDate, endDate: formattedEndDate };
};
