import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import socketIO from "socket.io-client";
import {
  IModalApproveResultItemProps,
  IModalApproveResultProps,
  IProgressBarModalApproveProps,
} from "../../interfaces/ApprovalQueue";
import { useActions } from "../../../hooks/useActions";

const ProgressBarModalApprove = ({
  setResult,
  showProgressBar,
  onFinish,
}: IProgressBarModalApproveProps) => {
  const sessionID = useTypedSelector(
    (state) => state.approveFooter.approveSessionID,
  );
  const [progress, setProgress] = useState(0);
  const { handleMultipleCheckboxAction } = useActions();

  const unselectApprovedRecords = (result: IModalApproveResultItemProps[]) => {
    const approvedRecords = result.filter((x) => x.isSuccess);
    const shouldUnselectRecords =
      approvedRecords.length > 0 && approvedRecords.length != result.length;

    if (shouldUnselectRecords) {
      const selectedSuccessItems = result
        .filter((x) => x.isSuccess)
        .map((x) => x.id);
      handleMultipleCheckboxAction(selectedSuccessItems);
    }
  };

  useEffect(() => {
    const socket = socketIO({ transports: ["websocket", "polling"] });
    socket.emit("join", { sessionID });

    socket.on("uploadProgress", (data) => {
      setProgress(parseFloat(((data.current * 100) / data.total).toFixed(2)));
    });

    socket.on("uploadResult", (data: IModalApproveResultProps) => {
      setResult(data);
      unselectApprovedRecords(data.result);
      onFinish(data);
    });

    return () => {
      socket.emit("end", sessionID);
      socket.disconnect();
    };
  }, []);

  return (
    <>
      {showProgressBar ? (
        <div className="progress" style={{ margin: "15px 30px 15px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progress}%` }}
          >
            {" "}
            {progress} %
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default ProgressBarModalApprove;
