import { useState } from "react";
import GenericModal from "../../../../modules/GenericModal";
import Textfield from "@atlaskit/textfield";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { AdminPageSuccessMessages } from "../../../../../constants/admin-page.constants";
import {
  ConnectionRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";

interface ITestConnectionModalProps {
  apiURL?: string;
  id?: string;
  system?: string;
  visible: boolean;
  onClose: () => void;
}

const urlBySystem: { [key: string]: string } = {
  JIRA: "/rest/api/2/issue/{Issue ID}",
  qTest: "api/v3/projects/{Project ID}/{Item Type}s/{Entity ID}",
  Tosca: "api/v3/projects/{Project ID}/{Item Type}s/{Entity ID}",
};

interface ITestConnectionResponse {
  testResult: boolean;
  testStatusCode?: number;
  Message: string;
}

const TestConnectionModal = ({
  apiURL,
  id,
  system,
  visible,
  onClose,
}: ITestConnectionModalProps) => {
  const [loading, setLoading] = useState(false);
  const baseUrl = apiURL?.match(/(?:^https?:\/\/([^/]+)(?:[/,]|$)|^(.*)$)/);
  const restUrl = apiURL?.replace(baseUrl ? baseUrl[0] : "", "");

  const [connectionURL, setConnectionURL] = useState(restUrl);

  const handleOnClickTestConnection = async () => {
    try {
      setLoading(true);
      const { data }: AxiosResponse<ITestConnectionResponse> = await axios.post(
        ConnectionRequestRoutes.TEST_CONNECTION,
        {
          testUrl: `/${connectionURL}`,
          id,
        },
      );

      if (data.testResult) {
        toast.success(AdminPageSuccessMessages.TEST_CONNECTION);
      } else {
        toast.error(data.Message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (e: any) => {
    const value = e.currentTarget.value as string;

    setConnectionURL(value);
  };

  return (
    <GenericModal
      labelConfirm="Test Connection"
      loading={loading}
      onClickConfirm={handleOnClickTestConnection}
      onCloseModal={onClose}
      size="x-large"
      title="Test Connection"
      visible={visible}
    >
      <div>
        <p>
          {`To test connection, enter an address for a simple GET call that requires authentication to the target system. 
            The connection will use the API url as the base for the test.`}
        </p>
      </div>
      <Textfield
        className="url-connection-input form-control-container"
        elemBeforeInput={
          <div className="url-domain-test-connection">
            {baseUrl ? baseUrl[0] : ""}
          </div>
        }
        isCompact={true}
        onChange={handleOnChange}
        placeholder={system && urlBySystem[system]}
        type="text"
        value={connectionURL}
      />
    </GenericModal>
  );
};

export default TestConnectionModal;
