import { useState } from "react";
import Tooltip from "@atlaskit/tooltip";
import ReVerifyModal from "./ReVerifyModal";
import { ISignatureVerificationTableItemProps } from "../../../interfaces/AdminPage";
import { unescape } from "lodash";

const SignatureVerificationTableItem = ({
  verificationData,
}: ISignatureVerificationTableItemProps) => {
  const [isOpenReVerifyModal, setOpenReVerifyModal] = useState<boolean>(false);
  const { itemUrl, record, details, message, timestamp, lastSuccessTimestamp } =
    verificationData;

  return (
    <tr>
      <td>
        <a
          aria-label="sig-verification-record-name"
          href={`/records/${record._id}/latest-revision`}
        >
          {unescape(record.name)}
        </a>
      </td>
      <td>{details.reviewer}</td>
      <td>{details.meaning}</td>
      <td>{record.recordType}</td>
      <td>{record.revision}</td>
      <td>{message}</td>
      <td>{timestamp}</td>
      <td>{lastSuccessTimestamp}</td>
      <td>
        <div className="table-actions">
          {isOpenReVerifyModal && (
            <ReVerifyModal
              closeModal={() => setOpenReVerifyModal(false)}
              isOpen={isOpenReVerifyModal}
              verificationData={verificationData}
            />
          )}

          <Tooltip content="Re-verify" position="top">
            <a
              aria-label="re-verify-btn"
              href="#"
              onClick={() => setOpenReVerifyModal(true)}
              role="button"
            >
              <i className="fa fa-refresh cursor-pointer" />
            </a>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

export default SignatureVerificationTableItem;
