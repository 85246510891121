import { IPolicyTableState } from "../../components/interfaces/Policy";
import { PoliciesAction, PoliciesActionTypes } from "../types/policies";

export const initialState: IPolicyTableState = {
  columnSearchField: {},
  tableData: {
    rows: [],
    total: 0,
  },
  selectedPage: 0,
  sortParams: {
    sort: "Name",
    order: "asc",
    offset: 0,
    limit: 15,
  },
  isLoading: false,
  tableHeaders: [
    { label: "Type", name: "Type", filterable: true, colSize: 3 },
    { label: "Name", name: "Name", filterable: true, colSize: 5 },
    { label: "Revision", name: "Revision", filterable: true, colSize: 1 },
    {
      label: "Owner",
      name: "PolicyOwnerFullName",
      filterable: true,
      colSize: 3,
    },
  ],
  networkError: "",
};

export const policiesReducer = (
  state = initialState,
  action: PoliciesAction,
): IPolicyTableState => {
  switch (action.type) {
    case PoliciesActionTypes.FETCHING_POLICY_TABLE_DATA:
      return { ...state, isLoading: true };
    case PoliciesActionTypes.UPDATE_POLICY_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload,
      };
    case PoliciesActionTypes.UPDATE_POLICY_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case PoliciesActionTypes.UPDATE_POLICY_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case PoliciesActionTypes.UPDATE_POLICY_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case PoliciesActionTypes.RESET_POLICY_DATA:
      return initialState;
    case PoliciesActionTypes.RESET_POLICY_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case PoliciesActionTypes.HANDLE_POLICIES_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        networkError: action.payload,
      };
    default:
      return state;
  }
};
