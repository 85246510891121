import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ButtonDividerContainer = styled(Box)`
  align-items: center;
  display: flex;
  margin: 12px 0;

  & > span {
    font-size: 1rem;
    padding: 0 20px;
  }

  & > hr {
    border: none;
    border-top: 1px solid #ddd;
    flex-grow: 1;
    margin: 0;
  }
`;
