import axios, { CancelTokenSource } from "axios";
import {
  DetailsPageRequestRoutes,
  ErrorMessage,
  AppRoutes,
} from "../../constants/general.constants";
import { Dispatch } from "redux";
import {
  RecordDetailsAction,
  RecordDetailsActionTypes,
} from "../types/recordDetail";
import { toast } from "react-toastify";
import {
  HistoryRecord,
  IDetailsPageDataResponse,
  IRecord,
  IRecordRevision,
  IRoute,
} from "../../components/interfaces/DetailsPage";
import { DetailsRoutes } from "../../constants/details-page.constants";
import { RootState } from "../reducers";
import { AppDispatch } from "../store";

let cancelToken: CancelTokenSource;

export const fetchRecordDetails = (
  history: any,
  routeId: string | undefined,
  recordId: string | undefined,
  revision: string | undefined,
  menuSelector: string | undefined,
  refetch?: boolean,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    cancelToken = axios.CancelToken.source();

    const isPrintView = menuSelector === DetailsRoutes.PRINT_VIEW;

    const state = getState();

    const { route, record, fullRecord, latestRevision } =
      state.recordDetails.data ?? {};

    const recordWasLoaded = (record: IRecord | undefined) =>
      (routeId && route?._id === routeId && record) ||
      (!routeId &&
        record?._id === recordId &&
        (record?.revision === revision ||
          (record?.revision === latestRevision &&
            revision === "latest-revision")));

    const url = routeId
      ? `${DetailsPageRequestRoutes.DETAILS}/${routeId}/${recordId}`
      : `${DetailsPageRequestRoutes.RECORDS}/${recordId}${
          revision ? `/${revision}` : ""
        }`;

    if (!refetch) {
      if (
        (isPrintView && recordWasLoaded(fullRecord)) ||
        (!isPrintView && recordWasLoaded(record))
      ) {
        return;
      }
    }

    try {
      dispatch({ type: RecordDetailsActionTypes.FETCHING_RECORD_DETAILS });

      const { data, status }: IDetailsPageDataResponse = await axios.get(url, {
        params: {
          fullRecord: isPrintView,
        },
      });
      if (status === 200) {
        const error = data.errorMessage;
        if (error) {
          toast.error(error);
          history.push(AppRoutes.ApprovalQueue);
          return;
        }

        if (menuSelector === DetailsRoutes.PRINT_VIEW) {
          dispatch({
            type: RecordDetailsActionTypes.UPDATE_FULL_RECORD,
            payload: data,
          });
        } else {
          dispatch({
            type: RecordDetailsActionTypes.UPDATE_RECORD_DETAILS,
            payload: data,
          });
        }
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.message ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: RecordDetailsActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
        history.push(AppRoutes.ApprovalQueue);
      }
    }
  };
};

let recordHistoryCancelToken: CancelTokenSource;

export const fetchRecordHistory = (recordId: string | undefined) => {
  return async (dispatch: Dispatch<RecordDetailsAction>) => {
    if (typeof recordHistoryCancelToken != typeof undefined) {
      recordHistoryCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    recordHistoryCancelToken = axios.CancelToken.source();

    try {
      dispatch({ type: RecordDetailsActionTypes.FETCHING_RECORD_HISTORY });

      const { data, status } = await axios.get<HistoryRecord[]>(
        `${DetailsPageRequestRoutes.HISTORY}/${recordId}`,
      );

      if (status === 200) {
        dispatch({
          type: RecordDetailsActionTypes.UPDATE_RECORD_HISTORY,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.message ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: RecordDetailsActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

let recordRevisionsCancelToken: CancelTokenSource;
export const fetchRecordRevisions = (recordId: string | undefined) => {
  return async (dispatch: Dispatch<RecordDetailsAction>) => {
    if (typeof recordRevisionsCancelToken != typeof undefined) {
      recordRevisionsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    recordRevisionsCancelToken = axios.CancelToken.source();

    try {
      dispatch({ type: RecordDetailsActionTypes.FETCHING_RECORD_REVISIONS });

      const { data, status } = await axios.get<IRecordRevision[]>(
        `${DetailsPageRequestRoutes.RECORD_REVISIONS}/${recordId}`,
      );

      if (status === 200) {
        dispatch({
          type: RecordDetailsActionTypes.UPDATE_RECORD_REVISIONS,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.message ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: RecordDetailsActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

let approvalRoutesCancelToken: CancelTokenSource;
export const fetchApprovalRoutes = (recordId: string | undefined) => {
  return async (dispatch: Dispatch<RecordDetailsAction>) => {
    if (typeof approvalRoutesCancelToken != typeof undefined) {
      approvalRoutesCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }

    approvalRoutesCancelToken = axios.CancelToken.source();

    try {
      dispatch({ type: RecordDetailsActionTypes.FETCHING_APPROVAL_ROUTES });

      const { data, status } = await axios.get<IRoute[]>(
        `${DetailsPageRequestRoutes.APPROVAL_ROUTES}/${recordId}`,
      );

      if (status === 200) {
        dispatch({
          type: RecordDetailsActionTypes.UPDATE_APPROVAL_ROUTES,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.message ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: RecordDetailsActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
      }
      dispatch({
        type: RecordDetailsActionTypes.HANDLE_APPROVAL_ROUTES_ERROR,
      });
    }
  };
};

export const resetRecordDetailsData = () => {
  return async (dispatch: Dispatch<RecordDetailsAction>) => {
    dispatch({ type: RecordDetailsActionTypes.RESET_RECORD_DETAILS_DATA });
  };
};
