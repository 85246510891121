import { useGetVersionQuery } from "../../../../store/slices/version.slice";
import CopyrightMessage from "../../../modules/CopyrightMessage";
import { VersionFooterText, VersionsContainer } from "./style";

const Versions = () => {
  const { data } = useGetVersionQuery();

  return (
    <VersionsContainer>
      {data?.version === null ? (
        <VersionFooterText
          className="text-danger font-weight-bold"
          id="version-footer-txt"
        >
          *** Vera API Server Unreachable ***
        </VersionFooterText>
      ) : (
        <VersionFooterText id="version-footer-txt">
          Vera {data?.version ?? ""}
        </VersionFooterText>
      )}
      <VersionFooterText>
        <CopyrightMessage />
      </VersionFooterText>
    </VersionsContainer>
  );
};

export default Versions;
