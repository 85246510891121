import axios, { CancelToken } from "axios";
import { AdminRequestRoutes } from "../../constants/general.constants";
import { IUser } from "../interfaces/AdminPage";
import { ITableData } from "../interfaces/Table";

export class UserService {
  static getAll = async (
    keyword: string,
    cancelToken?: CancelToken,
    onlyAdmin = false,
  ) => {
    const params = {
      fullName: keyword,
      limit: 25,
    };

    return await axios.get<ITableData<IUser>>(
      AdminRequestRoutes.USERS_BY_FULLNAME,
      {
        cancelToken: cancelToken,
        params: {
          ...params,
          onlyAdmin: onlyAdmin ? onlyAdmin : undefined,
        },
      },
    );
  };
}
