import PoliciesTable from "./PoliciesTable";

const Policies = () => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="table-heading-wrapper mb-3">
          <b>Policies</b>
        </div>
      </div>

      <PoliciesTable />
    </div>
  );
};

export default Policies;
