import SummarizeSelect from "./components/SummarizeSelect";
import PeriodSelect from "./components/PeriodSelect";
import ConcurrentUsersGraph from "./components/ConcurrentUsageGraph";
import ConcurrentUserRangeDate from "./components/ConcurrentUsageRangeDate";
import GroupBySelect from "./components/GroupBySelect";
import { useEffect } from "react";
import { resetConcurrentUsageData } from "../../../../../store/slices/user.slice";
import { useAppDispatch } from "../../../../../store/store";
import ExportReportButton from "./components/ExportReportButton";

const ConcurrentUserReport = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetConcurrentUsageData());
    };
  }, []);

  return (
    <>
      <div className="concurrent-users-report">
        <div className="table-heading-wrapper mb-3">
          <b>Concurrent Usage</b>
        </div>
        <div className="concurrent-users-report-filter mb-3">
          <SummarizeSelect />
          <GroupBySelect />
          <PeriodSelect />
          <ConcurrentUserRangeDate />
          <ExportReportButton />
        </div>
      </div>
      <ConcurrentUsersGraph />
    </>
  );
};

export default ConcurrentUserReport;
