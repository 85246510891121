import {
  ApprovalQueueAction,
  ApprovalQueueActionTypes,
} from "../types/approvalQueue";
import { Dispatch } from "redux";
import axios, { CancelTokenSource } from "axios";
import {
  IColumnsSearchFieldsFormData,
  IRecordData,
  ISortParams,
  ITableDataResponse,
} from "../../components/interfaces/ApprovalQueue";
import { toast } from "react-toastify";
import {
  ApprovalPageRequestRoutes,
  ErrorMessage,
} from "../../constants/general.constants";
import { UserAction } from "../types/user";
import { RootState } from "../reducers";

let cancelToken: CancelTokenSource;
const checkUniqueMeaning = (
  selectedItems: IRecordData[],
  meaning: string | undefined,
) => {
  return meaning !== undefined
    ? selectedItems.every((item) => item.Meaning === meaning)
    : true;
};

export const fetchTableData = (
  sortParams: ISortParams,
  searchQuery: {},
  selectedIds?: string[],
) => {
  return async (dispatch: Dispatch<ApprovalQueueAction | UserAction>) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();

    try {
      dispatch({ type: ApprovalQueueActionTypes.FETCHING_TABLE_DATA });

      const { data, status } = await axios.get<ITableDataResponse>(
        ApprovalPageRequestRoutes.TASKS_LIST,
        {
          cancelToken: cancelToken.token,
          params: { ...sortParams, ...searchQuery },
        },
      );
      if (status === 200) {
        dispatch({
          type: ApprovalQueueActionTypes.UPDATE_TABLE_DATA,
          payload: {
            tableData: data,
            rowsData: data.rows.map((item: IRecordData) => ({
              ...item,
              isSelected: selectedIds?.includes(item._id),
            })),
          },
        });
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: ApprovalQueueActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const handleMultipleCheckboxAction = (selectedIds: string[]) => {
  return async (
    dispatch: Dispatch<ApprovalQueueAction>,
    getState: () => RootState,
  ) => {
    const rowsData = getState().approvalQueue.rowsData;

    const result = rowsData
      ? rowsData.map((item) =>
          selectedIds.includes(item._id)
            ? {
                ...item,
                isSelected: !item.isSelected,
              }
            : item,
        )
      : null;

    const selectedItems = result
      ? result.filter((item) => item.isSelected)
      : [];
    const meaning = selectedItems.length ? selectedItems[0].Meaning : undefined;

    dispatch({
      type: ApprovalQueueActionTypes.UPDATE_ROWS_DATA,
      payload: {
        result,
        selectedItems,
        selectedMeaningsAreUnique: checkUniqueMeaning(selectedItems, meaning),
      },
    });
  };
};

export const handleCheckBoxAction = (selectedId: string) => {
  return async (
    dispatch: Dispatch<ApprovalQueueAction>,
    getState: () => RootState,
  ) => {
    const rowsData = getState().approvalQueue.rowsData;

    const result = rowsData
      ? rowsData.map((item) =>
          item._id === selectedId
            ? {
                ...item,
                isSelected: !item.isSelected,
              }
            : item,
        )
      : null;

    const selectedItems = result
      ? result.filter((item) => item.isSelected)
      : [];
    const meaning = selectedItems.length ? selectedItems[0].Meaning : undefined;

    dispatch({
      type: ApprovalQueueActionTypes.UPDATE_ROWS_DATA,
      payload: {
        result,
        selectedItems,
        selectedMeaningsAreUnique: checkUniqueMeaning(selectedItems, meaning),
      },
    });
  };
};

export const unselectRows =
  () => async (dispatch: Dispatch<ApprovalQueueAction>) => {
    dispatch({ type: ApprovalQueueActionTypes.UNSELECT_ROWS });
  };

export const updateColumnSearchField =
  (columnSearchField: IColumnsSearchFieldsFormData) =>
  async (dispatch: Dispatch<ApprovalQueueAction>) => {
    dispatch({
      type: ApprovalQueueActionTypes.UPDATE_COLUMN_SEARCH_FIELD,
      payload: columnSearchField,
    });
  };

export const handleBulkCheckboxSelectAction = (rowsData: IRecordData[]) => {
  return async (dispatch: Dispatch<ApprovalQueueAction>) => {
    const selectedItems = rowsData.filter((item) => item.isSelected);
    const meaning = selectedItems[0]?.Meaning;

    dispatch({
      type: ApprovalQueueActionTypes.UPDATE_ROWS_DATA,
      payload: {
        result: rowsData,
        selectedItems,
        selectedMeaningsAreUnique: checkUniqueMeaning(selectedItems, meaning),
      },
    });
  };
};

export const updateAllSortParams = (sortParams: ISortParams) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ApprovalQueueActionTypes.UPDATE_ALL_SORT_PARAMS,
      payload: sortParams,
    });
  };
};

export const updateSortParams = (sort: string, order: "asc" | "desc") => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ApprovalQueueActionTypes.UPDATE_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updatePageParams = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ApprovalQueueActionTypes.UPDATE_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const updateSelectedPage = (selectedPage: number) => {
  return {
    type: ApprovalQueueActionTypes.UPDATE_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const handleResetData = () => {
  cancelToken && cancelToken.cancel(ErrorMessage.LOGOUT);
  return { type: ApprovalQueueActionTypes.RESET_DATA };
};
