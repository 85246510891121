import {
  IRecordData,
  ISortParams,
  IApprovalQueueTableData,
  IColumnsSearchFieldsFormData,
} from "../../components/interfaces/ApprovalQueue";

export interface TableHeaderItem {
  label: string;
  name: string;
  value?: string;
  filterable?: boolean;
  notSearchable?: boolean;
  notSortable?: boolean;
  colSize?: number;
}

export interface ApprovalQueueState {
  tableData: IApprovalQueueTableData | null;
  rowsData: IRecordData[] | null;
  sortParams: ISortParams;
  searchField: boolean;
  selectedPage: number;
  selectedItems: IRecordData[] | [];
  selectedMeaningsAreUnique: boolean | undefined;
  isLoading: boolean;
  tableHeaders: TableHeaderItem[];
  networkError: string;
  columnSearchField: IColumnsSearchFieldsFormData;
}

export enum ApprovalQueueActionTypes {
  FETCHING_TABLE_DATA = "FETCHING_TABLE_DATA",
  UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA",
  UPDATE_ROWS_DATA = "UPDATE_ROWS_DATA",
  UPDATE_ALL_SORT_PARAMS = "UPDATE_ALL_SORT_PARAMS",
  UPDATE_SORT_PARAMS = "UPDATE_SORT_PARAMS",
  UPDATE_PAGE_PARAMS = "UPDATE_PAGE_PARAMS",
  UPDATE_SELECTED_PAGE = "UPDATE_SELECTED_PAGE",
  UPDATE_COLUMN_SEARCH_FIELD = "UPDATE_COLUMN_SEARCH_FIELD",
  HANDLE_NETWORK_ERROR = "HANDLE_NETWORK_ERROR",
  UNSELECT_ROWS = "UNSELECT_ROWS",
  RESET_DATA = "RESET_DATA",
}

interface FetchingData {
  type: ApprovalQueueActionTypes.FETCHING_TABLE_DATA;
}

interface UpdateTableData {
  type: ApprovalQueueActionTypes.UPDATE_TABLE_DATA;
  payload: {
    tableData: IApprovalQueueTableData;
    rowsData: IRecordData[];
  };
}

interface UpdateRowsData {
  type: ApprovalQueueActionTypes.UPDATE_ROWS_DATA;
  payload: {
    result: IRecordData[] | null;
    selectedItems: IRecordData[] | [];
    selectedMeaningsAreUnique: boolean | undefined;
  };
}

interface UpdateAllSortParams {
  type: ApprovalQueueActionTypes.UPDATE_ALL_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
    offset: number;
    limit: number;
  };
}

interface UpdateSortParams {
  type: ApprovalQueueActionTypes.UPDATE_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdatePageParams {
  type: ApprovalQueueActionTypes.UPDATE_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface UpdateSelectedPage {
  type: ApprovalQueueActionTypes.UPDATE_SELECTED_PAGE;
  payload: number;
}

interface HandleNetworkError {
  type: ApprovalQueueActionTypes.HANDLE_NETWORK_ERROR;
  payload: string;
}

interface UnselectRows {
  type: ApprovalQueueActionTypes.UNSELECT_ROWS;
}

interface UpdateColumnSearchField {
  type: ApprovalQueueActionTypes.UPDATE_COLUMN_SEARCH_FIELD;
  payload: IColumnsSearchFieldsFormData;
}

interface HandleResetData {
  type: ApprovalQueueActionTypes.RESET_DATA;
}

export type ApprovalQueueAction =
  | UpdateTableData
  | FetchingData
  | UpdateRowsData
  | UpdateAllSortParams
  | UpdateSortParams
  | UpdatePageParams
  | UpdateSelectedPage
  | HandleNetworkError
  | HandleResetData
  | UpdateColumnSearchField
  | UnselectRows;
