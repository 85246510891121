import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { PropsWithChildren } from "react";
import { IGenericModalProps } from "../interfaces/GenericModal";
import { IconButton } from "@atlaskit/button/new";

const GenericModal = ({
  children,
  onCloseModal,
  labelConfirm,
  labelCancel = "Cancel",
  loading,
  onClickCancel,
  onClickConfirm,
  size,
  title,
  visible,
}: PropsWithChildren<IGenericModalProps>) => {
  const handleOnClickCancel = () => {
    onClickCancel && onClickCancel();
    onCloseModal && onCloseModal();
  };

  const click = () => {
    onClickConfirm && onClickConfirm();
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal
          autoFocus={false}
          onClose={onCloseModal}
          shouldCloseOnOverlayClick={false}
          shouldScrollInViewport={true}
          width={size}
        >
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={onCloseModal}
            />
          </ModalHeader>

          <ModalBody>
            <div className="generic-modal-body">{children}</div>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  {labelConfirm && (
                    <button
                      aria-label="modal-confirm-btn"
                      className="btn btn-primary text-white"
                      disabled={loading}
                      id="btn-modal-confirm"
                      onClick={click}
                      type="button"
                    >
                      {labelConfirm}
                      {loading && (
                        <span
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ml-2"
                          role="status"
                        />
                      )}
                    </button>
                  )}

                  <button
                    className="btn btn-primary text-white ml-2"
                    id="btn-modal-cancel"
                    onClick={handleOnClickCancel}
                    type="button"
                  >
                    {labelCancel}
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default GenericModal;
