import Checkbox from "@atlaskit/checkbox";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import DropdownDomains from "./DropdownDomains";

const DomainsAndRolesFilter = () => {
  const {
    clearAllDomainsAndRolesFilters,
    handleResetDomainAndRolesSortParams,
    handleResetDomainsAndRolesData,
    toggleShowAllDomains,
  } = useActions();

  const {
    isLoadingDomains,
    isLoadingRoles,
    isLoadingDomainsAndRoles,
    showAllDomains,
  } = useTypedSelector((state) => state.domainsAndRoles);

  const handleOnClickSearch = () => {
    handleResetDomainAndRolesSortParams();
  };

  const reset = () => {
    clearAllDomainsAndRolesFilters();
    handleResetDomainsAndRolesData();
  };

  return (
    <div className="d-flex align-items-center domain-filter-actions">
      <DropdownDomains />
      <div className="checkbox show-all-domains">
        <Checkbox
          aria-label="bulk-checkbox"
          isChecked={showAllDomains}
          isDisabled={
            isLoadingDomains || isLoadingRoles || isLoadingDomainsAndRoles
          }
          label="Show all domains"
          name="showAllDomains"
          onChange={() => toggleShowAllDomains()}
          value="showAllDomains"
        />
      </div>
      <button
        className="btn btn-primary btn-sm text-white"
        id="search-domains-and-roles-btn"
        onClick={handleOnClickSearch}
      >
        Search
      </button>
      <button
        className="btn btn-primary btn-sm text-white"
        id="reset-domains-and-roles-btn"
        onClick={() => reset()}
      >
        Reset
      </button>
    </div>
  );
};

export default DomainsAndRolesFilter;
