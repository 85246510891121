import {
  IConnection,
  IConnectionFormData,
  IConnectionRequest,
  IConnectionResponse,
} from "../../../../interfaces/Connections";
import { useForm, useFieldArray } from "react-hook-form";
import FormTextField from "../../../../modules/common/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSchema } from "../../../../../validators/connectionsFormValidator";
import FormSelect from "../../../../modules/common/FormSelect";
import { useEffect, useMemo } from "react";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";
import LinkedConnection from "./LinkedConnection";
import {
  authModeOptions,
  transformConnectionForm,
  transformConnectionResponse,
} from "../../../../../helpers/connections.helper";
import ConnectionAlias from "./ConnectionAlias";
import { getNextNumber, getPropertiesName } from "../../../../../helpers";
import TestConnectionModal from "./TestConnectionModal";
import useModal from "../../../../../hooks/useModal";
import Form from "../../../../modules/common/Form";
import { useRef } from "react";

interface IConnectionDetailsForm {
  data: Partial<IConnectionFormData>;
  labelSaveButton: string;
  labelCancelChangesButton: string;
  title: (data: Partial<IConnection>) => string;
  onSubmit: (
    data: IConnectionRequest,
  ) => Promise<IConnectionResponse | void> | undefined;
}

const ConnectionDetailsForm = ({
  data,
  labelCancelChangesButton,
  labelSaveButton,
  onSubmit,
  title,
}: IConnectionDetailsForm) => {
  const { supportedSystems } = useTypedSelector((x) => x.connection);

  const { fetchSupportedSystems, fetchLinkableConnections } = useActions();
  const {
    control,
    handleSubmit,
    formState: {
      dirtyFields,
      errors,
      isSubmitting,
      isSubmitted,
      isDirty,
      touchedFields,
    },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    mode: "all",
    defaultValues: { ...data },
    resolver: yupResolver(createSchema()),
  });

  const { append, remove } = useFieldArray({
    name: "Aliases",
    control,
  });

  const { visible, show, hide } = useModal();

  const aliases = watch("Aliases");
  const system = watch("System");
  const authMode = watch("AuthMode");
  const linkedConnection = watch("LinkedConnections");
  const apiURL = watch("Urls.API");

  const systems = useMemo(() => {
    const systems =
      system?.value && !supportedSystems.includes(system.value)
        ? [...supportedSystems, system.value]
        : supportedSystems;

    return systems.map((x) => ({ label: x, value: x }));
  }, [system, supportedSystems]);

  const handleOnSubmit = async (data: IConnectionFormData) => {
    const payload = transformConnectionForm(data);

    if (!dirtyFields.ServicePassword) {
      delete payload.ServicePassword;
    }

    const result = await onSubmit(payload);
    result && reset(transformConnectionResponse(result));
  };

  const handleAddAlias = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    append({ id: getNextNumber(aliases?.map((x) => x.id)), value: "" });
  };

  const handleAddLinkedConnection = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setValue("LinkedConnections", null, { shouldDirty: true });
  };

  const handleRemoveLinkedConnection = () => {
    setValue("LinkedConnections", undefined, { shouldDirty: true });
  };

  const handleResetFields = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    reset();
  };

  const handleRemoveAlias = (index: number) => {
    remove(index);
  };

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    reset(data);
    Object.keys(data).length && trigger();
  }, [data]);

  useEffect(() => {
    !supportedSystems.length && fetchSupportedSystems();
    fetchLinkableConnections();
  }, []);

  useEffect(() => {
    const obj = isSubmitted
      ? {
          ...touchedFields,
          Urls: {
            Browser: true,
            API: true,
          },
        }
      : touchedFields;
    trigger(getPropertiesName(obj));
  }, [system, isSubmitted]);

  return (
    <div>
      <div className="table-heading-wrapper">
        <b>{title({ System: system?.value })}</b>
      </div>
      <Form
        aria-label="profile-form"
        noValidate
        onSubmit={handleSubmit((data) => handleOnSubmit(data))}
        style={{ maxWidth: "800px" }}
      >
        <FormSelect
          control={control}
          disabled={Boolean(data._id)}
          error={errors.System?.message}
          label="System:"
          name="System"
          options={systems}
          placeholder="Select System"
          wasValidated={!!system && !errors.System}
        />

        <FormTextField
          control={control}
          error={errors.Name?.message}
          label={"Name: "}
          name={"Name"}
          placeholder="Enter name"
          wasValidated={!errors.Name}
        />

        <FormTextField
          control={control}
          error={errors.Server?.message}
          label={"Server: "}
          name={"Server"}
          placeholder="Enter server"
          wasValidated={!errors.Server}
        />

        {system?.value !== "VERA" && (
          <FormTextField
            control={control}
            error={errors.ServiceAccount?.message}
            label={"Service Account: "}
            name={"ServiceAccount"}
            placeholder="Enter service account"
            wasValidated={!errors.ServiceAccount}
          />
        )}

        {system?.value !== "VERA" && (
          <FormTextField
            control={control}
            error={errors.ServicePassword?.message}
            label="Service Password: "
            name="ServicePassword"
            placeholder="Enter service password"
            type="password"
            wasValidated={!errors.ServicePassword}
          />
        )}

        <FormTextField
          control={control}
          error={errors.Urls?.Browser?.message}
          label={"Browser URL: "}
          name={"Urls.Browser"}
          placeholder="Enter Browser URL"
          wasValidated={!errors.Urls?.Browser}
        />

        <FormTextField
          control={control}
          error={errors.Urls?.API?.message}
          label={"API URL: "}
          name={"Urls.API"}
          placeholder="Enter API URL"
          wasValidated={!errors.Urls?.API}
        />

        {system?.value === "qTest" && (
          <FormTextField
            control={control}
            error={errors.qTestName?.message}
            label={"qTest Name: "}
            name={"qTestName"}
            placeholder="Enter qTest Name"
            wasValidated={!errors.qTestName}
          />
        )}

        {system?.value === "Tosca" && (
          <FormSelect
            control={control}
            error={errors.AuthMode?.message}
            label="Auth Mode: "
            name="AuthMode"
            options={authModeOptions}
            placeholder="Select Auth Mode"
            wasValidated={!!authMode && !errors.AuthMode}
          />
        )}

        {aliases &&
          aliases.map((x, index) => (
            <ConnectionAlias
              control={control}
              errors={errors}
              index={index}
              key={x.id}
              onClickRemove={() => handleRemoveAlias(index)}
              wasValidated={!errors.Aliases || !errors.Aliases[index]?.value}
            />
          ))}

        {system?.value !== "VERA" && (!aliases || aliases.length < 10) && (
          <button
            aria-label="add alias"
            className="btn btn-primary text-white mr-2 btn-add-alias"
            id="btn-add-alias"
            onClick={handleAddAlias}
          >
            Add Alias
          </button>
        )}

        {system?.value === "JIRA" && linkedConnection === undefined && (
          <div>
            <button
              aria-label="linked connection"
              className="btn btn-primary text-white mr-2"
              id="btn-add-alias"
              onClick={handleAddLinkedConnection}
            >
              Linked Connection
            </button>
          </div>
        )}

        {system?.value === "JIRA" && linkedConnection !== undefined && (
          <LinkedConnection
            control={control}
            errors={errors}
            onClickRemove={handleRemoveLinkedConnection}
            wasValidated={!!linkedConnection && !errors.LinkedConnections}
          />
        )}

        <div className="connection-buttons">
          {data._id && system?.value !== "VERA" && (
            <button
              aria-label="test connection"
              className="btn btn-primary text-white mr-2"
              disabled={!!errors.Urls?.API}
              id="btn-test-connection"
              onClick={(e) => {
                e.preventDefault();
                show();
              }}
            >
              Test Connection
            </button>
          )}

          <button
            aria-label="save connection"
            className="btn btn-primary text-white mr-2"
            disabled={!isDirty}
            id="btn-save-connection"
          >
            {labelSaveButton}
            {isSubmitting && (
              <span
                aria-hidden="true"
                className="spinner-border spinner-border-sm ml-2"
                role="status"
              />
            )}
          </button>

          <button
            aria-label="cancel changes"
            className="btn btn-primary text-white mr-2"
            disabled={!isDirty}
            id="btn-reset-connection"
            onClick={handleResetFields}
          >
            {labelCancelChangesButton}
          </button>
        </div>
      </Form>
      {visible && (
        <TestConnectionModal
          apiURL={apiURL}
          id={data._id}
          onClose={() => hide()}
          system={system?.value}
          visible={visible}
        />
      )}
    </div>
  );
};

export default ConnectionDetailsForm;
