const CopyrightMessage = ({ className }: { className?: string }) => {
  return (
    <>
      Copyright &copy;
      <span id="copyrightYear">{new Date().getFullYear()},</span>{" "}
      <a
        className={className}
        href="https://www.tricentis.com/"
        rel="noreferrer"
        target="_blank"
      >
        Tricentis
      </a>
    </>
  );
};

export default CopyrightMessage;
