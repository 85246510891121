import {
  HistoryRecord,
  IDetailsPageData,
  IRecordRevision,
  IRoute,
} from "../../components/interfaces/DetailsPage";

export enum RecordDetailsActionTypes {
  FETCHING_RECORD_DETAILS = "FETCHING_RECORD_DETAILS",
  UPDATE_RECORD_DETAILS = "UPDATE_RECORD_DETAILS",
  HANDLE_NETWORK_ERROR = "HANDLE_NETWORK_ERROR",
  FETCHING_RECORD_HISTORY = "FETCHING_RECORD_HISTORY",
  UPDATE_RECORD_HISTORY = "UPDATE_RECORD_HISTORY",
  FETCHING_RECORD_REVISIONS = "FETCHING_RECORD_REVISIONS",
  UPDATE_RECORD_REVISIONS = "UPDATE_RECORD_REVISIONS",
  UPDATE_FULL_RECORD = "UPDATE_FULL_RECORD",
  FETCHING_APPROVAL_ROUTES = "FETCHING_APPROVAL_ROUTES",
  UPDATE_APPROVAL_ROUTES = "UPDATE_APPROVAL_ROUTES",
  HANDLE_APPROVAL_ROUTES_ERROR = "HANDLE_APPROVAL_ROUTES_ERROR",
  RESET_RECORD_DETAILS_DATA = "RESET_RECORD_DETAILS_DATA",
}

interface HandleNetworkError {
  type: RecordDetailsActionTypes.HANDLE_NETWORK_ERROR;
  payload: string;
}

interface FetchingRecordDetails {
  type: RecordDetailsActionTypes.FETCHING_RECORD_DETAILS;
}

interface UpdateRecordDetails {
  type: RecordDetailsActionTypes.UPDATE_RECORD_DETAILS;
  payload: IDetailsPageData;
}

interface FetchingRecordHistory {
  type: RecordDetailsActionTypes.FETCHING_RECORD_HISTORY;
}

interface UpdateRecordHistory {
  type: RecordDetailsActionTypes.UPDATE_RECORD_HISTORY;
  payload: HistoryRecord[];
}

interface FetchingRecordRevisions {
  type: RecordDetailsActionTypes.FETCHING_RECORD_REVISIONS;
}

interface UpdateRecordRevisions {
  type: RecordDetailsActionTypes.UPDATE_RECORD_REVISIONS;
  payload: IRecordRevision[];
}

interface UpdateFullRecord {
  type: RecordDetailsActionTypes.UPDATE_FULL_RECORD;
  payload: IDetailsPageData;
}

interface FetchingApprovalRoutes {
  type: RecordDetailsActionTypes.FETCHING_APPROVAL_ROUTES;
}

interface UpdateApprovalRoutes {
  type: RecordDetailsActionTypes.UPDATE_APPROVAL_ROUTES;
  payload: IRoute[];
}

interface HandleApprovalRoutesError {
  type: RecordDetailsActionTypes.HANDLE_APPROVAL_ROUTES_ERROR;
}

interface ResetRecordDetailsData {
  type: RecordDetailsActionTypes.RESET_RECORD_DETAILS_DATA;
}

export type RecordDetailsAction =
  | FetchingRecordDetails
  | UpdateRecordDetails
  | FetchingRecordHistory
  | UpdateRecordHistory
  | FetchingRecordRevisions
  | UpdateRecordRevisions
  | UpdateFullRecord
  | ResetRecordDetailsData
  | FetchingApprovalRoutes
  | UpdateApprovalRoutes
  | HandleApprovalRoutesError
  | HandleNetworkError;
