import { IConnectionsTableItemProps } from "../../../interfaces/Connections";
import { AdminPageRoutes } from "../../../../constants/general.constants";
import { Link } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import DeleteConnectionConfirmationModal from "./DeleteConnectionConfirmationModal";
import Tooltip from "@atlaskit/tooltip";
import IconButton from "../../../modules/common/IconButton";

const ConnectionsTableItem = ({ connection }: IConnectionsTableItemProps) => {
  const { visible, show, hide } = useModal();

  const handleOnClickDelete = () => {
    show();
  };

  return (
    <>
      <tr className="table-item">
        <td>
          <Link
            className="user-details-btn"
            to={`${AdminPageRoutes.EDIT_CONNECTION}/${connection._id}`}
          >
            {connection.Name}
          </Link>
        </td>

        <td>{connection.System}</td>

        <td>{connection.DateCreated}</td>

        <td>{connection.LastUpdated}</td>

        <td>
          <div className="table-actions">
            <Tooltip content="Edit" position="top">
              <Link
                aria-label="edit-connection-btn"
                id={`link-edit-connection-${connection._id}`}
                to={`${AdminPageRoutes.EDIT_CONNECTION}/${connection._id}`}
              >
                <i className="fa fa-edit" />
              </Link>
            </Tooltip>
            {connection.System !== "VERA" ? (
              <Tooltip content="Delete" position="top">
                <IconButton
                  aria-label="delete-connection-btn"
                  icon="fa-trash-o"
                  id={`btn-delete-connection-${connection._id}`}
                  onClick={handleOnClickDelete}
                />
              </Tooltip>
            ) : (
              <Tooltip
                content="Cannot delete the VERA connection"
                position="top"
              >
                <i
                  className="fa fa-trash-o"
                  id={`icon-delete-connection-${connection._id}`}
                />
              </Tooltip>
            )}
          </div>
        </td>
      </tr>
      {visible && (
        <DeleteConnectionConfirmationModal
          id={connection._id}
          name={connection.Name}
          onClose={() => hide()}
          visible={visible}
        />
      )}
    </>
  );
};

export default ConnectionsTableItem;
