import { IConnectionsTableData } from "../../components/interfaces/Connections";

export enum ConnectionsActionTypes {
  FETCHING_CONNECTIONS_DATA = "FETCHING_CONNECTIONS_DATA",
  UPDATE_CONNECTIONS_DATA = "UPDATE_CONNECTIONS_DATA",
  HANDLE_CONNECTIONS_ERROR = "HANDLE_CONNECTIONS_ERROR",
  UPDATE_CONNECTIONS_SORT_PARAMS = "UPDATE_CONNECTIONS_SORT_PARAMS",
  UPDATE_CONNECTIONS_PAGE_PARAMS = "UPDATE_CONNECTIONS_PAGE_PARAMS",
  UPDATE_CONNECTIONS_SELECTED_PAGE = "UPDATE_CONNECTIONS_SELECTED_PAGE",
  RESET_CONNECTIONS_DATA = "RESET_CONNECTIONS_DATA",
}

interface FetchingConnectionsData {
  type: ConnectionsActionTypes.FETCHING_CONNECTIONS_DATA;
}

interface UpdateConnectionsTableData {
  type: ConnectionsActionTypes.UPDATE_CONNECTIONS_DATA;
  payload: IConnectionsTableData;
}

interface ResetConnectionsData {
  type: ConnectionsActionTypes.RESET_CONNECTIONS_DATA;
}

interface UpdateConnectionsSelectedPage {
  type: ConnectionsActionTypes.UPDATE_CONNECTIONS_SELECTED_PAGE;
  payload: number;
}

interface HandleConnectionsError {
  type: ConnectionsActionTypes.HANDLE_CONNECTIONS_ERROR;
}

interface UpdateConnectionsSortParams {
  type: ConnectionsActionTypes.UPDATE_CONNECTIONS_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdateConnectionsPageParams {
  type: ConnectionsActionTypes.UPDATE_CONNECTIONS_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

export type ConnectionsAction =
  | FetchingConnectionsData
  | UpdateConnectionsTableData
  | ResetConnectionsData
  | HandleConnectionsError
  | UpdateConnectionsSortParams
  | UpdateConnectionsPageParams
  | UpdateConnectionsSelectedPage;
