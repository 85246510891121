import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";
import { IPolicySetTableData } from "../../components/interfaces/PolicySet";
import { ITableData } from "../../components/interfaces/Table";

export enum PolicySetsActionTypes {
  FETCHING_POLICY_SETS_TABLE_DATA = "FETCHING_POLICY_SETS_TABLE_DATA",
  UPDATE_POLICY_SETS_TABLE_DATA = "UPDATE_POLICY_SETS_TABLE_DATA",
  UPDATE_POLICY_SETS_SELECTED_PAGE = "UPDATE_POLICY_SETS_SELECTED_PAGE",
  UPDATE_POLICY_SETS_SORT_PARAMS = "UPDATE_POLICY_SETS_SORT_PARAMS",
  UPDATE_POLICY_SETS_PAGE_PARAMS = "UPDATE_POLICY_SETS_PAGE_PARAMS",
  UPDATE_POLICY_SETS_COLUMN_SEARCH_FIELD = "UPDATE_POLICY_SETS_COLUMN_SEARCH_FIELD",
  RESET_POLICY_SETS_SORT_PARAMS = "RESET_POLICY_SETS_SORT_PARAMS",
  HANDLE_POLICY_SETS_NETWORK_ERROR = "HANDLE_POLICY_SETS_NETWORK_ERROR",
  RESET_POLICY_SETS_DATA = "RESET_POLICY_SETS_DATA",
}

interface FetchingPolicySetsData {
  type: PolicySetsActionTypes.FETCHING_POLICY_SETS_TABLE_DATA;
}

interface UpdatePolicySetsTableData {
  type: PolicySetsActionTypes.UPDATE_POLICY_SETS_TABLE_DATA;
  payload: ITableData<IPolicySetTableData>;
}

interface UpdatePolicySetsSelectedPage {
  type: PolicySetsActionTypes.UPDATE_POLICY_SETS_SELECTED_PAGE;
  payload: number;
}

interface HandlePolicySetsNetworkError {
  type: PolicySetsActionTypes.HANDLE_POLICY_SETS_NETWORK_ERROR;
  payload: string;
}

interface UpdatePolicySetsSortParams {
  type: PolicySetsActionTypes.UPDATE_POLICY_SETS_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdatePolicySetsPageParams {
  type: PolicySetsActionTypes.UPDATE_POLICY_SETS_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface HandleResetPolicySetsSortParams {
  type: PolicySetsActionTypes.RESET_POLICY_SETS_SORT_PARAMS;
}

interface UpdatePolicySetsColumnSearchField {
  type: PolicySetsActionTypes.UPDATE_POLICY_SETS_COLUMN_SEARCH_FIELD;
  payload: IColumnsSearchFieldsFormData;
}

interface HandleResetPolicySetsData {
  type: PolicySetsActionTypes.RESET_POLICY_SETS_DATA;
}

export type PolicySetsAction =
  | UpdatePolicySetsTableData
  | FetchingPolicySetsData
  | UpdatePolicySetsSelectedPage
  | HandlePolicySetsNetworkError
  | HandleResetPolicySetsData
  | UpdatePolicySetsSortParams
  | UpdatePolicySetsPageParams
  | UpdatePolicySetsColumnSearchField
  | HandleResetPolicySetsSortParams;
