import { Control, Controller } from "react-hook-form";
import TextArea from "@atlaskit/textarea";

interface IFormTextAreaProps {
  control: Control<any>;
  controllerName?: string;
  error?: string;
  label: string;
  name: string;
  placeholder: string;
  size: "small" | "medium" | "large";
  wasValidated?: boolean;
}

const FormTextArea = ({
  control,
  controllerName,
  error,
  label,
  name,
  placeholder,
  size,
  wasValidated,
}: IFormTextAreaProps) => {
  const getContainerClassName = () => {
    if (!label) {
      return "";
    } else if (size === "small") {
      return "form-with-label-small";
    } else if (size === "medium") {
      return "form-with-label--medium";
    } else if (label || size === "large") {
      return "form-with-label--large";
    }

    return "";
  };

  return (
    <Controller
      control={control}
      name={controllerName ?? name}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <div className={`form-with-label ${getContainerClassName()}`}>
          {label && (
            <label className="m-0" htmlFor={name} style={{ fontWeight: 500 }}>
              {label}
            </label>
          )}
          <div
            className={`input-group position-relative${
              value && wasValidated ? " was-validated" : ""
            }`}
          >
            <TextArea
              className={`form-control${error ? " is-invalid " : ""}`}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              rows={4}
              value={value}
            />
            {error && (
              <div className="invalid-feedback" role="alert">
                {error}
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default FormTextArea;
