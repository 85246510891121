import * as yup from "yup";
import { INewUserFormData } from "../components/interfaces/AdminPage";

function createSchema() {
  return yup.object().shape({
    idpUsername: yup.lazy((value, props) => {
      if (props.parent?.identityProvider === "VERA" || value === undefined) {
        return yup.string().notRequired();
      }

      return yup
        .string()
        .required(
          "An IdP username is required when the Identity Provider is not VERA.",
        )
        .matches(
          /^[\da-zA-Z0-9-.+_@]*$/,
          "Valid characters only include: alphanumeric, @, -, _, +, and .",
        );
    }),
    identityProvider: yup.lazy((value) => {
      if (value === undefined) {
        return yup.string().notRequired();
      }

      return yup.string().required("Please select an Identity Provider.");
    }),
    email: yup.lazy((value) => {
      if (value === undefined) {
        return yup.string().notRequired();
      }

      return yup
        .string()
        .required("Please enter a valid email address.")
        .email("Please enter a valid email address.");
    }),
    fullName: yup.lazy((value) => {
      if (value === undefined) {
        return yup.string().notRequired();
      }

      return yup.string().required("Please enter the Full Name.");
    }),
  });
}

export const validateExistingUserForm = (
  data: INewUserFormData,
  setErrorHandler: any,
) => {
  const validateNewUserFormScheme = createSchema();

  validateNewUserFormScheme
    .validate(data, { abortEarly: false })
    .then(() => setErrorHandler({}))
    .catch((err) => {
      const inner = err.inner as [];
      const validationErrors = inner.reduce((total, previous: any) => {
        total[previous.path] = previous.message || "";
        return total;
      }, {} as any);
      setErrorHandler(validationErrors);
    });
};
