import { ChangeEvent, useState, FocusEvent } from "react";
import eyeIcon from "../../../images/eye.svg";
import slashedEyeIcon from "../../../images/eye-slashed.svg";
import { ITextFieldProps } from "../../interfaces/TextField";

const TextField = ({
  centeredField = false,
  className = "",
  disabled = false,
  error,
  focus = false,
  inputStyle = {},
  label,
  min,
  name,
  placeholder,
  readonly = false,
  onBlur,
  onChange,
  onKeyDown,
  showPasswordFunction = false,
  size,
  type = "text",
  value,
  wasValidated = false,
}: ITextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const getDivClassName = () =>
    `input-group position-relative${wasValidated ? " was-validated" : ""}${
      showPasswordFunction ? " input-with-show-password" : ""
    }`;
  const getInputClassName = () =>
    `form-control common-text-field ${error ? "is-invalid " : " "}${
      readonly ? "disabled-input" : ""
    } ${className}`;

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange!({ name: [target.name], value: target.value });
  };

  const handleBlur = ({ target }: FocusEvent<HTMLInputElement, Element>) => {
    onBlur && onBlur!({ name: [target.name], value: target.value });
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const getContainerClassName = () => {
    if (!label) {
      return "";
    } else if (size === "small") {
      return "form-with-label-small";
    } else if (size === "medium") {
      return "form-with-label--medium";
    } else if (label || size === "large") {
      return "form-with-label--large";
    }

    return "";
  };

  return (
    <div
      className={`form-with-label${
        centeredField ? " centered-field" : ""
      } ${getContainerClassName()}`}
    >
      {label && (
        <label className="m-0" htmlFor={name} style={{ fontWeight: 500 }}>
          {label}
        </label>
      )}
      <div className={getDivClassName()}>
        <input
          autoFocus={focus}
          className={getInputClassName()}
          disabled={disabled}
          id={name || ""}
          min={min}
          name={name || ""}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={onKeyDown ? onKeyDown : undefined}
          placeholder={placeholder || ""}
          style={{ ...inputStyle, zIndex: 0 }}
          type={showPassword ? "text" : type}
          value={type === "number" ? value : value || ""}
        />
        {type === "password" && showPasswordFunction && (
          <button
            className="btn btn-eye-icon"
            onClick={toggleShowPassword}
            role="textbox"
            type="button"
          >
            <img alt="#" src={showPassword ? slashedEyeIcon : eyeIcon} />
          </button>
        )}
        {error && (
          <div className="invalid-feedback" role="alert">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextField;
