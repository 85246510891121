import { IPolicySet } from "../../components/interfaces/PolicySet";

export enum PolicySetActionTypes {
  FETCHING_POLICY_SET = "FETCHING_POLICY_SET",
  UPDATE_POLICY_SET = "UPDATE_POLICY_SET",
  HANDLE_POLICY_SET_NETWORK_ERROR = "HANDLE_POLICY_SET_NETWORK_ERROR",
}

interface FetchPolicySet {
  type: PolicySetActionTypes.FETCHING_POLICY_SET;
}

interface UpdatePolicySet {
  type: PolicySetActionTypes.UPDATE_POLICY_SET;
  payload: IPolicySet;
}

interface HandlePolicySetNetworkError {
  type: PolicySetActionTypes.HANDLE_POLICY_SET_NETWORK_ERROR;
  payload: string;
}

export type PolicySetAction =
  | FetchPolicySet
  | UpdatePolicySet
  | HandlePolicySetNetworkError;
