import { IAttachmentsModalState } from "../../components/interfaces/AttachmentsModal";
import {
  AttachmentsModalAction,
  AttachmentsModalActionTypes,
} from "../types/attachmentsModal";

const initialState: IAttachmentsModalState = {
  attachments: [],
  showModal: false,
};

export const attachmentsModalReducer = (
  state = initialState,
  action: AttachmentsModalAction,
): IAttachmentsModalState => {
  switch (action.type) {
    case AttachmentsModalActionTypes.SHOW_ATTACHMENT_MODAL:
      return {
        ...state,
        showModal: true,
        ...action.payload,
      };
    case AttachmentsModalActionTypes.HIDE_ATTACHMENT_MODAL:
      return initialState;
    default:
      return state;
  }
};
