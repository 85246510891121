import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import TablePagination from "../../../modules/TablePagination";
import React from "react";

const DomainsAndRolesPagination: React.FC = () => {
  const { updateDomainAndRolesPageParams, updateDomainsSelectedPage } =
    useActions();

  const { domainAndRoles, sortParams, selectedPage } = useTypedSelector(
    (state) => state.domainsAndRoles,
  );

  const updatePageParams = (offset: number, limit: number) => {
    updateDomainAndRolesPageParams(offset, limit);
  };

  return (
    <TablePagination
      initialRowsPerPage={10}
      selectedPage={selectedPage}
      tableData={domainAndRoles}
      updatePageParams={updatePageParams}
      updateSelectedPage={updateDomainsSelectedPage}
    />
  );
};

export default DomainsAndRolesPagination;
