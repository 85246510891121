import { PolicySetsRequestRoutes } from "../../../../../constants/policy-set.constants";
import GenericModal from "../../../../modules/GenericModal";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSchema } from "../../../../../validators/policySetFormValidator";
import Form from "../../../../modules/common/Form";
import FormTextField from "../../../../modules/common/FormTextField";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../../constants/admin-page.constants";
import {
  AdminPageRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import React from "react";
import { useHistory } from "react-router-dom";
import { IDefaultResponse } from "../../../../interfaces/General";
import { IAddPolicySetModalProps } from "../../../../interfaces/PolicySet";

const AddPolicySetModal = ({ onClose, visible }: IAddPolicySetModalProps) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string; description: string }>({
    mode: "all",
    resolver: yupResolver(createSchema()),
  });

  const handleOnClickAddPolicySet = async (policySet: any) => {
    try {
      setLoading(true);
      const { data } = await axios.post<IDefaultResponse<{ _id: string }>>(
        PolicySetsRequestRoutes.POLICY_SETS,
        policySet,
      );
      if (data.apiStatusCode === 201) {
        toast.success(AdminPageSuccessMessages.CREATE_POLICY_SET);
        history.push(
          `${AdminPageRoutes.POLICY_SET_DETAILS}/${data.apiData._id}`,
        );
      } else {
        toast.error(AdminPageErrors.CREATE_POLICY_SET);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <GenericModal
      labelConfirm="Save"
      loading={loading}
      onClickConfirm={handleSubmit((policySet) =>
        handleOnClickAddPolicySet(policySet),
      )}
      onCloseModal={onClose}
      size="medium"
      title="Create Policy Set"
      visible={visible}
    >
      <Form>
        <FormTextField
          control={control}
          error={errors.name?.message}
          label={"Name: "}
          name="name"
          placeholder="Enter Name"
          size="medium"
          wasValidated={!errors.name}
        />
        <FormTextField
          control={control}
          error={errors.description?.message}
          label={"Description: "}
          name="description"
          placeholder="Enter Description"
          size="medium"
          wasValidated={!errors.description}
        />
      </Form>
    </GenericModal>
  );
};

export default AddPolicySetModal;
