import { DetailsRoutes } from "../../../constants/details-page.constants";
import PrinterIcon from "./../../../images/bxs-printer.svg";
import SidebarItem from "./SidebarItem";
import ListCheckIcon from "./../../../images/bx-list-check.svg";
import HistoryIcon from "./../../../images/bx-history.svg";
import DetailIcon from "./../../../images/bxs-detail.svg";
import DirectionsIcon from "./../../../images/bxs-directions.svg";
import { AppRoutes } from "../../../constants/general.constants";
import { DetailsQueryParams } from "../../interfaces/DetailsPage";
import { matchPath, useLocation } from "react-router-dom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const SidebarRecordDetails = () => {
  const location = useLocation();
  const match = matchPath<DetailsQueryParams>(location.pathname, {
    path: [AppRoutes.DetailsPage, AppRoutes.RevisionPage],
  });

  const initialPath = match?.params?.routeId
    ? `/details/${match?.params?.routeId}/${match?.params?.recordId}`
    : `/records/${match?.params?.recordId}/${match?.params?.revision}`;

  const { data, isLoading } = useTypedSelector((state) => state.recordDetails);
  const { rejectionReason } = data || {};

  return (
    <>
      {" "}
      {match && (
        <ul className="features-list">
          <SidebarItem
            disabled={isLoading}
            icon={<img className="white-icon" src={DetailIcon} />}
            label="Details"
            to={`${initialPath}/${DetailsRoutes.ENTITY_DETAILS}`}
          />

          {rejectionReason && (
            <SidebarItem
              disabled={isLoading}
              icon={<img className="white-icon" src={ListCheckIcon} />}
              label="Rejection Reason"
              to={`${initialPath}/${DetailsRoutes.REJECTION_REASON}`}
            />
          )}

          <SidebarItem
            disabled={isLoading}
            icon={<img className="white-icon" src={HistoryIcon} />}
            label="Activity History"
            to={`${initialPath}/${DetailsRoutes.RECORD_HISTORY}`}
          />

          <SidebarItem
            disabled={isLoading}
            icon={<img className="white-icon" src={DirectionsIcon} />}
            label="Approval Routes"
            to={`${initialPath}/${DetailsRoutes.APPROVAL_ROUTES}`}
          />

          <SidebarItem
            disabled={isLoading}
            icon={<img className="white-icon" src={ListCheckIcon} />}
            label="Revision History"
            to={`${initialPath}/${DetailsRoutes.RECORD_REVISIONS}`}
          />

          <SidebarItem
            disabled={isLoading}
            icon={<img className="white-icon" src={PrinterIcon} />}
            label="Print View"
            to={`${initialPath}/${DetailsRoutes.PRINT_VIEW}`}
          />
        </ul>
      )}
    </>
  );
};

export default SidebarRecordDetails;
