import { useState } from "react";
import { toast } from "react-toastify";
import Tooltip from "@atlaskit/tooltip";
import axios from "axios";
import {
  IAssociatedTasksCheckResponse,
  IDomainProjectsTableItemProps,
  IRemoveProjectFromDomainResponse,
} from "../../../../interfaces/AdminPage";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import DeleteProjectConfirmationModal from "./DeleteProjectConfimationModal";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";

const DomainProjectsTableItem = ({
  domainId,
  projectData,
  updateTableData,
}: IDomainProjectsTableItemProps) => {
  const [isOpenDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [associatedTasksCheck, setAssociatedTasksCheck] =
    useState<boolean>(false);
  const user = useTypedSelector((state) => state.user.user);

  const handleDeleteProjectFromDomain = async () => {
    try {
      setLoading(true);
      const response: IRemoveProjectFromDomainResponse = await axios.post(
        AdminRequestRoutes.REMOVE_PROJECT_FROM_DOMAIN,
        {
          domainId: domainId,
          projectToRemove: {
            ProjectId: projectData.ProjectId,
            ConnectionId: projectData.ConnectionId,
            LocationId: projectData.LocationId,
          },
        },
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        updateTableData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
      setOpenDeleteConfirmationModal(false);
    }
  };

  const handleAssociatedTasksCheck = async () => {
    try {
      setLoading(true);
      const params = {
        domainId,
        projectId: projectData.ProjectId,
        locationId: projectData.LocationId,
        connectionId: projectData.ConnectionId,
      };

      const response: IAssociatedTasksCheckResponse = await axios.get(
        AdminRequestRoutes.ASSOCIATED_TASKS_CHECK,
        { params },
      );

      if (response.status === 200) {
        setAssociatedTasksCheck(response.data as boolean);
        setOpenDeleteConfirmationModal(true);
      } else {
        toast.error((response.data as { message: string }).message);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <tr>
      <td>{projectData.ProjectId}</td>

      <td>{projectData.LocationId}</td>

      {user?.isSystemAdmin && (
        <td>
          <div className="table-actions">
            {isOpenDeleteConfirmationModal && (
              <DeleteProjectConfirmationModal
                closeModal={() => setOpenDeleteConfirmationModal(false)}
                handleDeleteProject={handleDeleteProjectFromDomain}
                isLoading={loading}
                isOpen={isOpenDeleteConfirmationModal}
                projectHasAssociatedTasks={associatedTasksCheck}
                projectName={projectData.ProjectId}
              />
            )}

            <Tooltip content="Delete" position="top">
              <a
                aria-label="delete-project-btn"
                href="#"
                onClick={() => handleAssociatedTasksCheck()}
                role="button"
              >
                <i className="fa fa-trash cursor-pointer" />
              </a>
            </Tooltip>
          </div>
        </td>
      )}
    </tr>
  );
};

export default DomainProjectsTableItem;
