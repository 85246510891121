import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { ILoginForm, LoginFormProps } from "../../interfaces/LoginPage";
import { LoginPageWarnings } from "../../../constants/login-page.constants";
import { useHistory } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import PasswordField from "../../modules/PasswordField/PasswordField";
import { LoadingButton } from "@mui/lab";

const AdminLoginForm = () => {
  const { enteredUsername, loading, error } = useTypedSelector(
    (state) => state.user,
  );
  const [formData, setFormData] = useState<ILoginForm>({
    username: enteredUsername ?? "",
    password: "",
  });
  const { adminLogin } = useActions();
  const history = useHistory();

  useEffect(() => {
    error &&
      !loading &&
      setFormData((prevState) => ({ ...prevState, password: "" }));
  }, [loading]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    if (!formData.username) {
      toast.warning(LoginPageWarnings.EMPTY_USERNAME_FIELD);
      return;
    } else if (enteredUsername && !formData.password) {
      toast.warning(LoginPageWarnings.EMPTY_PASSWORD_FIELD);
      return;
    }

    adminLogin(enteredUsername!, formData.password);
  };

  const handleReset = () => {
    setFormData({ username: "", password: "" });

    history.goBack();
  };

  return (
    <form
      aria-label="login-form"
      className="form-signin"
      onSubmit={handleLogin}
    >
      <TextField
        disabled={true}
        name={"username"}
        onChange={handleFormChange}
        placeholder={"Username"}
        size="small"
        value={formData.username || enteredUsername}
      />

      {enteredUsername && (
        <PasswordField
          autoFocus
          id="txt-password"
          label="Password"
          name="password"
          onChange={handleFormChange}
          placeholder="Password"
          size="small"
          type="password"
          value={formData.password}
        />
      )}

      <LoadingButton
        disabled={loading}
        id="btn-login"
        loading={loading}
        onClick={handleLogin}
        type="submit"
        variant="contained"
      >
        {enteredUsername ? "Login" : "Continue"}
      </LoadingButton>

      {enteredUsername && (
        <Button focusRipple={false} id="btn-cancel" onClick={handleReset}>
          Cancel
        </Button>
      )}
    </form>
  );
};

export default AdminLoginForm;
