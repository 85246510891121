import React, { useEffect } from "react";
import { VerificationStatus } from "../../../../constants/approval-page.constants";
import { unescape } from "lodash";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Loader from "../../../modules/Loader";
import { useActions } from "../../../../hooks/useActions";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { DetailsQueryParams } from "../../../interfaces/DetailsPage";
import { AppRoutes } from "../../../../constants/general.constants";
import TaskGroups from "./TaskGroups";
import NoSignaturesInfo from "./NoSignaturesInfo";

const ApprovalRoutes = () => {
  const { fetchRecordDetails, fetchApprovalRoutes } = useActions();
  const { data, routeHistory, isLoadingApprovalRoutes } = useTypedSelector(
    (state) => state.recordDetails,
  );
  const history = useHistory();
  const location = useLocation();

  const match = matchPath<DetailsQueryParams>(location.pathname, {
    path: [AppRoutes.DetailsPage, AppRoutes.RevisionPage],
  });

  const { routeId, recordId, revision, menuSelector } = match?.params ?? {};

  useEffect(() => {
    fetchRecordDetails(history, routeId, recordId, revision, menuSelector);
  }, []);

  useEffect(() => {
    !routeHistory.length &&
      data?.record?._id &&
      fetchApprovalRoutes(data?.record?._id);
  }, [data?.record?._id]);

  if (!data || !data.record || isLoadingApprovalRoutes) {
    return <Loader message={"Loading, please wait..."} />;
  }

  const { record } = data;

  return (
    <>
      <h6>
        <b>{unescape(record.name)}</b>
      </h6>

      <div aria-label="approval-routes-tab">
        <h6>Audit Changes to Records</h6>

        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>Route</th>
              <th>Status</th>
              <th>Owner</th>
              <th>Started</th>
              <th>Ended</th>
            </tr>
          </thead>

          <tbody>
            {routeHistory &&
              routeHistory.map((route) => (
                <React.Fragment key={route._id}>
                  <tr
                    className="clickable"
                    data-bs-target={`#historyRoute${route._id}`}
                    data-bs-toggle="collapse"
                    id={`accordionRouteHistory${route._id}`}
                  >
                    <td>{route.name}</td>
                    <td>{route.status}</td>
                    <td>{route.ownerId}</td>
                    <td>{route.startDate}</td>
                    <td>{route.stopDate}</td>
                  </tr>
                  <tr>
                    <td
                      className="collapse"
                      colSpan={5}
                      data-bs-parent={`#accordionRouteHistory${route._id}`}
                      id={`historyRoute${route._id}`}
                    >
                      <div className="container">
                        {route.signaturesRequired ? (
                          <TaskGroups route={route} />
                        ) : (
                          <NoSignaturesInfo date={route.stopDate} />
                        )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ApprovalRoutes;
