import { IPolicySetTableData } from "../../components/interfaces/PolicySet";
import { ITableState } from "../../components/interfaces/Table";
import { PolicySetsAction, PolicySetsActionTypes } from "../types/policySets";

export const initialState: ITableState<IPolicySetTableData> = {
  columnSearchField: {},
  isLoading: false,
  selectedPage: 0,
  sortParams: {
    limit: 15,
    offset: 0,
    order: "asc",
    sort: "lastUpdated",
  },
  tableData: {
    rows: [],
    total: 0,
  },
  tableHeaders: [
    { name: "Name", label: "Name", colSize: 6, filterable: true },
    { name: "Domain", label: "Domains", colSize: 6, filterable: true },
  ],
};

export const policySetsReducer = (
  state = initialState,
  action: PolicySetsAction,
): ITableState<IPolicySetTableData> => {
  switch (action.type) {
    case PolicySetsActionTypes.FETCHING_POLICY_SETS_TABLE_DATA:
      return { ...state, isLoading: true };
    case PolicySetsActionTypes.UPDATE_POLICY_SETS_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload,
      };
    case PolicySetsActionTypes.UPDATE_POLICY_SETS_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case PolicySetsActionTypes.UPDATE_POLICY_SETS_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case PolicySetsActionTypes.UPDATE_POLICY_SETS_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case PolicySetsActionTypes.RESET_POLICY_SETS_DATA:
      return initialState;
    case PolicySetsActionTypes.RESET_POLICY_SETS_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case PolicySetsActionTypes.UPDATE_POLICY_SETS_COLUMN_SEARCH_FIELD:
      return {
        ...state,
        columnSearchField: action.payload,
      };
    case PolicySetsActionTypes.HANDLE_POLICY_SETS_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
