import { NavLink } from "react-router-dom";
import { IMenuItemProps } from "../../interfaces/Sidebar";

const levelNames = ["one", "two"];

const SidebarItem = ({
  activeClassName = "active",
  disabled,
  icon,
  level,
  label,
  to,
}: IMenuItemProps) => {
  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    disabled && e.preventDefault();
  };
  return (
    <li
      className={`features-item${
        level ? ` features-item__level-${levelNames[level - 1]}` : ""
      } ${disabled ? "features-item--disabled" : ""}`}
    >
      <NavLink
        activeClassName={activeClassName}
        onClick={handleOnClick}
        to={to}
      >
        {icon}
        <span className="features-item-text">{label}</span>
      </NavLink>
    </li>
  );
};

export default SidebarItem;
