import React from "react";

interface ICollapseButtonProps {
  label: string;
  targetId: string;
}

const CollapseButton = ({ label, targetId }: ICollapseButtonProps) => {
  const [tableIsCollapsed, setTableIsCollapsed] =
    React.useState<boolean>(false);

  return (
    <button
      aria-label="collapse-button"
      className="btn-link text-decoration-none"
      data-bs-target={targetId}
      data-bs-toggle="collapse"
      onClick={() => setTableIsCollapsed(!tableIsCollapsed)}
    >
      <i
        className={`fa ${
          tableIsCollapsed
            ? "fa-chevron-circle-right"
            : "fa-chevron-circle-down"
        }`}
      />
      <h6 className="d-inline"> {label}</h6>
    </button>
  );
};

export default CollapseButton;
