import { Redirect, Route, Switch } from "react-router-dom";
import {
  AdminPageRoutes,
  AppRoutes,
} from "../../../constants/general.constants";
import DomainDetailsPage from "./domainManagement/domainDetails/DomainDetailsPage";
import DomainManagement from "./domainManagement/DomainManagement";
import RoleManagement from "./roleManagement/RoleManagement";
import { toast } from "react-toastify";

const DomainAdminPage = () => {
  return (
    <Switch>
      <Route
        component={DomainManagement}
        exact
        path={AdminPageRoutes.DOMAIN_MANAGEMENT}
      />
      <Route
        component={DomainDetailsPage}
        exact
        path={`${AdminPageRoutes.DOMAIN_DETAILS}/:domainId`}
      />
      <Route
        component={RoleManagement}
        exact
        path={`${AdminPageRoutes.ROLE_MANAGEMENT}/:domainId`}
      />
      <Route
        path="*"
        render={() => {
          toast.error("You don't have access to this page");
          return <Redirect to={AppRoutes.ApprovalQueue} />;
        }}
      />
    </Switch>
  );
};

export default DomainAdminPage;
