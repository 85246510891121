import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";
import { LoginPageRequestRoutes } from "../../constants/general.constants";
import type { PayloadAction } from "@reduxjs/toolkit";
import axiosBaseQuery from "../../interceptors/axiosBaseQuery";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    loginBySelectedUsername: builder.mutation<
      any,
      { userId?: string; username: string }
    >({
      query: (params) => ({
        method: "POST",
        url: LoginPageRequestRoutes.SAML_LOGIN_BY_SELECTED_USERNAME,
        body: params,
      }),
    }),
  }),
});

interface IUserAccount {
  _id: string;
  userName: string;
}

interface ILoginState {
  username: string;
  userAccounts: IUserAccount[];
}

const initialState: ILoginState = {
  username: "",
  userAccounts: [],
};

const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetData() {
      return initialState;
    },
    setUsername(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setUserAccounts(state, action: PayloadAction<IUserAccount[]>) {
      state.userAccounts = action.payload;
    },
  },
});

export const { resetData, setUsername, setUserAccounts } = LoginSlice.actions;

export const { useLoginBySelectedUsernameMutation } = loginApi;

export default LoginSlice.reducer;
