import { useEffect } from "react";
import { useActions } from "../../../../../../hooks/useActions";
import PolicySetForm from "./PolicySetForm";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import Loader from "../../../../../modules/Loader";
import Policies from "./policies/Policies";
import Domains from "./domains/Domains";

const PolicySetDetailsPage = () => {
  const { fetchPolicySet, fetchPolicySetLinkedDomains } = useActions();

  const { policySetId } = useParams<{ policySetId: string }>();

  const { policySet, isLoading } = useTypedSelector((x) => x.policySet);
  const { sortParams } = useTypedSelector((x) => x.policySetLinkedDomains);
  useEffect(() => {
    fetchPolicySetLinkedDomains(policySetId);
  }, [sortParams]);

  useEffect(() => {
    fetchPolicySet(policySetId);
  }, []);

  return (
    <div className="policy-sets-content">
      {isLoading && <Loader message="Loading, please wait..." />}

      {policySet && !isLoading && (
        <>
          <PolicySetForm policySet={policySet} />
          <Policies policySet={policySet} />
          <Domains />
        </>
      )}
    </div>
  );
};

export default PolicySetDetailsPage;
