import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import React from "react";
import useModal from "../../../../../../hooks/useModal";
import ImportNewPolicyModal from "../modals/ImportNewPolicyModal";
import { ePolicyType } from "../../../../../interfaces/Policy";
import axios from "axios";
import { PolicyRequestRoutes } from "../../../../../../constants/policy.constants";
import { toast } from "react-toastify";
import { ErrorMessage } from "../../../../../../constants/general.constants";

const DropdownAddPolicy = () => {
  const { visible, hide, show } = useModal();
  const [policyData, setPolicyData] = React.useState<{
    hasDefaultPolicy: boolean;
    policyType: ePolicyType;
  }>();

  const handleOnClick = async (policyType: ePolicyType) => {
    try {
      const { data, status } = await axios.get<{ hasDefaultPolicy: boolean }>(
        PolicyRequestRoutes.POLICY_DEFAULT,
        {
          params: {
            policyType,
          },
        },
      );

      if (status === 200) {
        setPolicyData({ policyType, hasDefaultPolicy: data.hasDefaultPolicy });
        show();
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <DropdownMenu
        spacing="compact"
        trigger={({ triggerRef, ...props }) => (
          <>
            <button
              {...props}
              aria-label="btn-import-policy-modal"
              className="btn btn-primary btn-sm text-white"
              id="dropdown-add-policy"
              ref={triggerRef as any}
            >
              <i className="fa fa-plus-circle" /> Add Policy
            </button>
          </>
        )}
      >
        <DropdownItemGroup>
          <DropdownItem
            aria-label="btn-import-approval-policy"
            onClick={() => handleOnClick(ePolicyType.APPROVAL)}
          >
            Approval
          </DropdownItem>
          <DropdownItem
            aria-label="btn-import-rmp-policy"
            onClick={() => handleOnClick(ePolicyType.RECORD_MANAGEMENT)}
          >
            RMP
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
      {visible && policyData && (
        <ImportNewPolicyModal
          closeModal={hide}
          hasDefaultPolicy={policyData.hasDefaultPolicy}
          policyType={policyData.policyType}
          visible={visible}
        />
      )}
    </>
  );
};

export default DropdownAddPolicy;
