import { IDetailsPageState } from "../../components/interfaces/DetailsPage";
import {
  RecordDetailsAction,
  RecordDetailsActionTypes,
} from "../types/recordDetail";

const initialState: IDetailsPageState = {
  data: null,
  initialPath: "",
  isLoading: false,
  isLoadingRecordHistory: false,
  isLoadingRejectionReason: false,
  isLoadingApprovalRoutes: false,
  isLoadingRecordRevisions: false,
  networkError: "",
  recordHistory: null,
  recordRevisions: null,
  routeHistory: [],
  rejectionReason: null,
};

export const recordDetailsReducer = (
  state = initialState,
  action: RecordDetailsAction,
): IDetailsPageState => {
  switch (action.type) {
    case RecordDetailsActionTypes.FETCHING_RECORD_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case RecordDetailsActionTypes.UPDATE_RECORD_DETAILS:
      return {
        ...state,
        data: {
          ...action.payload,
          fullRecord: state.data?.fullRecord,
        },
        isLoading: false,
      };
    case RecordDetailsActionTypes.UPDATE_FULL_RECORD:
      return {
        ...state,
        data: {
          ...action.payload,
          record: state.data?.record,
          fullRecord: action.payload.record,
        },
        isLoading: false,
      };
    case RecordDetailsActionTypes.FETCHING_RECORD_HISTORY:
      return {
        ...state,
        recordHistory: null,
        isLoadingRecordHistory: true,
      };
    case RecordDetailsActionTypes.UPDATE_RECORD_HISTORY:
      return {
        ...state,
        recordHistory: action.payload,
        isLoadingRecordHistory: false,
      };
    case RecordDetailsActionTypes.FETCHING_RECORD_REVISIONS:
      return {
        ...state,
        recordRevisions: null,
        isLoadingRecordRevisions: true,
      };
    case RecordDetailsActionTypes.UPDATE_RECORD_REVISIONS:
      return {
        ...state,
        recordRevisions: action.payload,
        isLoadingRecordRevisions: false,
      };
    case RecordDetailsActionTypes.FETCHING_APPROVAL_ROUTES:
      return {
        ...state,
        isLoadingApprovalRoutes: true,
      };
    case RecordDetailsActionTypes.UPDATE_APPROVAL_ROUTES:
      return {
        ...state,
        routeHistory: action.payload,
        isLoadingApprovalRoutes: false,
      };
    case RecordDetailsActionTypes.HANDLE_APPROVAL_ROUTES_ERROR:
      return {
        ...state,
        isLoadingApprovalRoutes: false,
      };
    case RecordDetailsActionTypes.RESET_RECORD_DETAILS_DATA:
      return initialState;
    case RecordDetailsActionTypes.HANDLE_NETWORK_ERROR:
      return {
        ...state,
        networkError: action.payload,
      };
    default:
      return state;
  }
};
