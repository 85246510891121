import DropdownMenu, {
  DropdownItemCheckboxGroup,
} from "@atlaskit/dropdown-menu";
import { useEffect } from "react";
import { IDropdownProps } from "../../interfaces/DropDown";
import Textfield from "@atlaskit/textfield";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import Spinner from "@atlaskit/spinner";
import DropdownItems from "./DropdownItems";

const Dropdown = ({
  clearSelection,
  disabled,
  displaySearchField = true,
  displayShowMore,
  id,
  isError,
  isFirstPage,
  isLoading,
  items,
  label,
  onChangeCheckbox,
  onChangeSearchField,
  placement,
  searchField = "",
  selectedItems,
  showMore,
  totalItems,
}: IDropdownProps) => {
  const handleClearSelection = () => {
    clearSelection();
  };

  const totalSelectedItems = !selectedItems
    ? items.filter((x) => x.isSelected).length
    : selectedItems.length;
  const totalLoadedItems = items.length;

  const getSelectedFilters = () => {
    if (totalSelectedItems == 0) {
      return label;
    }

    return `${label}: (${totalSelectedItems})`;
  };

  const shouldDisplayShowMore =
    items.length < totalItems && displayShowMore && !isLoading && !isError;

  const dropdownId = `dropdown-${label.toLowerCase().replace(" ", "-")}`;

  useEffect(() => {
    const group = document.querySelector(`#vera-dropdown-item-group-${id}`);
    if (group && !isFirstPage) {
      group.scrollTop = group.scrollHeight;
    }
  }, [items?.length, isFirstPage]);

  return (
    <div
      aria-label={label}
      className={`vera-dropdown${disabled ? " vera-dropdown--disabled" : ""}`}
      id={dropdownId}
    >
      <DropdownMenu
        placement={placement}
        shouldRenderToParent={true}
        testId={dropdownId}
        trigger={getSelectedFilters()}
      >
        <div>
          {displaySearchField && (
            <Textfield
              className="txt-search-term input-search"
              elemAfterInput={
                searchField && (
                  <button
                    className="btn-link btn-clear-search-term"
                    onClick={() =>
                      onChangeSearchField && onChangeSearchField("")
                    }
                  >
                    <CrossCircleIcon label="delete" />
                  </button>
                )
              }
              id="search-term"
              onChange={(e) =>
                onChangeSearchField &&
                onChangeSearchField(e.currentTarget.value)
              }
              placeholder="Search"
              value={searchField}
            />
          )}
          {isLoading && isFirstPage ? (
            <div className="dropdown-spinner">
              <Spinner size="medium" />
            </div>
          ) : (
            <DropdownItemCheckboxGroup id={`vera-dropdown-item-group-${id}`}>
              <DropdownItems
                isError={isError}
                isFirstPage={isFirstPage}
                isLoading={isLoading}
                items={items}
                onChangeCheckbox={onChangeCheckbox}
                selectedItems={selectedItems}
              />
              {shouldDisplayShowMore ? (
                <button
                  className="btn-show-more"
                  key="show more"
                  onClick={() => showMore && showMore()}
                >
                  <span>Show more</span>
                </button>
              ) : undefined}
            </DropdownItemCheckboxGroup>
          )}
        </div>
        <div className="d-flex align-items-center dropdown-footer">
          <button
            className="btn-link dropdown-clear-selection"
            disabled={totalSelectedItems == 0}
            onClick={handleClearSelection}
          >
            Clear Selection
          </button>
          <div className="dropdown-selected-items">
            {`${totalSelectedItems} selected`}
          </div>
          <div className="dropdown-selected-items">
            {`${(isFirstPage && isLoading) || isError ? 0 : totalLoadedItems} displayed`}
          </div>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default Dropdown;
