import React from "react";
import { useActions } from "../../../../../../hooks/useActions";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../../../constants/admin-page.constants";
import { ErrorMessage } from "../../../../../../constants/general.constants";
import GenericModal from "../../../../../modules/GenericModal";
import { IDefaultResponse } from "../../../../../interfaces/General";
import { PolicySetsRequestRoutes } from "../../../../../../constants/policy-set.constants";
import Form from "../../../../../modules/common/Form";
import Alert from "../../../../../modules/Alert";
import { IDeletePolicySetConfirmationModalProps } from "../../../../../interfaces/PolicySet";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";

const DeletePolicySetConfirmationModal = ({
  hasDomains,
  hasPolicies,
  id,
  name,
  visible,
  onClose,
}: IDeletePolicySetConfirmationModalProps) => {
  const [loading, setLoading] = React.useState(false);
  const { fetchPolicySets, updatePolicySetsPageParams } = useActions();
  const { tableData, sortParams } = useTypedSelector((x) => x.policySets);

  const handleOnClickDelete = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete<IDefaultResponse<any>>(
        PolicySetsRequestRoutes.DELETE_POLICY_SET,
        {
          params: { id },
        },
      );

      if (data.apiStatusCode === 204) {
        toast.success(AdminPageSuccessMessages.DELETE_POLICY_SET);
        if (tableData.rows.length === 1) {
          updatePolicySetsPageParams(0, sortParams.limit);
        } else {
          fetchPolicySets();
        }
      } else {
        toast.error(AdminPageErrors.DELETE_POLICY_SET);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      onClose();
    } finally {
      onClose();
      setLoading(false);
    }
  };

  const getWarningMessage = () => {
    return (
      <ul>
        {hasPolicies && (
          <li>Associated policies will be delinked from policy set.</li>
        )}
        {hasDomains && (
          <li>
            Policy set is already linked to one or more domains. Deleting this
            will cause associated domains to use default policies.
          </li>
        )}
      </ul>
    );
  };

  return (
    <GenericModal
      labelConfirm="Delete"
      loading={loading}
      onClickConfirm={handleOnClickDelete}
      onCloseModal={onClose}
      size="medium"
      title="Delete Policy Set"
      visible={visible}
    >
      <Form>
        {`Are you sure you want to delete this policy set: ${name}?`}
        {(hasDomains || hasPolicies) && <Alert message={getWarningMessage()} />}
      </Form>
    </GenericModal>
  );
};

export default DeletePolicySetConfirmationModal;
