import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useEffect } from "react";
import { escapeOnlyNonHtmlTags } from "../../helpers/html.helper";
import { IconButton } from "@atlaskit/button/new";

const ExpandedContentModal = () => {
  const { content, renderAsHtml, showModal, title } = useTypedSelector(
    (x) => x.expandedContentModal,
  );
  const { closeExpandedContentModal } = useActions();

  const handleCloseModal = () => {
    closeExpandedContentModal();
  };

  useEffect(() => {
    return () => {
      closeExpandedContentModal();
    };
  }, []);

  return (
    <ModalTransition>
      {showModal && (
        <Modal
          label="expanded-content-modal"
          onClose={closeExpandedContentModal}
          shouldScrollInViewport={true}
          width="large"
        >
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={handleCloseModal}
            />
          </ModalHeader>
          <ModalBody>
            {renderAsHtml ? (
              <div
                className="external-content"
                dangerouslySetInnerHTML={{
                  __html: escapeOnlyNonHtmlTags(content),
                }}
              ></div>
            ) : (
              content
            )}
          </ModalBody>
          <ModalFooter />
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ExpandedContentModal;
