import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  ModuleInformation,
  VersionsPageData,
} from "../../interfaces/VersionsPage";
import Loader from "../../modules/Loader";
import {
  ErrorMessage,
  VersionsPageRequestRoute,
} from "../../../constants/general.constants";

const VersionsPage = () => {
  const [versionsData, setVersionsData] = React.useState<VersionsPageData>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getVersionsData = async () => {
    try {
      const { data, status } = await axios.get<VersionsPageData>(
        VersionsPageRequestRoute,
      );
      if (status === 200) {
        if (data.errorMessage) {
          toast.error(data.errorMessage);
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        setVersionsData(data);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
  useEffect(() => {
    getVersionsData();
  }, []);

  return (
    <main className="pt-3" role="main">
      <h4>
        <span className="font-weight-bolder mt-3">Versions</span>
      </h4>

      <div className="jumbotron">
        {isLoading && <Loader message="Loading, please wait..." />}

        {versionsData && (
          <>
            <h5 className="web-portal-info__headline font-weight-bolder">
              <u>Web Portal Info</u>
            </h5>
            <div className="row ">
              <div className="col ">
                <div className="version__item font-weight-bolder">
                  <span>Version:</span>
                  <span>{versionsData.webversion}</span>
                </div>

                <div className="build-info__headline font-weight-bolder">
                  Build Info:
                </div>
                {versionsData.build ? (
                  <>
                    <div className="version__item font-weight-bolder">
                      <span>Build:</span>
                      <span>{versionsData.build}</span>
                    </div>

                    <div className="version__item font-weight-bolder">
                      <span>Build Date:</span>
                      <span>{versionsData.buildDate}</span>
                    </div>
                  </>
                ) : (
                  <div className="version__item--not-found ml-3">
                    <span>Could not retrieve build info</span>
                  </div>
                )}
              </div>
            </div>
            <div>
              {versionsData.hasVersionInfo ? (
                <>
                  <br />
                  <h5 className="api-info__headline font-weight-bolder">
                    <u>API Server Info</u>
                  </h5>
                  <div className="row">
                    <div className="col">
                      <div className="version__item font-weight-bolder">
                        <span>API Version:</span>
                        <span>{versionsData.versionInfo[`VERA REST API`]}</span>
                      </div>

                      <div className="version__item font-weight-bolder">
                        <span>Server Version:</span>
                        <span>{versionsData.versionInfo["Vera Server"]}</span>
                      </div>

                      <div className="row">
                        <div className="col">
                          <br />

                          <div className="font-weight-bolder build-info__headline">
                            Build Info:
                          </div>
                          {versionsData.versionInfo["Build Info"].Build ? (
                            <>
                              <div className="build-info__item font-weight-bolder ml-3">
                                <span>Build:</span>
                                <span>
                                  {versionsData.versionInfo["Build Info"].Build}
                                </span>
                              </div>

                              <div className="build-info__item font-weight-bolder ml-3">
                                <span>Build Date:</span>
                                <span>
                                  {
                                    versionsData.versionInfo["Build Info"][
                                      "Build Date"
                                    ]
                                  }
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="version__item--not-found ml-3">
                              <span>Could not retrieve build info</span>
                            </div>
                          )}
                        </div>
                      </div>

                      <br />

                      <div className="font-weight-bolder module__headline">
                        Modules:
                      </div>
                      {versionsData.moduleInformation.map(
                        (item: ModuleInformation) => (
                          <div
                            className="module__item font-weight-bolder ml-3"
                            key={item.label}
                          >
                            <span>{item.label}:</span>
                            <span>{item.value}</span>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col">
                    <div className="font-weight-bolder ml-3">
                      Could not load Server Info
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default VersionsPage;
