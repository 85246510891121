import React from "react";
import { LinkedRecordsProps } from "../../../../interfaces/DetailsPage";
import LinkedRecord from "./LinkedRecord";

const LinkedRecords = ({ recordId, linkedRecords }: LinkedRecordsProps) => {
  const records = Object.entries(linkedRecords);
  return (
    <>
      {records &&
        records.map((linkedRecord) => {
          const linkedRecordName = linkedRecord[0];

          return (
            <div
              className="pt-3 pb-3"
              id={`linkedRecordsGroupTitle_${linkedRecordName.replaceAll(
                " ",
                "",
              )}_`}
              key={linkedRecordName}
            >
              <LinkedRecord
                linked_records={linkedRecord[1]}
                name={linkedRecordName}
                recordId={recordId}
              />
            </div>
          );
        })}
    </>
  );
};

export default React.memo(LinkedRecords);
