import { Radio } from "@atlaskit/radio";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useAppDispatch } from "../../../../store/store";
import { updateSelectedRole } from "../../../../store/slices/role-management.slice";

interface IRolesSelectorProps {
  roles: string[];
}

const RoleSelector = ({ roles }: IRolesSelectorProps) => {
  const dispatch = useAppDispatch();
  const { selectedRole } = useTypedSelector((x) => x.rolesManagement);

  return (
    <table className="w-100">
      <tbody>
        {roles.map((role) => (
          <tr
            className={`role ${role === selectedRole ? "role--selected" : ""}`}
            key={role}
            onClick={() => dispatch(updateSelectedRole(role))}
          >
            <td className="role-selector-checkbox__container">
              <Radio
                ariaLabel="roles-radio-item"
                className="role-selector-checkbox"
                crossOrigin="true"
                isChecked={role === selectedRole}
                name={role}
                onChange={() => dispatch(updateSelectedRole(role))}
                value={role}
              />
            </td>

            <td id={`row-${role}-description`}>{role}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RoleSelector;
