export function gainsightIdentifyCall(UserData) {
  if (typeof window !== "undefined" && window.aptrinsic) {
    window.aptrinsic(
      "identify",
      {
        //User Fields
        //ID tracking required, other PII prohibited under GDPR
        id: UserData.veraUserId,
      },
      {
        //Account Fields
        //Placeholder for configurable account information
        id: UserData.gainsightAccId,
        name: UserData.gainsightAccName,
      },
    );
  }
}
