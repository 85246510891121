import { IRoute } from "../../../interfaces/DetailsPage";

const PrintViewTaskGroups = ({ route }: { route?: IRoute }) => {
  return (
    <div className="table-responsive mt-2">
      <table className="table table-sm table-bordered" id="tblApprovals">
        <tbody>
          {route?.taskGroups?.map((subTask) =>
            subTask.tasks.map((task) => (
              <tr className="d-flex" key={task._id}>
                <td className="col-6">
                  <b>{task.meaning}: </b>
                  <br />
                  {task.status === "Complete" ? task.reviewerName : <p />}
                </td>

                <td className="col-6">
                  <b>Date: </b>
                  <br />
                  {task.status === "Complete" ? task.timestamp : <p />}
                </td>
              </tr>
            )),
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PrintViewTaskGroups;
