import * as yup from "yup";
import { IFormData } from "../components/interfaces/ChangePassword";
import { generatePasswordRegExp } from "../constants/general.constants";
import { IPasswordPolicyFormData } from "../components/interfaces/AdminPage";

export function createChangePasswordSchema({
  minLower,
  minUpper,
  minSpecial,
  minNumeric,
  minLength,
}: IPasswordPolicyFormData) {
  const newPasswordRules = () => (
    <>
      Your password must contain the following:
      <br />
      <ul className="text-left">
        <li>Between {minLength} and 128 characters</li>
        {minSpecial > 0 && <li>At least {minSpecial} special character</li>}
        {minUpper > 0 && <li>At least {minUpper} uppercase letter</li>}
        {minLower > 0 && <li>At least {minLower} lowercase letter</li>}
        {minNumeric > 0 && <li>At least {minNumeric} number</li>}
      </ul>
    </>
  );

  return yup.object().shape({
    confirmPassword: yup
      .string()
      .required("Please re-enter your new password.")
      .oneOf([yup.ref("newPassword"), ""], "Passwords must match"),
    newPassword: yup
      .string()
      .required("Please enter your new password.")
      .matches(
        generatePasswordRegExp(
          minLower,
          minUpper,
          minSpecial,
          minNumeric,
          minLength,
        ),
        newPasswordRules,
      ),
    currentPassword: yup
      .string()
      .required("Please enter your current password."),
  });
}

export function createSingleFieldScheme({
  minLower,
  minUpper,
  minSpecial,
  minNumeric,
  minLength,
}: IPasswordPolicyFormData) {
  const newPasswordRules = () => (
    <>
      Your password must contain the following:
      <br />
      <ul className="text-left">
        <li>Between {minLength} and 128 characters</li>
        {minSpecial > 0 && <li>At least {minSpecial} special character</li>}
        {minUpper > 0 && <li>At least {minUpper} uppercase letter</li>}
        {minLower > 0 && <li>At least {minLower} lowercase letter</li>}
        {minNumeric > 0 && <li>At least {minNumeric} number</li>}
      </ul>
    </>
  );

  return yup
    .string()
    .required("Please enter user's new password.")
    .matches(
      generatePasswordRegExp(
        minLower,
        minUpper,
        minSpecial,
        minNumeric,
        minLength,
      ),
      newPasswordRules,
    );
}

export const validateChangePassword = (
  data: IFormData,
  setErrorHandler: any,
  passwordPolicyData: IPasswordPolicyFormData,
) => {
  const validateScheme = createChangePasswordSchema(passwordPolicyData);
  validateScheme
    .validate(data)
    .then(() => setErrorHandler({}))
    .catch((err) => setErrorHandler({ [err.path]: err.message || "" }));
};

export const validateNewPassword = async (
  password: string,
  setErrorHandler: any,
  passwordPolicyData: IPasswordPolicyFormData,
) => {
  const validateSingleFieldScheme = createSingleFieldScheme(passwordPolicyData);
  validateSingleFieldScheme
    .validate(password)
    .then(() => setErrorHandler(""))
    .catch((err) => setErrorHandler(err.message));
};
