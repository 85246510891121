import { AdminPageQueryParams } from "../../interfaces/AdminPage";
import { matchPath, useLocation } from "react-router-dom";
import {
  AdminPageRoutes,
  AppRoutes,
} from "../../../constants/general.constants";
import DomainDetailsIcon from "./../../../images/bx-sitemap.svg";
import RoleIcon from "./../../../images/bxs-group.svg";
import DomainIcon from "./../../../images/bx-world.svg";
import SidebarItem from "./SidebarItem";

const SidebarDomainAdmin = () => {
  const location = useLocation();

  const matchUsers = matchPath<AdminPageQueryParams>(location.pathname, {
    path: [AppRoutes.AdminPage],
  });

  const displaySubItem = () => {
    const routes = [
      AdminPageRoutes.DOMAIN_DETAILS,
      AdminPageRoutes.ROLE_MANAGEMENT,
    ];

    return (
      matchUsers?.params?.subjectId &&
      matchUsers?.params?.subRoute &&
      routes.some((route) => route.includes(matchUsers?.params?.subRoute))
    );
  };

  return (
    <ul className="features-list">
      <SidebarItem
        icon={<img className="white-icon" src={DomainIcon} />}
        label="Domain Management"
        to={AdminPageRoutes.DOMAIN_MANAGEMENT}
      />
      {displaySubItem() && (
        <>
          <SidebarItem
            icon={<img className="white-icon" src={DomainDetailsIcon} />}
            label="Domain Details"
            level={1}
            to={`${AdminPageRoutes.DOMAIN_DETAILS}/${matchUsers?.params?.subjectId}`}
          />

          <SidebarItem
            icon={<img className="white-icon" src={RoleIcon} />}
            label="Role Management"
            level={1}
            to={`${AdminPageRoutes.ROLE_MANAGEMENT}/${matchUsers?.params?.subjectId}`}
          />
        </>
      )}
    </ul>
  );
};

export default SidebarDomainAdmin;
