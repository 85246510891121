import React, { useState } from "react";
import RecordHistoryDetailsItem from "./RecordHistoryDetailsItem";
import { RecordHistorySubDetailsProps } from "../../../interfaces/DetailsPage";

const RecordHistorySubDetails: React.FC<RecordHistorySubDetailsProps> = ({
  recordId,
  subDetails,
  getDetailsMarkup,
}) => {
  const [subDetailFieldCollapsed, setSubDetailFieldCollapsed] =
    useState<boolean>(true);

  return (
    <div className="row container pt-2" id={`accordionSubDetails${recordId}`}>
      <a
        className="text-decoration-none toggleRotate"
        data-bs-target={`#collapseSubDetails${recordId}`}
        data-bs-toggle="collapse"
        href="#!"
        onClick={() => setSubDetailFieldCollapsed(!subDetailFieldCollapsed)}
      >
        <i
          className={
            subDetailFieldCollapsed
              ? "fa fa-chevron-circle-right"
              : "fa fa-chevron-circle-down"
          }
        />
        <h6 className="d-inline details-title"> More Details...</h6>
      </a>

      <div
        className="container-fluid collapse pt-2 ml-2"
        data-bs-parent={`#accordionSubDetails${recordId}`}
        id={`collapseSubDetails${recordId}`}
      >
        {subDetails.map((subDetail) => (
          <>
            <RecordHistoryDetailsItem
              detailsItem={subDetail}
              getDetailsMarkup={getDetailsMarkup}
            />

            {subDetail.subDetails?.length > 0 && (
              <RecordHistorySubDetails
                getDetailsMarkup={getDetailsMarkup}
                recordId={subDetail["Record ID"]}
                subDetails={subDetail.subDetails}
              />
            )}

            {/* displays divider if there are more than 1 item */}
            {subDetails.indexOf(subDetail) !== subDetails.length - 1 && <hr />}
          </>
        ))}
      </div>
    </div>
  );
};

export default RecordHistorySubDetails;
