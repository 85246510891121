import { IFilterData } from "../../components/interfaces/LocateRecordsFilter";

export interface ILocateRecordsFilterState {
  projects: IFilterData;
  recordTypes: IFilterData;
  recordStatus: IFilterData;
  recordNameSearchField: string;
}

export interface ILocateRecordsState extends ILocateRecordsFilterState {
  projectSearchField: string;
  recordTypeSearchField: string;
  recordStatusSearchField: string;
  isLoadingProjects: boolean;
  isLoadingRecordTypes: boolean;
  isLoadingRecordStatuses: boolean;
  selectedProjects: string[];
  selectedRecordTypes: string[];
  skipProjects: number;
  skipRecordTypes: number;
}

export interface ILocateRecordsSearchParams {
  selectedProjects: string[];
  selectedRecordTypes: string[];
  recordStatus: IFilterData;
  recordNameSearchField: string;
}

export enum LocateRecordsFilterActionTypes {
  FETCHING_PROJECTS = "FETCHING_PROJECTS",
  FETCHING_RECORD_TYPES = "FETCHING_RECORD_TYPES",
  FETCHING_RECORD_STATUS = "FETCHING_RECORD_STATUS",
  UPDATE_PROJECTS = "UPDATE_PROJECTS",
  SHOW_MORE_PROJECTS = "SHOW_MORE_PROJECTS",
  SHOW_MORE_RECORD_TYPES = "SHOW_MORE_RECORD_TYPES",
  SHOW_MORE_RECORD_STATUSES = "SHOW_MORE_RECORD_STATUSES",
  UPDATE_RECORD_TYPES = "UPDATE_RECORD_TYPES",
  UPDATE_RECORD_STATUS = "UPDATE_RECORD_STATUS",
  UPDATE_SEARCH_FIELD = "UPDATE_SEARCH_FIELD",
  UPDATE_SEARCH_FIELD_PROJECT = "UPDATE_SEARCH_FIELD_PROJECT",
  UPDATE_SEARCH_FIELD_RECORD_TYPE = "UPDATE_SEARCH_FIELD_RECORD_TYPE",
  UPDATE_SEARCH_FIELD_RECORD_STATUS = "UPDATE_SEARCH_FIELD_RECORD_STATUS",
  UPDATE_SEARCH_FIELD_RECORD_NAME = "UPDATE_SEARCH_FIELD_RECORD_NAME",
  UPDATE_SELECTED_PROJECT = "UPDATE_SELECTED_PROJECT",
  UPDATE_SELECTED_PROJECTS = "UPDATE_SELECTED_PROJECTS",
  UPDATE_SELECTED_RECORD_TYPE = "UPDATE_SELECTED_RECORD_TYPE",
  UPDATE_SELECTED_RECORD_TYPES = "UPDATE_SELECTED_RECORD_TYPES",
  UPDATE_SELECTED_RECORD_STATUS = "UPDATE_SELECTED_RECORD_STATUS",
  UPDATE_SELECTED_RECORD_STATUSES = "UPDATE_SELECTED_RECORD_STATUSES",
  CLEAR_SELECTED_PROJECT = "CLEAR_SELECTED_PROJECT",
  CLEAR_SELECTED_RECORD_TYPE = "CLEAR_SELECTED_RECORD_TYPE",
  CLEAR_SELECTED_RECORD_STATUS = "CLEAR_SELECTED_RECORD_STATUS",
  CLEAR_FILTER = "CLEAR_FILTER",
  UPDATE_ALL_FILTERS = "UPDATE ALL FILTERS",
  HANDLE_NETWORK_ERROR = "HANDLE_NETWORK_ERROR",
}

interface HandleNetworkError {
  type: LocateRecordsFilterActionTypes.HANDLE_NETWORK_ERROR;
}

interface FetchingProjects {
  type: LocateRecordsFilterActionTypes.FETCHING_PROJECTS;
  payload: number;
}

interface UpdateProjects {
  type: LocateRecordsFilterActionTypes.UPDATE_PROJECTS;
  payload: {
    rows: string[];
    total: number;
  };
}

interface ShowMoreProjects {
  type: LocateRecordsFilterActionTypes.SHOW_MORE_PROJECTS;
  payload: {
    rows: string[];
    total: number;
  };
}

interface ShowMoreRecordTypes {
  type: LocateRecordsFilterActionTypes.SHOW_MORE_RECORD_TYPES;
  payload: {
    rows: string[];
    total: number;
  };
}

interface ShowMoreRecordStatuses {
  type: LocateRecordsFilterActionTypes.SHOW_MORE_RECORD_STATUSES;
  payload: {
    rows: string[];
    total: number;
  };
}

interface UpdateSearchFieldProject {
  type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_PROJECT;
  payload: {
    searchField: string;
  };
}

interface UpdateSearchFieldRecordType {
  type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_TYPE;
  payload: {
    searchField: string;
  };
}

interface UpdateSearchFieldRecordStatus {
  type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_STATUS;
  payload: {
    searchField: string;
  };
}

interface UpdateSelectedProject {
  type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_PROJECT;
  payload: string;
}

interface UpdateSelectedProjects {
  type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_PROJECTS;
  payload: {
    projects: string[];
  };
}

interface ClearSelectedProjects {
  type: LocateRecordsFilterActionTypes.CLEAR_SELECTED_PROJECT;
}

interface FetchingRecordTypes {
  type: LocateRecordsFilterActionTypes.FETCHING_RECORD_TYPES;
  payload: number;
}

interface UpdateRecordTypes {
  type: LocateRecordsFilterActionTypes.UPDATE_RECORD_TYPES;
  payload: {
    rows: string[];
    total: number;
  };
}

interface UpdateSelectedRecordType {
  type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_TYPE;
  payload: string;
}

interface UpdateSelectedRecordTypes {
  type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_TYPES;
  payload: {
    recordTypes: string[];
  };
}

interface ClearSelectedRecordTypes {
  type: LocateRecordsFilterActionTypes.CLEAR_SELECTED_RECORD_TYPE;
}

interface FetchingRecordStatus {
  type: LocateRecordsFilterActionTypes.FETCHING_RECORD_STATUS;
}

interface UpdateRecordStatus {
  type: LocateRecordsFilterActionTypes.UPDATE_RECORD_STATUS;
  payload: {
    rows: string[];
    total: number;
  };
}

interface UpdateSelectedRecordStatus {
  type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_STATUS;
  payload: {
    name: string;
    isSelected: boolean;
  };
}

interface UpdateSelectedRecordStatuses {
  type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_STATUSES;
  payload: {
    recordStatuses: string[];
  };
}

interface ClearSelectedRecordStatus {
  type: LocateRecordsFilterActionTypes.CLEAR_SELECTED_RECORD_STATUS;
}

interface UpdateSearchFieldRecordName {
  type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_NAME;
  payload: {
    searchField: string;
  };
}

interface ClearFilter {
  type: LocateRecordsFilterActionTypes.CLEAR_FILTER;
}

interface UpdateAllFilters {
  type: LocateRecordsFilterActionTypes.UPDATE_ALL_FILTERS;
  payload: {
    projects: string[];
    recordTypes: string[];
    recordStatus: string[];
  };
}

export type ProjectAction =
  | FetchingProjects
  | UpdateProjects
  | HandleNetworkError
  | UpdateSelectedProject
  | UpdateSelectedProjects
  | FetchingRecordTypes
  | UpdateRecordTypes
  | UpdateSelectedRecordType
  | UpdateSelectedRecordTypes
  | FetchingRecordStatus
  | UpdateRecordStatus
  | UpdateSelectedRecordStatus
  | UpdateSelectedRecordStatuses
  | ClearFilter
  | ClearSelectedProjects
  | ClearSelectedRecordTypes
  | ClearSelectedRecordStatus
  | UpdateSearchFieldProject
  | UpdateSearchFieldRecordType
  | UpdateSearchFieldRecordStatus
  | UpdateSearchFieldRecordName
  | ShowMoreProjects
  | ShowMoreRecordTypes
  | ShowMoreRecordStatuses
  | UpdateAllFilters;
