import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useGetVersionQuery } from "../../store/slices/version.slice";
import CopyrightMessage from "./CopyrightMessage";

const Footer = () => {
  const { initialization, user, hasActiveSession } = useTypedSelector(
    (state) => state.user,
  );

  const { data } = useGetVersionQuery(undefined, { skip: !hasActiveSession });

  return hasActiveSession ? (
    <>
      {!initialization && (
        <footer className={"no-print footer "} id="pageFooter">
          <div className={user ? "bottom-credits-wrapper" : ""}>
            <div className="d-flex align-items-center flex-column">
              <small>{`Vera ${data?.version ?? ""}`}</small>
              <small>
                <CopyrightMessage className="sidebar-link" />
              </small>
            </div>
          </div>
        </footer>
      )}
    </>
  ) : (
    <></>
  );
};

export default Footer;
