import { IConnectionsState } from "../../components/interfaces/Connections";
import {
  ConnectionsAction,
  ConnectionsActionTypes,
} from "../types/connections";

const initialState: IConnectionsState = {
  connectionsData: {
    rows: [],
    total: 0,
    totalNotFiltered: 0,
  },
  isLoading: false,
  selectedPage: 0,
  sortParams: {
    sort: "DateCreated",
    order: "desc",
    offset: 0,
    limit: 15,
  },
  tableHeaders: [
    { label: "Name", name: "Name", filterable: true, colSize: 4 },
    { label: "System", name: "System", filterable: true, colSize: 1 },
    {
      label: "Date Created",
      name: "DateCreated",
      filterable: true,
      colSize: 3,
    },
    {
      label: "Last Updated",
      name: "LastUpdated",
      filterable: true,
      colSize: 3,
    },
  ],
};

export const connectionsReducer = (
  state = initialState,
  action: ConnectionsAction,
): IConnectionsState => {
  switch (action.type) {
    case ConnectionsActionTypes.FETCHING_CONNECTIONS_DATA:
      return { ...state, isLoading: true };
    case ConnectionsActionTypes.UPDATE_CONNECTIONS_DATA:
      return {
        ...state,
        isLoading: false,
        connectionsData: action.payload,
      };
    case ConnectionsActionTypes.UPDATE_CONNECTIONS_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case ConnectionsActionTypes.UPDATE_CONNECTIONS_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case ConnectionsActionTypes.UPDATE_CONNECTIONS_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case ConnectionsActionTypes.HANDLE_CONNECTIONS_ERROR:
      return {
        ...state,
        connectionsData: initialState.connectionsData,
        isLoading: false,
      };
    case ConnectionsActionTypes.RESET_CONNECTIONS_DATA:
      return initialState;
    default:
      return state;
  }
};
