import { Dispatch } from "redux";
import {
  DefectsModalAction,
  DefectsModalActionTypes,
} from "../types/defectsModal";
import { IDefectsModalData } from "../../components/interfaces/DefectsModal";

export const openDefectsModal = (payload: IDefectsModalData) => {
  return async (dispatch: Dispatch<DefectsModalAction>) => {
    dispatch({ type: DefectsModalActionTypes.SHOW_DEFECTS_MODAL, payload });
  };
};

export const closeDefectsModal = () => {
  return async (dispatch: Dispatch<DefectsModalAction>) => {
    dispatch({ type: DefectsModalActionTypes.HIDE_DEFECTS_MODAL });
  };
};
