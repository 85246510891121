import {
  IRecordFilter,
  RecordAction,
  RecordActionTypes,
} from "../types/record";
import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import axios, { CancelTokenSource } from "axios";
import { IRecordDataResponse } from "../../components/interfaces/Record";
import { toast } from "react-toastify";
import { RecordRequestRoutes } from "../../constants/record.constants";
import { RootState } from "../reducers";
import { isEqual } from "lodash";

let cancelToken: CancelTokenSource;
export const fetchRecords = () => {
  return async (
    dispatch: Dispatch<RecordAction>,
    getState: () => RootState,
  ) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();

    const state = getState();

    const { sortParams, filter } = state.records;
    const { project, recordStatus, recordType, recordName } = filter;
    const { offset, ...recordSortParams } = sortParams;

    if (
      !project.length &&
      !recordStatus.length &&
      !recordType.length &&
      !recordName
    ) {
      return;
    }

    const params = {
      project: project.length ? project.join(",") : undefined,
      recordType: recordType.length ? recordType.join(",") : undefined,
      recordStatus: recordStatus.length ? recordStatus.join(",") : undefined,
      recordName: recordName ? recordName : undefined,
      ...recordSortParams,
      skip: offset,
    };

    try {
      dispatch({ type: RecordActionTypes.FETCHING_RECORD_TABLE_DATA });

      const { data, status } = await axios.get<IRecordDataResponse>(
        `${RecordRequestRoutes.RECORDS_LIST}`,
        { cancelToken: cancelToken.token, params },
      );

      if (status === 200) {
        dispatch({
          type: RecordActionTypes.UPDATE_RECORD_TABLE_DATA,
          payload: {
            tableData: data,
            rowsData: data.rows,
          },
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        const errorMessage = error?.response?.data?.message ?? error.message;
        toast.error(errorMessage);
        dispatch({
          type: RecordActionTypes.HANDLE_RECORD_NETWORK_ERROR,
          payload: errorMessage,
        });
      }
    }
  };
};

export const updateRecordSelectedPage = (selectedPage: number) => {
  return {
    type: RecordActionTypes.UPDATE_RECORD_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const updateRecordSortParams = (sort: string, order: "asc" | "desc") => {
  return async (dispatch: Dispatch<RecordAction>) => {
    dispatch({
      type: RecordActionTypes.UPDATE_RECORD_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updateRecordPageParams = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<RecordAction>) => {
    dispatch({
      type: RecordActionTypes.UPDATE_RECORD_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetSortParams = () => {
  return async (dispatch: Dispatch<RecordAction>) => {
    dispatch({ type: RecordActionTypes.RESET_RECORD_SORT_PARAMS });
  };
};

export const handleResetRecordData = () => {
  cancelToken && cancelToken.cancel(ErrorMessage.LOGOUT);
  return async (dispatch: Dispatch<RecordAction>) => {
    dispatch({ type: RecordActionTypes.RESET_RECORD_DATA });
  };
};

export const updateRecordFilter = (filter?: IRecordFilter) => {
  return async (
    dispatch: Dispatch<RecordAction>,
    getState: () => RootState,
  ) => {
    const state = getState();

    let recordFilter: IRecordFilter;

    if (filter) {
      recordFilter = filter;
    } else {
      const {
        selectedProjects,
        recordStatus,
        selectedRecordTypes,
        recordNameSearchField,
      } = state.locateRecordsFilter;

      recordFilter = {
        project: selectedProjects,
        recordStatus: recordStatus.items
          .filter((x) => x.isSelected)
          .map((x) => x.name),
        recordType: selectedRecordTypes,
        recordName: recordNameSearchField,
      };
    }

    dispatch({
      type: RecordActionTypes.UPDATE_RECORD_FILTER,
      payload: recordFilter,
    });
  };
};

export const isFilterApplied = (state: RootState) => {
  const {
    selectedProjects,
    recordStatus,
    selectedRecordTypes,
    recordNameSearchField,
  } = state.locateRecordsFilter;

  const filterApplied = state.records.filter;
  const filter = {
    project: selectedProjects,
    recordStatus: recordStatus.items
      .filter((x) => x.isSelected)
      .map((x) => x.name),
    recordType: selectedRecordTypes,
    recordName: recordNameSearchField,
  };

  return isEqual(filterApplied, filter);
};
