import {
  IPoliciesForm,
  IPolicySet,
  IPolicySetForm,
} from "../components/interfaces/PolicySet";

export const transformPolicySet = (policySet: IPolicySet): IPolicySetForm => {
  return {
    _id: policySet._id,
    name: policySet.Name,
    description: policySet.Description,
  };
};

export const transformToPoliciesForm = (
  policySet: IPolicySet,
): IPoliciesForm => {
  return {
    id: policySet._id,
    approvalPolicy: policySet?.ApprovalPolicy
      ? {
          label: policySet.ApprovalPolicy.Name,
          value: policySet.ApprovalPolicy._id,
        }
      : null,
    recordsManagementPolicy: policySet?.RecordsManagementPolicy
      ? {
          label: policySet.RecordsManagementPolicy.Name,
          value: policySet.RecordsManagementPolicy._id,
        }
      : null,
  };
};
