import Tooltip from "@atlaskit/tooltip";
import { IPolicyRevisionsTableItemProps } from "../../../../../interfaces/Policy";
import TruncatedText from "../../../../../modules/TruncatedText";
import IconButton from "../../../../../modules/common/IconButton";
import { PolicyService } from "../../../../../services/policyService";

const PolicyRevisionTableItem = ({
  name,
  policy,
}: IPolicyRevisionsTableItemProps) => {
  const {
    _id,
    Comment,
    LastUpdated,
    Revision,
    UpdatedByUserFullName,
    UpdatedByUserUserName,
  } = policy;

  const handleOnClickExportData = async () => {
    await PolicyService.export(name || "", Revision, _id);
  };

  return (
    <tr>
      <td>{Revision}</td>
      <td>{LastUpdated}</td>
      <td>
        {UpdatedByUserFullName
          ? `${UpdatedByUserFullName} (${UpdatedByUserUserName})`
          : ""}
      </td>
      <td>
        <TruncatedText
          content={Comment}
          maxLines={1}
          renderAsHtml={false}
          title="Revision Comment"
        />
      </td>
      <td>
        <div className="table-actions">
          <Tooltip content="Download" position="top">
            <IconButton
              aria-label="download-policy-btn"
              className="btn-link__download"
              icon="fa-download"
              id={`btn-export-policy-${_id}`}
              onClick={handleOnClickExportData}
            />
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

export default PolicyRevisionTableItem;
