import { useEffect } from "react";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import {
  resetData,
  setColumnsSearchField,
  setPageParams,
  setSelectedPage,
  setSortParams,
} from "../../../../../store/slices/user-session.slice";
import { useAppDispatch } from "../../../../../store/store";
import Table from "../../../../modules/Table";
import { toast } from "react-toastify";
import UserSessionsTableItem from "./UserSessionsTableItem";
import { useGetUserSessionsQuery } from "../../../../../store/slices/user-session.slice";

const UserSessions = () => {
  const dispatch = useAppDispatch();
  const { columnSearchField, selectedPage, sortParams, tableHeaders } =
    useTypedSelector((x) => x.userSessions);
  const {
    data: auditEntries = { rows: [], total: 0 },
    isFetching,
    error,
  } = useGetUserSessionsQuery(
    {
      limit: sortParams.limit,
      order: sortParams.order,
      skip: sortParams.offset,
      sort: sortParams.sort,
      filter: JSON.stringify(columnSearchField),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (error) {
      toast.error((error as any)?.data?.message);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  return (
    <div>
      <div className="table-heading-wrapper mb-3">
        <b>Active User Sessions</b>
      </div>

      <Table
        columnFiltrationIsActive={true}
        columnSearchField={columnSearchField}
        displayActionsColumn={true}
        isLoading={isFetching}
        selectedPage={selectedPage}
        setColumnsSearchField={(columns) =>
          dispatch(setColumnsSearchField(columns))
        }
        setPageParams={(offset, limit) =>
          dispatch(setPageParams({ offset, limit }))
        }
        setSelectedPage={(page: number) => dispatch(setSelectedPage(page))}
        setSortParams={(sort, order) =>
          dispatch(setSortParams({ sort, order }))
        }
        sortParams={sortParams}
        tableData={auditEntries}
        tableHeaders={tableHeaders}
      >
        {auditEntries?.rows?.map((userSession) => (
          <UserSessionsTableItem userSession={userSession} />
        ))}
      </Table>
    </div>
  );
};

export default UserSessions;
