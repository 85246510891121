import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import PassedImg from "../../../../images/VERAfy_pass.png";
import FailedImg from "../../../../images/VERAfy_fail.png";
import { VerificationStatus } from "../../../../constants/approval-page.constants";
import { EntityDetailsApprovalRouteProps } from "../../../interfaces/DetailsPage";
import ModalApprove from "../../approval-queue/ModalApprove";
import ModalReject from "../../approval-queue/ModalReject";
import { useActions } from "../../../../hooks/useActions";

const ApprovalRoute = ({
  route,
  routeView,
  assignedTask,
  handleTaskSubmit,
  onCloseApproveModal,
}: EntityDetailsApprovalRouteProps) => {
  const [isApprovalRouteCollapsed, setApprovalRouteCollapsed] = useState(false);
  const [isCollapsedTaskGroup, setCollapsedTaskGroup] = useState<any>({});
  const [isOpenApprove, setIsOpenApprove] = useState<boolean>(false);
  const [isOpenReject, setIsOpenReject] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setNewSessionID, clearSessionID } = useActions();

  const formatItemName = (name: string) => name.replaceAll(" ", "");
  const closeModal = (shouldRefresh: boolean) => {
    setIsOpenReject(false);
    setIsOpenApprove(false);
    clearSessionID();
    onCloseApproveModal(shouldRefresh);
  };

  const handleApproveOrReject = async (
    password: string,
    reason: string,
    taskId: string,
    taskRejection = false,
  ) => {
    setIsLoading(true);
    const result = await handleTaskSubmit(
      password,
      reason,
      taskId,
      taskRejection,
    );
    if (reason && result) {
      closeModal(true);
      setIsLoading(false);
    }

    if (!result) {
      setIsLoading(false);
    }
  };

  const openApproveModal = () => {
    setIsOpenApprove(true);
    setNewSessionID();
  };

  useEffect(() => {
    route?.taskGroups?.forEach((item) =>
      setCollapsedTaskGroup((prev: any) => ({
        ...prev,
        [formatItemName(item.name)]: false,
      })),
    );
  }, []);

  return (
    <div className="pb-3" id="accordionLevel">
      <a
        className="text-decoration-none toggleRotate"
        data-bs-target="#collapseLevels"
        data-bs-toggle="collapse"
        href="#!"
        onClick={() => setApprovalRouteCollapsed(!isApprovalRouteCollapsed)}
      >
        <i
          className={
            isApprovalRouteCollapsed
              ? "fa fa-chevron-circle-right"
              : "fa fa-chevron-circle-down"
          }
        />
        <h6 className="d-inline details-title"> Approval Route</h6>
      </a>

      <div
        className="collapse show pt-2"
        data-bs-parent="#accordionLevel"
        id="collapseLevels"
      >
        <div className="col-xs-12 col-md-6 details-field pb-2">
          <h6> {route?.name} </h6>
          {!!route?.status && (
            <div className="details-field">
              <label>Status: </label>
              <span>{route?.status}</span>
            </div>
          )}
          {route?.signaturesRequired === false && (
            <>
              <div className="details-field">
                No signatures required for this record.
              </div>
              {route.stopDate && (
                <div>
                  <span>Date: </span>
                  {route.stopDate}
                </div>
              )}
            </>
          )}
        </div>

        {route?.taskGroups &&
          route?.taskGroups.map((task) => (
            <div
              className="container-fluid"
              id={`accordion${formatItemName(task.name)}`}
              key={`${task.name}-${task.status}`}
            >
              <a
                className="text-decoration-none toggleRotate"
                data-bs-target={`#collapseLevel${formatItemName(task.name)}`}
                data-bs-toggle="collapse"
                href="#!"
                onClick={() =>
                  setCollapsedTaskGroup((prev: any) => ({
                    ...prev,
                    [formatItemName(task.name)]:
                      !isCollapsedTaskGroup[formatItemName(task.name)],
                  }))
                }
              >
                <i
                  className={
                    isCollapsedTaskGroup[formatItemName(task.name)]
                      ? "fa fa-chevron-circle-right"
                      : "fa fa-chevron-circle-down"
                  }
                />
                <h6 className="d-inline"> {task.name}</h6>
              </a>

              <div
                className="collapse show table-responsive pt-2"
                data-bs-parent={`#accordion${formatItemName(task.name)}`}
                id={`collapseLevel${formatItemName(task.name)}`}
              >
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col" />

                      <th className="text-nowrap" scope="col">
                        Approval Task
                      </th>

                      <th scope="col">Status</th>

                      <th scope="col">Reviewer</th>

                      <th scope="col">Date</th>

                      {!routeView && <th scope="col">Action</th>}
                    </tr>
                  </thead>

                  <tbody>
                    {task.tasks.map((subTask) => (
                      <tr className="table-item" key={subTask._id}>
                        {subTask.verificationStatus !== null ? (
                          <td>
                            <img
                              alt={subTask.verificationStatus}
                              src={
                                subTask.verificationStatus ===
                                VerificationStatus.Passed
                                  ? PassedImg
                                  : FailedImg
                              }
                              style={{ height: "25px" }}
                              title={`Last ${subTask.verificationStatus}: ${subTask.lastVerified}`}
                            />
                          </td>
                        ) : (
                          <td />
                        )}

                        <td>{subTask.meaning}</td>

                        <td>{subTask.status}</td>

                        {subTask.status === VerificationStatus.Complete ||
                        subTask.status === VerificationStatus.Rejected ? (
                          <td>{subTask.reviewerName}</td>
                        ) : (
                          <td>{subTask.group}</td>
                        )}

                        {subTask.status === VerificationStatus.Complete ||
                        subTask.status === VerificationStatus.Rejected ? (
                          <td>{subTask.timestamp}</td>
                        ) : (
                          <td />
                        )}

                        {!routeView && assignedTask?._id === subTask._id ? (
                          <td>
                            {isOpenApprove && (
                              <ModalApprove
                                approveId={subTask._id}
                                handleTaskSubmit={handleApproveOrReject}
                                hideLoading={() => setIsLoading(false)}
                                isLoading={isLoading}
                                isOpen={isOpenApprove}
                                meaning={subTask.meaning}
                                onCloseModal={closeModal}
                                showLoading={() => setIsLoading(true)}
                              />
                            )}

                            <ModalReject
                              handleTaskSubmit={handleApproveOrReject}
                              isLoading={isLoading}
                              isOpen={isOpenReject}
                              onCloseModal={closeModal}
                              rejectId={subTask._id}
                            />

                            <div className="d-flex">
                              <div
                                className="approval-tasks-action cursor-pointer"
                                onClick={() => openApproveModal()}
                              >
                                <FontAwesomeIcon
                                  className={"m-1"}
                                  color="green"
                                  icon={faPenAlt}
                                />{" "}
                                Approve
                              </div>

                              <div
                                className="approval-tasks-action cursor-pointer"
                                onClick={() => setIsOpenReject(true)}
                              >
                                <FontAwesomeIcon
                                  className={"m-1"}
                                  color="red"
                                  icon={faTimesCircle}
                                />{" "}
                                Reject
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ApprovalRoute;
