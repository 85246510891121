import { ITableSortParams } from "./AdminPage";

export enum eSummarizeBy {
  MAXIMUM = "max",
  MINIMUM = "min",
  AVERAGE = "avg",
}

export enum ePeriod {
  LAST_SEVEN_DAYS = "last-seven-days",
  LAST_THIRTY_DAYS = "last-thirty-days",
  LAST_YEAR = "last-year",
  CUSTOM_DATE_RANGE = "custom-date-range",
}

export enum eGroupBy {
  DAY = "day",
  MONTH = "month",
  YEAR = "year",
}

export const groupByDescriptionList = {
  [eGroupBy.DAY]: "Daily",
  [eGroupBy.MONTH]: "Monthly",
  [eGroupBy.YEAR]: "Yearly",
};

export const periodDescriptionList = {
  [ePeriod.LAST_SEVEN_DAYS]: "Last_7_Days",
  [ePeriod.LAST_THIRTY_DAYS]: "Last_30_Days",
  [ePeriod.LAST_YEAR]: "Last_Year",
};

export const summarizeDescriptionList = {
  [eSummarizeBy.MAXIMUM]: "Max_Concurrent_Users",
  [eSummarizeBy.MINIMUM]: "Min_Concurrent_Users",
  [eSummarizeBy.AVERAGE]: "Avg_Concurrent_Users",
};

export interface IConcurrentUsersState {
  groupBy: eGroupBy;
  lastData: IConcurrentUsersResponse[];
  summarizeBy: eSummarizeBy;
  period: ePeriod;
  startDate: string | null;
  endDate: string | null;
  errorStartDate: string | null;
  errorEndDate: string | null;
}

export interface IConcurrentUsersRequestParams {
  groupBy: eGroupBy;
  summarizeBy: eSummarizeBy;
  startDate?: string;
  endDate?: string;
}

export interface IConcurrentUsersResponse {
  date: string;
  userCount: number;
}

export interface IProvider {
  id: string;
  name: string;
  type: string;
}

export interface IUserListRequestParams {
  excludeDomain?: string;
  excludeRole?: string;
  searchTerm: string;
  skip: number;
}
