export enum PolicySetsRequestRoutes {
  DELETE_POLICY_SET = "/api/admin/delete-policy-set",
  LINK_POLICIES = "/api/admin/link-policies-to-policy-set",
  POLICY_SETS = "/api/admin/policy-sets",
  POLICY_SET = "/api/admin/policy-set",
  POLICY_SET_DOMAINS = "/api/admin/policy-set-domains",
  LINK_DOMAINS = "/api/admin/link-domains-to-policy-set",
  UNLINK_DOMAIN = "/api/admin/unlink-domain-from-policy-set",
  LINKED_DOMAINS = "/api/admin/linked-domains",
}
