interface ILoaderProps {
  isLoading: boolean;
}

const Loader = ({ isLoading }: ILoaderProps) => {
  return isLoading ? (
    <span
      aria-hidden="true"
      className="spinner-border spinner-border-sm ml-2"
      role="status"
    />
  ) : (
    <></>
  );
};

export default Loader;
