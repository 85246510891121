import axios, { CancelTokenSource } from "axios";
import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import { PolicyAction, PolicyActionTypes } from "../types/policy";
import { IDefaultResponse } from "../../components/interfaces/General";
import { IPolicy } from "../../components/interfaces/Policy";
import { PolicyRequestRoutes } from "../../constants/policy.constants";
import { toast } from "react-toastify";

let policyCancelToken: CancelTokenSource;
export const fetchPolicy = (policyId: string) => {
  return async (dispatch: Dispatch<PolicyAction>) => {
    if (typeof policyCancelToken != typeof undefined) {
      policyCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    policyCancelToken = axios.CancelToken.source();

    try {
      dispatch({ type: PolicyActionTypes.FETCHING_POLICY });

      const params = {
        id: policyId,
      };

      const { data, status } = await axios.get<IDefaultResponse<IPolicy>>(
        PolicyRequestRoutes.POLICY,
        { cancelToken: policyCancelToken.token, params },
      );

      if (status === 200) {
        dispatch({
          type: PolicyActionTypes.UPDATE_POLICY,
          payload: data.apiData,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: PolicyActionTypes.HANDLE_POLICY_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const setAsDefaultPolicy = () => {
  return async (dispatch: Dispatch<PolicyAction>) => {
    dispatch({ type: PolicyActionTypes.SET_AS_DEFAULT_POLICY });
  };
};
