import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useAppDispatch } from "../../../../../store/store";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import {
  resetFilter,
  setEndDate,
  setErrorEndDate,
  setErrorStartDate,
  setStartDate,
} from "../../../../../store/slices/audit-history.slice";
import { Button } from "@mui/material";

const AuditEntryDateFilter = () => {
  const dispatch = useAppDispatch();
  const {
    columnSearchField,
    startDate,
    errorStartDate,
    endDate,
    errorEndDate,
  } = useTypedSelector((x) => x.auditHistory);

  const errorStartDateMessage = useMemo(() => {
    switch (errorStartDate) {
      case "minDate": {
        return "Date must be greater than or equal to 01/01/1900";
      }

      case "maxDate": {
        return `Date must be today or earlier`;
      }

      case "invalidDate": {
        return "Date is not valid";
      }

      default: {
        return errorStartDate;
      }
    }
  }, [errorStartDate]);

  const errorEndDateMessage = useMemo(() => {
    switch (errorEndDate) {
      case "maxDate": {
        return `Date must be today or earlier`;
      }

      case "minDate": {
        return `Date must be greater than or equal to ${dayjs(startDate).format(
          "MM/DD/YYYY",
        )}`;
      }

      case "invalidDate": {
        return "Date is not valid";
      }

      default: {
        return errorEndDate;
      }
    }
  }, [errorEndDate, startDate]);

  return (
    <div className="audit-entry-date-filter">
      <DatePicker
        className="audit-entry-start-date"
        label="Start Date"
        maxDate={dayjs(new Date())}
        onChange={(value) => dispatch(setStartDate(value))}
        onError={(error) => dispatch(setErrorStartDate(error))}
        slotProps={{
          field: {
            clearable: true,
            onClear: () => dispatch(setEndDate(null)),
          },
          textField: {
            id: "audit-entry-start-date",
            size: "small",
            helperText: errorStartDateMessage,
          },
        }}
        value={startDate ? dayjs(startDate) : null}
      />
      <DatePicker
        className="audit-entry-end-date"
        disabled={!startDate || Boolean(errorStartDate)}
        label="End Date"
        maxDate={dayjs(new Date())}
        minDate={dayjs(startDate)}
        onChange={(value) => dispatch(setEndDate(value))}
        onError={(error) => dispatch(setErrorEndDate(error))}
        slotProps={{
          field: {
            clearable: true,
          },
          textField: {
            id: "audit-entry-end-date",
            size: "small",
            helperText: errorEndDateMessage,
          },
        }}
        value={endDate ? dayjs(endDate) : null}
      />
      <Button
        className="btn-reset"
        disabled={
          !startDate && !endDate && !Object.keys(columnSearchField).length
        }
        onClick={() => {
          dispatch(resetFilter());
        }}
        size="small"
        variant="contained"
      >
        Reset
      </Button>
    </div>
  );
};

export default AuditEntryDateFilter;
