import { Dispatch } from "redux";
import {
  AttachmentsModalAction,
  AttachmentsModalActionTypes,
} from "../types/attachmentsModal";
import { IAttachmentsModalData } from "../../components/interfaces/AttachmentsModal";

export const openAttachmentsModal = (payload: IAttachmentsModalData) => {
  return async (dispatch: Dispatch<AttachmentsModalAction>) => {
    dispatch({
      type: AttachmentsModalActionTypes.SHOW_ATTACHMENT_MODAL,
      payload,
    });
  };
};

export const closeAttachmentsModal = () => {
  return async (dispatch: Dispatch<AttachmentsModalAction>) => {
    dispatch({ type: AttachmentsModalActionTypes.HIDE_ATTACHMENT_MODAL });
  };
};
