import { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { CancelTokenSource } from "axios";
import {
  AdminPageQueryParams,
  IDomainDataResponse,
} from "../../../../interfaces/AdminPage";
import {
  AdminPageRoutes,
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import { useActions } from "../../../../../hooks/useActions";
import Loader from "../../../../modules/Loader";
import DomainProjectsTable from "./DomainProjectsTable";
import DomainUsersTable from "./DomainUsersTable";
import { IDomainDetailsForm } from "../../../../interfaces/Domain";
import Lozenge from "@atlaskit/lozenge";
import DomainDetailsForm from "./DomainDetailsForm";

let domainDetailsPageCancelToken: CancelTokenSource;

const DomainDetailsPage = () => {
  const history = useHistory();
  const { domainId } = useParams<AdminPageQueryParams>();
  const [formData, setFormData] = useState<IDomainDetailsForm>({
    domainName: "",
    domainOwner: null,
    description: "",
    isDefault: false,
  });
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const initialFormState = useRef(formData);

  const getDomainData = async () => {
    if (typeof domainDetailsPageCancelToken != typeof undefined) {
      domainDetailsPageCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    domainDetailsPageCancelToken = axios.CancelToken.source();

    try {
      const { data }: IDomainDataResponse = await axios.get(
        `${AdminRequestRoutes.DOMAINS}/${domainId}`,
        { cancelToken: domainDetailsPageCancelToken.token },
      );

      if (data.errorMessage) {
        toast.error(data.errorMessage);
        history.push(AdminPageRoutes.USER_MANAGEMENT);
      } else {
        const newFormState: IDomainDetailsForm = {
          domainName: data.domainName,
          description: data.domainDescription,
          domainOwner: data.domainOwner?.id
            ? {
                value: data.domainOwner?.id,
                label: `${data.domainOwner?.fullName} (${data.domainOwner?.userName})`,
              }
            : null,
          isDefault: data.isDefault,
          policySet: data.policySet,
        };

        initialFormState.current = newFormState;
        setFormData(newFormState);
        setInitialLoading(false);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    getDomainData();
  }, []);

  return (
    <>
      <div aria-label="domain-details">
        <div className="table-heading-wrapper">
          <b>
            Domain Details {formData.isDefault && <Lozenge>Default</Lozenge>}
          </b>
        </div>

        {initialLoading ? (
          <Loader message="Loading, please wait..." />
        ) : (
          <>
            <DomainDetailsForm domain={formData} />

            <DomainProjectsTable domainId={domainId!} />

            <DomainUsersTable domainId={domainId!} />
          </>
        )}
      </div>
    </>
  );
};

export default DomainDetailsPage;
