interface IButtonProps {
  icon: string;
}

const IconButton = ({
  icon,
  className,
  ...props
}: IButtonProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >) => {
  return (
    <button className={`btn-link ${className ?? ""}`} {...props}>
      <i className={`fa ${icon}`}></i>
    </button>
  );
};

export default IconButton;
