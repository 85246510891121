const DomainsAndRolesHeader = () => {
  return (
    <div className="domains-and-roles-list__header">
      <div className="domains-and-roles-list__domain">
        <span>Domain</span>
      </div>

      <div className="domains-and-roles-list__role">Roles</div>
      <div className="domains-and-roles-list__email_notifications">
        Email Opt In/Out
      </div>
    </div>
  );
};

export default DomainsAndRolesHeader;
