import { IPolicy } from "../../components/interfaces/Policy";

export enum PolicyActionTypes {
  FETCHING_POLICY = "FETCHING_POLICY",
  UPDATE_POLICY = "UPDATE_POLICY",
  SET_AS_DEFAULT_POLICY = "SET_AS_DEFAULT_POLICY",
  HANDLE_POLICY_NETWORK_ERROR = "HANDLE_POLICY_NETWORK_ERROR",
}

interface FetchPolicy {
  type: PolicyActionTypes.FETCHING_POLICY;
}

interface UpdatePolicy {
  type: PolicyActionTypes.UPDATE_POLICY;
  payload: IPolicy;
}

interface SetAsDefaultPolicy {
  type: PolicyActionTypes.SET_AS_DEFAULT_POLICY;
}

interface HandlePolicyNetworkError {
  type: PolicyActionTypes.HANDLE_POLICY_NETWORK_ERROR;
  payload: string;
}

export type PolicyAction =
  | FetchPolicy
  | UpdatePolicy
  | SetAsDefaultPolicy
  | HandlePolicyNetworkError;
