import companyLogo from "../../../images/company-logo.png";

const CompanyLogo = () => {
  return (
    <img
      alt="#"
      className="login-logo align-self-center"
      id="company-logo"
      src={companyLogo}
    />
  );
};

export default CompanyLogo;
