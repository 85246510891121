import { ChangeEvent } from "react";
import { IDropdownOption } from "../../interfaces/DropDown";
import Checkbox from "@atlaskit/checkbox";
import Spinner from "@atlaskit/spinner";

interface IDropdownItemsProps {
  isError?: boolean;
  isFirstPage?: boolean;
  isLoading: boolean;
  items: IDropdownOption[];
  onChangeCheckbox: (checked: boolean, option: IDropdownOption) => void;
  selectedItems?: string[];
}

const DropdownItems = ({
  isError,
  isFirstPage,
  isLoading,
  items,
  onChangeCheckbox,
  selectedItems,
}: IDropdownItemsProps) => {
  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    option: IDropdownOption,
  ) => {
    onChangeCheckbox(e.currentTarget.checked, option);
  };

  if (isError || (!items?.length && !isLoading)) {
    return (
      <div className="dropdown-empty-content">No matching items found</div>
    );
  }

  return (
    <>
      {items.map((option) => (
        <Checkbox
          className="vera-dropdown-item"
          isChecked={
            !selectedItems
              ? option.isSelected
              : selectedItems.includes(option.id)
          }
          key={option.id}
          label={option.label}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleOnChange(e, option)
          }
          value={option.id}
        />
      ))}
      {isLoading && !isFirstPage && (
        <div className="dropdown-show-more-spinner">
          <Spinner size="medium" />
        </div>
      )}
    </>
  );
};

export default DropdownItems;
