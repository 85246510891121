import { IAlertProps } from "../interfaces/Alert";

const ErrorAlert = ({ className, message }: IAlertProps) => {
  return (
    <div className={`error-alert ${className ? className : ""}`}>
      <div className="error-icon">
        <i className="fa fa-exclamation-circle fa-lg" />
      </div>
      <div className="error-message">{message}</div>
    </div>
  );
};

export default ErrorAlert;
