import {
  AdminPageErrors,
  AdminPageSuccessMessages,
  AdminPageWarnings,
} from "../../constants/admin-page.constants";
import { ErrorMessage } from "../../constants/general.constants";
import axios, { AxiosResponse } from "axios";
import {
  convertMegaBytesToBytes,
  readUploadedFileAsText,
} from "../../helpers/file.helper";

interface FileUploadErrorResponse {
  error: string;
  message: string;
}

export class FileUploadService {
  static uploadPolicy = async (
    importedFile: File | null,
    policyType: string,
    callbackUploadPolicy: () => Promise<AxiosResponse>,
  ): Promise<string | FileUploadErrorResponse | undefined> => {
    if (!importedFile || importedFile.size === 0) {
      return Promise.reject({
        type: "warning",
        message: AdminPageWarnings.EMPTY_UPLOAD_INPUT,
      });
    }

    if (importedFile.type !== "application/json") {
      return Promise.reject({
        type: "error",
        message: AdminPageErrors.IMPORT_POLICY_MEDIA_TYPE,
      });
    }

    if (!policyType) {
      return Promise.reject({
        type: "warning",
        message: AdminPageWarnings.IMPORT_NEW_POLICY_TYPE,
      });
    }

    const sizeInMb = 2;
    if (importedFile.size > convertMegaBytesToBytes(sizeInMb)) {
      return Promise.reject({
        type: "warning",
        message: AdminPageErrors.IMPORT_MAX_SIZE,
      });
    }

    const validPolicyType = new RegExp(`${policyType}`);
    const importedFileContent = await readUploadedFileAsText(importedFile);

    if (!validPolicyType.test(importedFileContent)) {
      return Promise.reject({
        type: "error",
        message: AdminPageErrors.IMPORT_POLICY_MISMATCH_TYPE,
      });
    } else {
      try {
        const { data } = await callbackUploadPolicy();
        if (data.apiStatusCode !== 200) {
          return Promise.reject({
            type: "error",
            message: data.message || AdminPageErrors.IMPORT_POLICY,
          });
        }

        return Promise.resolve(AdminPageSuccessMessages.IMPORT_POLICY);
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const { response } = error as any;
          return Promise.reject({
            type: "error",
            message: response?.data?.message as string,
          });
        } else if (
          error.message !== ErrorMessage.NEW_REQUEST &&
          error.message !== ErrorMessage.LOGOUT
        ) {
          return Promise.reject({ type: "warning", message: error.message });
        }
      }
    }
  };
}
