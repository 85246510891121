import React from "react";
import { IDeleteProjectConfirmationModalProps } from "../../../../interfaces/AdminPage";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { IconButton } from "@atlaskit/button/new";

const DeleteProjectConfirmationModal: React.FC<
  IDeleteProjectConfirmationModalProps
> = ({
  isOpen,
  isLoading,
  projectHasAssociatedTasks,
  closeModal,
  projectName,
  handleDeleteProject,
}) => {
  const renderModalBodyText = () => {
    return projectHasAssociatedTasks
      ? "This project contains route tasks associated with it. Deleting it will cause those tasks to be unreachable:"
      : "The following project will be deleted:";
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="large">
          <ModalHeader>
            <ModalTitle>Delete Project from Domain</ModalTitle>
            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <div className="remove-from-role-modal-body">
              <p style={{ fontWeight: "500" }}>{renderModalBodyText()}</p>

              {projectName}
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  <button
                    className="btn btn-primary text-white"
                    disabled={isLoading}
                    onClick={handleDeleteProject}
                    type="button"
                  >
                    Delete
                    {isLoading && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      />
                    )}
                  </button>

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default DeleteProjectConfirmationModal;
