import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "@atlaskit/select";
import { toast } from "react-toastify";
import { useActions } from "../../hooks/useActions";
import { ProfileFormData, ProfileProps } from "../interfaces/Profile";
import {
  INewUserFormValidationErrors,
  IResetPasswordResponse,
} from "../interfaces/AdminPage";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../constants/general.constants";
import {
  ProfilePageErrors,
  ProfilePageSuccessMessages,
  ProfilePageWarnings,
} from "../../constants/profile-page.constants";
import { validateNewPassword } from "../../validators/changePasswordFormValidator";
import MultiSelectField from "./common/MultiSelectField";
import TextField from "./common/TextField";
import ResetPasswordModal from "../pages/admin/userManagement/modals/ResetPasswordModal";
import { validateExistingUserForm } from "../../validators/editUserFormValidator";
import Toggle from "@atlaskit/toggle";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../constants/admin-page.constants";
import { UsersRequestRoutes } from "../../constants/users.constants";
import ErrorAlert from "./ErrorAlert";

const UserDetailsForm = ({
  profileData,
  adminAccess = false,
  passwordPolicyData,
}: ProfileProps) => {
  const { updateProfileData } = useActions();
  const [loading, setLoading] = useState<boolean>(false);
  const [unlocking, setUnlocking] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [formData, setFormData] = useState<ProfileFormData>({
    active: profileData.userDetails.active,
    email: profileData.emailAddress,
    fullName: profileData.userFullName,
    identityProvider: profileData.userDetails.identityProviders[0].providerName,
    idpUsername: profileData.userDetails.identityProviders[0].userName,
    locked: profileData.userDetails.locked,
    password: "",
    systemRoles: profileData.systemRolesList,
    userName: profileData.userDetails.userName,
    veraId: profileData.userDetails._id,
  });

  const [isOpenResetPassModal, setIsOpenResetPassModal] = useState(false);
  const [isChangingIdp, setIsChangingIdp] = useState(false);
  const [validationErrors, setValidationErrors] =
    useState<INewUserFormValidationErrors>({});
  const initialFormState = useRef(formData);
  const loggedUser = useTypedSelector((x) => x.user.user);

  let idpOptions;
  if (adminAccess) {
    idpOptions = profileData.idps
      .map((idp) => ({ label: idp.name, value: idp.id }))
      .concat({ label: "None", value: "None" });
  }

  const openResetPassModal = (event: any) => {
    event.preventDefault();
    setIsOpenResetPassModal(true);
  };
  const handleFormBlur = (newData: any) =>
    setFormData((prev) => ({
      ...prev,
      [newData.name]: newData.value?.trim() ?? "",
    }));
  const handleFormChange = (newData: any) =>
    setFormData((prev) => ({ ...prev, [newData.name]: newData.value }));
  const handleSelectChange = (item: any) =>
    setFormData((prev) => ({ ...prev, identityProvider: item.value }));
  const handleActiveChange = () =>
    setFormData((prev) => ({ ...prev, active: !prev.active }));

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    formData.password !== "" &&
      passwordPolicyData &&
      validateNewPassword(
        formData.password,
        setValidationError,
        passwordPolicyData!,
      );
    validateExistingUserForm(formData, setValidationErrors);
    if (formData.identityProvider !== "VERA" && !formData.idpUsername) {
      toast.warning(ProfilePageWarnings.EMPTY_IDP_USERNAME);
      return;
    }

    if (formData.identityProvider === "VERA" && validationError) {
      toast.warning(ProfilePageWarnings.EMPTY_PASSWORD);
      return;
    }

    try {
      setLoading(true);

      const requestResult = await updateProfileData(
        {
          ...formData,
          systemRoleMembership: formData.systemRoles
            .filter((role) => role.selected)
            .map((role) => role.id),
        },
        adminAccess,
      );

      if (requestResult as any) {
        initialFormState.current = formData;
        toast.success(ProfilePageSuccessMessages.UPDATE_PROFILE_DATA);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (
    userId: string,
    fullName: string,
    newPassword: string,
  ) => {
    try {
      const { data }: IResetPasswordResponse = await axios.post(
        AdminRequestRoutes.RESET_PASSWORD,
        {
          userId: userId,
          fullName: fullName,
          newPassword: newPassword,
        },
      );

      if (data.apiStatusCode === 200) {
        toast.success(ProfilePageSuccessMessages.RESET_PASSWORD, {
          autoClose: 8000,
        });
        return true;
      } else {
        toast.error(ProfilePageErrors.RESET_PASSWORD);
        return false;
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      return false;
    }
  };

  const handleOnClickUnlock = async () => {
    setUnlocking(true);
    try {
      const { data, status } = await axios.patch<ProfileFormData>(
        UsersRequestRoutes.USER_UNLOCK,
        {
          id: profileData.userDetails._id,
        },
      );

      if (status === 200) {
        initialFormState.current = { ...formData, locked: false };
        setFormData((x) => ({ ...x, locked: false }));
        toast.success(AdminPageSuccessMessages.USER_UNLOCK);
      } else {
        toast.error(AdminPageErrors.USER_UNLOCK);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setUnlocking(false);
    }
  };

  useEffect(() => {
    formData.password !== "" &&
      passwordPolicyData &&
      validateNewPassword(
        formData.password,
        setValidationError,
        passwordPolicyData!,
      );
  }, [formData.password]);

  useEffect(() => {
    formData !== initialFormState.current &&
      validateExistingUserForm(formData, setValidationErrors);
  }, [formData]);

  const getUnlockLoading = () => {
    return (
      unlocking && (
        <span
          aria-hidden="true"
          className="spinner-border spinner-border-sm ml-2"
          role="status"
        />
      )
    );
  };

  const getUnlockButton = () => {
    return (
      <button className="btn-link" onClick={handleOnClickUnlock}>
        Click here
      </button>
    );
  };

  const getUnlockUserMessage = () => {
    return (
      <span>
        This user is locked due to failed login attempts and will unlock
        automatically after the lockout period ends. {getUnlockButton()} to
        unlock the user now. {getUnlockLoading()}
      </span>
    );
  };

  return (
    <>
      {adminAccess && initialFormState?.current.locked && (
        <ErrorAlert
          className="unlock-user-message"
          message={getUnlockUserMessage()}
        />
      )}

      <form
        aria-label="profile-form"
        className={`needs-validation profile-wrapper mt-4`}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <TextField
          label={"User Name: "}
          name={"userName"}
          readonly={true}
          value={formData.userName}
        />

        <TextField
          label={"Vera ID: "}
          name={"veraId"}
          readonly={true}
          value={formData.veraId}
        />

        <TextField
          error={validationErrors.fullName}
          label={"Full Name: "}
          name={"fullName"}
          onBlur={handleFormBlur}
          onChange={handleFormChange}
          placeholder={"Enter the user's full name"}
          value={formData.fullName}
          wasValidated={!!(formData.fullName && !validationErrors.fullName)}
        />

        <TextField
          error={validationErrors.email}
          label={"Email Address: "}
          name={"email"}
          onBlur={handleFormBlur}
          onChange={handleFormChange}
          placeholder={"Enter the user's email address"}
          value={formData.email}
          wasValidated={!!(formData.email && !validationErrors.email)}
        />

        <>
          {adminAccess ? (
            <div className="form-with-label form-with-label--grid-3">
              <label className="m-0 font-weight-500">Identity Provider:</label>
              <Select
                aria-label="idp-select"
                className="single-select"
                classNamePrefix="react-select"
                inputId="single-select-example"
                isDisabled={!isChangingIdp}
                onChange={handleSelectChange}
                options={idpOptions}
                spacing={"default"}
                value={{
                  label:
                    idpOptions?.find(
                      (x) => x.value === formData.identityProvider,
                    )?.label ?? formData.identityProvider,
                  value: formData.identityProvider,
                }}
              />
              {isChangingIdp ? (
                <a
                  href="#"
                  onClick={() => {
                    setIsChangingIdp(false);
                    formData.identityProvider =
                      profileData.userDetails.identityProviders[0].providerName;
                  }}
                  role="button"
                >
                  Cancel Change
                </a>
              ) : (
                <a
                  href="#"
                  onClick={() => setIsChangingIdp(true)}
                  role="button"
                >
                  Change Identity Provider
                </a>
              )}
            </div>
          ) : (
            <TextField
              error={validationErrors.identityProvider}
              label={"Identity Provider: "}
              name={"identityProvider"}
              readonly={true}
              value={
                profileData?.idps?.find(
                  (x) => x.id === formData.identityProvider,
                )?.name ?? formData.identityProvider
              }
              wasValidated={
                !!(
                  formData.identityProvider &&
                  !validationErrors.identityProvider
                )
              }
            />
          )}
        </>

        {formData.identityProvider === "VERA" && (
          <div className="form-with-label form-with-label--grid">
            <label className="m-0 font-weight-500">Password:</label>
            <div>
              {adminAccess ? (
                <>
                  {profileData.userDetails.identityProviders[0].providerName ===
                  "VERA" ? (
                    <>
                      {isOpenResetPassModal && (
                        <ResetPasswordModal
                          closeModal={() => setIsOpenResetPassModal(false)}
                          isOpen={isOpenResetPassModal}
                          resetPassword={handleResetPassword}
                          userData={profileData.userDetails}
                        />
                      )}
                      <button
                        className="btn btn-outline-primary change-pass-btn"
                        onClick={openResetPassModal}
                      >
                        Reset Password...
                      </button>
                    </>
                  ) : (
                    <TextField
                      error={validationError}
                      name={"password"}
                      onChange={handleFormChange}
                      placeholder={"New User's Password"}
                      type="password"
                      value={formData.password}
                    />
                  )}
                </>
              ) : (
                <Link
                  className="btn btn-outline-primary change-pass-btn"
                  to="/change-password"
                >
                  Change Password...
                </Link>
              )}
            </div>
          </div>
        )}

        {formData.identityProvider !== "VERA" &&
          formData.identityProvider !== "None" && (
            <TextField
              disabled={!adminAccess}
              error={validationErrors.idpUsername}
              label={"Identity Provider Username: "}
              name={"idpUsername"}
              onBlur={handleFormBlur}
              onChange={handleFormChange}
              placeholder="Enter the identity provider username"
              value={formData.idpUsername}
              wasValidated={
                !!(formData.idpUsername && !validationErrors.idpUsername)
              }
            />
          )}

        <MultiSelectField
          disabled={!adminAccess}
          label={"System Roles: "}
          name="systemRoles"
          onChange={handleFormChange}
          options={formData.systemRoles || []}
        />
        {adminAccess && loggedUser?.veraUserId !== formData?.veraId && (
          <div className="form-with-label form-with-label--medium">
            <label className="m-0 font-weight-500">Active:</label>
            <Toggle
              id="toggle-set-as-default-policy"
              isChecked={formData.active}
              isDisabled={!adminAccess}
              label="Activate"
              name="active"
              onChange={handleActiveChange}
              size="large"
            />
          </div>
        )}

        <div className="form-actions">
          <button
            className="btn btn-primary text-white"
            disabled={
              JSON.stringify(formData) ===
                JSON.stringify(initialFormState.current) || unlocking
            }
            id="save-user-profile-btn"
          >
            Save Changes{" "}
            {loading && (
              <span
                aria-hidden="true"
                className="spinner-border spinner-border-sm ml-2"
                role="status"
              />
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default UserDetailsForm;
