import { Dispatch } from "redux";
import axios, { CancelTokenSource } from "axios";
import {
  AdminLoginResponse,
  GetAdminDataResponse,
  IActiveSessionProps,
  ILogoutRespone,
  LoginResponse,
  LoginVeraResponse,
  ProfileData,
  UserAction,
  UserActionTypes,
  UserRolesListResponse,
} from "../types/user";
import { ProfileFormData } from "../../components/interfaces/Profile";
import { toast } from "react-toastify";
import {
  AdminRequestRoutes,
  ErrorMessage,
  LoginPageRequestRoutes,
  ProfileRequestRoutes,
  AppRoutes,
} from "../../constants/general.constants";
import { ProfilePageWarnings } from "../../constants/profile-page.constants";
import { RootState } from "../reducers";
import { versionApi } from "../slices/version.slice";
import { gainsightIdentifyCall } from "../../hooks/gainsight.js";

let cancelToken: CancelTokenSource;

export const login = (username: string, providerId?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch({ type: UserActionTypes.FETCHING_DATA });
      const { data }: LoginResponse = await axios.post(
        LoginPageRequestRoutes.LOGIN,
        { username, providerId },
      );

      if (data.url) {
        window.location.replace(data.url);
      } else if (data.message) {
        toast.error(data.message);
        dispatch({
          type: UserActionTypes.STOP_LOADING,
        });
      } else {
        //send tracking information to Gainsight
        gainsightIdentifyCall(data);

        dispatch({
          type: UserActionTypes.LOGIN,
          payload: {
            username: data.username!,
            veraIdp: data.veraIdp!,
          },
        });

        updateVersion(dispatch, getState);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message || response?.data?.message);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      dispatch({
        type: UserActionTypes.STOP_LOADING,
      });
    }
  };
};

export const loginVera = (username: string, password: string) => {
  return async (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCHING_DATA });
      const response: LoginVeraResponse = await axios.post(
        LoginPageRequestRoutes.LOGIN_VERA,
        {
          username,
          password,
        },
      );
      const { message, url } = response.data;

      if (message) {
        toast.error(message);
        dispatch({
          type: UserActionTypes.STOP_LOADING,
        });
      } else if (url) {
        window.location.replace(url);
      } else {
        //send tracking information to Gainsight
        gainsightIdentifyCall(response.data);

        dispatch({
          type: UserActionTypes.LOGIN_VERA,
          payload: response.data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      dispatch({
        type: UserActionTypes.STOP_LOADING,
      });
    }
  };
};

export const getAdminData = () => {
  return async (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCHING_DATA });
      const { data }: GetAdminDataResponse = await axios.get(
        AdminRequestRoutes.ADMIN_LOGIN,
      );
      if (data.url) {
        window.location.replace(data.url);
      } else if (!data.username) {
        dispatch({ type: UserActionTypes.LOGOUT });
      } else {
        dispatch({ type: UserActionTypes.GET_ADMIN_DATA, payload: data });
      }
    } catch (error: any) {
      /* nothing to catch */
    }
  };
};

export const adminLogin = (username: string, password: string) => {
  return async (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCHING_DATA });
      const response: AdminLoginResponse = await axios.post(
        AdminRequestRoutes.ADMIN_LOGIN,
        {
          username,
          password,
        },
      );
      const { message } = response.data;

      if (message) {
        toast.error(message);
        dispatch({
          type: UserActionTypes.STOP_LOADING,
        });
      } else {
        dispatch({
          type: UserActionTypes.ADMIN_LOGIN,
          payload: response.data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      dispatch({
        type: UserActionTypes.STOP_LOADING,
      });
    }
  };
};

export const resetForm = () => {
  return {
    type: UserActionTypes.RESET_FORM,
  };
};

export const getProfileData = (veraUserId: string) => {
  return async (dispatch: Dispatch<UserAction>) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();
    try {
      dispatch({ type: UserActionTypes.FETCHING_DATA });
      const [{ data, status }, systemRolesList] = await Promise.all([
        axios.get<ProfileData>(ProfileRequestRoutes.PROFILE, {
          cancelToken: cancelToken.token,
        }),
        axios.get<UserRolesListResponse>(
          `${ProfileRequestRoutes.USER_SYSTEM_ROLES_LIST}`,
          { cancelToken: cancelToken.token, params: { veraId: veraUserId } },
        ),
      ]);

      if (status === 200) {
        const result = {
          ...data,
          systemRolesList: systemRolesList.data.results.map((x) => ({
            ...x,
            selected: data.systemRoles.includes(x.text),
          })),
        };
        dispatch({
          type: UserActionTypes.GET_PROFILE_DATA,
          payload: result,
        });
      }
    } catch (error: any) {
      error.message !== ErrorMessage.LOGOUT && toast.error(error.message);
      dispatch({
        type: UserActionTypes.STOP_LOADING,
      });
    }
  };
};

export const updateProfileData = (
  formData: ProfileFormData,
  adminAccess: boolean,
) => {
  return async (dispatch: Dispatch<UserAction>) => {
    try {
      await axios.post(ProfileRequestRoutes.ADD_EDIT_USER, { ...formData });
      if (adminAccess) {
        dispatch({
          type: UserActionTypes.STOP_LOADING,
        });
      } else {
        dispatch({
          type: UserActionTypes.UPDATE_PROFILE_DATA,
          payload: formData.fullName,
        });
      }
      return true;
    } catch (error: any) {
      if (axios.isAxiosError(error) && error?.response?.status === 400) {
        toast.warning(error.response.data);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }

      dispatch({
        type: UserActionTypes.STOP_LOADING,
      });
      return false;
    }
  };
};

export const checkActiveSession = () => {
  return async (dispatch: Dispatch<UserAction>) => {
    const { data } = await axios.get<IActiveSessionProps>(
      LoginPageRequestRoutes.LOGIN_VERA,
    );
    const { user, hasActiveSession } = data;
    if (!hasActiveSession) {
      dispatch({
        type: UserActionTypes.LOGOUT,
      });
    } else if (user?.isSystemAdmin && !user?.tokenHasSuperScope) {
      dispatch({
        type: UserActionTypes.REVERIFY_ADMIN,
        payload: data,
      });
    } else {
      dispatch({
        type: UserActionTypes.CHECK_ACTIVE_SESSION,
        payload: data,
      });
    }
  };
};

export const logout = (history?: any, redirectToLogin?: boolean) => {
  return async (dispatch: Dispatch<UserAction>) => {
    cancelToken && cancelToken.cancel(ErrorMessage.LOGOUT);
    try {
      const { data }: ILogoutRespone = await axios.get(
        LoginPageRequestRoutes.LOGOUT,
        { params: { redirectToLogin } },
      );
      if (data.url) {
        window.location.replace(data.url);
      } else if (data.message) {
        toast.error(data.message);
        dispatch({
          type: UserActionTypes.STOP_LOADING,
        });
      } else if (data.status) {
        dispatch({
          type: UserActionTypes.LOGOUT,
        });
        history.push(AppRoutes.LoginPage);
      } else {
        dispatch({
          type: UserActionTypes.STOP_LOADING,
        });
      }
    } catch (error: any) {
      toast.error(error.message);
      dispatch({
        type: UserActionTypes.STOP_LOADING,
      });
    }
  };
};

const updateVersion = (dispatch: Dispatch<any>, getState: () => RootState) => {
  const state = getState();
  const version = (state.versionApi.queries["getVersion(undefined)"] as any)
    ?.data.version;
  if (!version) {
    dispatch(versionApi.util.invalidateTags(["version"]));
  }
};
