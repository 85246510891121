import LoginForm from "../LoginForm/LoginForm";
import CompanyLogo from "../Logo";
import CompanyBanner from "./../../../../images/company-banner-min.png";
import { LoginContainer } from "./style";
import { Typography } from "@mui/material";
import Versions from "../Versions/Versions";

const LoginPage = () => {
  return (
    <main className="text-center login-body login-body__container" role="main">
      <div
        className="login-banner"
        style={{ backgroundImage: `url(${CompanyBanner})` }}
      ></div>
      <div className="d-flex login-wrapper">
        <LoginContainer className="container text-center">
          <CompanyLogo />
          <div className="card-container login-form__container">
            <Typography className="font-weight-normal mb-4" variant="h5">
              Please sign in
            </Typography>
            <LoginForm />
          </div>
          <Versions />
        </LoginContainer>
      </div>
    </main>
  );
};

export default LoginPage;
