import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { IAuthorsModalProps } from "../../../interfaces/DetailsPage";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import AuthorName from "../../../modules/AuthorName";
import { IconButton } from "@atlaskit/button/new";

const AuthorsModal = ({
  authorsList,
  isOpen,
  closeModal,
}: IAuthorsModalProps) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="large">
          <ModalHeader>
            <ModalTitle>Authors</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <ul>
              {authorsList.map((author) => (
                <li key={author._id}>
                  <AuthorName {...author} />
                </li>
              ))}
            </ul>
          </ModalBody>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default AuthorsModal;
