import axios from "axios";
import { toast } from "react-toastify";
import { AdminPageErrors } from "../../constants/admin-page.constants";
import { ErrorMessage } from "../../constants/general.constants";
import { PolicyRequestRoutes } from "../../constants/policy.constants";

export class PolicyService {
  static export = async (
    name: string,
    revision: number,
    revisionId: string,
  ) => {
    try {
      const { data, status } = await axios.get(
        `${PolicyRequestRoutes.DOWNLOAD_POLICY}`,
        {
          params: {
            id: revisionId,
          },
        },
      );
      if (status === 200) {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(data, null, 4),
        )}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = jsonString;
        downloadLink.download = `${name}-R${revision}.json`;
        downloadLink.click();
      } else {
        toast.error(AdminPageErrors.DOWNLOAD_POLICY);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
}
