import { useEffect } from "react";
import PolicySetsTable from "./PolicySetsTable";
import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import AddPolicySet from "./AddPolicySet";

const PolicySets = () => {
  const { fetchPolicySets, handleResetPolicySetsData } = useActions();
  const { sortParams } = useTypedSelector((x) => x.policySets);

  useEffect(() => {
    fetchPolicySets();
  }, [sortParams]);

  useEffect(
    () => () => {
      handleResetPolicySetsData();
    },
    [],
  );

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="table-heading-wrapper mb-3">
          <b>Policy Sets</b>
        </div>
      </div>
      <div className="table-action-bar">
        <AddPolicySet />
      </div>
      <PolicySetsTable />
    </div>
  );
};

export default PolicySets;
