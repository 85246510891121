import GenericModal from "../../../../modules/GenericModal";
import { toast } from "react-toastify";
import axios from "axios";
import { useActions } from "../../../../../hooks/useActions";
import { IDefaultResponse } from "../../../../interfaces/General";
import React from "react";
import { PolicyRequestRoutes } from "../../../../../constants/policy.constants";
import { IPolicy } from "../../../../interfaces/Policy";
import { ErrorMessage } from "../../../../../constants/general.constants";

interface ISetDefaultConfirmationModalProps {
  id: string;
  visible: boolean;
  onCancel: () => void;
  onClose: () => void;
}

const SetDefaultConfirmationModal = ({
  id,
  onCancel,
  onClose,
  visible,
}: ISetDefaultConfirmationModalProps) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { setAsDefaultPolicy } = useActions();

  const handleOnClickConfirm = async () => {
    setIsSaving(true);
    try {
      const { status } = await axios.patch<IDefaultResponse<IPolicy>>(
        PolicyRequestRoutes.POLICY,
        {
          _id: id,
          IsDefault: true,
        },
      );

      if (status === 200) {
        setAsDefaultPolicy();
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsSaving(false);
      onClose();
    }
  };

  return (
    <GenericModal
      labelConfirm="Confirm"
      loading={isSaving}
      onClickCancel={onCancel}
      onClickConfirm={handleOnClickConfirm}
      onCloseModal={onClose}
      size="medium"
      title="Replace Default Policy"
      visible={visible}
    >
      This action will replace the current default policy with this policy.
    </GenericModal>
  );
};

export default SetDefaultConfirmationModal;
