import { useEffect } from "react";
import CompanyLogo from "./Logo";
import {
  Box,
  Button,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Buffer } from "buffer";
import {
  setUsername,
  setUserAccounts,
  useLoginBySelectedUsernameMutation,
  resetData,
} from "../../../store/slices/login.slice";
import { useAppDispatch } from "../../../store/store";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { toast } from "react-toastify";

const UserAccountsPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { userAccounts = [], username = "" } = useTypedSelector((x) => x.login);
  const [loginBySelectedUsername] = useLoginBySelectedUsernameMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUsername(event.target.value));
  };

  const handleOnClickOnBack = () => {
    history.push("/");
  };

  const handleOnClick = async () => {
    const userId = userAccounts?.find((x) => x.userName == username)?._id;

    loginBySelectedUsername({
      userId,
      username,
    })
      .unwrap()
      .then(() => {
        window.location.replace("/");
      })
      .catch(({ message }) => toast.error(message));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const accounts = searchParams.get("accounts");
    if (accounts) {
      const parsedAccounts = JSON.parse(
        Buffer.from(accounts, "base64").toString(),
      );
      if (parsedAccounts && parsedAccounts.length) {
        dispatch(setUserAccounts(parsedAccounts));
      }
    }

    return () => {
      dispatch(resetData());
    };
  }, []);

  return (
    <Box className="user-accounts-page">
      <Box className="company-logo-container">
        <CompanyLogo />
      </Box>
      <Box className="user-accounts-content">
        <Box className="user-accounts-content-title">
          <Typography
            className="user-account-main-heading"
            id="lbl-main-heading"
            variant="h6"
          >
            Multiple Vera accounts are associated with this SSO login.
          </Typography>
          <Typography
            className="user-account-heading"
            id="lbl-heading"
            variant="h6"
          >
            Please select the account you wish to login as:
          </Typography>
        </Box>
        <RadioGroup onChange={handleChange} value={username}>
          <List className="user-accounts" dense>
            {userAccounts.map((value, index) => {
              return (
                <ListItem key={value._id}>
                  <FormControlLabel
                    className="txt-username"
                    control={<Radio />}
                    id={`txt-username-${index}`}
                    label={value.userName}
                    value={value.userName}
                  />
                </ListItem>
              );
            })}
          </List>
        </RadioGroup>
        <Box className="user-accounts-action-buttons">
          <Button id="btn-back" onClick={handleOnClickOnBack}>
            Go Back
          </Button>
          <Button
            disabled={!username}
            id="btn-continue"
            onClick={handleOnClick}
            variant="contained"
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserAccountsPage;
