import useModal from "../../../../../hooks/useModal";
import AddPolicySetModal from "./AddPolicySetModal";

const AddPolicySet = () => {
  const { visible, hide, show } = useModal();

  return (
    <>
      <button
        aria-label="btn-open-add-policy-set-modal"
        className="btn btn-primary btn-sm text-white"
        id="btn-add-policy-set"
        onClick={show}
      >
        <i className="fa fa-plus-circle" /> Add Policy Set
      </button>
      {visible && <AddPolicySetModal onClose={hide} visible={visible} />}
    </>
  );
};

export default AddPolicySet;
