import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { RecordInfo } from "../../../constants/record.constants";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { IRecordData } from "../../interfaces/Record";
import Loader from "../../modules/Loader";
import TableHead from "../../modules/TableHead";
import TablePagination from "../../modules/TablePagination";
import LocateRecordFilter from "./LocateRecordFilter/LocateRecordFilter";
import RecordPageItem from "./RecordPageItem";
import { updateUrlWithRecordsParams } from "../../../helpers";

const RecordSearchResultsPage: React.FC = () => {
  const queryString = new URLSearchParams(useLocation().search);
  const isFirstRun = useRef(true);
  const {
    tableData,
    rowsData,
    tableHeaders,
    isLoading,
    sortParams,
    selectedPage,
  } = useTypedSelector((state) => state.records);

  const {
    projects,
    recordTypes,
    recordStatus,
    recordNameSearchField,
    selectedProjects,
    selectedRecordTypes,
  } = useTypedSelector((state) => state.locateRecordsFilter);

  const {
    fetchRecords,
    updateRecordSortParams,
    updateRecordPageParams,
    updateRecordSelectedPage,
    updateSearchFieldRecordName,
    fetchProjects,
    fetchRecordStatus,
    fetchRecordTypes,
    handleResetRecordData,
    updateSelectedProjects,
    updateSelectedRecordTypes,
    updateSelectedRecordStatuses,
    clearFilters,
    updateRecordFilter,
  } = useActions();

  const setSortParams = (
    sort: string = sortParams.sort,
    order: "asc" | "desc" = sortParams.order,
  ) => {
    updateRecordSortParams(sort, order);
  };

  const setPageParams = (
    offset: number = sortParams.offset,
    limit: number = sortParams.limit,
  ) => {
    updateRecordPageParams(offset, limit);
  };

  const setSelectedPage = (pageNumber: number) => {
    updateRecordSelectedPage(pageNumber);
  };

  const loadSelectedFilter = async () => {
    const selectedProjects = queryString.getAll("projects");
    const selectedRecordTypes = queryString.getAll("recordTypes");
    const selectedRecordStatuses = queryString.getAll("recordStatuses");
    const recordNameSearchCriteria = queryString.get("recordName") ?? "";

    updateSearchFieldRecordName(recordNameSearchCriteria);

    Promise.all([fetchProjects("")]).then((x) =>
      updateSelectedProjects(selectedProjects),
    );
    Promise.all([fetchRecordTypes("")]).then((x) =>
      updateSelectedRecordTypes(selectedRecordTypes),
    );
    Promise.all([fetchRecordStatus("")]).then((x) =>
      updateSelectedRecordStatuses(selectedRecordStatuses),
    );

    updateRecordFilter({
      project: selectedProjects,
      recordName: recordNameSearchCriteria,
      recordStatus: selectedRecordStatuses,
      recordType: selectedRecordTypes,
    });
  };

  useEffect(() => {
    loadSelectedFilter();

    return () => {
      handleResetRecordData();
      clearFilters();
    };
  }, []);

  useEffect(() => {
    const id = toast.info(RecordInfo.ENTER_SEARCH_CRITERIA);

    return () => toast.dismiss(id);
  }, []);

  useEffect(() => {
    updateUrlWithRecordsParams({
      selectedProjects,
      selectedRecordTypes,
      recordStatus,
      recordNameSearchField,
    });
  }, [
    selectedProjects,
    selectedRecordTypes,
    recordStatus,
    recordNameSearchField,
  ]);

  useEffect(() => {
    if (!isFirstRun.current) {
      fetchRecords();
    }

    isFirstRun.current = false;
  }, [sortParams]);

  return (
    <div className="vera-content-wrapper">
      <div className="table-heading-wrapper mb-3">
        <b>Record</b>
      </div>
      <LocateRecordFilter />

      <table className="table table-bordered table-sm">
        <TableHead
          displayActionsColumn={false}
          isLoading={isLoading}
          setPageParams={setPageParams}
          setSortParams={setSortParams}
          sortParams={sortParams}
          tableHeaders={tableHeaders}
        />

        <tbody
          className="position-relative"
          style={{ height: isLoading || !rowsData?.length ? "40vh" : 0 }}
        >
          {!isLoading && !rowsData?.length && (
            <div className="table-no-records-found">
              No matching records found
            </div>
          )}

          {isLoading && (
            <tr key="loading">
              <th className="loading-column" colSpan={4}>
                {
                  <Loader
                    message={"Loading, please wait..."}
                    tableMode={true}
                  />
                }
              </th>
            </tr>
          )}
          {!isLoading &&
            rowsData?.map((itemData: IRecordData) => (
              <RecordPageItem key={itemData.recordId} tableItem={itemData} />
            ))}
        </tbody>
      </table>
      {tableData && (
        <TablePagination
          selectedPage={selectedPage}
          tableData={tableData}
          updatePageParams={setPageParams}
          updateSelectedPage={setSelectedPage}
        />
      )}
    </div>
  );
};

export default RecordSearchResultsPage;
