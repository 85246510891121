import { unescape } from "lodash";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Loader from "../../modules/Loader";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { DetailsQueryParams } from "../../interfaces/DetailsPage";
import { useActions } from "../../../hooks/useActions";
import { AppRoutes } from "../../../constants/general.constants";
import { useEffect } from "react";

const RejectionReason = () => {
  const { fetchRecordDetails } = useActions();
  const { data, isLoading } = useTypedSelector((state) => state.recordDetails);
  const history = useHistory();
  const location = useLocation();

  const match = matchPath<DetailsQueryParams>(location.pathname, {
    path: [AppRoutes.DetailsPage, AppRoutes.RevisionPage],
  });

  const { routeId, recordId, revision, menuSelector } = match?.params ?? {};

  const convertDate = function (isoDate: any) {
    const lastIndex =
      isoDate.lastIndexOf("+") !== -1
        ? isoDate.lastIndexOf("+")
        : isoDate.lastIndexOf("-");
    const milliseconds = isoDate.substring(isoDate.lastIndexOf("."), lastIndex);
    return isoDate.replace(/T/, " ").replace(milliseconds, " ");
  };

  useEffect(() => {
    fetchRecordDetails(history, routeId, recordId, revision, menuSelector);
  }, []);

  if (!data || !data.record || isLoading) {
    return <Loader message={"Loading, please wait..."} />;
  }

  const { rejectionReason, record } = data;

  return (
    <>
      <h6>
        <b>{unescape(record?.name)}</b>
      </h6>

      <div>
        <div
          aria-label="rejection-reason-tab"
          className="row show container text-nowrap"
        >
          {record?.locations.map((location) => (
            <>
              <div className="col-6 details-field">
                <label>System:</label>
                <span>{location.system}</span>
              </div>

              <div className="col-6 details-field">
                <label>Item Type:</label>
                <span>{location.item_type}</span>
              </div>

              <div className="col-6 details-field">
                <label>Record ID:</label>
                <span>
                  <a
                    href={"this.urls.Browser"}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {location.local_id_fields.Key}
                  </a>
                </span>
              </div>
            </>
          ))}

          <div className="col-6 details-field">
            <label>Record Type:</label>
            <span>{record?.record_type}</span>
          </div>

          <div className="col-6 details-field">
            <label>Record Status:</label>
            <span>{record?.status}</span>
          </div>
        </div>
        <br />

        <table className="table table-hover table-sm" id="rejectionTable">
          <thead>
            <tr>
              <th>Rejection Reason</th>
              <th>Reviewer</th>
              <th>Meaning</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {rejectionReason?.map((reason) => (
              <tr>
                <td>{reason.reason}</td>
                <td>
                  {reason.fullName} ({reason.userName})
                </td>
                <td>{reason.meaning}</td>
                <td>{convertDate(reason.timeStamp)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RejectionReason;
