import { useEffect, useState } from "react";
import { useActions } from "../../../../../hooks/useActions";
import axios from "axios";
import { toast } from "react-toastify";
import {
  IAddProjectModalProps,
  IAddProjectToDomainResponseData,
  IIdpSelectOption,
} from "../../../../interfaces/AdminPage";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import Select from "@atlaskit/select";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import { AdminPageWarnings } from "../../../../../constants/admin-page.constants";
import { ITableData } from "../../../../interfaces/Table";
import { IConnectionTableData } from "../../../../interfaces/Connections";
import { IconButton } from "@atlaskit/button/new";

const AddProjectModal = ({
  isOpen,
  closeModal,
  domainId,
  updateTableData,
}: IAddProjectModalProps) => {
  const [formData, setFormData] = useState({
    connection: { label: "Loading, please wait...", value: "" },
    projectsToAdd: "",
  });
  const [locationsOptionsList, setLocationsOptionsList] = useState<
    IIdpSelectOption[] | null
  >(null);
  const [loading, setLoading] = useState(false);

  const getProjectLocations = async () => {
    try {
      const { data } = await axios.get<ITableData<IConnectionTableData>>(
        AdminRequestRoutes.CONNECTIONS,
      );

      if (data) {
        const rows = data.rows.filter((x) => x.System != "VERA");

        setFormData((prev) => ({
          ...prev,
          connection: { label: rows[0].Name, value: rows[0]._id },
        }));
        setLocationsOptionsList(
          rows.map((connection) => ({
            label: connection.Name,
            value: connection._id,
          })),
        );
      } else {
        closeModal();
      }
    } catch (error: any) {
      closeModal();
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
  const handleAddProject = async () => {
    if (!formData.projectsToAdd) {
      toast.warning(AdminPageWarnings.ADD_PROJECT_TO_DOMAIN_EMPTY_FORM);
      return;
    }

    try {
      setLoading(true);

      const { data }: IAddProjectToDomainResponseData = await axios.post(
        AdminRequestRoutes.ADD_PROJECTS_TO_DOMAIN,
        {
          projectsToAddDomainId: domainId,
          connectionId: formData.connection.value,
          projectsToAddInput: formData.projectsToAdd,
        },
      );

      if (data.apiStatusCode === 200) {
        toast.success(data.message);
        updateTableData();
      } else if (data.apiStatusCode === 400) {
        toast.warning(data.message, { autoClose: 7000 });
      } else {
        toast.error(data.message, { autoClose: 7000 });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
      closeModal();
    }
  };
  const handleSelectChange = (item: any) =>
    setFormData((prev) => ({
      ...prev,
      connection: item,
    }));

  useEffect(() => {
    getProjectLocations();
  }, []);

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="large">
          <ModalHeader>
            <ModalTitle>Add Projects to a Domain</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <form className="create-domain-form">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Connection:</label>

                    <Select
                      aria-label="search-select"
                      className="single-select"
                      classNamePrefix="react-select"
                      inputId="single-select-example"
                      isDisabled={!locationsOptionsList}
                      menuPosition="fixed"
                      onChange={handleSelectChange}
                      options={locationsOptionsList!}
                      spacing={"default"}
                      value={formData.connection}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Projects to Add:</label>

                    <textarea
                      aria-label="projects-to-add-input"
                      className="form-control"
                      onChange={(event) =>
                        setFormData((prev) => ({
                          ...prev,
                          projectsToAdd: event.target.value,
                        }))
                      }
                      placeholder={
                        "Enter projects as a comma-separated list of project identifiers. Example: For Jira, enter a list of project keys (alphanumeric and/or underscores only) in the selected location above (e.g. VS, VF, etc.). For qTest, enter a list of Project ID numbers (e.g. 12,10,9)."
                      }
                      rows={4}
                      value={formData.projectsToAdd}
                    />
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  <button
                    aria-label="add-projects-submit"
                    className="btn btn-primary text-white"
                    disabled={loading || !locationsOptionsList}
                    id="submit-add-projects-btn"
                    onClick={handleAddProject}
                    type="button"
                  >
                    Add Projects
                    {loading && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      />
                    )}
                  </button>

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default AddProjectModal;
