import React from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import {
  IDeleteUserModalProps,
  IDeleteUserResponse,
} from "../../../../interfaces/AdminPage";
import {
  ErrorMessage,
  ProfileRequestRoutes,
} from "../../../../../constants/general.constants";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../../constants/admin-page.constants";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useActions } from "../../../../../hooks/useActions";
import { IconButton } from "@atlaskit/button/new";

const DeleteUserModal = ({
  closeModal,
  isOpen,
  userData,
}: IDeleteUserModalProps) => {
  const [loading, setLoading] = React.useState(false);
  const { tableData, sortParams } = useTypedSelector((x) => x.users);
  const { fetchUsers, updateUsersPageParams } = useActions();

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      const { data }: IDeleteUserResponse = await axios.post(
        ProfileRequestRoutes.USER_DELETE,
        {
          deleteUserId: userData._id,
        },
      );

      if (data.apiStatusCode === 204) {
        toast.success(AdminPageSuccessMessages.USER_DELETE);
        if (tableData.rows.length === 1) {
          updateUsersPageParams(0, sortParams.limit);
        } else {
          fetchUsers();
        }
      } else {
        toast.error(AdminPageErrors.USER_DELETE);
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="medium">
          <ModalHeader>
            <ModalTitle>Delete User</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col">
                <p>The following user will be deleted:</p>
              </div>
            </div>

            <form>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Name:</label>

                    <input
                      className="form-control"
                      readOnly
                      value={userData.claims[0].value}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Username:</label>

                    <input
                      className="form-control"
                      readOnly
                      value={userData.userName}
                    />
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  <button
                    className="btn btn-primary text-white"
                    onClick={handleDeleteUser}
                    type="button"
                  >
                    Delete
                    {loading && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      />
                    )}
                  </button>

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default DeleteUserModal;
