import { useEffect, useState } from "react";
import { Buffer } from "buffer";
import CompanyLogo from "./login/Logo";
import ErrorAlert from "../modules/ErrorAlert";
import { useHistory } from "react-router-dom";

const ErrorPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const handleOnClick = () => {
    history.push("/");
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const errorText = searchParams.get("errorText");
    if (errorText) {
      setErrorMessage(Buffer.from(errorText, "base64").toString());
    }
  }, []);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <CompanyLogo />
      <ErrorAlert message={errorMessage} />
      <div>
        <button className="btn btn-primary text-white" onClick={handleOnClick}>
          Go back
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
