import { useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { Radio } from "@atlaskit/radio";
import { validatePasswordPolicyForm } from "../../../validators/passwordPolicyValidator";
import {
  AdminRequestRoutes,
  DEFAULT_PASSWORD_POLICY_SETTINGS,
  ErrorMessage,
} from "../../../constants/general.constants";
import { AdminPageSuccessMessages } from "../../../constants/admin-page.constants";
import {
  IPasswordPolicyFormData,
  IPasswordPolicyFormErrors,
  IPasswordPolicyResponseData,
} from "../../interfaces/AdminPage";
import TextField from "../../modules/common/TextField";
import Loader from "../../modules/Loader";
import PasswordAlert from "./PasswordAlert";

let passwordPolicyCancelToken: CancelTokenSource;

const PasswordPolicyTab = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<IPasswordPolicyFormErrors>({});
  const [passwordPolicyTypeIsDefault, setPasswordPolicyTypeIsDefault] =
    useState<boolean>(true);
  const [formData, setFormData] = useState<IPasswordPolicyFormData>({
    minLower: 0,
    minUpper: 0,
    minNumeric: 0,
    minSpecial: 0,
    minLength: 0,
  });
  const initialFormState = useRef(formData);

  const handleFormChange = (newData: any) =>
    setFormData((prev) => ({ ...prev, [newData.name]: Number(newData.value) }));
  const getNumberOfRequiredCharacters = () =>
    formData.minUpper +
    formData.minNumeric +
    formData.minSpecial +
    formData.minLower;
  const handleResetForm = () => {
    setFormData(initialFormState.current);
    validatePasswordPolicyForm(
      initialFormState.current,
      setErrors,
      getNumberOfRequiredCharacters(),
    );
  };
  const getPasswordPolicyData = async () => {
    if (typeof passwordPolicyCancelToken != typeof undefined) {
      passwordPolicyCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    passwordPolicyCancelToken = axios.CancelToken.source();

    try {
      const { data, status }: IPasswordPolicyResponseData = await axios.get(
        AdminRequestRoutes.PASSWORD_POLICY,
        { cancelToken: passwordPolicyCancelToken.token },
      );
      if (status === 200) {
        if (data.apiStatusCode === 200) {
          const receivedFormData = {
            minLower: data.apiData.min_lower,
            minUpper: data.apiData.min_upper,
            minNumeric: data.apiData.min_numeric,
            minSpecial: data.apiData.min_special,
            minLength: data.apiData.min_length,
          };
          initialFormState.current = receivedFormData;
          setPasswordPolicyTypeIsDefault(data.apiData.isDefault);
          setFormData(receivedFormData);
          setInitialLoading(false);
        } else {
          toast.error(
            data.message ||
              "An unexpected status was received when getting the Password Policy.",
          );
        }
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (typeof passwordPolicyCancelToken != typeof undefined) {
      passwordPolicyCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    passwordPolicyCancelToken = axios.CancelToken.source();

    try {
      setIsLoading(true);
      const { data }: IPasswordPolicyResponseData = await axios.post(
        AdminRequestRoutes.PASSWORD_POLICY,
        {
          ...formData,
          passwordPolicyType: passwordPolicyTypeIsDefault
            ? "default"
            : "custom",
        },
        { cancelToken: passwordPolicyCancelToken.token },
      );

      if (data.apiStatusCode === 200) {
        initialFormState.current = formData;
        toast.success(AdminPageSuccessMessages.PASSWORD_POLICY);
      } else {
        toast.error(
          data.message ||
            "An unexpected error occurred when setting the Password Policy.",
        );
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (
      ASCIICode > 31 &&
      (ASCIICode < 48 || ASCIICode > 57) &&
      (ASCIICode < 96 || ASCIICode > 105)
    ) {
      evt.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    getPasswordPolicyData();
  }, []);

  useEffect(() => {
    formData !== initialFormState.current &&
      validatePasswordPolicyForm(
        formData,
        setErrors,
        getNumberOfRequiredCharacters(),
      );
  }, [formData]);

  useEffect(() => {
    if (passwordPolicyTypeIsDefault) {
      setFormData(DEFAULT_PASSWORD_POLICY_SETTINGS);
    } else {
      setFormData(initialFormState.current);
    }
  }, [passwordPolicyTypeIsDefault]);

  const hasErrors =
    errors.minLength ||
    errors.minLower ||
    errors.minNumeric ||
    errors.minSpecial ||
    errors.minUpper;
  const isValid =
    !hasErrors &&
    JSON.stringify(formData) !== JSON.stringify(initialFormState.current);

  return (
    <>
      <div aria-label="password-policy" className="table-heading-wrapper">
        <b>Password Policy Settings</b>
      </div>

      {initialLoading ? (
        <div className="col-9" style={{ fontSize: "1rem" }}>
          <Loader message="Loading, please wait..." />
        </div>
      ) : (
        <form
          aria-label="password-policy-form"
          className={`needs-validation policy-settings-form`}
          noValidate
          onSubmit={handleFormSubmit}
        >
          <PasswordAlert {...formData} />

          <div className="mb-3">
            <div
              className="password-policy-radio-item"
              onClick={() => setPasswordPolicyTypeIsDefault(true)}
              style={{
                backgroundColor: passwordPolicyTypeIsDefault
                  ? "#e7e8ff"
                  : "transparent",
              }}
            >
              <Radio
                crossOrigin
                id="default-password-policy-radio"
                isChecked={passwordPolicyTypeIsDefault}
                label="Default: Use the default VERA settings"
                name="passwordPolicyTypeDefault"
              />
            </div>

            <div
              className="password-policy-radio-item"
              onClick={() => setPasswordPolicyTypeIsDefault(false)}
              style={{
                backgroundColor: !passwordPolicyTypeIsDefault
                  ? "#e7e8ff"
                  : "transparent",
              }}
            >
              <Radio
                crossOrigin
                id="custom-password-policy-radio"
                isChecked={!passwordPolicyTypeIsDefault}
                label="Custom: Use your own settings"
                name="passwordPolicyTypeDefault"
              />
            </div>
          </div>
          <div className="password-policy-table">
            <div className="password-policy-table__header">
              <div className="password-policy-table__type">
                Type of characters
              </div>
              <div className="password-policy-table__minimum">Minimum</div>
            </div>
            <div className="password-policy-fields">
              <TextField
                centeredField={true}
                disabled={passwordPolicyTypeIsDefault}
                error={errors.minLength}
                label={"Total:"}
                min={0}
                name={"minLength"}
                onChange={handleFormChange}
                onKeyDown={handleKeyDown}
                type="number"
                value={formData?.minLength}
              />

              <TextField
                centeredField={true}
                disabled={passwordPolicyTypeIsDefault}
                error={errors.minLower}
                label={"Lower Case:"}
                min={0}
                name={"minLower"}
                onChange={handleFormChange}
                onKeyDown={handleKeyDown}
                type="number"
                value={formData?.minLower}
              />

              <TextField
                centeredField={true}
                disabled={passwordPolicyTypeIsDefault}
                error={errors.minUpper}
                label={"Upper Case:"}
                min={0}
                name={"minUpper"}
                onChange={handleFormChange}
                onKeyDown={handleKeyDown}
                type="number"
                value={formData?.minUpper}
              />

              <TextField
                centeredField={true}
                disabled={passwordPolicyTypeIsDefault}
                error={errors.minNumeric}
                label={"Numeric:"}
                min={0}
                name={"minNumeric"}
                onChange={handleFormChange}
                onKeyDown={handleKeyDown}
                type="number"
                value={formData?.minNumeric}
              />

              <TextField
                centeredField={true}
                disabled={passwordPolicyTypeIsDefault}
                error={errors.minSpecial}
                label={"Special:"}
                min={0}
                name={"minSpecial"}
                onChange={handleFormChange}
                onKeyDown={handleKeyDown}
                type="number"
                value={formData?.minSpecial}
              />
            </div>
          </div>
          <div className="col">
            <span className="float-right">
              <button
                className="btn btn-primary text-white mr-2"
                disabled={!isValid}
                id="save-password-policy-btn"
              >
                Save Changes{" "}
                {isLoading && (
                  <span
                    aria-hidden="true"
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                  />
                )}
              </button>

              <button
                className="btn btn-primary text-white"
                id="reset-password-policy-btn"
                onClick={handleResetForm}
                type="button"
              >
                Reset
              </button>
            </span>
          </div>
        </form>
      )}
    </>
  );
};

export default PasswordPolicyTab;
