import { SidebarAction, SidebarActionTypes } from "../types/sidebar";

export interface SidebarState {
  isCollapsed: boolean;
}

const initialState: SidebarState = {
  isCollapsed: false,
};

export const sidebarReducer = (
  state = initialState,
  action: SidebarAction,
): SidebarState => {
  switch (action.type) {
    case SidebarActionTypes.TOGGLE_SIDEBAR:
      return { ...state, isCollapsed: !state.isCollapsed };
    default:
      return state;
  }
};
