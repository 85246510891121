import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  IConnection,
  IConnectionPageParams,
} from "../../../../interfaces/Connections";
import ConnectionDetailsForm from "./ConnectionDetailsForm";
import Loader from "../../../../modules/Loader";
import {
  ConnectionRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import axios from "axios";
import { AdminPageSuccessMessages } from "../../../../../constants/admin-page.constants";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { transformConnectionResponse } from "../../../../../helpers/connections.helper";
import { AxiosResponse } from "axios";

const EditConnectionPage = () => {
  const { data, isLoading } = useTypedSelector((x) => x.connection);

  const { fetchConnectionDetails } = useActions();
  const { connectionId } = useParams<IConnectionPageParams>();
  const history = useHistory();

  useEffect(() => {
    fetchConnectionDetails(history, connectionId);
  }, []);

  const handleFormSubmit = async (connection: IConnection) => {
    try {
      const { data, status }: AxiosResponse<IConnection> = await axios.patch(
        ConnectionRequestRoutes.UPDATE_CONNECTION,
        connection,
      );

      if (status === 200) {
        toast.success(AdminPageSuccessMessages.UPDATE_CONNECTION);
        return data;
      } else {
        toast.error(data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  const connectionFormData = useMemo(
    () => transformConnectionResponse(data),
    [data],
  );

  if (isLoading || !data) {
    return <Loader message="Loading, please wait..." />;
  }

  return (
    <ConnectionDetailsForm
      data={connectionFormData}
      labelCancelChangesButton="Cancel Changes"
      labelSaveButton="Save Changes"
      onSubmit={handleFormSubmit}
      title={(data) => `Connections: ${data.System ?? ""}`}
    />
  );
};

export default EditConnectionPage;
