import axios, { CancelTokenSource } from "axios";
import { Dispatch } from "redux";
import { IFilterRecordItemResponse } from "../../components/interfaces/LocateRecordsFilter";
import {
  ErrorMessage,
  LocateRecordsFilterRequestRoutes,
} from "../../constants/general.constants";
import {
  ProjectAction,
  LocateRecordsFilterActionTypes,
} from "../types/locateRecordsFilter";
import { toast } from "react-toastify";
import { RecordAction } from "../types/record";

let cancelTokenProjects: CancelTokenSource;
let cancelTokenRecordTypes: CancelTokenSource;
let cancelTokenRecordStatus: CancelTokenSource;

export const fetchProjects = (searchField: string, offset = 0) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    if (typeof cancelTokenProjects != typeof undefined) {
      cancelTokenProjects.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelTokenProjects = axios.CancelToken.source();

    const sort = "asc";
    const limit = 10;

    try {
      dispatch({
        type: LocateRecordsFilterActionTypes.FETCHING_PROJECTS,
        payload: offset,
      });

      const { data, status } = await axios.get<IFilterRecordItemResponse>(
        LocateRecordsFilterRequestRoutes.PROJECTS,
        {
          params: { sort, offset, limit, keyword: searchField },
        },
      );

      if (status === 200) {
        if (offset) {
          dispatch({
            type: LocateRecordsFilterActionTypes.SHOW_MORE_PROJECTS,
            payload: data,
          });
        } else {
          dispatch({
            type: LocateRecordsFilterActionTypes.UPDATE_PROJECTS,
            payload: data,
          });
        }
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: LocateRecordsFilterActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchRecordTypes = (searchField: string, offset = 0) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    if (typeof cancelTokenRecordTypes != typeof undefined) {
      cancelTokenRecordTypes.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelTokenRecordTypes = axios.CancelToken.source();

    const sort = "asc";
    const limit = 10;

    try {
      dispatch({
        type: LocateRecordsFilterActionTypes.FETCHING_RECORD_TYPES,
        payload: offset,
      });

      const { data, status } = await axios.get<IFilterRecordItemResponse>(
        LocateRecordsFilterRequestRoutes.RECORD_TYPES,
        {
          cancelToken: cancelTokenRecordTypes.token,
          params: { sort, offset, limit, keyword: searchField },
        },
      );

      if (status === 200) {
        if (offset) {
          dispatch({
            type: LocateRecordsFilterActionTypes.SHOW_MORE_RECORD_TYPES,
            payload: data,
          });
        } else {
          dispatch({
            type: LocateRecordsFilterActionTypes.UPDATE_RECORD_TYPES,
            payload: data,
          });
        }
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: LocateRecordsFilterActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const fetchRecordStatus = (searchField: string, offset = 0) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    if (typeof cancelTokenRecordStatus != typeof undefined) {
      cancelTokenRecordStatus.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelTokenRecordStatus = axios.CancelToken.source();
    const sort = "asc";
    const limit = 10;

    try {
      dispatch({ type: LocateRecordsFilterActionTypes.FETCHING_RECORD_STATUS });

      const { data, status } = await axios.get<IFilterRecordItemResponse>(
        LocateRecordsFilterRequestRoutes.RECORD_STATUS,
        {
          cancelToken: cancelTokenRecordTypes.token,
          params: { sort, offset, limit, keyword: searchField },
        },
      );

      if (status === 200) {
        if (offset) {
          dispatch({
            type: LocateRecordsFilterActionTypes.SHOW_MORE_RECORD_STATUSES,
            payload: data,
          });
        } else {
          dispatch({
            type: LocateRecordsFilterActionTypes.UPDATE_RECORD_STATUS,
            payload: data,
          });
        }
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: LocateRecordsFilterActionTypes.HANDLE_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const updateSelectedProject = (name: string) => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_PROJECT,
      payload: name,
    });
  };
};

export const updateSelectedRecordType = (name: string) => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_TYPE,
      payload: name,
    });
  };
};

export const updateSelectedRecordStatus = (name: string, selected: boolean) => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_STATUS,
      payload: { name, isSelected: selected },
    });
  };
};

export const updateSearchFieldRecordName = (searchField: string) => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_NAME,
      payload: { searchField },
    });
  };
};

export const updateSearchFieldProject = (searchField: string) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_PROJECT,
      payload: { searchField },
    });
  };
};

export const updateSearchFieldRecordStatus = (searchField: string) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_STATUS,
      payload: { searchField },
    });
  };
};

export const updateSearchFieldRecordType = (searchField: string) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SEARCH_FIELD_RECORD_TYPE,
      payload: { searchField },
    });
  };
};

export const clearFilters = () => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    dispatch({ type: LocateRecordsFilterActionTypes.CLEAR_FILTER });
  };
};

export const clearSelectedProjects = () => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({ type: LocateRecordsFilterActionTypes.CLEAR_SELECTED_PROJECT });
  };
};

export const clearSelectedRecordTypes = () => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.CLEAR_SELECTED_RECORD_TYPE,
    });
  };
};

export const clearSelectedRecordStatus = () => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.CLEAR_SELECTED_RECORD_STATUS,
    });
  };
};

export const updateSelectedProjects = (projects: string[]) => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_PROJECTS,
      payload: { projects },
    });
  };
};

export const updateSelectedRecordTypes = (recordTypes: string[]) => {
  return async (dispatch: Dispatch<ProjectAction | RecordAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_TYPES,
      payload: { recordTypes },
    });
  };
};

export const updateSelectedRecordStatuses = (recordStatuses: string[]) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_SELECTED_RECORD_STATUSES,
      payload: { recordStatuses },
    });
  };
};

export const updateAllFilters = (
  projects: string[],
  recordTypes: string[],
  recordStatus: string[],
) => {
  return async (dispatch: Dispatch<ProjectAction>) => {
    dispatch({
      type: LocateRecordsFilterActionTypes.UPDATE_ALL_FILTERS,
      payload: {
        projects,
        recordTypes,
        recordStatus,
      },
    });
  };
};
