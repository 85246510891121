import { ILinkedPolicySetTableState } from "../../components/interfaces/Policy";
import {
  PolicyLinkedPolicySetsAction,
  PolicyLinkedPolicySetsActionTypes,
} from "../types/policyLinkedPolicySets";

export const initialState: ILinkedPolicySetTableState = {
  columnSearchField: {},
  isLoading: false,
  tableData: {
    rows: [],
    total: 0,
  },
  selectedPage: 0,
  sortParams: {
    sort: "Name",
    order: "asc",
    offset: 0,
    limit: 15,
  },
  tableHeaders: [
    { label: "Name", name: "name", filterable: true, colSize: 12 },
  ],
};

export const policyLinkedPolicySetsReducer = (
  state = initialState,
  action: PolicyLinkedPolicySetsAction,
): ILinkedPolicySetTableState => {
  switch (action.type) {
    case PolicyLinkedPolicySetsActionTypes.FETCHING_LINKED_POLICY_SETS_TABLE_DATA:
      return { ...state, isLoading: true };
    case PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload,
      };
    case PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case PolicyLinkedPolicySetsActionTypes.RESET_LINKED_POLICY_SETS_DATA:
      return initialState;
    case PolicyLinkedPolicySetsActionTypes.RESET_LINKED_POLICY_SETS_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case PolicyLinkedPolicySetsActionTypes.UPDATE_LINKED_POLICY_SETS_COLUMN_SEARCH_FIELD:
      return {
        ...state,
        columnSearchField: action.payload,
      };
    case PolicyLinkedPolicySetsActionTypes.HANDLE_LINKED_POLICY_SETS_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        networkError: action.payload,
      };
    default:
      return state;
  }
};
