import { useState } from "react";
import { toast } from "react-toastify";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import TextArea from "@atlaskit/textarea";
import { IModalRejectProps } from "../../interfaces/ApprovalQueue";
import { ApprovalPageWarnings } from "../../../constants/approval-page.constants";
import Loader from "../../modules/Loader";
import { IconButton } from "@atlaskit/button/new";
import CrossIcon from "@atlaskit/icon/glyph/cross";

const ModalReject = ({
  rejectId,
  onCloseModal,
  handleTaskSubmit,
  isOpen,
  isLoading,
}: IModalRejectProps) => {
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const handleSubmit = () => {
    if (!rejectionReason) {
      toast.warning(ApprovalPageWarnings.EMPTY_REJECTION_REASON);
      return;
    }
    handleTaskSubmit("", rejectionReason, rejectId, true);
  };

  const handleOnCloseModal = () => {
    onCloseModal(false);
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleOnCloseModal} width="large">
          <ModalHeader>
            <ModalTitle>Reject Selected Entity</ModalTitle>
            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={handleOnCloseModal}
            />
          </ModalHeader>

          <div className="position-relative">
            {isLoading && <Loader message={"Processing..."} tableMode={true} />}

            <ModalBody>
              <div className="row" id="signatureConsent">
                <div className="col">
                  <p>
                    Please enter the rejection reason in the text box below:
                  </p>
                </div>
              </div>

              <TextArea
                maxHeight="20vh"
                minimumRows={7}
                name="area"
                onBlur={(e) => setRejectionReason((x) => x.trim())}
                onChange={(e) => setRejectionReason(e.target.value)}
                placeholder={"Rejection reason..."}
                resize="auto"
                value={rejectionReason}
              />
            </ModalBody>

            <ModalFooter>
              <div style={{ zIndex: 2 }}>
                <button
                  className="btn btn-primary text-white mr-2"
                  disabled={isLoading}
                  onClick={handleSubmit}
                  type="button"
                >
                  Submit
                </button>

                <button
                  className="btn btn-primary text-white"
                  id="btnCancel"
                  onClick={handleOnCloseModal}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ModalReject;
