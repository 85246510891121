import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormControl } from "@mui/material";
import { PasswordInputLabel } from "./styles";

const PasswordField = (props: OutlinedInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="outlined">
      <PasswordInputLabel
        htmlFor="outlined-adornment-password"
        id="password-input-label"
      >
        Password
      </PasswordInputLabel>
      <OutlinedInput
        {...props}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={() => setShowPassword((x) => !x)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        id="password-input"
        type={showPassword ? "text" : "password"}
      />
    </FormControl>
  );
};

export default PasswordField;
