const AuthorWarningFooter = () => {
  return (
    <div
      className="card mx-auto w-50 text-center align-middle fixed-bottom no-print"
      style={{ background: "rgba(255, 250, 0, 0.53)" }}
    >
      <div className="superseded-footer align-middle text-black">
        <i aria-hidden="true" className="fa fa-exclamation-triangle mr-1" />
        You are listed as an author for this record and cannot approve.
      </div>
    </div>
  );
};

export default AuthorWarningFooter;
