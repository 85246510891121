import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getChartOptions } from "../../../../../../helpers/concurrent-users.helper";
import { useGetConcurrentUsersQuery } from "../../../../../../store/slices/user.slice";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import Loader from "../../../../../modules/Loader";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const ConcurrentUsersGraph = () => {
  const {
    startDate,
    endDate,
    errorStartDate,
    errorEndDate,
    groupBy,
    summarizeBy,
  } = useTypedSelector((x) => x.concurrentUsersReport);

  const { data = [], isLoading } = useGetConcurrentUsersQuery(
    {
      startDate: startDate ? `${startDate.split("T")[0]}` : undefined,
      endDate: endDate ? `${endDate.split("T")[0]}` : undefined,
      groupBy,
      summarizeBy: summarizeBy,
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        !startDate ||
        !endDate ||
        Boolean(errorStartDate) ||
        Boolean(errorEndDate),
    },
  );

  const graphData = {
    labels: data.map((x) => x.date),
    datasets: [
      {
        label: "Concurrent Users",
        data: data.map((x) => x.userCount),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data.length) {
    return <div className="data-not-found">No matching data found</div>;
  }

  return (
    <>
      <Line data={graphData} options={getChartOptions()} />
    </>
  );
};

export default ConcurrentUsersGraph;
