import { useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Loader from "../../modules/Loader";
import Tooltip from "@atlaskit/tooltip";
import Toggle from "@atlaskit/toggle";
import DomainsAndRolesListItem from "./DomainsAndRolesListItem";

const DomainAndRolesContent = () => {
  const { user } = useTypedSelector((x) => x.user);

  const { domainAndRoles, isLoadingDomainsAndRoles, sortParams } =
    useTypedSelector((state) => state.domainsAndRoles);

  const { fetchDomainsAndRolesFromUser, handleResetAllDomainsAndRolesData } =
    useActions();

  useEffect(() => {
    user?.veraUserId &&
      fetchDomainsAndRolesFromUser(user?.veraUserId, sortParams);
  }, [sortParams]);

  useEffect(() => {
    return () => {
      handleResetAllDomainsAndRolesData();
    };
  }, []);

  if (isLoadingDomainsAndRoles) {
    return <Loader message="Loading, please wait..." />;
  }

  if (domainAndRoles.rows.length == 0) {
    return (
      <div className="empty-list">
        <div>No domains/roles assigned to this user.</div>
      </div>
    );
  }

  return domainAndRoles.rows.map((row) => (
    <DomainsAndRolesListItem domainsAndRoles={row} />
  ));
};

export default DomainAndRolesContent;
