import DomainsAndRolesList from "./DomainsAndRolesList";
import DomainsAndRolesPagination from "../admin/domainsAndRolesManagement/DomainsAndRolesPagination";

const DomainsAndRolesProfilePage = () => {
  return (
    <div>
      <div className="mt-4">
        <DomainsAndRolesList />
        <DomainsAndRolesPagination />
      </div>
    </div>
  );
};

export default DomainsAndRolesProfilePage;
