import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "@atlaskit/checkbox";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import ModalApprove from "./ModalApprove";
import ModalReject from "./ModalReject";
import { IRecordData, ITableItemProps } from "../../interfaces/ApprovalQueue";
import { unescape } from "lodash";

const ApprovalQueueTableItem = ({
  tableItem,
  onTaskApprove,
  onTaskReject,
  onCloseModal,
}: ITableItemProps) => {
  const { veraIdp } = useTypedSelector((state) => state.user);
  const { handleCheckBoxAction, clearSessionID, setNewSessionID } =
    useActions();
  const [isOpenApproveModal, setIsOpenApproveModal] =
    React.useState<boolean>(false);
  const [isOpenRejectModal, setIsOpenRejectModal] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleCheckBoxChange = (selectedItem: IRecordData) => {
    handleCheckBoxAction(selectedItem._id);
  };
  const openApproveModal = () => {
    setIsOpenApproveModal(true);
    setNewSessionID();
  };
  const openRejectModal = () => setIsOpenRejectModal(true);

  const handleOnCloseApproveModal = (
    shouldRefresh: boolean,
    numOfApprovedRecords?: number,
  ) => {
    setIsOpenApproveModal(false);
    clearSessionID();
    onCloseModal(shouldRefresh, numOfApprovedRecords);
  };

  const handleOnCloseRejectModal = (
    shouldRefresh: boolean,
    numOfRejectedRecords?: number,
  ) => {
    setIsOpenRejectModal(false);
    clearSessionID();
    onCloseModal(shouldRefresh, numOfRejectedRecords);
  };

  const handleTaskSubmit = async (
    password = "",
    reason = "",
    taskId: string,
  ) => {
    setIsLoading(true);

    if (reason) {
      const success = await onTaskReject(taskId, reason);
      veraIdp && setIsLoading(false);
      success && handleOnCloseRejectModal(true, 1);
    } else {
      const success = await onTaskApprove(taskId, password);

      if (!success) {
        setIsLoading(false);
      }
    }
  };

  return (
    <tr className={tableItem.isSelected ? "selected-item" : "table-item"}>
      <th scope="row">
        <Checkbox
          aria-label="select-task"
          isChecked={!!tableItem.isSelected}
          onChange={() => handleCheckBoxChange(tableItem)}
        />
      </th>
      <td>{tableItem.Domain}</td>
      <td>{tableItem.Project}</td>
      <td>{tableItem.System}</td>
      <td>{tableItem.RecordType}</td>
      <td>
        <Link
          to={`/details/${tableItem.RouteId}/${tableItem._id}/entity-details`}
        >
          {unescape(tableItem.RecordName)}
        </Link>
      </td>
      <td>{tableItem.Meaning}</td>
      <td>{tableItem.Timestamp}</td>
      <td>
        {isOpenApproveModal && (
          <ModalApprove
            approveId={tableItem._id}
            handleTaskSubmit={handleTaskSubmit}
            hideLoading={() => setIsLoading(false)}
            isLoading={isLoading}
            isOpen={isOpenApproveModal}
            meaning={tableItem.Meaning}
            onCloseModal={handleOnCloseApproveModal}
            showLoading={() => setIsLoading(true)}
          />
        )}
        <ModalReject
          handleTaskSubmit={handleTaskSubmit}
          isLoading={isLoading}
          isOpen={isOpenRejectModal}
          onCloseModal={handleOnCloseRejectModal}
          rejectId={tableItem._id}
        />

        <div className="table-actions">
          <FontAwesomeIcon
            aria-label="open-modal-approve"
            className={"m-1"}
            color="green"
            cursor={"pointer"}
            icon={faPenAlt}
            onClick={openApproveModal}
          />
          <FontAwesomeIcon
            aria-label="open-modal-reject"
            className={"m-1"}
            color="red"
            cursor={"pointer"}
            icon={faTimesCircle}
            onClick={openRejectModal}
          />
        </div>
      </td>
    </tr>
  );
};

export default ApprovalQueueTableItem;
