import * as yup from "yup";

export function createSchema() {
  return yup.object().shape({
    domainName: yup.lazy(() => {
      return yup
        .string()
        .nullable()
        .required("You must provide a domain name.");
    }),
  });
}
