interface IExpandedContentModalTitleProps {
  id: string;
  description: string;
}

const ExpandedContentModalTitle: React.FC<IExpandedContentModalTitleProps> = ({
  id,
  description,
}) => {
  return (
    <span className="expanded-content-modal__title-container">
      {id && (
        <>
          <span className="expanded-content-modal__title" title={id}>
            {id}
          </span>
          <span>-</span>
        </>
      )}
      <span className="expanded-content-modal__title">{description}</span>
    </span>
  );
};

export default ExpandedContentModalTitle;
