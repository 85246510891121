import { IPasswordPolicyFormData } from "../../interfaces/AdminPage";

const PasswordAlert = ({
  minLength,
  minLower,
  minNumeric,
  minSpecial,
  minUpper,
}: IPasswordPolicyFormData) => {
  const messages = [];
  if ([minLower, minNumeric, minSpecial, minUpper].some((x) => x > 2)) {
    messages.push(
      "Setting a minimum to more than 2 may result in passwords that are difficult to remember",
    );
  }

  if (minLength < 8) {
    messages.push(
      "Setting a minimum length to less than 8 may result in insecure passwords",
    );
  }

  if (messages.length) {
    return (
      <div className="alert alert-warning alert-password" role="alert">
        <ul className="mb-0">
          {messages.map((x, index) => (
            <li key={index}>{x}</li>
          ))}
        </ul>
      </div>
    );
  }

  return <div>&nbsp;</div>;
};

export default PasswordAlert;
