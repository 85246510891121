import {
  ApprovalQueueAction,
  ApprovalQueueActionTypes,
  ApprovalQueueState,
} from "../types/approvalQueue";

const initialState: ApprovalQueueState = {
  tableData: null,
  rowsData: null,
  sortParams: {
    sort: "Timestamp",
    order: "asc",
    offset: 0,
    limit: 15,
  },
  searchField: false,
  selectedPage: 0,
  selectedMeaningsAreUnique: true,
  isLoading: false,
  selectedItems: [],
  tableHeaders: [
    { label: "Domain", name: "Domain", filterable: true, colSize: 1 },
    { label: "Project", name: "Project", filterable: true, colSize: 1 },
    { label: "System", name: "System", filterable: false, colSize: 1 },
    { label: "Record Type", name: "RecordType", filterable: true, colSize: 1 },
    { label: "Record Name", name: "RecordName", filterable: true, colSize: 5 },
    { label: "Approval Task", name: "Meaning", filterable: true, colSize: 1 },
    { label: "Assigned Date", name: "Timestamp", filterable: true, colSize: 2 },
  ],
  networkError: "",
  columnSearchField: {},
};

export const approvalQueueReducer = (
  state = initialState,
  action: ApprovalQueueAction,
): ApprovalQueueState => {
  switch (action.type) {
    case ApprovalQueueActionTypes.FETCHING_TABLE_DATA:
      return { ...state, isLoading: true };

    case ApprovalQueueActionTypes.UPDATE_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload.tableData,
        rowsData: action.payload.rowsData,
        selectedItems: action.payload.rowsData.filter((x) => x.isSelected),
      };

    case ApprovalQueueActionTypes.UPDATE_ROWS_DATA:
      return {
        ...state,
        selectedItems: action.payload.selectedItems,
        selectedMeaningsAreUnique: action.payload.selectedMeaningsAreUnique,
        rowsData: action.payload.result,
      };
    case ApprovalQueueActionTypes.UPDATE_ALL_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
      };

    case ApprovalQueueActionTypes.UPDATE_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };

    case ApprovalQueueActionTypes.UPDATE_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };

    case ApprovalQueueActionTypes.UPDATE_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };

    case ApprovalQueueActionTypes.UNSELECT_ROWS:
      return {
        ...state,
        selectedItems: [],
        selectedMeaningsAreUnique: true,
      };
    case ApprovalQueueActionTypes.UPDATE_COLUMN_SEARCH_FIELD:
      return {
        ...state,
        columnSearchField: action.payload,
      };
    case ApprovalQueueActionTypes.HANDLE_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        networkError: action.payload,
      };

    case ApprovalQueueActionTypes.RESET_DATA:
      return initialState;

    default:
      return state;
  }
};
