import useModal from "../../../../../hooks/useModal";
import { IAuditHistoryTableItemProps } from "../../../../interfaces/AuditHistory";
import AuditHistoryModal from "./modal/AuditHistoryModal";

const AuditHistoryTableItem = ({ auditEntry }: IAuditHistoryTableItemProps) => {
  const { action, timestamp, entity, user } = auditEntry;
  const { visible, show, hide } = useModal();

  return (
    <tr>
      <td>
        <button className="btn-link" onClick={show}>
          {timestamp}
        </button>
      </td>
      <td>{user.userName}</td>
      <td>{action.name}</td>
      <td>{entity.type}</td>
      <td>{entity.name}</td>
      <td>
        {entity.id}
        {visible && (
          <AuditHistoryModal
            auditEntry={auditEntry}
            onClose={hide}
            visible={visible}
          />
        )}
      </td>
    </tr>
  );
};

export default AuditHistoryTableItem;
