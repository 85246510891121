import { RecordState, RecordAction, RecordActionTypes } from "../types/record";

export const initialState: RecordState = {
  tableData: null,
  rowsData: null,
  selectedPage: 0,
  sortParams: {
    sort: "recordName",
    order: "asc",
    offset: 0,
    limit: 15,
  },
  isLoading: false,
  tableHeaders: [
    { label: "Project Name", name: "project", filterable: true, colSize: 1 },
    { label: "Record Type", name: "recordType", filterable: true, colSize: 2 },
    { label: "Record Name", name: "recordName", filterable: true, colSize: 5 },
    {
      label: "Record Status",
      name: "recordStatus",
      filterable: true,
      colSize: 1,
    },
  ],
  networkError: "",
  filter: {
    project: [],
    recordStatus: [],
    recordType: [],
    recordName: "",
  },
};

export const recordReducer = (
  state = initialState,
  action: RecordAction,
): RecordState => {
  switch (action.type) {
    case RecordActionTypes.FETCHING_RECORD_TABLE_DATA:
      return { ...state, rowsData: null, isLoading: true };
    case RecordActionTypes.UPDATE_RECORD_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload.tableData,
        rowsData: action.payload.rowsData,
      };
    case RecordActionTypes.UPDATE_RECORD_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case RecordActionTypes.UPDATE_RECORD_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case RecordActionTypes.UPDATE_RECORD_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case RecordActionTypes.RESET_RECORD_TABLE_DATA:
      return {
        ...state,
        filter: initialState.filter,
        rowsData: null,
        tableData: null,
        sortParams: {
          ...state.sortParams,
          offset: 0,
        },
        selectedPage: 0,
      };
    case RecordActionTypes.RESET_RECORD_DATA:
      return initialState;
    case RecordActionTypes.RESET_RECORD_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case RecordActionTypes.UPDATE_RECORD_FILTER:
      return {
        ...state,
        filter: action.payload,
        sortParams: {
          ...state.sortParams,
          offset: 0,
        },
        selectedPage: 0,
      };
    case RecordActionTypes.HANDLE_RECORD_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        networkError: action.payload,
      };
    default:
      return state;
  }
};
