import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import {
  IReVerifyModalProps,
  IReverifySignatureResponseData,
} from "../../../interfaces/AdminPage";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
  ReVerifyStatus,
} from "../../../../constants/admin-page.constants";
import { IconButton } from "@atlaskit/button/new";

const ReVerifyModal = ({
  isOpen,
  closeModal,
  verificationData,
}: IReVerifyModalProps) => {
  const [loading, setLoading] = React.useState(false);

  const handleReVerifySignature = async () => {
    try {
      setLoading(true);
      const { data }: IReverifySignatureResponseData = await axios.post(
        AdminRequestRoutes.REVERIFY_SIGNATURE,
        {
          reverifySignatureId: verificationData._id,
        },
      );

      if (
        data.apiStatusCode === 200 &&
        data.apiData.status === ReVerifyStatus.FAILED
      ) {
        toast.error(AdminPageErrors.REVERIFY_SIGNATURE);
        closeModal();
      } else if (
        data.apiStatusCode === 200 &&
        data.apiData.status === ReVerifyStatus.PASSED
      ) {
        toast.success(AdminPageSuccessMessages.REVERIFY_SIGNATURE);
      } else {
        toast.error(data.message);
        closeModal();
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="large">
          <ModalHeader>
            <ModalTitle>Reverify Signature</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <div style={{ fontSize: "1rem" }}>
              <p>Do you want to reverify this signature?</p>

              <form aria-label="re-verify-modal" className="mt-2">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label style={{ fontWeight: 500 }}>Record Name:</label>

                      <input
                        className="form-control"
                        readOnly
                        value={verificationData.record.name}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label style={{ fontWeight: 500 }}>Error:</label>

                      <textarea
                        className="form-control"
                        readOnly
                        rows={6}
                        value={verificationData.message}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="row">
              <div className="col">
                <span className="float-right">
                  <button
                    className="btn btn-primary text-white"
                    disabled={loading}
                    onClick={handleReVerifySignature}
                    type="button"
                  >
                    Reverify
                    {loading && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ml-2"
                        role="status"
                      />
                    )}
                  </button>

                  <button
                    className="btn btn-primary text-white ml-2"
                    onClick={closeModal}
                    type="button"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ReVerifyModal;
