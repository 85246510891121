import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "@atlaskit/tooltip";
import { AdminPageRoutes } from "../../../../constants/general.constants";
import { IDomainsTableItemProps } from "../../../interfaces/AdminPage";
import DeleteDomainModal from "./DeleteDomainModal";
import Lozenge from "@atlaskit/lozenge";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

const DomainsTableItem = ({
  domainData,
  updateTableData,
}: IDomainsTableItemProps) => {
  const [isOpenDeleteDomainModal, setOpenDeleteDomainModal] =
    React.useState<boolean>(false);
  const { _id, Name, Description, DomainOwnerFullName, IsDefault } = domainData;
  const { user } = useTypedSelector((state) => state.user);

  return (
    <tr>
      <td>
        <Link to={`${AdminPageRoutes.DOMAIN_DETAILS}/${_id}`}>{Name}</Link>{" "}
        &nbsp;
        {IsDefault && <Lozenge>Default</Lozenge>}
      </td>

      <td>{Description}</td>
      <td>{DomainOwnerFullName}</td>
      {user?.isSystemAdmin && (
        <td>
          <div className="table-actions">
            {isOpenDeleteDomainModal && (
              <DeleteDomainModal
                closeModal={() => setOpenDeleteDomainModal(false)}
                domainData={domainData}
                isOpen={isOpenDeleteDomainModal}
                updateTableData={updateTableData}
              />
            )}

            <Tooltip content="Edit" position="top">
              <Link
                aria-label="edit-domain-btn"
                to={`${AdminPageRoutes.DOMAIN_DETAILS}/${_id}`}
              >
                <i className="fa fa-edit cursor-pointer" />
              </Link>
            </Tooltip>

            {IsDefault ? (
              <Tooltip
                content="Cannot delete the default domain"
                position="top"
              >
                <i className="fa fa-trash-o cursor-pointer" />
              </Tooltip>
            ) : (
              <Tooltip content="Delete" position="top">
                <a
                  aria-label="delete-domain-btn"
                  href="#"
                  onClick={() => setOpenDeleteDomainModal(true)}
                  role="button"
                >
                  <i className="fa fa-trash-o cursor-pointer" />
                </a>
              </Tooltip>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default DomainsTableItem;
