import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IAuditEntry,
  IAuditHistoryState,
  IAuditRequestParams,
} from "../../components/interfaces/AuditHistory";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../interceptors/axiosBaseQuery";
import { AdminRequestRoutes } from "../../constants/general.constants";
import { ITableData } from "../../components/interfaces/Table";
import { Dayjs } from "dayjs";

const initialState: IAuditHistoryState = {
  columnSearchField: {},
  startDate: null,
  endDate: null,
  errorStartDate: null,
  errorEndDate: null,
  sortParams: {
    sort: "timestamp",
    order: "desc",
    offset: 0,
    limit: 15,
  },
  selectedPage: 0,
  tableHeaders: [
    {
      label: "Timestamp",
      name: "timestamp",
      filterable: true,
      colSize: 2,
      notSearchable: true,
      notSortable: false,
    },
    {
      label: "Username",
      name: "user.userName",
      filterable: true,
      colSize: 1,
      notSortable: false,
    },
    {
      label: "Action",
      name: "action.name",
      filterable: true,
      colSize: 1,
      notSortable: false,
    },
    {
      label: "Entity Type",
      name: "entity.type",
      filterable: true,
      colSize: 1,
      notSortable: false,
    },
    {
      label: "Entity Name",
      name: "entity.name",
      filterable: true,
      colSize: 1,
      notSortable: false,
    },
    {
      label: "Vera ID",
      name: "entity.id",
      filterable: true,
      colSize: 3,
      notSortable: true,
    },
  ],
};

export const auditHistoryApi = createApi({
  reducerPath: "audit-history",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAuditEntries: builder.query<
      ITableData<IAuditEntry>,
      IAuditRequestParams
    >({
      query: (params) => ({
        url: AdminRequestRoutes.AUDIT_HISTORY,
        params,
      }),
    }),
  }),
});

const auditHistorySlice = createSlice({
  name: "audit-history",
  initialState,
  reducers: {
    setColumnsSearchField(state, action: PayloadAction<{}>) {
      state.columnSearchField = action.payload;
    },
    setSelectedPage(state, action: PayloadAction<number>) {
      state.selectedPage = action.payload;
    },
    setSortParams(
      state,
      action: PayloadAction<{ sort: string; order: "asc" | "desc" }>,
    ) {
      state.sortParams = {
        ...state.sortParams,
        ...action.payload,
      };
    },
    setPageParams(
      state,
      action: PayloadAction<{ offset: number; limit: number }>,
    ) {
      state.sortParams = {
        ...state.sortParams,
        ...action.payload,
      };
    },
    setStartDate(state, action: PayloadAction<Dayjs | null>) {
      state.startDate = action.payload?.format("YYYY-MM-DD") ?? null;
    },
    setErrorStartDate(state, action: PayloadAction<string | null>) {
      state.errorStartDate = action.payload;
      state.errorEndDate = null;
      state.endDate = null;
    },
    setEndDate(state, action: PayloadAction<Dayjs | null>) {
      state.endDate = action.payload?.format("YYYY-MM-DD") ?? null;
    },
    setErrorEndDate(state, action: PayloadAction<string | null>) {
      state.errorEndDate = action.payload;
    },
    resetFilter(state) {
      state.columnSearchField = {};
      state.startDate = null;
      state.endDate = null;
      state.errorStartDate = null;
      state.errorEndDate = null;
    },
    resetData: () => {
      return initialState;
    },
  },
});

export const { useGetAuditEntriesQuery } = auditHistoryApi;

export const {
  setStartDate,
  setErrorStartDate,
  setEndDate,
  setErrorEndDate,
  resetData,
  resetFilter,
  setColumnsSearchField,
  setPageParams,
  setSelectedPage,
  setSortParams,
} = auditHistorySlice.actions;

export default auditHistorySlice.reducer;
