import { useActions } from "../../../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../../../hooks/useTypedSelector";
import Table from "../../../../../../modules/Table";
import CollapseButton from "../../../../../../modules/common/CollapseButton";
import CollapseContent from "../../../../../../modules/common/CollapseContent";
import PolicySetsTableItem from "./PolicySetsTableItem";

const PolicySetsTable = () => {
  const policyLinkedPolicySetsState = useTypedSelector(
    (x) => x.policyLinkedPolicies,
  );

  const {
    updatePolicyLinkedPoliciesColumnSearchField,
    updatePolicyLinkedPoliciesSelectedPage,
    updatePolicyLinkedPoliciesSortParams,
    updatePolicyLinkedPoliciesPageParams,
  } = useActions();

  return (
    <div className="mt-4" id="accordionLinkedPolicySets">
      <CollapseButton
        label="Linked Policy Sets"
        targetId="#collapseLinkedPolicySets"
      />
      <CollapseContent
        id="collapseLinkedPolicySets"
        parentId="accordionLinkedPolicySets"
      >
        <Table
          {...policyLinkedPolicySetsState}
          columnFiltrationIsActive={true}
          setColumnsSearchField={updatePolicyLinkedPoliciesColumnSearchField}
          setPageParams={updatePolicyLinkedPoliciesPageParams}
          setSelectedPage={updatePolicyLinkedPoliciesSelectedPage}
          setSortParams={updatePolicyLinkedPoliciesSortParams}
        >
          {policyLinkedPolicySetsState.tableData?.rows?.map((policySet) => (
            <PolicySetsTableItem key={policySet._id} policySet={policySet} />
          ))}
        </Table>
      </CollapseContent>
    </div>
  );
};

export default PolicySetsTable;
