import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import SubAttachmentModal from "./SubAttachmentModal";

const AttachmentsModal = () => {
  const showModal = useTypedSelector((x) => x.attachmentsModal.showModal);

  return showModal ? <SubAttachmentModal /> : <></>;
};

export default AttachmentsModal;
