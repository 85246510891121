import { IPolicyRevision } from "../../components/interfaces/Policy";
import { ITableState } from "../../components/interfaces/Table";
import {
  PolicyRevisionsAction,
  PolicyRevisionsActionTypes,
} from "../types/policyRevisions";

export const initialState: ITableState<IPolicyRevision> = {
  columnSearchField: {},
  isLoading: false,
  selectedPage: 0,
  sortParams: {
    limit: 15,
    offset: 0,
    order: "desc",
    sort: "Revision",
  },
  tableData: {
    rows: [],
    total: 0,
  },
  tableHeaders: [
    { name: "Revision", label: "Revision", colSize: 1, filterable: true },
    {
      name: "LastUpdated",
      label: "Last Updated",
      colSize: 3,
      filterable: true,
    },
    { name: "UpdatedBy", label: "Updated By", colSize: 3 },
    { name: "Comment", label: "Comment", colSize: 5 },
  ],
};

export const policyRevisionsReducer = (
  state = initialState,
  action: PolicyRevisionsAction,
): ITableState<IPolicyRevision> => {
  switch (action.type) {
    case PolicyRevisionsActionTypes.FETCHING_POLICY_REVISIONS_TABLE_DATA:
      return { ...state, isLoading: true };
    case PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_TABLE_DATA:
      return {
        ...state,
        isLoading: false,
        tableData: action.payload,
      };
    case PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case PolicyRevisionsActionTypes.RESET_POLICY_REVISIONS_DATA:
      return initialState;
    case PolicyRevisionsActionTypes.RESET_POLICY_REVISIONS_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case PolicyRevisionsActionTypes.HANDLE_POLICY_REVISIONS_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
