import {
  DetailsQueryParams,
  EntityDetailsProps,
} from "../../../interfaces/DetailsPage";
import VeraDetails from "./VeraDetails";
import SystemDetails from "./systemDetails/SystemDetails";
import DraftStatus from "./DraftStatus";
import ApprovalRoute from "./ApprovalRoute";
import { unescape } from "lodash";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { AppRoutes } from "../../../../constants/general.constants";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../../modules/Loader";

const EntityDetails = ({
  handleTaskSubmit,
  onCloseApproveModal,
}: EntityDetailsProps) => {
  const { fetchRecordDetails } = useActions();
  const { data, isLoading } = useTypedSelector((state) => state.recordDetails);
  const location = useLocation();

  const match = matchPath<DetailsQueryParams>(location.pathname, {
    path: [AppRoutes.DetailsPage, AppRoutes.RevisionPage],
  });

  const { routeId, recordId, revision, menuSelector } = match?.params ?? {};

  const history = useHistory();

  useEffect(() => {
    fetchRecordDetails(history, routeId, recordId, revision, menuSelector);
  }, []);

  if (!data || !data.record || isLoading) {
    return <Loader message={"Loading, please wait..."} />;
  }

  const { record, route, routeView, assignedTask } = data;

  return (
    <>
      <h3>{unescape(record.name)}</h3>

      <br />

      <VeraDetails record={record} />

      {record?.status === "Draft" ? (
        <DraftStatus record={record} />
      ) : (
        <>
          <SystemDetails record={record} />

          <ApprovalRoute
            assignedTask={assignedTask}
            handleTaskSubmit={handleTaskSubmit}
            onCloseApproveModal={onCloseApproveModal}
            route={route}
            routeView={routeView}
          />
        </>
      )}
    </>
  );
};

export default EntityDetails;
