import React from "react";
import LinkedRecords from "./LinkedRecords";
import { LinkedRecordTableItemProps } from "../../../../interfaces/DetailsPage";
import TruncatedText from "../../../../modules/TruncatedText";
import uuid from "react-uuid";
import ExpandedContentModalTitle from "../../../../modules/ExpandedContentModalTitle";
import { useActions } from "../../../../../hooks/useActions";

const LinkedRecordTableItem = ({
  linkedRecord,
}: LinkedRecordTableItemProps) => {
  const [isCollapsed, setCollapsed] = React.useState(true);
  const id = React.useMemo(
    () => `linkedRecordsGroupDetail_${uuid()}`.replaceAll("-", "_"),
    [],
  );
  const { openAttachmentsModal } = useActions();
  const { openDefectsModal } = useActions();

  return (
    <>
      <tr>
        {Object.entries(linkedRecord.linked_records).length > 0 ? (
          <td
            aria-label="linked-record-collapse"
            data-bs-target={`#${id}`}
            data-bs-toggle="collapse"
            onClick={() => setCollapsed((prev) => !prev)}
          >
            <a className="text-decoration-none" href="javascript:void(0)">
              <i
                className={
                  isCollapsed
                    ? "fa fa-chevron-circle-right"
                    : "fa fa-chevron-circle-down"
                }
              />
            </a>
          </td>
        ) : (
          <td />
        )}

        {linkedRecord.name && (
          <td className="linked-record-name">
            <TruncatedText content={linkedRecord.name} title={"Name"} />
          </td>
        )}

        {Object.entries(linkedRecord.fields).map((entry: any, index) => {
          const title = (
            <ExpandedContentModalTitle
              description={entry[0]}
              id={linkedRecord.name ?? ""}
            />
          );

          return (
            <td key={`${index}-${entry[1]}`}>
              <TruncatedText content={entry[1]} title={title} />
            </td>
          );
        })}

        <td style={{ textAlign: "center" }}>
          {linkedRecord.attachments?.length > 0 && (
            <button
              aria-label="linked-record-open-attach-modal"
              className="btn btn-light btn-sm"
              onClick={() =>
                openAttachmentsModal({ attachments: linkedRecord.attachments })
              }
              type="button"
            >
              <i aria-hidden="true" className="fa fa-paperclip" />
            </button>
          )}
        </td>

        <td style={{ textAlign: "center" }}>
          {linkedRecord.linked_records.Defects && (
            <button
              aria-label="linked-record-open-defects-modal"
              className="btn btn-light"
              onClick={() =>
                openDefectsModal({
                  defects: linkedRecord.linked_records.Defects,
                  recordId: linkedRecord._id,
                })
              }
              type="button"
            >
              <i aria-hidden="true" className="fa fa-bug" />
            </button>
          )}
        </td>
      </tr>

      {Object.keys(linkedRecord.linked_records).length > 0 && (
        <tr className="collapse" id={id}>
          <td />
          <td colSpan={100}>
            <LinkedRecords
              linkedRecords={linkedRecord.linked_records}
              recordId={linkedRecord._id}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default LinkedRecordTableItem;
