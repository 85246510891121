import { Typography } from "@mui/material";
import { ButtonDividerContainer } from "./style";

const ButtonDivider = () => {
  return (
    <ButtonDividerContainer>
      <hr />
      <Typography variant="overline">OR</Typography>
      <hr />
    </ButtonDividerContainer>
  );
};

export default ButtonDivider;
