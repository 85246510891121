import { useActions } from "../../../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../../../hooks/useTypedSelector";
import Table from "../../../../../../modules/Table";
import DomainTableItem from "./DomainTableItem";

const DomainsTable = () => {
  const policySetLinkedDomainsState = useTypedSelector(
    (x) => x.policySetLinkedDomains,
  );

  const {
    updatePolicySetLinkedDomainsSelectedPage,
    updatePolicySetLinkedDomainsSortParams,
    updatePolicySetLinkedDomainsPageParams,
    updatePolicySetLinkedDomainsColumnSearchField,
  } = useActions();

  return (
    <div>
      <div className="table-heading-wrapper mb-3">
        <b>Linked Domains</b>
      </div>
      <Table
        {...policySetLinkedDomainsState}
        columnFiltrationIsActive={true}
        setColumnsSearchField={updatePolicySetLinkedDomainsColumnSearchField}
        setPageParams={updatePolicySetLinkedDomainsPageParams}
        setSelectedPage={updatePolicySetLinkedDomainsSelectedPage}
        setSortParams={updatePolicySetLinkedDomainsSortParams}
      >
        {policySetLinkedDomainsState.tableData?.rows?.map((domain) => (
          <DomainTableItem domain={domain} key={domain.Id} />
        ))}
      </Table>
    </div>
  );
};

export default DomainsTable;
