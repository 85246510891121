import { DropdownItem } from "@atlaskit/dropdown-menu";
import { Link } from "react-router-dom";
import { IDropdownLinkItemProps } from "../../interfaces/HeaderDropdown";

const DropdownLinkItem: React.FC<IDropdownLinkItemProps> = ({
  label,
  pathname,
  target = "_self",
  ...props
}) => {
  return (
    <Link target={target} to={{ pathname }} {...props}>
      <DropdownItem>
        <div>{label}</div>
      </DropdownItem>
    </Link>
  );
};

export default DropdownLinkItem;
