import { ePolicyType } from "../../components/interfaces/Policy";
import {
  ILocatePolicyState,
  LocatePolicyFilterAction,
  LocatePolicyFilterActionTypes,
} from "../types/locatePolicyFilter";

export const initialState: ILocatePolicyState = {
  isLoadingOwners: false,
  policyType: {
    items: [
      { id: ePolicyType.APPROVAL, name: "Approval", isSelected: false },
      { id: ePolicyType.RECORD_MANAGEMENT, name: "RMP", isSelected: false },
    ],
    total: 0,
  },
  onlyShowDefaultPolicies: false,
};

export const locatePolicyReducer = (
  state = initialState,
  action: LocatePolicyFilterAction,
): ILocatePolicyState => {
  switch (action.type) {
    case LocatePolicyFilterActionTypes.UPDATE_SELECTED_POLICY_TYPE: {
      const index = state.policyType.items.findIndex(
        (x) => x.id === action.payload.name,
      );
      const items = [...state.policyType.items];
      items[index].isSelected = action.payload.isSelected;
      return {
        ...state,
        policyType: {
          ...state.policyType,
          items: items,
        },
      };
    }
    case LocatePolicyFilterActionTypes.UPDATE_POLICY_NAME_SEARCH_FIELD:
      return {
        ...state,
        policyName: action.payload.searchField,
      };
    case LocatePolicyFilterActionTypes.TOGGLE_ONLY_SHOW_DEFAULT_POLICIES:
      return {
        ...state,
        onlyShowDefaultPolicies: !state.onlyShowDefaultPolicies,
      };
    case LocatePolicyFilterActionTypes.CLEAR_POLICY_FILTER:
      return {
        ...state,
        policyName: "",
        policyType: {
          ...state.policyType,
          ...{
            items: state.policyType.items.map((x) => ({
              ...x,
              isSelected: false,
            })),
          },
        },
        onlyShowDefaultPolicies: false,
      };
    case LocatePolicyFilterActionTypes.CLEAR_SELECTED_POLICY_TYPE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          ...{
            items: state.policyType.items.map((x) => ({
              ...x,
              isSelected: false,
            })),
          },
        },
      };
    default:
      return state;
  }
};
