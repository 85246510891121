import { IRecord } from "./../components/interfaces/DetailsPage";

export const shouldDisplayAuthor = (record: IRecord) => {
  const systemAllowedToShowAuthorUnknown = ["qTest"];

  if (!Object.prototype.hasOwnProperty.call(record, "authors")) {
    return false;
  }

  return systemAllowedToShowAuthorUnknown.includes(
    record.original_location.system,
  );
};
