import { SubStepsTableProps } from "../../../../interfaces/DetailsPage";
import SubStepsTableItem from "./SubStepsTableItem";

const SubStepsTable = ({ data }: SubStepsTableProps) => {
  const { title, subRecord } = data;

  return (
    <div className="table-responsive pb-3">
      <h6 className="d-inline details-title" id="SubRecordDetailsTitle">
        {`${title} Details`}
      </h6>

      <table className="table table-sm table-hover pt-2" id="Steps">
        <thead>
          <tr>
            <th scope="col">Step</th>
            <th scope="col">Status</th>
            {subRecord[0].fields.map((item) => (
              <th
                className="text-nowrap"
                key={`${item.label}-${item.value}`}
                scope="col"
              >
                {item.label}
              </th>
            ))}
            <th scope="col">
              <i aria-hidden="true" className="fa fa-paperclip" />
            </th>
            <th scope="col">
              <i aria-hidden="true" className="fa fa-bug" />
            </th>
          </tr>
        </thead>

        <tbody id="stepBody">
          {subRecord.map((item) => (
            <SubStepsTableItem key={item._id} record={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubStepsTable;
