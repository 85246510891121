const NoSignaturesInfo = ({ date }: { date?: string }) => {
  return (
    <div className="d-flex flex-column align-items-center no-signature-info">
      <div className="details-field">
        No signatures required for this record
      </div>
    </div>
  );
};

export default NoSignaturesInfo;
