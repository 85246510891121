import { IPolicyState } from "../../components/interfaces/Policy";
import { PolicyAction, PolicyActionTypes } from "../types/policy";

export const initialState: IPolicyState = {
  isLoading: false,
  networkError: "",
};

export const policyReducer = (
  state = initialState,
  action: PolicyAction,
): IPolicyState => {
  switch (action.type) {
    case PolicyActionTypes.FETCHING_POLICY:
      return { ...state, isLoading: true };
    case PolicyActionTypes.UPDATE_POLICY:
      return {
        ...state,
        isLoading: false,
        policy: action.payload,
      };
    case PolicyActionTypes.SET_AS_DEFAULT_POLICY:
      return {
        ...state,
        policy: state.policy
          ? {
              ...state.policy,
              IsDefault: "true",
            }
          : undefined,
      };
    case PolicyActionTypes.HANDLE_POLICY_NETWORK_ERROR:
      return {
        ...state,
        isLoading: false,
        networkError: action.payload,
      };
    default:
      return state;
  }
};
