import { PoliciesAction, PoliciesActionTypes } from "../types/policies";
import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { PolicyRequestRoutes } from "../../constants/policy.constants";
import { IPolicyDataResponse } from "../../components/interfaces/Policy";
import { RootState } from "../reducers";

let cancelToken: CancelTokenSource;
export const fetchPolicies = () => {
  return async (dispatch: Dispatch<PoliciesAction>, getState: Function) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();

    const state = getState() as RootState;
    const { policyName, policyType, onlyShowDefaultPolicies } =
      state.locatePolicyFilter;
    const { offset, ...rest } = state.policies.sortParams;

    const sortParams = { ...rest, skip: offset };
    const policyTypes = policyType.items
      ?.filter((x) => x.isSelected)
      ?.map((x) => x.id)
      ?.join(",");

    const params = {
      policyType: policyTypes ? policyTypes : undefined,
      name: policyName ? policyName : undefined,
      isDefault: onlyShowDefaultPolicies || undefined,
      ...sortParams,
    };

    try {
      dispatch({ type: PoliciesActionTypes.FETCHING_POLICY_TABLE_DATA });

      const { data, status } = await axios.get<IPolicyDataResponse>(
        PolicyRequestRoutes.POLICY_LIST,
        { cancelToken: cancelToken.token, params },
      );

      if (status === 200) {
        dispatch({
          type: PoliciesActionTypes.UPDATE_POLICY_TABLE_DATA,
          payload: data,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: PoliciesActionTypes.HANDLE_POLICIES_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const updatePolicySelectedPage = (selectedPage: number) => {
  return {
    type: PoliciesActionTypes.UPDATE_POLICY_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const updatePolicySortParams = (sort: string, order: "asc" | "desc") => {
  return async (dispatch: Dispatch<PoliciesAction>) => {
    dispatch({
      type: PoliciesActionTypes.UPDATE_POLICY_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updatePolicyPageParams = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<PoliciesAction>) => {
    dispatch({
      type: PoliciesActionTypes.UPDATE_POLICY_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetPolicySortParams = () => {
  return async (dispatch: Dispatch<PoliciesAction>) => {
    dispatch({ type: PoliciesActionTypes.RESET_POLICY_SORT_PARAMS });
  };
};

export const handleResetPolicyData = () => {
  cancelToken && cancelToken.cancel(ErrorMessage.LOGOUT);
  return async (dispatch: Dispatch<PoliciesAction>) => {
    dispatch({ type: PoliciesActionTypes.RESET_POLICY_DATA });
  };
};
