import React from "react";
import { useActions } from "../../../../../../hooks/useActions";
import { PolicyRequestRoutes } from "../../../../../../constants/policy.constants";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../../../constants/admin-page.constants";
import { ErrorMessage } from "../../../../../../constants/general.constants";
import GenericModal from "../../../../../modules/GenericModal";
import { IDeletePolicyConfirmationModalProps } from "../../../../../interfaces/Policy";
import { IDefaultResponse } from "../../../../../interfaces/General";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";

const DeletePolicyConfirmationModal = ({
  id,
  name,
  visible,
  onClose,
}: IDeletePolicyConfirmationModalProps) => {
  const [loading, setLoading] = React.useState(false);
  const { fetchPolicies, updatePolicyPageParams } = useActions();
  const { tableData, sortParams } = useTypedSelector((x) => x.policies);

  const handleOnClickDelete = async () => {
    setLoading(true);
    try {
      const { data } = await axios.delete<IDefaultResponse<any>>(
        PolicyRequestRoutes.DELETE_POLICY,
        {
          params: { id },
        },
      );

      if (data.apiStatusCode === 204) {
        toast.success(AdminPageSuccessMessages.DELETE_POLICY);
        if (tableData.rows.length === 1) {
          updatePolicyPageParams(0, sortParams.limit);
        } else {
          fetchPolicies();
        }
      } else {
        toast.error(AdminPageErrors.DELETE_POLICY);
      }
      onClose();
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericModal
      labelConfirm="Delete"
      loading={loading}
      onClickConfirm={handleOnClickDelete}
      onCloseModal={onClose}
      size="medium"
      title="Delete Policy"
      visible={visible}
    >
      {`Are you sure you want to delete this policy: ${name}?`}
    </GenericModal>
  );
};

export default DeletePolicyConfirmationModal;
