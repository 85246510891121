import axios from "axios";
import {
  AdminPageRoutes,
  ConnectionRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import ConnectionDetailsForm from "./ConnectionDetailsForm";
import { AdminPageSuccessMessages } from "../../../../../constants/admin-page.constants";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  IAddOrUpdateConnectionResponse,
  IConnection,
} from "../../../../interfaces/Connections";
import { AxiosResponse } from "axios";

const AddConnectionPage = () => {
  const history = useHistory();

  const handleFormSubmit = async (data: IConnection) => {
    try {
      const response: AxiosResponse<IAddOrUpdateConnectionResponse> =
        await axios.post(ConnectionRequestRoutes.ADD_CONNECTION, data);

      if (response.status === 201) {
        toast.success(AdminPageSuccessMessages.CREATE_CONNECTION);
        history.push(
          `${AdminPageRoutes.EDIT_CONNECTION}/${response.data.apiData._id}`,
        );
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };

  return (
    <ConnectionDetailsForm
      data={{ Urls: { API: "", Browser: "" } }}
      labelCancelChangesButton="Reset Fields"
      labelSaveButton="Add Connection"
      onSubmit={handleFormSubmit}
      title={() => `Add Connection`}
    />
  );
};

export default AddConnectionPage;
