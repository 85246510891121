import { IPrintViewSignaturesProps } from "../../../interfaces/DetailsPage";
import NoSignaturesInfo from "../approvalRoutes/NoSignaturesInfo";
import PrintViewNoSignatures from "./PrintViewNoSignatures";
import PrintViewTaskGroups from "./PrintViewTaskGroups";

const PrintViewSignatures = ({ route }: IPrintViewSignaturesProps) => {
  return (
    <div className="pb-3" id="print-view-signatures">
      <h4>Signatures</h4>
      {route?.signaturesRequired ? (
        <PrintViewTaskGroups route={route} />
      ) : (
        <PrintViewNoSignatures route={route} />
      )}
    </div>
  );
};

export default PrintViewSignatures;
