export enum VerificationStatus {
  Complete = "Complete",
  Rejected = "Rejected",
  Passed = "Passed",
  Pending = "Pending",
}

export enum ApprovalPageWarnings {
  EMPTY_PASSWORD_FIELD = "Please enter your password.",
  EMPTY_REJECTION_REASON = "Please enter rejection reason.",
}

export enum ApprovalSuccessMessages {
  TASK_APPROVE = "Task has been approved.",
  BULK_APPROVAL = "Selected tasks have been approved.",
}
