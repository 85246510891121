import * as yup from "yup";
import { IMailSettingsFormData } from "../components/interfaces/AdminPage";

const validateMailSettingsFormScheme = yup.object().shape({
  smtpPort: yup.lazy((value) => {
    if (value === "") {
      return yup.mixed().notRequired();
    }
    return yup
      .number()
      .integer("Port must be an integer between 0 and 65353.")
      .min(0, "Port must be an integer between 0 and 65353.")
      .max(65353, "Port must be an integer between 0 and 65353.");
  }),
  from: yup.string().email("Please enter a valid email address."),
});

export const validateMailSettingsForm = (
  data: IMailSettingsFormData,
  setErrorHandler: any,
) => {
  validateMailSettingsFormScheme
    .validate(data)
    .then(() => setErrorHandler({}))
    .catch((err) => setErrorHandler({ [err.path]: err.message || "" }));
};
