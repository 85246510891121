import React from "react";
import { Link } from "react-router-dom";
import { IRecordData } from "../../interfaces/Record";

export interface IRecordPageItemProps {
  tableItem: IRecordData;
}

const RecordPageItem: React.FC<IRecordPageItemProps> = ({ tableItem }) => {
  return (
    <tr>
      <td>{tableItem.projectName}</td>
      <td>{tableItem.recordType}</td>
      <td>
        <Link to={`/records/${tableItem.recordId}/latest-revision`}>
          {tableItem.recordName}
        </Link>
      </td>
      <td>{tableItem.recordStatus}</td>
    </tr>
  );
};

export default RecordPageItem;
