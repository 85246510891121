import { IPrintViewRecordLocationProps } from "../../../interfaces/AdminPage";
import PrintViewDetailField from "./PrintViewDetailField";

const PrintViewRecordLocation = ({ record }: IPrintViewRecordLocationProps) => {
  return (
    <>
      <PrintViewDetailField
        label="System:"
        value={record.original_location.system}
      />

      <PrintViewDetailField
        label="Item Type:"
        value={record.original_location.item_type}
      />

      <PrintViewDetailField
        label="Record ID:"
        value={record.locations[0].local_id_fields.Key}
      />
    </>
  );
};

export default PrintViewRecordLocation;
