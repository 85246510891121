import { useState } from "react";
import {
  IChangePasswordFormProps,
  IFormData,
} from "../../interfaces/ChangePassword";
import { createChangePasswordSchema } from "../../../validators/changePasswordFormValidator";
import { useForm } from "react-hook-form";
import FormTextField from "../../modules/common/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "../../modules/common/Form";

const ChangePasswordForm = ({
  changePassword,
  passwordPolicyData,
}: IChangePasswordFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormData>({
    mode: "all",
    resolver: yupResolver(createChangePasswordSchema(passwordPolicyData)),
  });

  const handleOnSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await changePassword(data);
      (document.activeElement as any)?.blur();
      reset();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      aria-label="form"
      className="form-change-password"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <FormTextField
        control={control}
        error={errors.currentPassword?.message}
        label=""
        name="currentPassword"
        placeholder="Current Password"
        size="large"
        type="password"
        wasValidated={!errors.currentPassword}
      />

      <FormTextField
        control={control}
        error={errors.newPassword?.message}
        label=""
        name="newPassword"
        placeholder="New Password"
        size="large"
        type="password"
        wasValidated={!errors.newPassword}
      />

      <FormTextField
        control={control}
        error={errors.confirmPassword?.message}
        label=""
        name="confirmPassword"
        placeholder="Confirm Password"
        size="large"
        type="password"
        wasValidated={!errors.confirmPassword}
      />

      <button
        className="btn btn-primary btn-block text-white"
        disabled={!!Object.keys(errors).length}
        id="save-password-btn"
      >
        {loading ? (
          <span
            aria-hidden="true"
            className="spinner-border spinner-border-sm ml-2"
            role="status"
          />
        ) : (
          "Save changes"
        )}
      </button>
    </Form>
  );
};

export default ChangePasswordForm;
