import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import SubDefectsModal from "./SubDefectsModal";

const DefectsModal = () => {
  const showModal = useTypedSelector((x) => x.defectsModal.showModal);
  const { defects, recordId } = useTypedSelector((x) => x.defectsModal);

  return showModal ? (
    <SubDefectsModal defects={defects} recordId={recordId} />
  ) : (
    <></>
  );
};

export default DefectsModal;
