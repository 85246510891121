import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import axios, { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../reducers";
import { PolicySetsRequestRoutes } from "../../constants/policy-set.constants";
import { ITableData } from "../../components/interfaces/Table";
import { IPolicySetDomain } from "../../components/interfaces/PolicySet";
import { IDefaultResponse } from "../../components/interfaces/General";
import {
  PolicySetLinkedDomainsAction,
  PolicySetLinkedDomainsActionTypes,
} from "../types/policySetLinkedDomains";
import { IColumnsSearchFieldsFormData } from "../../components/interfaces/ApprovalQueue";

let cancelToken: CancelTokenSource;
export const fetchPolicySetLinkedDomains = (policySetId: string) => {
  return async (
    dispatch: Dispatch<PolicySetLinkedDomainsAction>,
    getState: Function,
  ) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    cancelToken = axios.CancelToken.source();

    const state = getState() as RootState;
    const { offset, ...rest } = state.policySetLinkedDomains.sortParams;
    const columnSearchField = state.policySetLinkedDomains.columnSearchField;

    const params = {
      ...rest,
      skip: offset,
      id: policySetId,
      name: columnSearchField?.Name,
    };

    try {
      dispatch({
        type: PolicySetLinkedDomainsActionTypes.FETCHING_POLICY_SET_DOMAINS_TABLE_DATA,
      });

      const { data, status } = await axios.get<
        IDefaultResponse<ITableData<IPolicySetDomain>>
      >(PolicySetsRequestRoutes.POLICY_SET_DOMAINS, {
        cancelToken: cancelToken.token,
        params,
      });

      if (status === 200) {
        dispatch({
          type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_TABLE_DATA,
          payload: data.apiData,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: PolicySetLinkedDomainsActionTypes.HANDLE_POLICY_SET_DOMAINS_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};

export const updatePolicySetLinkedDomainsSelectedPage = (
  selectedPage: number,
) => {
  return {
    type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_SELECTED_PAGE,
    payload: selectedPage,
  };
};

export const updatePolicySetLinkedDomainsSortParams = (
  sort: string,
  order: "asc" | "desc",
) => {
  return async (dispatch: Dispatch<PolicySetLinkedDomainsAction>) => {
    dispatch({
      type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_SORT_PARAMS,
      payload: { sort, order },
    });
  };
};

export const updatePolicySetLinkedDomainsPageParams = (
  offset: number,
  limit: number,
) => {
  return async (dispatch: Dispatch<PolicySetLinkedDomainsAction>) => {
    dispatch({
      type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_PAGE_PARAMS,
      payload: { offset, limit },
    });
  };
};

export const handleResetPolicySetLinkedDomainsSortParams = () => {
  return async (dispatch: Dispatch<PolicySetLinkedDomainsAction>) => {
    dispatch({
      type: PolicySetLinkedDomainsActionTypes.RESET_POLICY_SET_DOMAINS_SORT_PARAMS,
    });
  };
};

export const handleResetPolicySetLinkedDomainsData = () => {
  cancelToken && cancelToken.cancel(ErrorMessage.LOGOUT);
  return async (dispatch: Dispatch<PolicySetLinkedDomainsAction>) => {
    dispatch({
      type: PolicySetLinkedDomainsActionTypes.RESET_POLICY_SET_DOMAINS_DATA,
    });
  };
};

export const updatePolicySetLinkedDomainsColumnSearchField =
  (columnSearchField: IColumnsSearchFieldsFormData) =>
  async (dispatch: Dispatch<PolicySetLinkedDomainsAction>) => {
    dispatch({
      type: PolicySetLinkedDomainsActionTypes.UPDATE_POLICY_SET_DOMAINS_COLUMN_SEARCH_FIELD,
      payload: columnSearchField,
    });
  };
