import { useEffect, useState } from "react";
import _, { String } from "lodash";
import {
  IFailedVerificationInfo,
  ISignatureVerificationTableData,
  ISignatureVerificationTableProps,
} from "../../../interfaces/AdminPage";
import { ISortParams } from "../../../interfaces/ApprovalQueue";
import TableHead from "../../../modules/TableHead";
import TablePagination from "../../../modules/TablePagination";
import TableSearchField from "../../../modules/TableSearchField";
import SignatureVerificationTableItem from "./SignatureVerificationTableItem";
import { TableHeaderItem } from "../../../../store/types/approvalQueue";

const SignatureVerificationTable = ({
  failedVerifications,
}: ISignatureVerificationTableProps) => {
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [sortParams, setSortParams] = useState<ISortParams>({
    sort: "timestamp",
    order: "desc",
    offset: 0,
    limit: 10,
  });
  const [tableData, setTableData] = useState<ISignatureVerificationTableData>({
    rows: [],
    total: 0,
    totalNotFiltered: 0,
  });
  const tableHeaders: TableHeaderItem[] = [
    { label: "Record Name", name: "record.name", filterable: true },
    { label: "Approver", name: "details.reviewer", filterable: true },
    { label: "Meaning", name: "details.meaning", filterable: true },
    { label: "Record Type", name: "record.recordType", filterable: true },
    { label: "Revision", name: "record.revision", filterable: true },
    { label: "Error Code", name: "message", filterable: true },
    { label: "Failed Date", name: "timestamp", filterable: true },
    {
      label: "Last Successful Date",
      name: "lastSuccessTimestamp",
      filterable: true,
    },
  ];

  const sortTableData = (
    data: IFailedVerificationInfo[],
    sort: string,
    order: "asc" | "desc",
  ) => _.orderBy(data, [sort], [order]);
  const formatTableData = (searchValue: string | undefined = undefined) => {
    let newData: IFailedVerificationInfo[];
    let sortedData = sortTableData(
      failedVerifications,
      sortParams.sort,
      sortParams.order,
    );

    if (searchValue) {
      sortedData = searchByText(sortedData, searchValue);
    }
    if (sortParams.limit > sortedData.length) {
      newData = sortedData;
    } else {
      newData = sortedData.slice(
        selectedPage === 0 ? 0 : sortParams.limit * selectedPage,
        (selectedPage + 1) * sortParams.limit,
      );
    }

    setTableData({
      rows: newData,
      total: sortedData.length,
      totalNotFiltered: sortedData.length,
    });
  };
  const searchByText = (
    verificationRows: IFailedVerificationInfo[],
    searchValue: string,
  ) => {
    searchValue = searchValue.toLowerCase();
    const flattenedData = verificationRows.map((row) => ({
      ...row,
      ...row.details,
      ...row.record,
    }));
    return flattenedData.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchValue),
      ),
    );
  };
  const handleOfflineSearch = (
    sortParams: ISortParams,
    searchValue: string | undefined,
  ) => {
    setSortParams(sortParams);
    formatTableData(searchValue);
  };

  const updateSortParams = (sort: string, order: "asc" | "desc") => {
    setSelectedPage(0);
    setSortParams((prev) => ({
      ...prev,
      sort,
      order,
      offset: 0,
    }));
  };

  const updatePageParams = (offset: number, limit: number) => {
    if (offset === 0) {
      setSelectedPage(0);
    }

    setSortParams((prev) => ({
      ...prev,
      offset,
      limit,
    }));
  };

  useEffect(() => {
    formatTableData(undefined);
  }, []);
  useEffect(() => {
    formatTableData(undefined);
  }, [sortParams, selectedPage]);

  return (
    <div
      aria-label="signature-verification-table"
      className="mr-4 overflow-auto"
    >
      <div className="user-management-table-heading">
        <TableSearchField
          fetchTableData={handleOfflineSearch}
          offlineSearch={true}
          sortParams={sortParams}
        />
      </div>

      <table className="table table-bordered table-sm">
        <TableHead
          isLoading={false}
          setPageParams={updatePageParams}
          setSortParams={updateSortParams}
          sortParams={sortParams}
          tableHeaders={tableHeaders}
        />

        <tbody
          className="position-relative"
          style={{ height: tableData?.rows?.length ? "0" : "10vh" }}
        >
          {!tableData?.rows?.length && (
            <div className="table-no-records-found">
              No matching verifications found.
            </div>
          )}

          {tableData?.rows.map((verification) => (
            <SignatureVerificationTableItem
              key={verification._id}
              verificationData={verification}
            />
          ))}
        </tbody>
      </table>

      {tableData?.rows?.length > 0 && (
        <TablePagination
          initialRowsPerPage={10}
          selectedPage={selectedPage}
          tableData={tableData}
          updatePageParams={updatePageParams}
          updateSelectedPage={setSelectedPage}
        />
      )}
    </div>
  );
};

export default SignatureVerificationTable;
