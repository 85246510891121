import { PropsWithChildren } from "react";

const Form = ({
  children,
  className,
  ...props
}: PropsWithChildren & React.HTMLProps<HTMLFormElement>) => {
  return (
    <form
      {...props}
      className={`needs-validation form-container ${className ?? ""}`}
    >
      {children}
    </form>
  );
};

export default Form;
