import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios, { CancelTokenSource } from "axios";
import Tooltip from "@atlaskit/tooltip";
import Button from "@atlaskit/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useActions } from "../../../../../hooks/useActions";
import {
  IDomainProjectsTableData,
  IDomainTableProps,
} from "../../../../interfaces/AdminPage";
import { ISortParams } from "../../../../interfaces/ApprovalQueue";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import TableHead from "../../../../modules/TableHead";
import Loader from "../../../../modules/Loader";
import TablePagination from "../../../../modules/TablePagination";
import AddProjectModal from "./AddProjectModal";
import DomainProjectsTableItem from "./DomainProjectsTableItem";
import { TableHeaderItem } from "../../../../../store/types/approvalQueue";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";

let domainProjectsCancelToken: CancelTokenSource;

const DomainProjectsTable = ({ domainId }: IDomainTableProps) => {
  const user = useTypedSelector((state) => state.user.user);

  const [tableIsCollapsed, setTableIsCollapsed] = useState<boolean>(false);
  const [isAddProjectModalOpen, setAppProjectModalOpen] =
    useState<boolean>(false);
  const [tableData, setTableData] = useState<IDomainProjectsTableData>({
    rows: [],
    total: 0,
    totalNotFiltered: 0,
  });
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortParams, setSortParams] = useState<ISortParams>({
    sort: "ProjectId",
    order: "asc",
    offset: 0,
    limit: 10,
  });

  const tableHeaders: TableHeaderItem[] = [
    { label: "Project", name: "ProjectId", filterable: true },
    { label: "Connection", name: "Locationid", filterable: true },
  ];

  const getProjectsListData = async (sortParams: ISortParams) => {
    if (typeof domainProjectsCancelToken != typeof undefined) {
      domainProjectsCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    domainProjectsCancelToken = axios.CancelToken.source();

    try {
      setLoading(true);

      const { data } = await axios.get(
        AdminRequestRoutes.DOMAIN_PROJECTS_LIST,
        {
          cancelToken: domainProjectsCancelToken.token,
          params: { ...sortParams, domainId },
        },
      );
      setTableData(data);
      setLoading(false);
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        setLoading(false);
      }
    }
  };

  const updatePageParams = (offset: number, limit: number) => {
    if (offset === 0) {
      setSelectedPage(0);
    }

    setSortParams((prev) => ({
      ...prev,
      offset,
      limit,
    }));
  };

  const updateSortParams = (
    sort: string = sortParams.sort,
    order: "asc" | "desc" = sortParams.order,
  ) => {
    setSelectedPage(0);
    setSortParams((prev) => ({
      ...prev,
      sort,
      order,
      offset: 0,
    }));
  };

  useEffect(() => {
    getProjectsListData(sortParams);
  }, [sortParams]);

  return (
    <div
      aria-label="domain-management-table"
      className="row pt-3"
      id="accordionProjects"
    >
      <a
        aria-label="collapse-domain-projects-table"
        className="text-decoration-none toggleRotate col"
        data-bs-target="#collapseProjects, #collapseProjectsToolbar"
        data-bs-toggle="collapse"
        href="#!"
        onClick={() => setTableIsCollapsed(!tableIsCollapsed)}
      >
        <i
          className={`fa ${
            tableIsCollapsed
              ? "fa-chevron-circle-right"
              : "fa-chevron-circle-down"
          }`}
        />
        <h6 className="d-inline"> Domain Projects</h6>
      </a>

      <div
        className="collapse show col container-fluid"
        data-bs-parent="#accordionProjects"
        id="collapseProjectsToolbar"
      >
        <div className="domain-management-table-heading">
          {user?.isSystemAdmin && (
            <a
              className="btn btn-primary btn-sm text-white"
              href="#!"
              id="add-projects-btn"
              onClick={() => setAppProjectModalOpen(true)}
              role="button"
            >
              <i className="fa fa-plus" /> Add Projects
            </a>
          )}

          {isAddProjectModalOpen && (
            <AddProjectModal
              closeModal={() => setAppProjectModalOpen(false)}
              domainId={domainId}
              isOpen={isAddProjectModalOpen}
              updateTableData={() => getProjectsListData(sortParams)}
            />
          )}

          <Tooltip content="Refresh table" position="top">
            <Button
              appearance="subtle"
              aria-label="refresh-btn"
              onClick={() => getProjectsListData(sortParams)}
            >
              <FontAwesomeIcon icon={faSyncAlt} />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div
        className="collapse show container-fluid"
        data-bs-parent="#accordionProjects"
        id="collapseProjects"
      >
        <table className="table table-bordered table-sm">
          <TableHead
            displayActionsColumn={user?.isSystemAdmin}
            isLoading={false}
            setPageParams={updatePageParams}
            setSortParams={updateSortParams}
            sortParams={sortParams}
            tableHeaders={tableHeaders}
          />

          <tbody
            className="position-relative"
            style={{ height: loading || !tableData?.rows?.length ? "10vh" : 0 }}
          >
            {loading && (
              <Loader message={"Loading, please wait..."} tableMode={true} />
            )}
            {!loading && !tableData?.rows?.length && (
              <div className="table-no-records-found">
                No matching projects found.
              </div>
            )}
            {!loading &&
              tableData?.rows?.map((project) => (
                <DomainProjectsTableItem
                  domainId={domainId}
                  key={project.ConnectionId}
                  projectData={project}
                  updateTableData={() => getProjectsListData(sortParams)}
                />
              ))}
          </tbody>
        </table>

        {tableData?.rows?.length > 0 && (
          <TablePagination
            initialRowsPerPage={10}
            selectedPage={selectedPage}
            tableData={tableData}
            updatePageParams={updatePageParams}
            updateSelectedPage={setSelectedPage}
          />
        )}
      </div>
    </div>
  );
};

export default DomainProjectsTable;
