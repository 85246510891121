import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  IModalApproveProps,
  IModalApproveResultProps,
} from "../../interfaces/ApprovalQueue";
import {
  ApprovalPageWarnings,
  ApprovalSuccessMessages,
} from "../../../constants/approval-page.constants";
import Loader from "../../modules/Loader";
import ModalFailedApprovals from "./ModalFailedApprovals";
import ProgressBarModalApprove from "./ProgressBarModalApprove";
import {
  ApprovalPageRequestRoutes,
  ErrorMessage,
} from "../../../constants/general.constants";
import axios from "axios";
import TextField from "../../modules/common/TextField";
import { IconButton } from "@atlaskit/button/new";

const ModalApprove = ({
  meaning,
  isOpen,
  onCloseModal,
  handleTaskSubmit,
  isLoading,
  approveId,
  showLoading,
  hideLoading,
}: IModalApproveProps) => {
  const { user, veraIdp } = useTypedSelector((state) => state.user);
  const [password, setPassword] = useState<string>("");
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [result, setResult] = useState<IModalApproveResultProps | null>(null);
  const [openFailedApprovalsModal, setOpenFailedApprovalsModal] =
    useState(false);
  const [approvedRecords, setApprovedRecords] = useState(0);

  const selectedItems = useTypedSelector(
    (state) => state.approvalQueue.selectedItems,
  );
  const sessionID = useTypedSelector(
    (state) => state.approveFooter.approveSessionID,
  );

  const onFormSubmit = () => {
    if (veraIdp && !password) {
      toast.warning(ApprovalPageWarnings.EMPTY_PASSWORD_FIELD);
      return;
    }

    veraIdp && setShowProgressBar(true);
    handleTaskSubmit(password, "", approveId!);
  };

  const handleOnCloseModal = () => {
    onCloseModal(approvedRecords > 0, approvedRecords);
    setResult(null);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("approve") != null && !veraIdp && isOpen) {
      const approveTask = async () => {
        setShowProgressBar(true);
        showLoading();
        try {
          await axios.post(ApprovalPageRequestRoutes.SAML_TASK_APPROVE, {
            sessionID,
          });
        } catch (error: any) {
          if (
            error.message !== ErrorMessage.NEW_REQUEST &&
            error.message !== ErrorMessage.LOGOUT
          ) {
            toast.error(error.message);
          }
          hideLoading();
          setShowProgressBar(false);
        } finally {
          window.history.pushState(
            null,
            "Tricentis Vera",
            window.location.pathname,
          );
        }
      };

      approveTask();
    }
  }, []);

  const handleOnFinishApproval = (data: IModalApproveResultProps) => {
    if (data != null) {
      const { result } = data;

      const approvedRecords = result.filter((x) => x.isSuccess).length;
      const allRecordsAreApproved = result.length === approvedRecords;

      setApprovedRecords((x) => x + approvedRecords);

      if (allRecordsAreApproved) {
        toast.success(
          result.length > 1
            ? ApprovalSuccessMessages.BULK_APPROVAL
            : ApprovalSuccessMessages.TASK_APPROVE,
        );
        onCloseModal(true, approvedRecords);
        hideLoading();
      } else {
        setTimeout(() => setShowProgressBar(false), 1200);
      }
    }

    hideLoading();
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={handleOnCloseModal} width="large">
          <ModalHeader>
            <ModalTitle>
              Approve Selected{" "}
              {approveId || selectedItems.length == 1 ? "Entity" : "Entities"}
            </ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={handleOnCloseModal}
            />
          </ModalHeader>
          {showProgressBar && (
            <ProgressBarModalApprove
              onFinish={handleOnFinishApproval}
              setResult={setResult}
              showProgressBar={selectedItems.length > 1}
            />
          )}
          <div className="position-relative">
            {isLoading && <Loader message={"Processing..."} tableMode={true} />}

            <ModalBody>
              <>
                <div className="row" id="signatureConsent">
                  <div className="col">
                    <p>
                      {approveId || selectedItems.length === 1
                        ? "I have reviewed the selected record and now apply my electronic signature with a meaning of "
                        : `I have reviewed the selected record(s) and now apply my electronic signature to ${selectedItems.length} record(s) with a meaning of `}
                      <b>{meaning}</b>
                    </p>
                  </div>
                </div>

                {veraIdp && (
                  <form data-toggle="validator" id="modalFormApprove">
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group row">
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="task-username"
                          >
                            Username:
                          </label>

                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              name="username"
                              readOnly={true}
                              required
                              type="text"
                              value={user?.username || ""}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="task-password"
                          >
                            Password:
                          </label>
                          <div className="col-sm-10">
                            <TextField
                              focus={true}
                              name={"password"}
                              onChange={(item: any) => setPassword(item.value)}
                              onKeyDown={(e) =>
                                e.key === "Enter" && onFormSubmit()
                              }
                              placeholder={"Enter your password"}
                              showPasswordFunction={true}
                              type={"password"}
                              value={password}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
                {result && (
                  <div className="d-flex" style={{ gap: "10px" }}>
                    <div>
                      {result.result.filter((x) => x.isSuccess).length} Success
                    </div>
                    <a
                      href="#!"
                      onClick={() => setOpenFailedApprovalsModal(true)}
                    >
                      {result.result.filter((x) => !x.isSuccess).length} Failure
                    </a>
                  </div>
                )}
              </>
            </ModalBody>

            <ModalFooter>
              <div style={{ zIndex: 2 }}>
                <button
                  className="btn btn-primary text-white mr-2"
                  disabled={isLoading}
                  onClick={onFormSubmit}
                  type="button"
                >
                  {veraIdp ? "Submit" : "Click here to authenticate externally"}
                </button>

                <button
                  className="btn btn-primary text-white"
                  id="btnCancel"
                  onClick={handleOnCloseModal}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </div>
          <ModalFailedApprovals
            closeModal={() => setOpenFailedApprovalsModal(false)}
            data={result}
            isOpen={openFailedApprovalsModal}
          />
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ModalApprove;
