import React from "react";
import { useActions } from "../../../../../../hooks/useActions";
import axios from "axios";
import { toast } from "react-toastify";
import { AdminPageSuccessMessages } from "../../../../../../constants/admin-page.constants";
import { ErrorMessage } from "../../../../../../constants/general.constants";
import GenericModal from "../../../../../modules/GenericModal";
import { PolicySetsRequestRoutes } from "../../../../../../constants/policy-set.constants";
import Form from "../../../../../modules/common/Form";
import { IUnlinkDomainConfirmationModalProps } from "../../../../../interfaces/PolicySet";
import { useParams } from "react-router-dom";

const UnlinkDomainConfirmationModal = ({
  domain,
  onClose,
  visible,
}: IUnlinkDomainConfirmationModalProps) => {
  const { policySetId } = useParams<{ policySetId: string }>();
  const [loading, setLoading] = React.useState(false);
  const { fetchPolicySetLinkedDomains } = useActions();

  const handleOnClickDelete = async () => {
    const params = {
      id: policySetId,
      domainId: domain.Id,
    };
    try {
      setLoading(true);
      const { status } = await axios.delete<any>(
        PolicySetsRequestRoutes.UNLINK_DOMAIN,
        {
          data: params,
        },
      );

      if (status === 200) {
        toast.success(AdminPageSuccessMessages.UNLINK_DOMAIN_POLICY_SET);
        fetchPolicySetLinkedDomains(policySetId);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    } finally {
      onClose();
      setLoading(false);
    }
  };

  return (
    <GenericModal
      labelConfirm="Unlink"
      loading={loading}
      onClickConfirm={handleOnClickDelete}
      onCloseModal={onClose}
      size="medium"
      title="Unlink Domain"
      visible={visible}
    >
      <Form>
        {`Are you sure you want to unlink this domain: ${domain.Name}?`}
      </Form>
    </GenericModal>
  );
};

export default UnlinkDomainConfirmationModal;
