import { CSVLink } from "react-csv";
import {
  ePeriod,
  groupByDescriptionList,
  periodDescriptionList,
  summarizeDescriptionList,
} from "../../../../../interfaces/User";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { useMemo } from "react";
import dayjs from "dayjs";
import GetAppIcon from "@mui/icons-material/GetApp";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

const ExportReportButton = () => {
  const {
    startDate,
    endDate,
    errorStartDate,
    errorEndDate,
    groupBy,
    lastData,
    period,
    summarizeBy,
  } = useTypedSelector((x) => x.concurrentUsersReport);

  const headers = [
    { label: "Date", key: "date" },
    { label: "Concurrent Users", key: "userCount" },
  ];

  const fileName = useMemo(() => {
    let periodDescription;
    if (period === ePeriod.CUSTOM_DATE_RANGE) {
      periodDescription = `${dayjs(startDate).format("YYYY-MM-DD")}-${dayjs(
        endDate,
      ).format("YYYY-MM-DD")}`;
    } else {
      periodDescription = periodDescriptionList[period];
    }

    const summarizeByDescription = summarizeDescriptionList[summarizeBy];
    const groupByDescription = groupByDescriptionList[groupBy];

    return `VeraConcurrentUsageReport-${periodDescription}-${summarizeByDescription}-${groupByDescription}.csv`;
  }, [period, summarizeBy, groupBy]);

  const disabled =
    !lastData ||
    lastData.length == 0 ||
    !!errorStartDate ||
    !!errorEndDate ||
    !startDate ||
    !endDate;

  return (
    <CSVLink
      className={disabled ? "link-disabled" : ""}
      data={lastData}
      filename={fileName}
      headers={headers}
    >
      <Tooltip title="Export">
        <span>
          <IconButton aria-label="export" color="primary" disabled={disabled}>
            <GetAppIcon />
          </IconButton>
        </span>
      </Tooltip>
    </CSVLink>
  );
};

export default ExportReportButton;
