import Button from "@atlaskit/button";
import Tooltip from "@atlaskit/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { ITableSearchFieldProps } from "../interfaces/ApprovalQueue";

const TableSearchField = ({
  sortParams,
  fetchTableData,
  offlineSearch = false,
}: ITableSearchFieldProps) => {
  return (
    <div className="d-flex align-items-center" style={{ fontSize: "1rem" }}>
      {!offlineSearch && (
        <Tooltip content="Refresh table" position="top">
          <Button
            appearance="subtle"
            aria-label="refresh-btn"
            onClick={() => fetchTableData(sortParams)}
          >
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default TableSearchField;
