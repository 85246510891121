import { ReactNode } from "react";

export enum ExpandedContentModalActionTypes {
  SHOW_EXPANDED_CONTENT_MODAL = "SHOW_EXPANDED_CONTENT_MODAL",
  HIDE_EXPANDED_CONTENT_MODAL = "HIDE_EXPANDED_CONTENT_MODAL",
}

export interface IContentProps {
  content: string;
  renderAsHtml: boolean;
  title: ReactNode;
}

interface showContentModal {
  type: ExpandedContentModalActionTypes.SHOW_EXPANDED_CONTENT_MODAL;
  payload: IContentProps;
}

interface hideContentModal {
  type: ExpandedContentModalActionTypes.HIDE_EXPANDED_CONTENT_MODAL;
}

export type ExpandedContentAction = showContentModal | hideContentModal;
