import { IAlertProps } from "../interfaces/Alert";
import WarningIcon from "@atlaskit/icon/glyph/warning";

const Alert = ({ message }: IAlertProps) => {
  return (
    <div className="alert-message">
      <WarningIcon label="" primaryColor="rgb(237, 108, 2)" />
      <div>{message}</div>
    </div>
  );
};

export default Alert;
