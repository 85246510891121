import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import {
  IUserImportModalProps,
  IUserImportResponse,
} from "../../../../interfaces/AdminPage";
import {
  AdminRequestRoutes,
  ErrorMessage,
} from "../../../../../constants/general.constants";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
  AdminPageWarnings,
} from "../../../../../constants/admin-page.constants";
import { convertMegaBytesToBytes } from "../../../../../helpers/file.helper";
import { IconButton } from "@atlaskit/button/new";

const ImportUsersModal = ({ isOpen, closeModal }: IUserImportModalProps) => {
  const [loading, setLoading] = useState(false);
  const [importedFile, setImportedFile] = useState<File | null>(null);

  const handleFileUpload = async () => {
    if (!importedFile) {
      toast.warning(AdminPageWarnings.EMPTY_UPLOAD_INPUT);
      return;
    }
    const sizeInMb = 2;
    if (importedFile.size > convertMegaBytesToBytes(sizeInMb)) {
      toast.warning(AdminPageErrors.IMPORT_MAX_SIZE);
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("importFile", importedFile!);
      const { data }: IUserImportResponse = await axios.post(
        AdminRequestRoutes.IMPORT_USERS,
        formData,
      );

      if (data.apiStatusCode === 415) {
        toast.error(AdminPageErrors.IMPORT_USERS_MEDIA_TYPE);
        return;
      } else if (data.apiStatusCode === 400) {
        toast.error(AdminPageErrors.IMPORT_USERS_FILE_NAME);
        return;
      } else if (data.apiStatusCode !== 200) {
        toast.error(AdminPageErrors.IMPORT_USERS);
        return;
      }
      toast.success(AdminPageSuccessMessages.IMPORT_USERS);
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        closeModal();
      }
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal autoFocus={false} onClose={closeModal} width="medium">
          <ModalHeader>
            <ModalTitle>Import Users</ModalTitle>

            <IconButton
              appearance="subtle"
              icon={CrossIcon}
              label="Close Modal"
              onClick={closeModal}
            />
          </ModalHeader>

          <ModalBody>
            <div aria-label="import-users-modal" className="container rounded">
              <input
                accept=".csv"
                aria-label="import-users-input"
                name="importFile"
                onChange={(event) => setImportedFile(event.target.files![0])}
                type="file"
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="col">
              <span className="float-right">
                <button
                  className="btn btn-primary text-white ml-2"
                  id="submit-import-users-btn"
                  onClick={handleFileUpload}
                  type="button"
                >
                  Import
                  {loading && (
                    <span
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                    />
                  )}
                </button>

                <button
                  className="btn btn-primary text-white ml-2"
                  onClick={closeModal}
                  type="button"
                >
                  Cancel
                </button>
              </span>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ImportUsersModal;
