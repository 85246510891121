import React from "react";
import { escapeOnlyNonHtmlTags } from "../../../../helpers/html.helper";
import { RecordHistoryTableItemProps } from "../../../interfaces/DetailsPage";
import AuthorsModal from "../entityDetails/AuthorsModal";

const RecordHistoryDetailsItem: React.FC<RecordHistoryTableItemProps> = ({
  detailsItem,
  getDetailsMarkup,
}) => {
  const itemMarkup = getDetailsMarkup(detailsItem);

  return (
    <>
      <h6 dangerouslySetInnerHTML={{ __html: detailsItem.name }}></h6>

      <div className="row pl-3">
        {itemMarkup.shortItems.map((shortItem, index) => (
          <div
            className="col-xs-12 col-md-6 details-field"
            key={`${shortItem.label}-${shortItem.value}`}
          >
            <label>{shortItem.label}:</label>
            <span>
              {shortItem.label === "Author" ? (
                <AuthorField author={shortItem.value} />
              ) : (
                shortItem.value
              )}
            </span>
          </div>
        ))}

        {itemMarkup.longItems.map((longItem) => (
          <div className="col-xs-10 col-md-10 d-flex details-field">
            <label>{longItem.label}:</label>
            <div
              className="external-content"
              dangerouslySetInnerHTML={{
                __html: escapeOnlyNonHtmlTags(longItem.value),
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

const AuthorField: React.FC<{ author: string | [] }> = ({ author }) => {
  if (Array.isArray(author)) {
    return <MultipleAuthorField authors={author} />;
  }

  return <>{author}</>;
};

const MultipleAuthorField: React.FC<{ authors: [] }> = ({ authors }) => {
  const [isAuthorsModalOpen, setAuthorsModalOpen] = React.useState(false);

  return (
    <>
      {isAuthorsModalOpen && (
        <AuthorsModal
          authorsList={authors}
          closeModal={() => setAuthorsModalOpen(false)}
          isOpen={isAuthorsModalOpen}
        />
      )}
      <span>
        <a href="#" onClick={() => setAuthorsModalOpen(true)} role="button">
          Show
        </a>
      </span>
    </>
  );
};

export default RecordHistoryDetailsItem;
