import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import {
  AdminPageRoutes,
  AppRoutes,
} from "../../../constants/general.constants";
import { AdminPageQueryParams } from "../../interfaces/AdminPage";
import UserDetailIcon from "./../../../images/bxs-user.svg";
import RoleIcon from "./../../../images/bxs-group.svg";
import SignatureIcon from "./../../../images/bxs-edit-alt.svg";
import DomainIcon from "./../../../images/bx-world.svg";
import PolicyIcon from "./../../../images/bxs-shield.svg";
import SettingsIcon from "./../../../images/bx-slider.svg";
import AddUserIcon from "./../../../images/bxs-user-plus.svg";
import EditUserIcon from "./../../../images/bxs-user-detail.svg";
import DomainDetailsIcon from "./../../../images/bx-sitemap.svg";
import MailIcon from "./../../../images/bxs-envelope.svg";
import KeyIcon from "./../../../images/bxs-key.svg";
import ConnectionIcon from "./../../../images/bx-stats.svg";
import ConnectionAddIcon from "./../../../images/connection-add.svg";
import ConnectionEditIcon from "./../../../images/connection-edit.svg";
import PolicyDetailsIcon from "./../../../images/bxs-check-shield.svg";
import PolicyManagementIcon from "./../../../images/policy-management.svg";
import PolicySetIcon from "./../../../images/policy-set.svg";
import PolicySetDetailsIcon from "./../../../images/policy-set-details.svg";
import ReportIcon from "./../../../images/bxs-report.svg";
import LineChartIcon from "./../../../images/bx-line-chart.svg";
import AuditHistoryIcon from "./../../../images/bxs-calendar-check.svg";
import SidebarItem from "./SidebarItem";
import ActiveUserIcon from "./../../../images/bxs-user-voice.svg";

const SidebarSystemAdmin = () => {
  const location = useLocation();

  const matchUsers = matchPath<AdminPageQueryParams>(location.pathname, {
    path: [AppRoutes.AdminPage],
  });

  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);
  const [isReportsExpanded, setIsReportsExpanded] = useState(false);

  useEffect(() => {
    const isMailSettings = AdminPageRoutes.MAIL_SETTINGS.includes(
      location.pathname,
    );
    const isPasswordPolicySettings = AdminPageRoutes.PASSWORD_POLICY.includes(
      location.pathname,
    );
    const isConnectionSettings =
      location.pathname.includes(AdminPageRoutes.ADD_CONNECTION) ||
      location.pathname.includes(AdminPageRoutes.EDIT_CONNECTION) ||
      location.pathname.includes(AdminPageRoutes.CONNECTIONS);

    setIsSettingsExpanded(
      isMailSettings || isPasswordPolicySettings || isConnectionSettings,
    );
  }, [location.pathname]);

  return (
    <ul className="features-list">
      <SidebarItem
        icon={<img className="white-icon" src={UserDetailIcon} />}
        label="User Management"
        to={AdminPageRoutes.USER_MANAGEMENT}
      />

      {location.pathname &&
        matchUsers?.params?.subjectId &&
        (location.pathname.includes(AdminPageRoutes.EDIT_USER) ||
          location.pathname.includes(AdminPageRoutes.DOMAINS_AND_ROLES)) && (
          <>
            <SidebarItem
              icon={<img className="white-icon" src={EditUserIcon} />}
              label="Edit User"
              level={1}
              to={`${AdminPageRoutes.EDIT_USER}/${matchUsers.params.subjectId}`}
            />
            <SidebarItem
              icon={<img className="white-icon" src={DomainIcon} />}
              label="Domains & Roles"
              level={1}
              to={`${AdminPageRoutes.DOMAINS_AND_ROLES}/${matchUsers.params.subjectId}`}
            />
          </>
        )}
      {location.pathname &&
        location.pathname.includes(AdminPageRoutes.ADD_USER) && (
          <SidebarItem
            icon={<img className="white-icon" src={AddUserIcon} />}
            label="Add User"
            level={1}
            to={AdminPageRoutes.ADD_USER}
          />
        )}

      <SidebarItem
        icon={<img className="white-icon" src={SignatureIcon} />}
        label="Signature Management"
        to={AdminPageRoutes.SIGNATURE_VERIFICATION}
      />

      <SidebarItem
        icon={<img className="white-icon" src={DomainIcon} />}
        label="Domain Management"
        to={AdminPageRoutes.DOMAIN_MANAGEMENT}
      />
      {matchUsers?.params?.subRoute &&
        (AdminPageRoutes.DOMAIN_DETAILS.includes(
          matchUsers?.params?.subRoute,
        ) ||
          AdminPageRoutes.ROLE_MANAGEMENT.includes(
            matchUsers?.params?.subRoute,
          )) &&
        matchUsers?.params?.subjectId && (
          <>
            <SidebarItem
              icon={<img className="white-icon" src={DomainDetailsIcon} />}
              label="Domain Details"
              level={1}
              to={`${AdminPageRoutes.DOMAIN_DETAILS}/${matchUsers?.params?.subjectId}`}
            />
            <SidebarItem
              icon={<img className="white-icon" src={RoleIcon} />}
              label="Role Management"
              level={1}
              to={`${AdminPageRoutes.ROLE_MANAGEMENT}/${matchUsers?.params?.subjectId}`}
            />
          </>
        )}

      <SidebarItem
        activeClassName=""
        icon={<img className="white-icon" src={PolicyManagementIcon} />}
        label="Policy Management"
        to={AdminPageRoutes.POLICIES}
      />

      {matchUsers?.params?.subRoute &&
        (AdminPageRoutes.POLICIES.includes(matchUsers?.params?.subRoute) ||
          AdminPageRoutes.POLICY_SET_DETAILS.includes(
            matchUsers?.params?.subRoute,
          ) ||
          AdminPageRoutes.POLICY_SETS.includes(matchUsers?.params?.subRoute) ||
          AdminPageRoutes.POLICY_DETAILS.includes(
            matchUsers?.params?.subRoute,
          )) && (
          <>
            <SidebarItem
              icon={<img className="white-icon" src={PolicyIcon} />}
              label="Policy"
              level={1}
              to={`${AdminPageRoutes.POLICIES}`}
            />
          </>
        )}
      {matchUsers?.params?.subRoute &&
        AdminPageRoutes.POLICY_DETAILS.includes(matchUsers?.params?.subRoute) &&
        matchUsers?.params?.subjectId && (
          <>
            <SidebarItem
              icon={<img className="white-icon" src={PolicyDetailsIcon} />}
              label="Policy Details"
              level={2}
              to={`${AdminPageRoutes.POLICY_DETAILS}`}
            />
          </>
        )}
      {matchUsers?.params?.subRoute &&
        (AdminPageRoutes.POLICIES.includes(matchUsers?.params?.subRoute) ||
          AdminPageRoutes.POLICY_SETS.includes(matchUsers?.params?.subRoute) ||
          AdminPageRoutes.POLICY_SET_DETAILS.includes(
            matchUsers?.params?.subRoute,
          ) ||
          AdminPageRoutes.POLICY_DETAILS.includes(
            matchUsers?.params?.subRoute,
          )) && (
          <>
            <SidebarItem
              icon={<img className="white-icon" src={PolicySetIcon} />}
              label="Policy Sets"
              level={1}
              to={`${AdminPageRoutes.POLICY_SETS}`}
            />
          </>
        )}
      {matchUsers?.params?.subRoute &&
        AdminPageRoutes.POLICY_SET_DETAILS.includes(
          matchUsers?.params?.subRoute,
        ) &&
        matchUsers?.params?.subjectId && (
          <>
            <SidebarItem
              icon={<img className="white-icon" src={PolicySetDetailsIcon} />}
              label="Policy Sets Details"
              level={2}
              to={`${AdminPageRoutes.POLICY_SET_DETAILS}`}
            />
          </>
        )}
      <SidebarItem
        icon={<img className="white-icon" src={KeyIcon} />}
        label={"License Management"}
        to={AdminPageRoutes.USER_SESSIONS}
      />
      {location.pathname &&
        location.pathname.includes(AdminPageRoutes.USER_SESSIONS) && (
          <SidebarItem
            icon={<img className="white-icon" src={ActiveUserIcon} />}
            label="Active User Sessions"
            level={1}
            to={AdminPageRoutes.USER_SESSIONS}
          />
        )}
      <li className="features-item">
        <a
          href="#!"
          onClick={() => setIsReportsExpanded((isExpanded) => !isExpanded)}
        >
          <img className="white-icon" src={ReportIcon} />
          <span className="features-item-text">Reports</span>
        </a>
      </li>
      {isReportsExpanded && (
        <>
          <SidebarItem
            icon={<img className="white-icon" src={LineChartIcon} />}
            label="Concurrent Usage"
            level={1}
            to={AdminPageRoutes.CONCURRENT_USAGE}
          />
          <SidebarItem
            icon={<img className="white-icon" src={AuditHistoryIcon} />}
            label="Audit History"
            level={1}
            to={AdminPageRoutes.AUDIT_HISTORY}
          />
        </>
      )}
      <li className="features-item">
        <a
          href="#!"
          onClick={() => setIsSettingsExpanded((isExpanded) => !isExpanded)}
        >
          <img className="white-icon" src={SettingsIcon} />
          <span className="features-item-text">Settings</span>
        </a>
      </li>
      {isSettingsExpanded && (
        <>
          <SidebarItem
            icon={<img className="white-icon" src={MailIcon} />}
            label="Mail Settings"
            level={1}
            to={AdminPageRoutes.MAIL_SETTINGS}
          />
          <SidebarItem
            icon={<img className="white-icon" src={KeyIcon} />}
            label="Password Policy"
            level={1}
            to={AdminPageRoutes.PASSWORD_POLICY}
          />
          <SidebarItem
            icon={<img className="white-icon" src={ConnectionIcon} />}
            label="Connections"
            level={1}
            to={AdminPageRoutes.CONNECTIONS}
          />
          {matchUsers?.params?.subRoute &&
            AdminPageRoutes.ADD_CONNECTION.includes(
              matchUsers?.params?.subRoute,
            ) && (
              <>
                <SidebarItem
                  icon={<img className="white-icon" src={ConnectionAddIcon} />}
                  label="Add Connection"
                  level={2}
                  to={`${AdminPageRoutes.ADD_CONNECTION}`}
                />
              </>
            )}
          {matchUsers?.params?.subRoute &&
            AdminPageRoutes.EDIT_CONNECTION.includes(
              matchUsers?.params?.subRoute,
            ) &&
            matchUsers?.params?.subjectId && (
              <>
                <SidebarItem
                  icon={<img className="white-icon" src={ConnectionEditIcon} />}
                  label="Edit Connection"
                  level={2}
                  to={`${AdminPageRoutes.EDIT_CONNECTION}/${matchUsers?.params?.subjectId}`}
                />
              </>
            )}
        </>
      )}
    </ul>
  );
};

export default SidebarSystemAdmin;
