import GenericModal from "../../../modules/GenericModal";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ConnectionRequestRoutes,
  ErrorMessage,
} from "../../../../constants/general.constants";
import { useState } from "react";
import {
  AdminPageErrors,
  AdminPageSuccessMessages,
} from "../../../../constants/admin-page.constants";
import { useActions } from "../../../../hooks/useActions";
import { IDefaultResponse } from "../../../interfaces/General";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

interface IDeleteConnectionConfirmationModalProps {
  id: string;
  name: string;
  visible: boolean;
  onClose: () => void;
}

const DeleteConnectionConfirmationModal = ({
  id,
  name,
  onClose,
  visible,
}: IDeleteConnectionConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);
  const { getConnectionsData, updateConnectionsPageParams } = useActions();
  const { connectionsData, sortParams } = useTypedSelector(
    (x) => x.connections,
  );

  const handleOnClickDelete = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete<IDefaultResponse<any>>(
        ConnectionRequestRoutes.DELETE_CONNECTION,
        {
          data: { id },
        },
      );

      if (data.apiStatusCode === 204) {
        toast.success(AdminPageSuccessMessages.DELETE_CONNECTION);
        if (connectionsData.rows.length === 1) {
          updateConnectionsPageParams(0, sortParams.limit);
        } else {
          getConnectionsData();
        }
      } else {
        toast.error(AdminPageErrors.DELETE_CONNECTION);
      }
      onClose();
      setLoading(false);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
      onClose();
    }
  };

  return (
    <GenericModal
      labelConfirm="Delete"
      loading={loading}
      onClickConfirm={handleOnClickDelete}
      onCloseModal={onClose}
      size="medium"
      title="Delete Connection"
      visible={visible}
    >
      {`Are you sure you want to delete this connection: ${name}?`}
    </GenericModal>
  );
};

export default DeleteConnectionConfirmationModal;
