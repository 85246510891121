import { IPolicyRevision } from "../../components/interfaces/Policy";
import { ITableData } from "../../components/interfaces/Table";

export enum PolicyRevisionsActionTypes {
  FETCHING_POLICY_REVISIONS_TABLE_DATA = "FETCHING_POLICY_REVISIONS_TABLE_DATA",
  UPDATE_POLICY_REVISIONS_TABLE_DATA = "UPDATE_POLICY_REVISIONS_TABLE_DATA",
  UPDATE_POLICY_REVISIONS_SELECTED_PAGE = "UPDATE_POLICY_REVISIONS_SELECTED_PAGE",
  UPDATE_POLICY_REVISIONS_SORT_PARAMS = "UPDATE_POLICY_REVISIONS_SORT_PARAMS",
  UPDATE_POLICY_REVISIONS_PAGE_PARAMS = "UPDATE_POLICY_REVISIONS_PAGE_PARAMS",
  RESET_POLICY_REVISIONS_SORT_PARAMS = "RESET_POLICY_REVISIONS_SORT_PARAMS",
  HANDLE_POLICY_REVISIONS_NETWORK_ERROR = "HANDLE_POLICY_REVISIONS_NETWORK_ERROR",
  RESET_POLICY_REVISIONS_DATA = "RESET_POLICY_REVISIONS_DATA",
}

interface FetchingTableData {
  type: PolicyRevisionsActionTypes.FETCHING_POLICY_REVISIONS_TABLE_DATA;
}

interface UpdateTableData {
  type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_TABLE_DATA;
  payload: ITableData<IPolicyRevision>;
}

interface UpdateSelectedPage {
  type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_SELECTED_PAGE;
  payload: number;
}

interface HandleNetworkError {
  type: PolicyRevisionsActionTypes.HANDLE_POLICY_REVISIONS_NETWORK_ERROR;
  payload: string;
}

interface UpdateSortParams {
  type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_SORT_PARAMS;
  payload: {
    sort: string;
    order: "asc" | "desc";
  };
}

interface UpdatePageParams {
  type: PolicyRevisionsActionTypes.UPDATE_POLICY_REVISIONS_PAGE_PARAMS;
  payload: {
    offset: number;
    limit: number;
  };
}

interface HandleResetSortParams {
  type: PolicyRevisionsActionTypes.RESET_POLICY_REVISIONS_SORT_PARAMS;
}

interface HandleResetData {
  type: PolicyRevisionsActionTypes.RESET_POLICY_REVISIONS_DATA;
}

export type PolicyRevisionsAction =
  | UpdateTableData
  | FetchingTableData
  | UpdateSelectedPage
  | HandleNetworkError
  | HandleResetData
  | UpdateSortParams
  | UpdatePageParams
  | HandleResetSortParams;
