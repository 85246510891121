import {
  IDomainAndRolesState,
  IDomainFilterItem,
} from "../../components/interfaces/DomainsAndRoles";
import { IFilterItem } from "../../components/interfaces/LocateRecordsFilter";
import {
  DomainsAndRolesAction,
  DomainsAndRolesActionTypes,
} from "../types/DomainsAndRoles";

const initialState: IDomainAndRolesState = {
  domains: {
    items: [],
    total: 0,
  },
  showAllDomains: false,
  domainsSearchField: "",
  rolesSearchField: "",
  isLoadingDomains: false,
  isLoadingRoles: false,
  isLoadingDomainsAndRoles: false,
  domainAndRoles: {
    total: 0,
    fullname: "",
    rows: [],
  },
  selectedDomains: [],
  selectedPage: 0,
  sortParams: {
    offset: 0,
    limit: 10,
    order: "asc",
    sort: "name",
  },
  skip: 0,
};

export const domainsAndRolesReducer = (
  state = initialState,
  action: DomainsAndRolesAction,
): IDomainAndRolesState => {
  switch (action.type) {
    case DomainsAndRolesActionTypes.FETCHING_DOMAINS:
      return { ...state, isLoadingDomains: true, skip: action.payload };
    case DomainsAndRolesActionTypes.UPDATE_DOMAINS:
      return {
        ...state,
        isLoadingDomains: false,
        domains: {
          items: convertDomainToFilterItem(action.payload.rows),
          total: action.payload.total,
        },
      };
    case DomainsAndRolesActionTypes.SHOW_MORE_DOMAINS:
      return {
        ...state,
        isLoadingDomains: false,
        domains: {
          items: [
            ...state.domains.items,
            ...convertDomainToFilterItem(action.payload.rows),
          ],
          total: action.payload.total,
        },
      };
    case DomainsAndRolesActionTypes.UPDATE_SELECTED_DOMAINS: {
      const set = new Set(state.selectedDomains);
      if (set.has(action.payload)) {
        set.delete(action.payload);
      } else {
        set.add(action.payload);
      }

      return {
        ...state,
        selectedDomains: Array.from(set),
      };
    }
    case DomainsAndRolesActionTypes.CLEAR_SELECTED_DOMAINS:
      return {
        ...state,
        selectedDomains: [],
      };
    case DomainsAndRolesActionTypes.FETCHING_DOMAINS_AND_ROLES:
      return {
        ...state,
        domainAndRoles: {
          ...state.domainAndRoles,
          rows: [],
        },
        isLoadingDomainsAndRoles: true,
      };
    case DomainsAndRolesActionTypes.UPDATE_DOMAINS_AND_ROLES:
      return {
        ...state,
        isLoadingDomainsAndRoles: false,
        domainAndRoles: {
          total: action.payload.domainAndRoles.total,
          fullname: action.payload.domainAndRoles.fullname,
          rows: action.payload.domainAndRoles.rows.map((x) => ({
            domain: {
              id: x._id,
              name: x.name,
            },
            notificationTypes: x.notificationTypes,
            roles: x.roles,
          })),
        },
      };
    case DomainsAndRolesActionTypes.UPDATE_SELECTED_USER_ROLES: {
      const rows = [...state.domainAndRoles.rows];
      const rowIndex = state.domainAndRoles.rows.findIndex(
        (x) => x.domain.id === action.payload.domainId,
      );
      rows[rowIndex].roles = action.payload.roles;

      return {
        ...state,
        domainAndRoles: {
          ...state.domainAndRoles,
          rows,
        },
      };
    }
    case DomainsAndRolesActionTypes.RESET_DOMAIN_AND_ROLES_SORT_PARAMS:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
      };
    case DomainsAndRolesActionTypes.RESET_DOMAINS_AND_ROLES_DATA:
      return {
        ...state,
        selectedPage: initialState.selectedPage,
        sortParams: { ...initialState.sortParams },
        domainAndRoles: initialState.domainAndRoles,
        selectedDomains: [],
        showAllDomains: false,
      };
    case DomainsAndRolesActionTypes.RESET_USER_DATA:
      return {
        ...state,
        domainAndRoles: {
          ...state.domainAndRoles,
          fullname: "",
        },
      };
    case DomainsAndRolesActionTypes.UPDATE_DOMAIN_AND_ROLES_SORT_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          sort: action.payload.sort,
          order: action.payload.order,
          offset: 0,
        },
        selectedPage: 0,
      };
    case DomainsAndRolesActionTypes.UPDATE_DOMAIN_AND_ROLES_PAGE_PARAMS:
      return {
        ...state,
        sortParams: {
          ...state.sortParams,
          offset: action.payload.offset,
          limit: action.payload.limit,
        },
        selectedPage: action.payload.offset === 0 ? 0 : state.selectedPage,
      };
    case DomainsAndRolesActionTypes.CLEAR_FILTER:
      return {
        ...state,
        domainsSearchField: "",
        rolesSearchField: "",
        showAllDomains: false,
        selectedDomains: [],
      };
    case DomainsAndRolesActionTypes.TOGGLE_SHOW_ALL_DOMAINS:
      return {
        ...state,
        showAllDomains: !state.showAllDomains,
      };
    case DomainsAndRolesActionTypes.UPDATE_SEARCH_FIELD_ROLE:
      return {
        ...state,
        rolesSearchField: action.payload.searchField,
      };
    case DomainsAndRolesActionTypes.UPDATE_SEARCH_FIELD_DOMAIN:
      return {
        ...state,
        domainsSearchField: action.payload.searchField,
      };
    case DomainsAndRolesActionTypes.UPDATE_DOMAIN_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.payload,
      };
    case DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_DOMAINS:
      return {
        ...state,
        isLoadingDomains: false,
      };
    case DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_ROLES:
      return {
        ...state,
        isLoadingRoles: false,
      };
    case DomainsAndRolesActionTypes.HANDLE_NETWORK_ERROR_DOMAINS_AND_ROLES:
      return {
        ...state,
        isLoadingDomainsAndRoles: false,
      };
    case DomainsAndRolesActionTypes.RESET_ALL_DOMAINS_AND_ROLES_DATA:
      return initialState;
    default:
      return state;
  }
};

const convertToFilterItem = (rows: string[]): IFilterItem[] => {
  return rows.map(
    (item: string) =>
      ({ id: item, name: item, isSelected: false }) as IFilterItem,
  );
};

const convertDomainToFilterItem = (
  rows: IDomainFilterItem[],
): IFilterItem[] => {
  return rows.map(
    (item: IDomainFilterItem) =>
      ({ id: item.id, name: item.name, isSelected: false }) as IFilterItem,
  );
};
