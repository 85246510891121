import CompanyLogo from "./Logo";
import { Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const AccountReuseNotAllowedPage = () => {
  const history = useHistory();

  const handleOnClickOnBack = () => {
    history.push("/");
  };

  return (
    <Box
      className="account-reuse-not-allowed-page"
      id="account-reuse-not-allowed-page"
    >
      <Box className="company-logo-container">
        <CompanyLogo />
      </Box>
      <Box className="account-reuse-content">
        <Box className="account-reuse-content-title">
          <Typography
            className="account-reuse-main-heading"
            id="lbl-main-heading"
            variant="h6"
          >
            Multiple Vera accounts are associated with this Identity Provider
            account.
          </Typography>
          <Typography
            className="account-reuse-account-heading"
            id="lbl-heading"
            variant="h6"
          >
            Vera is not configured to allow this login. Please contact an
            administrator.
          </Typography>
        </Box>
        <Box className="account-reuse-action-buttons">
          <Button
            id="btn-back"
            onClick={handleOnClickOnBack}
            variant="contained"
          >
            Go Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountReuseNotAllowedPage;
