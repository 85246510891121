import axios, { CancelTokenSource } from "axios";
import { Dispatch } from "redux";
import { ErrorMessage } from "../../constants/general.constants";
import { PolicySetAction, PolicySetActionTypes } from "../types/PolicySet";
import { PolicySetsRequestRoutes } from "../../constants/policy-set.constants";
import { IDefaultResponse } from "../../components/interfaces/General";
import { IPolicySet } from "../../components/interfaces/PolicySet";
import { toast } from "react-toastify";

let policyCancelToken: CancelTokenSource;
export const fetchPolicySet = (policySetId: string) => {
  return async (dispatch: Dispatch<PolicySetAction>) => {
    if (typeof policyCancelToken != typeof undefined) {
      policyCancelToken.cancel(ErrorMessage.NEW_REQUEST);
    }
    policyCancelToken = axios.CancelToken.source();

    try {
      dispatch({ type: PolicySetActionTypes.FETCHING_POLICY_SET });

      const params = {
        id: policySetId,
      };

      const { data, status } = await axios.get<IDefaultResponse<IPolicySet>>(
        PolicySetsRequestRoutes.POLICY_SET,
        { cancelToken: policyCancelToken.token, params },
      );

      if (status === 200) {
        dispatch({
          type: PolicySetActionTypes.UPDATE_POLICY_SET,
          payload: data.apiData,
        });
      }
    } catch (error: any) {
      if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
        dispatch({
          type: PolicySetActionTypes.HANDLE_POLICY_SET_NETWORK_ERROR,
          payload: error.message,
        });
      }
    }
  };
};
