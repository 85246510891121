export enum AdminPageWarnings {
  FILL_FORM = "Please fill form to proceed.",
  NO_IDP_USERNAME = "An IdP username is required when the Identity Provider is not VERA.",
  NO_PASSWORD = "The 'VERA' identity provider requires a password to be configured.",
  EMPTY_UPLOAD_INPUT = "Please choose file to import.",
  PASSWORD_IS_GENERATING = "New password is generating, please wait.",
  TEST_EMAIL_EMPTY_FORM = "Please fill test email form.",
  ADD_PROJECT_TO_DOMAIN_EMPTY_FORM = "Please enter Projects to Add.",
  IMPORT_NEW_POLICY_NAME = "Please enter a policy name.",
  IMPORT_NEW_POLICY_TYPE = "Please enter a policy type.",
}

export enum AdminPageErrors {
  IMPORT_USERS = "An error occurred when attempting to import users.",
  IMPORT_USERS_MEDIA_TYPE = "Unsupported file type. Please upload a CSV file.",
  IMPORT_USERS_FILE_NAME = "Unsupported file name. File name can only contain alphanumerical characters and the following special characters: . _ - @",
  USER_DELETE = "An error occurred when attempting to delete the user.",
  USER_ACTIVATE = "An error occurred when attempting to activate the user.",
  USER_DEACTIVATE = "An error occurred when attempting to deactivate the user.",
  USER_UNLOCK = "An error occurred when attempting to unlock the user.",
  REMOVE_ROLE_USERS = "Modifying the user role membership failed.",
  REVERIFY_SIGNATURE = "The signature was reverified and it failed verification again.",
  DELETE_DOMAIN = "An error occurred when attempting to delete the domain.",
  DOMAIN_UPDATE = "An error occurred when attempting to update the domain.",
  SMTP_CONFIGS_TEST = "Testing the SMTP configurations failed.",
  IMPORT_POLICY = "An error occurred when attempting to import the policy file.",
  IMPORT_POLICY_MEDIA_TYPE = "Unsupported file type. Please upload a JSON file.",
  IMPORT_MAX_SIZE = "File size exceeds 2 Mb",
  IMPORT_POLICY_MISMATCH_TYPE = "The wrong policy file type was uploaded. Please double check and try again.",
  DELETE_CONNECTION = "An error occurred when attempting to delete the connection.",
  DELETE_POLICY = "An error occurred when attempting to delete the policy.",
  DOWNLOAD_POLICY = "An error occurred when attempting to download the policy.",
  CREATE_POLICY_SET = "An error occurred when attempting to create a policy set.",
  UPDATE_POLICY_SET = "An error occurred when attempting to update a policy set.",
  DELETE_POLICY_SET = "An error occurred when attempting to delete the policy set.",
  UPDATE_EMAIL_SETTINGS = "Failed to update email settings. If this issue persists, please contact a system administrator.",
}

export enum AdminPageSuccessMessages {
  IMPORT_USERS = "User import file has been submitted. Please check the Latest Import Results for a summary of changes.",
  CLIPBOARD = "The password has been copied to the clipboard to be provided to the user upon reset.",
  USER_DELETE = "The user was deleted successfully.",
  USER_ACTIVATE = "The user was activated successfully",
  USER_DEACTIVATE = "The user was deactivated successfully",
  USER_UNLOCK = "The user was unlocked successfully",
  PASSWORD_CHANGE = "Your password has been changed successfully.",
  REMOVE_ROLE_USERS = "Successfully modified the role membership.",
  REVERIFY_SIGNATURE = "The signature was reverified and passed. You may close this dialog.",
  CREATE_DOMAIN = "New Domain has been created successfully.",
  DOMAIN_UPDATE = "Domain has been updated successfully.",
  UPDATE_DOMAIN_USERS = "The domain user modifications were saved successfully.",
  PASSWORD_POLICY = "The Password Policy was set successfully.",
  SMTP_CONFIGS = "The SMTP configurations were saved successfully.",
  SEND_TEST_EMAIL = "The test email was sent successfully.",
  IMPORT_POLICY = "The policy file has been uploaded successfully.",
  ROLES = "Roles were saved successfully.",
  CREATE_CONNECTION = "New connection has been created successfully.",
  UPDATE_CONNECTION = "Connection has been updated successfully.",
  DELETE_CONNECTION = "The connection was deleted successfully.",
  TEST_CONNECTION = "The connection was tested successfully.",
  DELETE_POLICY = "The policy was deleted successfully.",
  SAVE_POLICY = "The policy has been updated successfully.",
  CREATE_POLICY_SET = "The policy set has been created successfully.",
  UPDATE_POLICY_SET = "The policy set has been updated successfully.",
  DELETE_POLICY_SET = "The policy set was deleted successfully.",
  LINK_DOMAIN_POLICY_SET = "Domains were linked to the policy set successfully.",
  UNLINK_DOMAIN_POLICY_SET = "Domain has been unlinked successfully",
  ENABLE_EMAIL_NOTIFICATIONS = "Email notifications have been enabled successfully.",
  DISABLE_EMAIL_NOTIFICATIONS = "Email notifications have been turned off successfully.",
  DELETE_USER_SESSION = "The user session was deleted successfully.",
}

export enum ReVerifyStatus {
  PASSED = "Passed",
  FAILED = "Failed",
}

export enum SelectFieldValidation {
  SUCCESS = "success",
  ERROR = "error",
  DEFAULT = "default",
}

export enum PoliciesTabs {
  POLICIES = "Policies",
  POLICY_SETS = "Policy Sets",
}
