import Tooltip from "@atlaskit/tooltip";
import { Link } from "react-router-dom";
import { AdminPageRoutes } from "../../../../../../../constants/general.constants";
import { ILinkedPolicySet } from "../../../../../../interfaces/Policy";

const PolicySetsTableItem = ({
  policySet,
}: {
  policySet: ILinkedPolicySet;
}) => {
  return (
    <tr>
      <td>{policySet.Name}</td>
      <td>
        <div className="table-actions">
          <Tooltip content="View" position="top">
            <Link
              aria-label="edit-policy-set-btn"
              id={`link-policy-set-${policySet._id}`}
              to={`${AdminPageRoutes.POLICY_SET_DETAILS}/${policySet._id}`}
            >
              <i className="fa fa-edit" />
            </Link>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

export default PolicySetsTableItem;
