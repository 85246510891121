import styled from "@emotion/styled";

export const LoginFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ProvidersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
