import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IPolicyImportNewProps } from "../../../../../interfaces/AdminPage";
import GenericModal from "../../../../../modules/GenericModal";
import { FileUploadService } from "../../../../../services/fileUploadService";
import { useForm, Controller } from "react-hook-form";
import FormTextField from "../../../../../modules/common/FormTextField";
import UserSelect from "../../../../../modules/UserSelect";
import { createSchema } from "../../../../../../validators/policyFormValidator";
import { yupResolver } from "@hookform/resolvers/yup";
import { useActions } from "../../../../../../hooks/useActions";
import { PolicyRequestRoutes } from "../../../../../../constants/policy.constants";
import {
  IImportNewPolicyModalFormData,
  ePolicyTypeValue,
} from "../../../../../interfaces/Policy";
import Form from "../../../../../modules/common/Form";

interface ImportNewPolicyModalFormData {
  description: string;
  importedFile: File[];
  name: string;
  policyOwnerUserId?: { label: string; value: string };
}

const ImportNewPolicyModal = ({
  closeModal,
  hasDefaultPolicy,
  policyType,
  visible,
}: IPolicyImportNewProps) => {
  const [loading, setLoading] = React.useState(false);
  const { fetchPolicies } = useActions();

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<IImportNewPolicyModalFormData>({
    mode: "all",
    defaultValues: { name: hasDefaultPolicy ? "" : `Default ${policyType}` },
    resolver: yupResolver(createSchema()),
  });

  const policyOwner = watch("policyOwnerUserId.value");
  const importedFile = watch("importedFile");

  const getTitle = () => {
    return `Create ${ePolicyTypeValue[policyType]}`;
  };

  const uploadPolicy = async (
    data: IImportNewPolicyModalFormData,
    policyType: string,
  ) => {
    const formData = new FormData();
    formData.append("importFile", data.importedFile[0]);

    return await axios.post(
      `${PolicyRequestRoutes.UPLOAD_NEW_POLICY}`,
      formData,
      {
        params: {
          ...data,
          policyOwnerUserId: data.policyOwnerUserId?.value,
          policyType,
        },
      },
    );
  };

  const handleFileUpload = async (data: IImportNewPolicyModalFormData) => {
    setLoading(true);
    FileUploadService.uploadPolicy(
      importedFile[0],
      policyType,
      async () => await uploadPolicy(data, policyType),
    )
      .then((response) => {
        typeof response === "string" && toast.success(response);
        fetchPolicies();
        closeModal();
      })
      .catch((response) => {
        if (response) {
          switch (response.type) {
            case "error":
              toast.error(response.message);
              break;
            case "warning":
              toast.warning(response.message);
              break;
          }
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <GenericModal
      labelConfirm="Import"
      loading={loading}
      onClickConfirm={handleSubmit((data) => handleFileUpload(data))}
      onCloseModal={closeModal}
      size="medium"
      title={getTitle()}
      visible={visible}
    >
      <div aria-label="import-users-modal" className="container rounded">
        <Form className="import-new-policy-form">
          <FormTextField
            control={control}
            disabled={!hasDefaultPolicy}
            error={errors.name?.message}
            label={"Name: "}
            name="name"
            placeholder="Enter Name"
            size="medium"
            wasValidated={!errors.name}
          />

          <Controller
            control={control}
            name="policyOwnerUserId"
            render={({ field: { onChange, onBlur, value, name } }) => (
              <UserSelect
                error={errors.policyOwnerUserId?.message}
                label="Owner"
                multiple={false}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                size="medium"
                value={value}
                wasValidated={!!policyOwner && !errors.policyOwnerUserId?.value}
              />
            )}
          />

          <FormTextField
            control={control}
            error={errors.description?.message}
            label={"Description: "}
            name="description"
            placeholder="Enter Description"
            size="medium"
            wasValidated={!errors.description}
          />

          <input accept=".json" type="file" {...register("importedFile")} />
        </Form>
      </div>
    </GenericModal>
  );
};

export default ImportNewPolicyModal;
