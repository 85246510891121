import Tooltip from "@atlaskit/tooltip";
import useModal from "../../../../../hooks/useModal";
import { IUserSessionTableItemProps } from "../../../../interfaces/UserSession";
import DeleteUserSessionConfirmationModal from "./DeleteUserSessionConfirmationModal";
import IconButton from "../../../../modules/common/IconButton";
import dayjs from "dayjs";

const UserSessionsTableItem = ({ userSession }: IUserSessionTableItemProps) => {
  const { fullName, idpUsername, isCurrent, lastAccess } = userSession;
  const { visible, show, hide } = useModal();

  return (
    <tr>
      <td>{idpUsername}</td>
      <td>{fullName}</td>
      <td>
        {lastAccess
          ? dayjs(lastAccess).utcOffset(0).format("YYYY-MM-DD HH:mm:ss Z")
          : ""}
      </td>
      <td>
        {!isCurrent && (
          <>
            <div className="table-actions">
              <Tooltip content="Delete" position="top">
                <IconButton
                  aria-label="delete-user-session-btn"
                  icon="fa-trash-o"
                  onClick={show}
                />
              </Tooltip>
            </div>
            {visible && (
              <DeleteUserSessionConfirmationModal
                onClose={hide}
                userSession={userSession}
                visible={visible}
              />
            )}
          </>
        )}
      </td>
    </tr>
  );
};

export default UserSessionsTableItem;
