import React from "react";
import { escapeOnlyNonHtmlTags } from "../../../../helpers/html.helper";
import { PrintViewSubRecordsProps } from "../../../interfaces/DetailsPage";
import { useHistory } from "react-router-dom";
import { useActions } from "../../../../hooks/useActions";
import { downloadFile } from "../../../../helpers/file.helper";

const PrintViewSubRecords = ({
  record,
  getAttachmentsList,
  getAttachmentTable,
}: PrintViewSubRecordsProps) => {
  const history = useHistory();

  const { logout } = useActions();

  const getTitle = (prompt: string) => {
    switch (prompt) {
      case "test-log":
        return "Execution Log";
      case "test-log-step":
        return "Step";
      case "test-step":
        return "Step";
      case "test-step-log":
        return "Step";
      default:
        return prompt;
    }
  };

  const handleOnClickDownload = async (e: any) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLAnchorElement;

    downloadFile({ name: target.download, url: target.href }).catch(
      (response) => {
        if (response.status === 401) {
          logout(history);
        }
      },
    );
  };

  return (
    <>
      <p style={{ pageBreakAfter: "always" }}>&nbsp;</p>

      <div className="pb-3" id="print-view-subrecord">
        <h4>{`${getTitle(
          record.sub_records[0].original_location.item_type,
        )}s`}</h4>

        {record.sub_records.map((subRecord) => (
          <React.Fragment key={subRecord._id}>
            {subRecord.fields.length > 6 ? (
              <div className="container-fluid row">
                <div className="col-xs-12 col-md-6 details-field">
                  <label>
                    {getTitle(subRecord.original_location.item_type)}:
                  </label>
                  {subRecord.name}
                </div>

                {subRecord.fields.map((field) => (
                  <div
                    className="col-xs-12 col-md-6 details-field"
                    key={`${field.label}-${field.value}`}
                  >
                    <label>{field.label}:</label>
                    <span>{field.value}</span>
                  </div>
                ))}

                {subRecord.attachments.length > 0 && (
                  <div className="col-xs-12 col-md-12 details-field">
                    <label>Attachments:</label>
                    {getAttachmentsList(subRecord.attachments)}
                  </div>
                )}
              </div>
            ) : (
              <table className="table table-sm table-bordered">
                <tr className="d-flex">
                  <td className="col-2">
                    <b>{getTitle(subRecord.original_location.item_type)}:</b>
                  </td>
                  <td className="col-10">{subRecord.name}</td>
                </tr>

                {subRecord.fields.map((field) => (
                  <tr className="d-flex" key={`${field.label}-${field.value}`}>
                    <td className="col-2">
                      <b>{field.label}:</b>
                    </td>
                    <td className="col-10">
                      <div
                        className="external-content"
                        dangerouslySetInnerHTML={{
                          __html: escapeOnlyNonHtmlTags(field.value),
                        }}
                      />
                    </td>
                  </tr>
                ))}

                <tr className="d-flex">
                  <td className="col-2">
                    <b>Attachments:</b>
                  </td>
                  <td className="col-10">
                    {subRecord.attachments.length > 0 &&
                      getAttachmentsList(subRecord.attachments)}
                  </td>
                </tr>
              </table>
            )}

            {subRecord.attachments.length > 0 && (
              <div
                className="container-fluid mt-4"
                id="print-view-record-attachments"
              >
                <h6>{subRecord.name} Attachment(s):</h6>

                {subRecord.attachments.map((attachment) => (
                  <table>
                    {attachment.FileType === "image" ? (
                      getAttachmentTable(attachment)
                    ) : (
                      <div className="mb-2">
                        <a
                          download={attachment.name}
                          href={attachment.url}
                          onClick={handleOnClickDownload}
                        >
                          {attachment.name}
                        </a>
                      </div>
                    )}
                  </table>
                ))}
              </div>
            )}

            {subRecord.sub_records.length > 0 && (
              <div className="container-fluid">
                {subRecord.sub_records.map((subRecord) => (
                  <>
                    <table
                      className="table table-sm table-bordered"
                      id={`tblSteps${subRecord.name}`}
                    >
                      <tr className="d-flex">
                        <td className="col-2">
                          <b>
                            {getTitle(subRecord.original_location.item_type)}:{" "}
                          </b>
                        </td>
                        <td className="col-10">{subRecord.name}</td>
                      </tr>

                      <tr className="d-flex">
                        <td className="col-2">
                          <b>Status:</b>
                        </td>
                        <td className="col-10">{subRecord.status}</td>
                      </tr>

                      {subRecord.fields.map((field) => (
                        <tr
                          className="d-flex"
                          key={`${field.label}-${field.value}`}
                        >
                          <td className="col-2">
                            <b>{field.label}:</b>
                          </td>
                          <td className="col-10">
                            <div
                              className="external-content"
                              dangerouslySetInnerHTML={{
                                __html: escapeOnlyNonHtmlTags(field.value),
                              }}
                            />
                          </td>
                        </tr>
                      ))}

                      <tr className="d-flex">
                        <td className="col-2">
                          <b>Attachments:</b>
                        </td>
                        <td className="col-10">
                          {subRecord.attachments.length > 0 &&
                            getAttachmentsList(subRecord.attachments)}
                        </td>
                      </tr>
                    </table>

                    {subRecord.attachments.length > 0 && (
                      <div
                        className="container-fluid mt-4"
                        id="print-view-record-attachments"
                      >
                        <h6>{subRecord.name} Attachment(s):</h6>

                        {subRecord.attachments.map((attachment) => (
                          <table key={attachment._id}>
                            {attachment.FileType === "image" ? (
                              getAttachmentTable(attachment)
                            ) : (
                              <a
                                download={attachment.name}
                                href={attachment.url}
                                onClick={handleOnClickDownload}
                              >
                                {attachment.name}
                              </a>
                            )}
                          </table>
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}

            {/* Page break after each subRecord except last one */}
            {record.sub_records.indexOf(subRecord) !==
              record.sub_records.length - 1 && (
              <p style={{ pageBreakAfter: "always" }}>&nbsp;</p>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default PrintViewSubRecords;
