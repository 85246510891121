import { BreadcrumbsProps } from "../../interfaces/DetailsPage";

const Breadcrumbs = ({ data }: BreadcrumbsProps) => {
  const { fullRecord, record } = data;

  const recordData = record ?? fullRecord;

  return (
    <nav className="row no-print" id="navBreadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">{recordData?.domain}</li>

        <li className="breadcrumb-item">
          {recordData?.locations[0].local_id_fields["Project Name"] ||
            recordData?.locations[0].local_id_fields["Project Key"]}
        </li>

        <li className="breadcrumb-item">
          {recordData?.locations[0].local_id_fields.Key}
        </li>

        {data.assignedTask?.meaning ? (
          <li className="breadcrumb-item">{data.assignedTask.meaning}</li>
        ) : (
          ""
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
