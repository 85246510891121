import GetAppIcon from "@mui/icons-material/GetApp";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import { downloadFile } from "../../../../../helpers/file.helper";
import { AuditRequestRoutes } from "../../../../../constants/audit.constants";
import { useActions } from "../../../../../hooks/useActions";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";

const ExportAuditHistoryButton = () => {
  const { errorStartDate, errorEndDate } = useTypedSelector(
    (x) => x.auditHistory,
  );

  const { logout } = useActions();
  const { startDate, endDate, columnSearchField, sortParams } =
    useTypedSelector((x) => x.auditHistory);

  const getFileName = (startDate: string | null, endDate: string | null) => {
    let downloadFileName = "Audit_History";
    if (startDate) {
      downloadFileName += "_" + startDate.split("T")[0];
    }
    if (endDate) {
      downloadFileName += "_" + endDate.split("T")[0];
    }
    downloadFileName += ".csv";
    return downloadFileName;
  };

  const handleOnClickDownload = async (e: any) => {
    e.preventDefault();

    downloadFile({
      name: getFileName(startDate, endDate),
      params: {
        limit: sortParams.limit,
        order: sortParams.order === "asc" ? "1" : "-1",
        skip: sortParams.offset,
        sort: sortParams.sort,
        filter: JSON.stringify(columnSearchField),
        startDate: startDate ? startDate.split("T")[0] : null,
        endDate: endDate ? endDate.split("T")[0] : null,
      },
      url: AuditRequestRoutes.EXPORT_AUDIT_HISTORY,
    }).catch((response) => {
      if (response.status === 401) {
        logout(history);
      }
    });
  };

  const disabled = !!errorStartDate || !!errorEndDate;

  return (
    <Tooltip title="Export">
      <span>
        <IconButton
          aria-label="export"
          color="primary"
          disabled={disabled}
          id="btn-export-audit-entry"
          onClick={handleOnClickDownload}
        >
          <GetAppIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ExportAuditHistoryButton;
