import { IPrintViewDetailFieldProps } from "../../../interfaces/AdminPage";

const PrintViewDetailField = ({ label, value }: IPrintViewDetailFieldProps) => {
  return (
    <div className="col-xs-12 col-md-6 details-field">
      <label>{label} </label>
      <span>{value}</span>
    </div>
  );
};

export default PrintViewDetailField;
