import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap";
import App from "./App";
import Header from "./components/modules/Header";
import { store } from "./store/store";
import "./index.css";
import Sidebar from "./components/modules/SideBar/Sidebar";
import ScrollToTop from "./components/modules/ScrollToTop";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import {
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider,
} from "@mui/material/styles";
import themeOptions from "@tricentis/aura/constants/themeOptions.js";
import themeOptionsDataGrid from "@tricentis/aura/constants/themeOptionsDataGrid.js";

dayjs.extend(utcPlugin);

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);
const theme = extendTheme(themeOptions, themeOptionsDataGrid);

root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Provider store={store}>
            <Header />
            <Sidebar />
            <App />
            <ScrollToTop />
          </Provider>
        </LocalizationProvider>
      </BrowserRouter>
    </CssVarsProvider>
  </React.StrictMode>,
);
