import React, { PropsWithChildren, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ChangePasswordResponse,
  IFormData,
} from "../../interfaces/ChangePassword";
import {
  AdminRequestRoutes,
  ErrorMessage,
  ProfileRequestRoutes,
} from "../../../constants/general.constants";
import { AdminPageSuccessMessages } from "../../../constants/admin-page.constants";
import {
  IPasswordPolicyFormData,
  IPasswordPolicyResponseData,
} from "../../interfaces/AdminPage";
import Loader from "../../modules/Loader";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePasswordPage = () => {
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
  const [passwordPolicyData, setPasswordPolicyData] =
    React.useState<IPasswordPolicyFormData>({
      minLower: 0,
      minUpper: 0,
      minNumeric: 0,
      minSpecial: 0,
      minLength: 0,
    });

  const getPasswordPolicyData = async () => {
    try {
      const { data }: IPasswordPolicyResponseData = await axios.get(
        AdminRequestRoutes.PASSWORD_POLICY,
      );

      if (data.apiStatusCode === 200) {
        const receivedFormData = {
          minLower: data.apiData.min_lower,
          minUpper: data.apiData.min_upper,
          minNumeric: data.apiData.min_numeric,
          minSpecial: data.apiData.min_special,
          minLength: data.apiData.min_length,
        };
        setPasswordPolicyData(receivedFormData);
        setInitialLoading(false);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const { response } = error as any;
        toast.error(response?.data?.message as string);
      } else if (
        error.message !== ErrorMessage.NEW_REQUEST &&
        error.message !== ErrorMessage.LOGOUT
      ) {
        toast.error(error.message);
      }
    }
  };
  const handleChangePassword = async (formData: IFormData) => {
    const { data }: ChangePasswordResponse = await axios.post(
      ProfileRequestRoutes.CHANGE_PASSWORD,
      formData,
    );

    data.apiStatusCode !== 200
      ? toast.error(data.message)
      : toast.success(AdminPageSuccessMessages.PASSWORD_CHANGE);
  };

  useEffect(() => {
    getPasswordPolicyData();
  }, []);

  return (
    <>
      <main className="container" role="main">
        <div className="container text-center col-6 pt-5 mt-2">
          <div>
            <h5 className="font-weight-normal mb-3">Change your password</h5>
            {initialLoading ? (
              <Loader message="Loading, please wait..." />
            ) : (
              <ChangePasswordForm
                changePassword={handleChangePassword}
                passwordPolicyData={passwordPolicyData}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default ChangePasswordPage;
